import React, { useCallback, useEffect, useState } from "react";
import UpiImage from "../../../assets/upi_logo.png";
import DebitCredit from "../../../assets/cards_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import appConfig from "../../../appconfig.json";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const WalletTopUpTransaction = ({ rechargeAmount }) => {
  const address = appConfig.api_url;
  const [loading, setLoading] = useState(false);
  const [paymentOption, setPaymentOption] = useState("card");
  const [cashSubmitOpenClose, setCashSubmitOpenClose] = useState(false);
  const handleCashSubmitOpenClose = () => {
    setCashSubmitOpenClose(!cashSubmitOpenClose);
  };

  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.walletTopUp.customerDetailsData
  );
  const cashAmount = useSelector(
    (state) => state.moreRewards.walletTopUp.cashAmount
  );
  const differentValues = useSelector(
    (state) =>
      state.moreRewards.walletTopUp.differentRechargeValueData || cashAmount
  );
  const sucessMesage =
    useSelector((state) => state.moreRewards.walletTopUp.rechargeSuccess) || "";
  const inputDisable = useSelector(
    (state) => state.moreRewards.walletTopUp.inputFieldEnable
  );
  const walletRechargeData = useSelector(
    (state) => state.moreRewards?.walletTopUp?.walletRechargeData
  );

  const dispatch = useDispatch();

  function loadPaymentType(module) {
    try {
      if (module === "cash") {
        if (paymentOption !== "cash") {
          window.setTimeout(function () {
            document.getElementById("txtCashAmount").focus();
            document.getElementById("txtCashAmount").select();
          }, 0);
        }
        setPaymentOption("cash");
      } else if (module === "card") {
        if (paymentOption !== "card") {
          window.setTimeout(function () {
            document.getElementById("txtCardAmount").focus();
            document.getElementById("txtCardAmount").select();
          }, 0);
        }
        setPaymentOption("card");
      }
    } catch (err) {
      console.log("walletTopUp-loadPaymentType", err);
    }
  }

  const findIfCardORUpi = (cashValue, typeOfPayment) => {
    let data = differentValues.filter(
      (elem, index) =>
        elem.recharge_amt === cashValue && elem.recharge_type === typeOfPayment
    );
    dispatch(moreRewardsActions.setRechargeDataWalletTopUp(data));
  };
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const basketId = useSelector(
    (state) => state.moreRewards?.walletTopUp.basketID
  );

  const walletRechargetDataTend =
    useSelector(
      (state) => state.moreRewards.walletTopUp.walletRechargeData.tend
    ) || [];

  const totalCartValue = useSelector(
    (state) => state.moreRewards.walletTopUp.totalCartValue
  );
  const [tendAmountValues, setTendAmountValues] = useState(cashAmount || "");

  useEffect(() => {
    setTendAmountValues(cashAmount);
    if (sucessMesage === "BILLSUCCESS") {
      dispatch(moreRewardsActions.setInputFieldEnableWalletTopUp(true));
    }
  }, [cashAmount, sucessMesage]);

  const handleTransactionForWalletTopUp = async (tendtype) => {
    if (Number(tendAmountValues) > Number(cashAmount)) {
      toast.error("Enter a value less than cart Value", {
        autoClose: 1000,
      });
      return;
    }
    setLoading(true);
    try {
      let payload = {
        event: "wallet_paymentconfirm",
        cust_id: strCustomerDetails.cust_id,
        customer_no:
          strCustomerDetails.customer_number ||
          document.getElementById("walletTop.searchCustomer").value,
        cashier_id: cashier_id,
        basket_id: basketId,
        ttype: tendtype === "CASH" ? "CASH" : "CARD",
        sub_ttype: tendtype === "CASH" ? "CASH" : tendtype,
        finalCartAmt: Number(totalCartValue),
        tend_amount: Number(tendAmountValues) || Number(cashAmount),
      };
      let result = await fetch(address + "postDsWalletRecharge/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          dispatch(
            moreRewardsActions.setWalletRechargeDataWalletTopUp(result.data[0])
          );
          dispatch(
            moreRewardsActions.setRechargeSuccesWalletTopUp(result.message)
          );
          dispatch(
            moreRewardsActions.setCashAmountForRechargeWalletTopUp(
              result.data[0].balanceAmt
            )
          );
          dispatch(moreRewardsActions.setResetEnableWalletTopUp(true));
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(result["message"], { autoClose: 2000 });
        console.log(
          "WalletTopUp-postDsWalletRecharge-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      setLoading(false);
      console.log("WalletTopUp-postDsWalletRecharge :", err);
    }
  };

  const keyboardEventListenerNewSalePayment = useCallback(
    (e) => {
      //logic for shortcut key
      if (
        e.altKey &&
        e.code === "KeyM" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        loadPaymentType("card");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyA" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        loadPaymentType("cash");
        return;
      }
    },
    [loadPaymentType, paymentOption]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListenerNewSalePayment);
    return () => {
      document.body.removeEventListener(
        "keydown",
        keyboardEventListenerNewSalePayment
      );
    };
  }, [keyboardEventListenerNewSalePayment]);

  return (
    <>
      <div className="newSalePaymentCard">
        <div className="row" style={{ marginTop: "1.6rem", marginLeft: 1 }}>
          <div
            className="col-md-5 newSaleCards"
            style={{
              width: "12.6rem",
            }}
          >
            <div
              className={
                "card " + (paymentOption === "card" ? "cardactive" : "")
              }
              onClick={() => loadPaymentType("card")}
            >
              <span className="shortcutkeytitle">ALT + M</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <i className="fa fa-credit-card fa-2x ms-1"></i>
                  <span className="title">UPI - DEBIT/CREDIT</span>
                  <div className="input-group input-group-sm ">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      ₹
                    </span>
                    <input
                      type="text"
                      id="txtCardAmount"
                      name="txtCardAmount"
                      className="form-control text-end"
                      aria-label="Sizing example input"
                      value={tendAmountValues}
                      onChange={(event) => {
                        var value = event.target.value;
                        value = value.replace(/[^0-9. ]/g, "");
                        document.getElementById(event.target.id).value = value;

                        let checkVal = value;
                        if (Number(checkVal) > cashAmount) {
                          toast.error(
                            `Recharge Amount should not be greater than cart amount ${cashAmount}`,
                            {
                              autoClose: 1500,
                            }
                          );
                          setTendAmountValues(cashAmount);
                          return;
                        }
                        setTendAmountValues(value);
                      }}
                      onKeyDown={(event) => {
                        if (event.target.value.length >= 1) {
                          if (event.key === "Enter") {
                          }
                        }
                      }}
                      disabled={inputDisable}
                      autoComplete="off"
                    />
                  </div>
                  {paymentOption === "card" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        marginTop: "12px",
                      }}
                    >
                      <button
                        className="btn d-flex justify-content-center align-items-center bg-white w-100"
                        onClick={() => {
                          handleTransactionForWalletTopUp("UPI");
                        }}
                        disabled={inputDisable}
                      >
                        <img
                          alt="Upi"
                          src={UpiImage}
                          style={{
                            height: "auto",
                            width: "100px",
                          }}
                        />
                      </button>

                      <button
                        onClick={() => {
                          handleTransactionForWalletTopUp("CARD");
                        }}
                        disabled={inputDisable}
                        className="btn d-flex justify-content-center align-items-center bg-white w-100"
                      >
                        <img
                          alt="Debit Card"
                          src={DebitCredit}
                          style={{
                            height: "auto",
                            width: "100px",
                          }}
                        />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ------------------ */}
          <div
            className="col-md-5 newSaleCards"
            style={{
              width: "12.6rem",
            }}
          >
            <div
              className={
                "card " + (paymentOption === "cash" ? "cardactive" : "")
              }
              onClick={() => loadPaymentType("cash")}
            >
              <span className="shortcutkeytitle">ALT + A</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <div className="row">
                    <div className="col-12">
                      <i className="fa fa-money-bill fa-2x ms-1"></i>
                      <span className="title text-uppercase">CASH</span>
                      <div className="input-group input-group-sm">
                        <span
                          className="input-group-text"
                          id="inputGroup-sizing-sm"
                        >
                          ₹
                        </span>
                        <input
                          type="text"
                          id="txtCashAmount"
                          name="txtCashAmount"
                          className="form-control text-end"
                          aria-label="Sizing example input"
                          value={tendAmountValues}
                          tabIndex={1}
                          onChange={(event) => {
                            if (paymentOption === "cash") {
                              var value = event.target.value;
                              value = value.replace(/[^0-9. ]/g, "");
                              document.getElementById(event.target.id).value =
                                value;
                              let checkVal = value;
                              if (Number(checkVal) > cashAmount) {
                                toast.error(
                                  `Recharge Amount should not be greater than cart amount ${cashAmount}`,
                                  {
                                    autoClose: 1500,
                                  }
                                );
                                setTendAmountValues(cashAmount);
                                return;
                              }
                              setTendAmountValues(value);
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.target.value.length >= 1) {
                              if (event.key === "Enter") {
                              }
                            }
                          }}
                          disabled={inputDisable}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  {paymentOption === "cash" ? (
                    <div className="row" style={{ paddingTop: "10px" }}>
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={inputDisable}
                          onClick={() => {
                            // findIfCardORUpi(cashAmount, "CASH");
                            // handleTransactionForWalletTopUp("CASH");
                            handleCashSubmitOpenClose();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{
          marginLeft: "0.1rem",
        }}
      >
        <div className="col-4" style={{ paddingTop: "20px" }}>
          {walletRechargetDataTend.length > 0 ? (
            <table className="table table-bordered table-sm">
              <thead className="table-dark">
                <tr>
                  <th colSpan={2} className="title text-center">
                    Payment Summary
                  </th>
                </tr>
              </thead>
              <tbody>
                {walletRechargetDataTend.map((tend, index) => {
                  return (
                    <tr key={tend.ttype} style={{ fontSize: "1.1rem" }}>
                      <td>{tend.ttype} ( ₹ )</td>
                      <td className="text-end">{tend.tend_amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>

      {loading ? (
        <div
          className=" forWallet  align-items-center justify-content-center"
          style={{ zIndex: "1035" }}
        >
          <div
            style={{
              position: "fixed",
              top: "35%",
              left: "50%",
              width: "30em",
              height: "18em",
              marginLeft: "-15em",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  placeSelf: "center",
                  marginLeft: "1.6rem",
                }}
              >
                Transaction is in progress, please do not press F5 or do not
                click Back or close the Browser
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal
        show={cashSubmitOpenClose | false}
        className="modal-trans"
        centered
      >
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <div>
                  <div className="modal-title w-100">
                    Remaining Bill Amount is{" "}
                    <span
                      style={{
                        fontSize: "1.6rem",
                        color: "#f04e23",
                      }}
                      className="header-flash-button text-end"
                    >
                      {walletRechargeData?.balanceAmt
                        ? walletRechargeData?.balanceAmt
                        : walletRechargeData?.balanceAmt === 0
                        ? 0
                        : totalCartValue}
                    </span>
                  </div>
                </div>
                <h5 className="modal-title w-100">CONFIRM RECHARGE WALLET ?</h5>
              </div>
              <div className="modal-footer justify-content-center">
                <>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setCashSubmitOpenClose(!cashSubmitOpenClose);
                      }}
                    >
                      No
                    </button>
                  </div>
                  <button
                    id="yesButton"
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onClick={() => {
                      findIfCardORUpi(cashAmount, "CASH");
                      handleTransactionForWalletTopUp("CASH");
                      setCashSubmitOpenClose(!cashSubmitOpenClose);
                    }}
                  >
                    Yes
                  </button>
                </>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletTopUpTransaction;
