import { createSlice } from "@reduxjs/toolkit";

const loginInitialState = {
  isAuthenticated: false,
  userDetailsData: [],
  isUpdate: false,
  cashierMetricsData: {},
  locValue: "1904_01",
};

const loginSlice = createSlice({
  name: "loginDs",
  initialState: loginInitialState,
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setUserDetailsData(state, action) {
      state.userDetailsData = action.payload;
    },
    setIsUpdate(state, action) {
      state.isUpdate = action.payload;
    },
    setCashierMetricsData(state, action) {
      state.cashierMetricsData = action.payload;
    },
    setLocValue(state, action) {
      state.locValue = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;
export default loginSlice.reducer;
