import React from "react";

const Loader = () => {
  return (
    <div className="overlay d-flex align-items-center justify-content-center">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <div style={{ color: "#ffffff", fontSize: "12px" }}>
        Loading... Please Wait
      </div>
    </div>
  );
};

export default Loader;
