import { useSelector } from "react-redux";

// Example selectors

// You can add more selectors as needed

// Custom hook to access multiple selectors easily
export const useCommonSelectors = () => {
  const locAndPosId = useSelector((state) => state.login.locValue);

  const newValue = locAndPosId.split("_");
  const loc = Number(newValue[0]) || 0;
  const pos_id_mpos = Number(newValue[0] + newValue[1]) || 0;

  // Add more selector hooks as needed

  return {
    loc,
    pos_id_mpos,
    // Add more selectors here as needed
  };
};
