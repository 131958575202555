import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { commonActions } from "../store/commonStore";
import { loginActions } from "../store/login";

const ModalWelcomeMessage = (props) => {
  const [arrUserDetails, setArrUserDetails] = useState(
    props.strUserDetails ?? []
  );
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    if (arrUserDetails["customMsgUrl"] === "") {
      setTimeout(function () {
        setShowCloseButton(true);
      }, 5000);
    }
  }, []);

  function btnModalCloseClick() {}

  const openMessageDetails = (url) => {
    setShowCloseButton(true);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleClose = () => {
    props.strSetIsWelcomeMsgViewed(true);
    props.button_clicked();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.strSetIsWelcomeMsgViewed(true);
          props.button_clicked();
        }}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 welcomeMsgLabel">
              <div
                dangerouslySetInnerHTML={{
                  __html: arrUserDetails["customMsg"],
                }}
              ></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {showCloseButton ? (
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "120px" }}
                  role="button"
                  onClick={handleClose}
                >
                  Ok
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 text-end">
              {arrUserDetails["customMsgUrl"] !== "" ? (
                <>
                  <button
                    className="welcomeMsgLinkButton"
                    onClick={() =>
                      openMessageDetails(arrUserDetails["customMsgUrl"])
                    }
                  >
                    View More Details
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    strSetIsWelcomeMsgViewed: (isViewed) =>
      dispatch(commonActions.setIsWelcomeMsgViewed(isViewed)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalWelcomeMessage);
