import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { newSaleActions } from "../store/newSaleStore";
import UserRegister from "../userRegister";
import appConfig from "../../appconfig.json";
import { customerActions } from "../store/customerStore";
import axios from "axios";
import { useCommonSelectors } from "../../Common/selector";

const NewSaleYesNoModal = ({
  heading,
  handleClose,
  openOrClose,
  mainFunction,
  typeOfVoidPayment,
  voidFunction,
  cashOption,
  voidForMorePoints,
}) => {
  const typeahead = useRef(null); //unknown
  const deliveryMode = useRef(null); //unknown
  const address = appConfig.api_url;
  const cashierId = useSelector(
    (state) => state.login?.userDetailsData.empCode
  );
  const posID = useSelector((state) => state.login?.userDetailsData.pos_id);
  const [loading, setLoading] = useState(false);
  //Dispatch is used for handling the function written in reducer
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(newSaleActions.setNewSaleCurrentStatus("newSaleBilling"));
  };
  const { loc, pos_id_mpos } = useCommonSelectors();

  //Payment summary has the details of type of payments customer has done
  const paymentSummary = useSelector((state) => state.newSale.itemsListTend);

  const billBalanceAmount = useSelector(
    (state) => state?.newSale?.itemsBillBalanceAmount
  );

  //Checking if customer is registered or not
  //If not show him the register page and mke him register
  const customerDetailsData = useSelector(
    (state) => state.customer.customerDetailsData
  );
  //checking customer ends here

  //Setting in which type of payment do user need
  const [typeOfPaymentUserNeed, setTypeofPaymentUserNeed] = useState("");
  const handleTypeOfPaymentUserNeed = (value) => {
    setTypeofPaymentUserNeed(value);
  };

  //Get user Details function for changing unRegistered to Register
  //required values from redux
  const strNewSaleItemsBasketId = useSelector(
    (state) => state.newSale?.itemsbasketId
  );

  const getItemsDetails = (payload) => {
    try {
      axios.post(address + "postDsItemSubmit/", payload).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.status === "success") {
            if (response.data.message === "FINAL") {
              response = response.data;
              dispatch(
                newSaleActions.setAvailableDeliverySlot(
                  response["data"][0]["hdAvailSlots"]
                )
              );
              response["data"][0]["sub_tran_type"] === "HOMEDE" &&
              customerDetailsData.isCustExisting
                ? dispatch(
                    newSaleActions.setSelectedDeliverySlot(
                      response["data"][0]["slot"]
                    )
                  )
                : dispatch(newSaleActions.setSelectedDeliverySlot(""));
              dispatch(
                newSaleActions.setTranType(response["data"][0]["sub_tran_type"])
              );
              dispatch(
                newSaleActions.setCancelAllowed(
                  response["data"][0]["isEdcCancelAllowed"]
                )
              );
              dispatch(
                newSaleActions.setItemsBasketId(
                  response["data"][0]["basket_id"]
                )
              );
              dispatch(
                newSaleActions.setCardTransactionStatus(
                  response["data"][0]["statusCheck"]
                )
              );
              dispatch(
                newSaleActions.setItemsCartValue(
                  response["data"][0]["totalCart"]
                )
              );
              dispatch(
                newSaleActions.setItemsTotalDiscount(
                  response["data"][0]["totalDiscount"]
                )
              );
              dispatch(
                newSaleActions.setItemsTotalDiscount(
                  response["data"][0]["finalCart"]
                )
              );
              dispatch(
                newSaleActions.setItemsBillBalanceAmount(
                  response["data"][0]["balanceAmt"]
                )
              );
              dispatch(
                newSaleActions.setItemsListData(response["data"][0]["items"])
              );
              dispatch(
                newSaleActions.setRecommendOffer(
                  response["data"][0]["recomOffers"]
                )
              );
              dispatch(
                newSaleActions.setSlabOfferDesc(
                  response["data"][0]["slab_offer_description"]
                )
              );
              dispatch(
                newSaleActions.setCouponCnt(
                  response["data"][0]["eligCouponsCnt"]
                )
              );
              if (response["data"][0]["tendAmt"] > 0) {
                dispatch(
                  newSaleActions.setNewSaleCurrentStatus("newSalePayment")
                );
                dispatch(
                  newSaleActions.setItemsListTend(response["data"][0]["tend"])
                );
              }
            }
          } else {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        } else {
          console.log(
            "newSaleYesNoModal.getItemsDetails",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("newSaleYesNoModal.getItemsDetails : ", err);
    }
  };

  async function getCustomerDetails(forNo) {
    try {
      var custid = document.getElementById("phoneNumber").value;
      let item = {
        searchText: custid,
        basket_id:
          strNewSaleItemsBasketId?.length > 0 ? strNewSaleItemsBasketId : "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let result = await fetch(appConfig.api_url + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          dispatch(customerActions.setIsValidCustomer(true));
          dispatch(customerActions.setCustomerDetailsData(result["data"][0]));
          // props.setStrCustomerDetailsData(result["data"][0]);
          dispatch(customerActions.setHomeDelivery("N"));
          // props.setHomeDelivery("N");
          document.getElementById("deliverySlot").select = "";
          dispatch(newSaleActions.setSelectedDeliverySlot(""));
          // props.setSelectedDeliverySlot("");
          dispatch(newSaleActions.setSelectedDeliverySlot(""));

          if (strNewSaleItemsBasketId.length > 0) {
            getItemsDetails({
              event: "basket_getdata",
              basket_id: strNewSaleItemsBasketId,
              loc: loc,
              pos_id: pos_id_mpos,
            });

            if (!result.data[0].isCustExisting) {
              dispatch(customerActions.setHomeDelivery("N"));
              document.getElementById("deliverySlot").select = "";

              dispatch(newSaleActions.setSelectedDeliverySlot(""));
            }
          }
          setTimeout(() => typeahead.current.focus(), 1);

          if (result.data[0].isCustExisting) {
            dispatch(
              customerActions.setHomeDelivery(result.data[0]["allowHomDel"])
            );
            setTimeout(() => deliveryMode.current.focus(), 1);
          }
        } else {
          dispatch(customerActions.setIsValidCustomer(false));
          dispatch(customerActions.setCustomerDetailsData([]));
          dispatch(customerActions.setHomeDelivery("N"));
          dispatch(newSaleActions.setSelectedDeliverySlot(""));
          document.getElementById("deliverySlot").select = "";
          toast.error(result["message"], {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "NewSaleYesNoModal-getCustomerDetails-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("NewSaleYesNoModal-getCustomerDetails :", err);
    }
  }
  //unRegistered to Register check ends here

  //Total Amount
  let totalAmount = 0;
  paymentSummary.map((elem, index) => {
    totalAmount += elem.tend_amount;
  });

  //submit function for CASH OR EDC
  const handleSubmitForCashOrEDC = () => {
    setLoading(true);

    let value = 0;
    paymentSummary.map((elem) => {
      if (elem.ttype === "POINTS") {
        value += elem.tend_amount;
      }
    });

    if (value > 0) {
      handleSubmitForMorePoints(value);
    }

    const newValueForPayment = totalAmount - value;

    try {
      const payload = {
        cashier_id: cashierId,
        basket_id: strNewSaleItemsBasketId,
        ttype: paymentSummary[0].ttype,
        tend_amount: -newValueForPayment,
      };

      axios
        .post(address + "postDsBasketPartialVoid/", payload)
        .then((response) => {
          if (response["data"]["status"] === "success") {
            toast.success(response["data"]["message"], {
              autoClose: 1500,
            });
            handleTypeOfPaymentUserNeed("");
            setTimeout(() => {
              handleClose();
              handleBack();
              voidFunction();
              mainFunction();
              setLoading(false);
            }, 1500);
          } else {
            toast.error("Error while submitting the void payment", {
              autoClose: 1500,
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Something wrong with", err);
          setLoading(false);
        });
    } catch (err) {
      console.log("newSaleYesNoModal.handleSubmit : ", err);
      setLoading(false);
    }
  };
  //submit function for CASH ends here

  // Submit function for Morepoints
  const handleSubmitForMorePoints = async (value, onlyMorePoints) => {
    setLoading(true);
    try {
      const payload = {
        customer_number: customerDetailsData.customer_number,
        morepoints: value,
        reference_no: strNewSaleItemsBasketId,
      };
      const { data } = await axios.post(
        address + "postMorePointsRefund/",
        payload
      );
      if (data.status === "success") {
        toast.success(data.message, {
          autoClose: 1500,
        });
        if (onlyMorePoints) {
          handleTypeOfPaymentUserNeed("");

          setTimeout(() => {
            handleClose();
            handleBack();
            setLoading(false);
            voidFunction();
          }, 1500);
        }
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("handleSubmitForMorePoints.NewSaleYesNoModal", error);
      setLoading(false);
    }
  };
  //Common function for postBasketPartialVoid
  const postBasketPartailVoidFn = (payload) => {
    axios
      .post(address + "postDsBasketPartialVoid/", payload)
      .then((response) => {
        if (response["data"]["status"] === "success") {
          setLoading(true);
          handleSubmitForWallet();
        }
      })
      .catch((err) => {
        console.log("NewSaleYesNoModal-postDsBasketPartialVoid", err);
      });
  };
  // Common Function ends here

  //Submit function for Wallet

  const handleSubmitForWallet = () => {
    setLoading(true);
    let value = 0;
    paymentSummary.map((elem, index) => {
      if (elem.ttype === "POINTS") {
        value += elem.tend_amount;
      }
    });
    if (value > 0) {
      handleSubmitForMorePoints(value);
    }
    try {
      const payload = {
        cust_id: customerDetailsData.cust_id,
        customer_number: customerDetailsData.customer_number,
        pos_id: posID,
        tran_amt: totalAmount - value,
        tran_ref_number: strNewSaleItemsBasketId,
      };

      axios
        .post(address + "postCustVoidWalletBalance/", payload)
        .then((response) => {
          if (response["data"]["status"] === "success") {
            toast.success(
              response["data"]["message"] +
                " - " +
                response["data"]["data"][0].wallet_balance,
              {
                autoClose: 1500,
              }
            );
            handleTypeOfPaymentUserNeed("");

            setTimeout(() => {
              handleClose();
              handleBack();

              mainFunction();
              setLoading(false);
              voidFunction();
            }, 1500);
          } else {
            setLoading(false);
            toast.success(response["data"]["message"], {
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          toast.error("Something wrong with", err);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);

      console.log("handleSubmitForWallet.NewSaleYesNoModal", err);
      setLoading(false);
    }
  };
  //Submit function for Wallet ends here

  //Main submit function
  const [checkUserRegister, setCheckUserRegister] = useState(false);
  const handleMainSubmit = () => {
    if (typeOfPaymentUserNeed === "WALLET") {
      if (!customerDetailsData.isCustExisting) {
        setCheckUserRegister(!checkUserRegister);
      } else {
        // handleSubmitForWallet();
        const payload = {
          cashier_id: cashierId,
          basket_id: strNewSaleItemsBasketId,
          ttype: "WALLET",
          tend_amount: -totalAmount,
        };
        postBasketPartailVoidFn(payload);
      }
    } else if (
      typeOfPaymentUserNeed === "CASH" ||
      typeOfPaymentUserNeed === "CARD"
    ) {
      handleSubmitForCashOrEDC();
    }
  };
  //Main submit function ends here

  //This is keyboard handler fucntion
  const keyboardEventListenerNewSalePayment = useCallback(
    (e) => {
      //logic for shortcut key
      if (
        (heading === "Void" ||
          heading === "Hold" ||
          heading === "Payment" ||
          heading === "VoidForPayment") &&
        e.altKey &&
        e.code === "KeyZ" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleClose(!openOrClose);
        return;
      }
    },
    [openOrClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListenerNewSalePayment);
    return () => {
      document.body.removeEventListener(
        "keydown",
        keyboardEventListenerNewSalePayment
      );
    };
  }, [keyboardEventListenerNewSalePayment]);

  useEffect(() => {
    document.getElementById("yesButton").focus();
  }, []);
  //keyboard handler ends here

  const isMorePointsPresent = paymentSummary.some(
    (obj) => obj.ttype === "POINTS"
  );
  return (
    <>
      <Modal show={openOrClose | false} className="modal-trans" centered>
        {loading ? (
          <div className="forVoidInPayment  align-items-center justify-content-center">
            <div
              style={{
                position: "fixed",
                top: "35%",
                left: "50%",
                width: "30em",
                height: "18em",
                marginLeft: "-17em",
              }}
            >
              <div style={{ display: "inline-flex" }}>
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: "18px",
                    placeSelf: "center",
                    marginLeft: "1.6rem",
                  }}
                >
                  Transaction is in progress, please do not press F5 or do not
                  click Back or close the Browser
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                {heading === "VoidForPayment" ? (
                  <div className="modal-title w-100">
                    <div>Do You want to Void the basket</div>
                    {isMorePointsPresent &&
                    paymentSummary.length > 1 &&
                    typeOfVoidPayment ? (
                      <span
                        style={{
                          color: "red",
                        }}
                        className="header-flash-button text-end text-red"
                      >
                        Your More Points will be credited back to your More
                        Points Wallet
                      </span>
                    ) : (
                      isMorePointsPresent &&
                      paymentSummary.length === 1 && (
                        <span
                          style={{
                            color: "red",
                          }}
                          className="header-flash-button text-end text-red"
                        >
                          Your More Points will be credited back to you More
                          Points Wallet
                        </span>
                      )
                    )}
                  </div>
                ) : (
                  <>
                    {cashOption === "CASH" && billBalanceAmount >= 2000 && (
                      <div>
                        <div className="modal-title w-100">
                          Bill Amount is More than{" "}
                          <span
                            style={{
                              fontSize: "1.6rem",
                              color: "#f04e23",
                            }}
                            className="header-flash-button text-end"
                          >
                            {2000}
                          </span>
                        </div>
                      </div>
                    )}

                    <h5 className="modal-title w-100">Confirm {heading}?</h5>
                  </>
                )}
              </div>
              <div className="modal-footer justify-content-center">
                {typeOfVoidPayment ? (
                  <>
                    <div
                      style={{
                        display: "flex",

                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          handleTypeOfPaymentUserNeed("WALLET");
                        }}
                      >
                        WAllET
                      </button>

                      <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={paymentSummary.some(
                          (obj) =>
                            obj.ttype === "CASH" || obj.ttype === "WALLET"
                        )}
                        onClick={() => {
                          handleTypeOfPaymentUserNeed("CARD");
                        }}
                      >
                        CARD
                      </button>

                      <button
                        id="yesButton"
                        type="button"
                        autoFocus={true}
                        disabled={paymentSummary.some(
                          (obj) => obj.ttype === "EDC" || obj.ttype === "WALLET"
                        )}
                        className="btn btn-secondary"
                        onClick={() => {
                          handleTypeOfPaymentUserNeed("CASH");
                        }}
                      >
                        CASH
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          handleClose(!openOrClose);
                        }}
                      >
                        No / (ALT + Z)
                      </button>
                    </div>
                    <button
                      id="yesButton"
                      type="button"
                      autoFocus={true}
                      className="btn btn-danger"
                      onClick={() => {
                        if (voidForMorePoints) {
                          let value = 0;
                          paymentSummary.map((elem, index) => {
                            if (elem.ttype === "POINTS") {
                              value += elem.tend_amount;
                            }
                          });
                          handleSubmitForMorePoints(value, true);
                          return;
                        }
                        mainFunction();
                        if (heading !== "VoidForPayment") {
                          handleClose(!openOrClose);
                        }
                      }}
                    >
                      Yes
                    </button>
                  </>
                )}
              </div>
              {typeOfVoidPayment && (
                <>
                  <div className="row justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary col-md-5"
                      style={{}}
                      onClick={() => {
                        handleClose();
                        mainFunction();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary col-md-5"
                      disabled={
                        typeOfPaymentUserNeed === "" || loading === true
                      }
                      onClick={() => {
                        if (typeOfPaymentUserNeed === "") {
                          toast.error(
                            "Please select type or return payment user requested",
                            {
                              autoClose: 2000,
                            }
                          );
                        } else {
                          handleMainSubmit();
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <p
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#f04e23",
                      marginTop: "20px",
                    }}
                  >
                    You have to return {totalAmount} ( ₹ )
                  </p>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {checkUserRegister ? (
        <UserRegister
          show={checkUserRegister}
          button_clicked={() => {
            setCheckUserRegister(!checkUserRegister);
          }}
          customerValidate={""}
          // customerNumber={props.strCustomerDetails.customer_number}
          // disableField={false}
          getCustomerName={getCustomerDetails}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default NewSaleYesNoModal;
