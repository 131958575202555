import React, { useState } from "react";
import appConfig from "../../../appconfig.json";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import CustomerCheck from "../../../Common/CustomerCheck/CustomerCheck";
import axios from "axios";

const CheckWalletBalance = () => {
  const address = appConfig.api_url;
  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.customerDetailsData
  );
  const strWalletBalanceDetails = useSelector(
    (state) => state.moreRewards.walletBalance
  );
  const strMorePointsBalanceDetails = useSelector(
    (state) => state.moreRewards.morePointsBalance
  );
  const [loadingCustData, setLoadingCustData] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  async function getWalletBalance(payload) {
    setLoading(true);
    morePointsGetBalance(payload.customer_number);
    try {
      let response = await fetch(address + "wallet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          dispatch(moreRewardsActions.walletBalanceData(response["data"][0]));
          setLoading(false);
        } else {
          toast.error(response["message"], {
            autoClose: 2500,
          });
          setLoading(false);
        }
      } else {
        console.log(
          "walltetCheckWalletBalance-wallet-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("walltetCheckWalletBalance-wallet :", err);
    }
  }

  const morePointsGetBalance = async (number) => {
    const payload = {
      customer_number: number,
    };
    try {
      const { data } = await axios.post(address + "getCustMorePoints", payload);
      if (data.status === "success") {
        dispatch(moreRewardsActions.morePointBalanceData(data.data[0]));
        setLoading(false);
      } else {
        toast.error("Cannot get Usable Point Balance", {
          autoClose: 1500,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("moreRewardsCheckWalletBalance-getCustMorePoints", error);
    }
  };

  return (
    <>
      <CustomerCheck
        showCustomerName={true}
        extraFunctionCall={getWalletBalance}
        loader={loadingCustData}
        setLoader={setLoadingCustData}
        showCustId={true}
      />

      {strCustomerDetails["isCustExisting"] && (
        <Box className="row">
          {loading ? (
            <Box display={"flex"} gap={2}>
              <Stack
                className="col-md-4"
                mt={2}
                p={2}
                sx={{
                  border: "1px solid lightGrey",
                  color: "grey",
                }}
              >
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </Stack>
              <Stack
                className="col-md-4"
                mt={2}
                p={2}
                sx={{
                  border: "1px solid lightGrey",
                  color: "grey",
                }}
              >
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </Stack>
            </Box>
          ) : (
            <Box display={"flex"} gap={2}>
              <Box
                className="col-md-4"
                mt={2}
                p={2}
                sx={{
                  border: "1px solid lightGrey",
                  color: "grey",
                }}
              >
                <Typography color={"#f04e23"} textAlign={"center"} mb={2}>
                  Wallet Balance
                </Typography>

                <Typography>
                  Wallet Balance :{" "}
                  <span
                    style={{
                      color: "#f04e23",
                    }}
                  >
                    {strWalletBalanceDetails["wallet_bal"] || 0}
                  </span>
                </Typography>
                <Typography>
                  Cashback :
                  <span
                    style={{
                      color: "#f04e23",
                    }}
                  >
                    {" "}
                    {strWalletBalanceDetails["cashback"] || 0}
                  </span>
                </Typography>
              </Box>
              <Box
                className="col-md-4"
                mt={2}
                p={2}
                sx={{
                  border: "1px solid lightGrey",
                  color: "grey",
                }}
              >
                <Typography color={"#f04e23"} textAlign={"center"} mb={2}>
                  more+ Points Balance
                </Typography>

                <Typography>
                  Points :
                  <span
                    style={{
                      color: "#f04e23",
                    }}
                  >
                    {" "}
                    {strMorePointsBalanceDetails.balance_points || 0}
                  </span>
                </Typography>

                <Typography>
                  1 more+ Point :
                  <span
                    style={{
                      color: "#f04e23",
                    }}
                  >
                    {" "}
                    {/* {strWalletBalanceDetails["cashback"]} */}
                    Rs.1
                  </span>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CheckWalletBalance;
