import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import partyPopperImage from "../../assets/party_popper.png";

import appConfig from "../../appconfig.json";
import { newSaleActions } from "../store/newSaleStore";
import { customerActions } from "../store/customerStore";
import ModalGstSelect from "../Modal/ModalGstSelect";
import AskCustomerModal from "./AskCustomerModal";
import axios from "axios";
import { moreRewardsActions } from "../store/MoreRewards/MoreRewardsStore";
import { useCommonSelectors } from "../../Common/selector";

const NewSaleComplete = (props, fortuneEligble) => {
  const customerData = props.strCustomerDetails;

  const [extraGstNumbersState, setExtraGstNumbersState] = useState([]);
  const [disablePrintButton, setDisablePrintButton] = useState(false);

  const [gstNumSelect, setGstNumSelect] = useState("");
  const [showSelectGstNumModal, setShowGstNumModal] = useState(false);

  const [askCustModel, setAskCustModel] = useState(false);
  const handleShowGstModal = () => {
    setShowGstNumModal(!showSelectGstNumModal);
    if (gstNumSelect !== "") {
      postGstIrnFunction();
    }
  };
  const SMorHM = props.strUserDetails.loc_type;
  const { loc, pos_id_mpos } = useCommonSelectors();

  function btnTransactionCompleteClick() {
    props.setStrIsValidCustomer(false);
    props.setHomeDelivery("N");
    props.setStrCustomerDetailsData([]);
    props.setStrNewSaleCurrentStatus("newSaleBilling");
    props.setStrItemsBasketId("");
    props.setCardTransactionStatus(false);
    props.setTranType("OFFLIN");
    props.setSelectedDeliverySlot("");
    props.setAvailableDeliverySlot([]);
    props.setStrItemsCartValue("0");
    props.setStrItemsTotalDiscount("0");
    props.setStrItemsFinalCartValue("0");
    props.setStrTotalFoodValue(0);
    props.setStrTotalNonFoodValue(0);
    props.setStrItemsCouponNo("");
    props.setStrItemsBillNo("");
    props.setStrItemsBalanceAmount(0);
    props.setStrItemsBillTendAmount(0);
    props.setStrItemsListData([]);
    props.setStrItemsListTend([]);
    props.setRecommendOffer([]);
    props.setSlabOfferDesc("");
    props.setCouponCnt(0);
    props.setCancelAllowed("N");
    props.setIfCashOnDelivery(false);
    props.setBillSuccessOrBalance("");
    setDisablePrintButton(false);
    props.setMorePointsBalance(0);
    props.setMorePointsUsableBalance(0);
    props.setCustomerTier("");
    document.getElementById("searchItem.txtCustItem").value = "";
  }

  const printBill = async () => {
    setDisablePrintButton(true);
    try {
      let payload = {
        bill_no: props.strNewSaleItemsBillNo,
        gsttin_no: gstNumSelect,
        print_type: "NS",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "getDsReceiptPrint/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          toast.success(response["message"], {
            autoClose: 1000,
          });
          // btnTransactionCompleteClick(); //Disable this
          setAskCustModel(true); //Enable this for customer feedback
        } else {
          setDisablePrintButton(false);
          toast.error(response["message"], {
            autoClose: 1000,
          });
        }
      } else {
        setDisablePrintButton(false);
        console.log(
          "newSaleComplete-printBill-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleComplete-printBill :", err);
    }
  };
  //just to check if person is eleigible for fortune wheel
  const [ifEligibleFortune, setIfEligibleFortune] = useState("N");
  const [changeFortune, setChangeFortune] = useState({});

  const homeReceiptPrint = async () => {
    try {
      let payload = {
        bill_no: props.strNewSaleItemsBillNo,
        gsttin_no: gstNumSelect,
        print_type: "NS",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(
        appConfig.api_url + "getDsHomeDelChallanPrint/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          toast.success(response["message"], {
            autoClose: 1000,
          });
          if (Object.keys(changeFortune).length !== 0) {
            postFortuneDetails();
          }
        } else {
          if (Object.keys(changeFortune).length !== 0) {
            postFortuneDetails();
          }
          toast.error(response["message"], {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "newSaleComplete-homeReceiptPrint-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleComplete-homeReceiptPrint :", err);
    }
  };

  const sendEbill = async (printStatus) => {
    if (props.strCustomerDetails.customer_number === "") {
      return;
    }
    try {
      let payload = {
        bill_no: props.strNewSaleItemsBillNo,
        print_status: printStatus,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "sendEbill/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
      } else {
        console.log(
          "newSaleComplete-sendEbill-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleComplete-sendEbill :", err);
    }
  };

  //Adding function for super tax api
  const postGstIrnFunction = async () => {
    if (gstNumSelect === "") {
      return;
    }
    let today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const format1 = dd + "/" + mm + "/" + yyyy;

    try {
      let payload = {
        bill_no: props.strNewSaleItemsBillNo,
        gsttin_no: gstNumSelect,
        customer_number: props.strCustomerDetails.customer_number || "",
        pos_id: props.strUserDetails.pos_id || "",
        b_date: format1,
        cashier_id: props.strUserDetails.empCode || "",
      };
      let response = await fetch(appConfig.api_url + "postGstIrn/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        // console.log(response);
      } else {
        console.log(
          "newSaleComplete-postGstIrn-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleComplete-postGstIrn :", err);
    }
  };
  // Adding a function for Fortune Eligibility check
  const [loadingFortune, setLoadingFortune] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const checkCustEligbleFortune = async () => {
    setLoadingFortune(true);
    const payload = {
      bill_value: props.strItemsFinalCartValue,
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "getCustomerFortuneEligible",
        payload
      );
      if (data.status === "success") {
        setIfEligibleFortune(data.data[0].eligible);
        setLoadingFortune(false);
      } else {
        setIfEligibleFortune(data.data[0].eligible);
        setLoadingFortune(false);
      }
    } catch (error) {
      console.log("newSaleComplete-getCustomerFortuneEligible :", error);
      setLoadingFortune(false);
    }
  };
  const getWheelOptions = async () => {
    try {
      const { data } = await axios.get(
        appConfig.api_url + "getFortuneWheelOptions"
      );
      if (data.status === "success") {
        setOfferList(data.data);
      } else {
        setOfferList(data.data);
      }
    } catch (error) {
      console.log("newSaleComplete-getFortuneWheelOptions :", error);
    }
  };

  const postFortuneDetails = async () => {
    const payload = {
      bill_no: props.strNewSaleItemsBillNo || "",
      cashier_id: props.strUserDetails.empCode || "",
      loc: props.strUserDetails.loc_id || "",
      pos_id: props.strUserDetails.pos_id || "",
      option: changeFortune.option || "",
      offer_no: changeFortune.offer_no,
      mobile_no: props.strCustomerDetails.customer_number || "",
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postFortuneBillDetails",
        payload
      );

      if (data.status === "success") {
        postAnotherFortuneDetails();
      }
    } catch (error) {
      console.log("newSaleComplete-postFortuneBillDetails :", error);
    }
  };

  const postAnotherFortuneDetails = async () => {
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postFortuneDetails/"
      );
    } catch (error) {
      console.log("newSaleComplete-postFortuneDetails :", error);
    }
  };

  const extraGstNumbers = async () => {
    const payload = {
      searchText: customerData.customer_number,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "getDsCustomer/",
        payload
      );
      if (data.status === "success") {
        props.setNewGstData(data.data[0].gstData);
      }
    } catch (error) {
      console.log("newSaleComplete-getDsCustomer :", error);
    }
  };

  const getMethodForExtraNumbers = async () => {
    try {
      const { data } = await axios.get(
        appConfig.api_url + `getCustGSTDetails/${customerData.customer_number}`
      );
      if (data.status === "success") {
        setExtraGstNumbersState(data.data);
      }
    } catch (error) {
      console.log("newSaleComplete-getCustGSTDetails :", error);
    }
  };
  useEffect(() => {
    getMethodForExtraNumbers();
    extraGstNumbers();
  }, []);

  // useEffect(() => {
  //   if (
  //     props.strCustomerDetails.isCustExisting === true &&
  //     ifEligibleFortune === "N"
  //   ) {
  //     checkCustEligbleFortune();
  //   }
  //   if (ifEligibleFortune === "Y") {
  //     getWheelOptions();
  //   }
  // }, [props.strCustomerDetails, ifEligibleFortune]);

  return (
    <>
      <div className="col-md-12 newSaleComplete">
        <div
          className="row"
          style={{ paddingTop: ifEligibleFortune === "Y" ? "8px" : "50px" }}
        >
          <div className="col-5 text-end" style={{ color: "#57a959" }}>
            <i className="fa fa-check-circle fa-4x ms-1"></i>
          </div>
          <div className="col-7">
            <div className="title" style={{ fontWeight: "bold" }}>
              Bill Generated Successfully
            </div>
            <div className="title" style={{ fontSize: "20px" }}>
              Bill No :{" "}
              <span style={{ color: "#f04e23" }}>
                {props.strNewSaleItemsBillNo}
              </span>
              {"  "}
              {Number(props.strBalanceAmount) > 0 ? (
                <span className="returnCashBalance">
                  &nbsp;&nbsp;
                  <span style={{ color: "black" }}>
                    <i>
                      Return Cash Amount : <b>₹ {props.strBalanceAmount}</b>
                    </i>
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: ifEligibleFortune === "Y" ? "-1%" : "2%" }}
        >
          {/* {ifEligibleFortune === "Y" && (
            <div className="col-10" style={{ marginBottom: "-1px" }}>
              <div className="fortuneWheel" style={{ marginBottom: "0%" }}>
                <div>
                  <div>
                    <div
                      className="font-weight-bold header-flash-button"
                      style={{
                        fontSize: "1.2rem",
                        textAlign: "center",
                        color: "#f04e23",
                        marginTop: "-1.1rem",
                      }}
                    >
                      <span
                        style={{
                          paddingLeft: "0%",
                          marginRight: "1rem",
                        }}
                      >
                        <img
                          src={partyPopperImage}
                          alt="Party Popper img"
                          style={{ width: "2.2rem", marginBottom: "1rem" }}
                        />
                      </span>
                      Congratulations! You are eligible to spin the fortune
                      wheel
                    </div>
                    <div>
                      <FormControl
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(event) => {
                            let obj = offerList.find(
                              (o) => o.coupon_desc === event.target.defaultValue
                            );
                            setChangeFortune(obj);
                          }}
                        >
                          {offerList.map((elem, index) => (
                            <FormControlLabel
                              sx={{}}
                              key={index}
                              value={elem.coupon_desc}
                              control={
                                <Radio
                                  sx={{
                                    height: "1.5px",
                                    color: "#f04e23",
                                    "&.Mui-checked": { color: "#f04e23" },
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 20,
                                    },
                                  }}
                                />
                              }
                              label={elem.coupon_desc}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {/* end */}

          <div className="col-10">
            <div
              className="printMessage text-center"
              style={{ marginTop: ifEligibleFortune === "Y" && "0%" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginLeft: "0%",
                  marginTop: ifEligibleFortune === "Y" ? "-2%" : "",
                }}
              >
                <i style={{ color: "#dc143c" }}></i> Do you want to print the
                bill?
              </div>
              <div
                style={{
                  fontStyle: "italic",
                  color: "#f04e23",
                  fontSize: 20,
                  marginTop: "1%",
                }}
              >
                <i className="fa fa-bullhorn bullhorn-icon">
                  &nbsp;&nbsp;
                  <span style={{ color: "black" }}>
                    <i>(Ask the shopper)</i>
                  </span>
                </i>
              </div>
              <div style={{ marginTop: "16px" }}>
                <span style={{ marginRight: "10px" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: "220px" }}
                    disabled={loadingFortune || disablePrintButton}
                    onClick={() => {
                      if (
                        extraGstNumbersState.length > 0 ||
                        customerData.gstData?.length > 0
                      ) {
                        handleShowGstModal();
                        return;
                      }
                      if (Object.keys(changeFortune).length !== 0) {
                        postFortuneDetails();
                      }
                      printBill();
                      sendEbill("Y");
                    }}
                  >
                    Yes <br /> (Print Bill)
                  </button>
                </span>
                <span style={{ marginLeft: "10px" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: "220px" }}
                    onClick={() => {
                      // btnTransactionCompleteClick();
                      setAskCustModel(true);
                      if (Object.keys(changeFortune).length !== 0) {
                        postFortuneDetails();
                      }
                      sendEbill("N");
                    }}
                    disabled={
                      SMorHM === "SM" ? (loadingFortune ? true : false) : true
                    }
                  >
                    No <br /> (Complete Transaction)
                  </button>
                </span>
                {props.strTranType === "HOMEDE" ? (
                  <span
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-"
                      style={{
                        width: "185px",
                        background: "#8a2be2e6 ",
                        color: "#fff",
                      }}
                      onClick={() => homeReceiptPrint()}
                    >
                      <i
                        className="fa fa-print"
                        style={{
                          marginRight: "7px",
                        }}
                      ></i>
                      {"    "} Challan
                    </button>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSelectGstNumModal && (
        <ModalGstSelect
          openOrClose={showSelectGstNumModal}
          printBill={printBill}
          homeReceiptPrint={homeReceiptPrint}
          gstNumSelect={gstNumSelect}
          setGstNumSelect={setGstNumSelect}
          closeModal={handleShowGstModal}
          gstData={customerData.gstData}
          sendEbill={sendEbill}
          postFortuneDetails={postFortuneDetails}
          changeFortune={changeFortune}
        />
      )}

      {askCustModel && (
        <AskCustomerModal
          setAskCustModel={setAskCustModel}
          askCustModel={askCustModel}
          btnTransactionCompleteClick={btnTransactionCompleteClick}
        />
      )}
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strNewSaleItemsBillNo: reduxState?.newSale?.itemsBillNo,
    strBalanceAmount: reduxState?.newSale?.balanceAmount,
    strTranType: reduxState?.newSale?.tranType,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strUserDetails: reduxState?.login?.userDetailsData,
    checkValue: reduxState?.newSale,
    strItemsFinalCartValue: reduxState?.newSale?.itemsFinalCartValue,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrNewSaleCurrentStatus: (newSaleCurrStatus) =>
      dispatch(newSaleActions.setNewSaleCurrentStatus(newSaleCurrStatus)),
    setStrItemsBasketId: (basketId) =>
      dispatch(newSaleActions.setItemsBasketId(basketId)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrTotalFoodValue: (foodValue) =>
      dispatch(newSaleActions.setTotalFoodValue(foodValue)),
    setStrTotalNonFoodValue: (nonFoodValue) =>
      dispatch(newSaleActions.setTotalNonFoodValue(nonFoodValue)),
    setStrItemsCouponNo: (couponNo) =>
      dispatch(newSaleActions.setItemsCoupon(couponNo)),
    setStrItemsBillNo: (billNo) =>
      dispatch(newSaleActions.setItemsBillNo(billNo)),
    setStrItemsBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setStrItemsListTend: (tendList) =>
      dispatch(newSaleActions.setItemsListTend(tendList)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setStrIsValidCustomer: (isValidCustomer) =>
      dispatch(customerActions.setIsValidCustomer(isValidCustomer)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
    setHomeDelivery: (flag) => dispatch(customerActions.setHomeDelivery(flag)),
    setTranType: (flag) => dispatch(newSaleActions.setTranType(flag)),
    setSelectedDeliverySlot: (flag) =>
      dispatch(newSaleActions.setSelectedDeliverySlot(flag)),
    setAvailableDeliverySlot: (slotList) =>
      dispatch(newSaleActions.setAvailableDeliverySlot(slotList)),
    setCardTransactionStatus: (flag) =>
      dispatch(newSaleActions.setCardTransactionStatus(flag)),
    setIfCashOnDelivery: (flag) =>
      dispatch(newSaleActions.setIfCashOnDelivery(flag)),
    setBillSuccessOrBalance: (billBalanceOrSuccess) =>
      dispatch(newSaleActions.setBillSuccessOrBalance(billBalanceOrSuccess)),
    setNewGstData: (gstData) =>
      dispatch(customerActions.setNewGstData(gstData)),
    setMorePointsUsableBalance: (usablePoints) =>
      dispatch(moreRewardsActions.setMorePointsUsableBalance(usablePoints)),
    setMorePointsBalance: (balancePoints) =>
      dispatch(moreRewardsActions.setMorePointsBalance(balancePoints)),
    setCustomerTier: (tier) => dispatch(customerActions.setCustomerTier(tier)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSaleComplete);
