import React, { useEffect, useRef } from "react";
import c3 from "c3";
const PieChart = ({ headerText, couponRedeemPerecentage }) => {
  const chart1Ref = useRef(null);

  useEffect(() => {
    // Data for the first chart
    const data1 = {
      columns: [
        ["Current", Number(couponRedeemPerecentage)],
        ["Remaining", 100 - Number(couponRedeemPerecentage)],
      ],
      type: "pie",
      colors: {
        Current: "#267332",
        Remaining: "#8E1C15",
      },
    };

    // Configuration for the first chart
    const chart1Config = {
      bindto: chart1Ref.current,
      data: data1,
      size: {
        width: 220, // Set the width of the chart
        height: 220, // Set the height of the chart
      },
    };

    // Generate charts
    const chart1 = c3.generate(chart1Config);

    // Cleanup function to destroy the charts when the component unmounts
    return () => {
      chart1.destroy();
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div className="col rounded border border-dark p-0">
      <div className="fs-6 fw-bold px-2 mt-1">{headerText}</div>

      <div className="d-flex justify-content-center">
        <div ref={chart1Ref}></div>
      </div>
    </div>
  );
};

export default PieChart;
