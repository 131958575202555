import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { BsUpcScan } from "react-icons/bs";
import appConfig from "../../appconfig.json";
import axios from "axios";
import { toast } from "react-toastify";
import ModalScanBarcodeOtp from "./ModalScanBarcodeOtp";
import { useDispatch, useSelector } from "react-redux";
import BarcodeRedeemImage from "../../assets/BarcodeRedeem.jpg";
import { useCommonSelectors } from "../../Common/selector";
const ModalScanBarcode = ({
  modalOpenClose,
  handleClose,
  customerNumber,
  functionCall,
  couponModalFunction,
  coupon,
  couponModal = false,
  headerText = "Download More App & Scan barcode to redeem Morepoints",
  loader,
  setLoader,
  paymentModal,
}) => {
  const [barCode, setBarcode] = useState("");
  const barcodeRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingBarcode, setLoadingBarcode] = useState(false);
  const dispatch = useDispatch();
  //Sending otp modal values to Barcode modal
  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  // let showCustomerOTPState = useRef(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const handleOtpStatus = () => {
    setOtpStatus(!otpStatus);
  };
  const [failedBarcodeValues, setFailedBarcodeValues] = useState({
    otpExpInSecs: 0,
    otp: 0,
  });
  const redemption_auth_type = useSelector(
    (state) => state.login.userDetailsData.redemption_auth_type
  );
  const basket_id = useSelector((state) => state?.newSale?.itemsbasketId);
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const pos_id = useSelector((state) => state.login?.userDetailsData["pos_id"]);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const barcodeScan = async () => {
    if (barCode.length <= 10 || barCode.length > 13 || barCode === "") {
      toast.error("Please enter a valid barcode of 12 numbers", {
        autoClose: 1500,
      });
      return;
    }
    setLoader(true);

    const payload = {
      barcode: barCode,
      customer_number: customerNumber,
      redeem_source: paymentModal ? "morepoints" : "coupon",
      redeem_type: redemption_auth_type,
      skip_to_otp: "N",
      pos_id: pos_id_mpos,
      basket_id: basket_id,
      cashier_id: cashier_id,
      loc: loc,
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postMoreBarcodeValidation/",
        payload
      );
      if (data.status === "success") {
        if (couponModal) {
          functionCall(coupon);
        } else {
          functionCall();
        }
        const msg = "Barcode matching";
        toast.success(msg, {
          autoClose: 1500,
        });
        if (msg !== data.message) {
          console.log(
            "ModalScanBarcode-postMoreBarcodeValidation-api",
            data.message
          );
        }
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
        setLoader(false);
      }
    } catch (error) {
      console.log("ModalScanBarcode-postMoreBarcodeValidation-api", error);
      setLoader(false);
    }
  };

  const sendOtpForBarcode = async () => {
    setLoading(true);
    const payload = {
      customer_number: customerNumber,
      message: "OTP",
      otp_for: "Wallet",
      pos_id: pos_id.toString(),
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "send_otp/",
        payload
      );
      if (data.status === "success") {
        setFailedBarcodeValues(data.data[0]);
        setShowCustomerOTPState(true);
        setLoading(false);
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("ModalScanBarcode-send_otp-api", error);
      setLoading(false);
    }
  };

  const postRedemptionDetailsApi = async () => {
    const payload = {
      customer_number: customerNumber,
      pos_id: pos_id.toString(),
      basket_id: basket_id,
      cashier_id: cashier_id,
      redeem_source: paymentModal ? "morepoints" : "coupon",
      redeem_type: redemption_auth_type,
      skip_to_otp: "Y",
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postRedemptionDetails/",
        payload
      );
      if (data.status === "success") {
      } else {
      }
    } catch (error) {
      console.log("ModalScanBarcode-postRedemptionDetails-api", error);
      setLoader(false);
    }
  };
  useEffect(() => {
    document.getElementById("modalScanBarCode").focus();
  }, []);
  return (
    <>
      <Modal
        show={modalOpenClose || false}
        className="modal"
        backdrop="static"
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="w-100"
            style={{
              color: "#f04e23",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            {headerText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="p-.5rem "
          style={{
            fontWeight: "500",
            alignSelf: "center",
          }}
        >
          <div
            className="input-group"
            style={{
              height: "30px",
            }}
          >
            <OutlinedInput
              className="form-control"
              sx={{
                minHeight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                fontSize: "12px",
              }}
              disabled={loading}
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <BsUpcScan />
                </InputAdornment>
              }
              ref={barcodeRef}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                if (value.length <= 13) {
                  setBarcode(value);
                }
              }}
              inputComponent="input"
              type="text"
              pattern="[0-9]+"
              placeholder="Enter or Scan Barcode ... "
              required
              maxRows={14}
              minRows={10}
              value={barCode}
              id="modalScanBarCode"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!loader || !loading) {
                    barcodeScan();
                  }
                }
              }}
            />
            <button
              className="btn btn-outline-primary h-80"
              disabled={loader || loading}
              onClick={barcodeScan}
            >
              {loader ? (
                <label
                  className="spinner-border"
                  style={{
                    width: ".8rem",
                    height: ".8rem",
                  }}
                ></label>
              ) : (
                `Submit`
              )}
            </button>
            <div
              style={{
                margin: "10px 12px 0px 12px",
              }}
            >
              OR
            </div>
            <button
              className="btn btn-outline-primary h-80"
              disabled={loading || loader}
              onClick={() => {
                sendOtpForBarcode();
              }}
            >
              {loading ? (
                <label
                  className="spinner-border"
                  style={{
                    width: ".8rem",
                    height: ".8rem",
                  }}
                ></label>
              ) : (
                `Skip to OTP`
              )}
            </button>
          </div>
          <div
            className="text-center mt-3"
            style={{
              color: "#f04e23",
            }}
          >{`How to get the barcode: `}</div>
          <div
            className="text-center mt-1"
            style={{
              fontSize: "12px",
            }}
          >
            {`Open the More App -> Click on More+ on the bottom right -> Click on View Code`}
          </div>
          <div>
            <img
              src={BarcodeRedeemImage}
              alt="Barcode Redeem Image"
              width={"100%"}
              style={{
                height: "350px",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      <ModalScanBarcodeOtp
        show={showCustomerOTPState}
        button_clicked={() => {
          setShowCustomerOTPState(!showCustomerOTPState);
        }}
        customerNumber={customerNumber}
        setOtpStatusFunc={handleOtpStatus}
        barcodeFailed={true}
        timerExpires={failedBarcodeValues.otpExpInSecs}
        receivedOtp={failedBarcodeValues.otp}
        postRedemptionDetailsApi={postRedemptionDetailsApi}
        mainFunction={
          couponModal
            ? () => {
                functionCall(coupon);
              }
            : functionCall
        }
        loader={loader}
        setLoader={setLoader}
        paymentModal={paymentModal}
      />
    </>
  );
};

export default ModalScanBarcode;
