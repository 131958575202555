import { createSlice } from "@reduxjs/toolkit";

const moreRewardsInitialState = {
  isValidCustomer: false,
  customerDetailsData: [],
  customerNumber: "",
  screen: "",
  walletBalance: [],
  lastScreen: "",
  differentRechargeValueData: [],
  cashAmount: "",
  totalCartValue: "",
  rechargeData: [],
  otpStatus: false,
  referralProgram: [],
  basketID: "",
  walletRechargeData: [],
  rechargeSuccess: "",
  walletTopUp: {
    isValidCustomer: false,
    customerDetailsData: [],
    customerNumber: "",
    walletBalance: [],
    morePointBalance: [],
    differentRechargeValueData: [],
    cashAmount: "",
    totalCartValue: "",
    rechargeData: [],
    otpStatus: false,
    basketID: "",
    walletRechargeData: [],
    rechargeSuccess: "",
    numberOfTopUpButtons: [],
    inputFieldEnable: true,
    resetEnable: false,
    cashbacks: [],
  },
  storeCard: {
    customerDetailsData: [],
    otpStatus: false,
    storeCardField: false,
    storeCardBalance: 0,
    storeCardNumber: "",
  },
  morePointsBalance: {
    usage_points: 0,
    balance_points: 0,
  },
  moreRewardsRecharge: {
    isValidCustomer: false,
    customerDetailsData: [],
    customerNumber: "",
    walletBalance: [],
    differentRechargeValueData: [],
    cashAmount: "",
    totalCartValue: "",
    rechargeData: [],
    otpStatus: false,
    basketID: "",
    walletRechargeData: [],
    rechargeSuccess: "",
    numberOfTopUpButtons: [],
    inputFieldEnable: true,
    resetEnable: false,
    cashbacks: [],
  },
};

const moreRewardsSlice = createSlice({
  name: "moreRewardsDs",
  initialState: moreRewardsInitialState,
  reducers: {
    setRewardScreen(state, action) {
      state.screen = action.payload;
    },
    setLastRewardScreen(state, action) {
      state.lastScreen = action.payload;
    },
    setIsValidCustomer(state, action) {
      state.isValidCustomer = action.payload;
    },
    setCustomerDetailsData(state, action) {
      state.customerDetailsData = action.payload;
    },
    walletBalanceData(state, action) {
      state.walletBalance = action.payload;
    },
    morePointBalanceData(state, action) {
      state.morePointsBalance = action.payload;
    },
    setRechargeData(state, action) {
      state.rechargeData = action.payload;
    },
    setDifferentRechargeValueData(state, action) {
      state.differentRechargeValueData = action.payload;
    },
    setCashAmountForRecharge(state, action) {
      state.cashAmount = action.payload;
    },
    setTotalCartValue(state, action) {
      state.totalCartValue = action.payload;
    },
    setOtpStatus(state, action) {
      state.otpStatus = action.payload;
    },
    setReferralProgram(state, action) {
      state.referralProgram = action.payload;
    },
    setCustomerNumber(state, action) {
      state.customerNumber = action.payload;
    },
    setBasketID(state, action) {
      state.basketID = action.payload;
    },
    setWalletRechargeData(state, action) {
      state.walletRechargeData = action.payload;
    },
    setRechargeSucces(state, action) {
      state.rechargeSuccess = action.payload;
    },
    //Wallet Top up
    setIsValidCustomerWalletTopUp(state, action) {
      state.walletTopUp.isValidCustomer = action.payload;
    },
    setCustomerDetailsDataWalletTopUp(state, action) {
      state.walletTopUp.customerDetailsData = action.payload;
    },
    setCustomerNumberWalletTopUp(state, action) {
      state.walletTopUp.customerNumber = action.payload;
    },
    setWalletBalanceDataWalletTopUp(state, action) {
      state.walletTopUp.walletBalance = action.payload;
    },
    setDifferentRechargeValueDataWalletTopUp(state, action) {
      state.walletTopUp.differentRechargeValueData = action.payload;
    },
    setCashAmountForRechargeWalletTopUp(state, action) {
      state.walletTopUp.cashAmount = action.payload;
    },
    setTotalCartValueWalletTopUp(state, action) {
      state.walletTopUp.totalCartValue = action.payload;
    },
    setRechargeDataWalletTopUp(state, action) {
      state.walletTopUp.rechargeData = action.payload;
    },
    setOtpStatusWalletTopUp(state, action) {
      state.walletTopUp.otpStatus = action.payload;
    },
    setBasketIDWalletTopUp(state, action) {
      state.walletTopUp.basketID = action.payload;
    },
    setWalletRechargeDataWalletTopUp(state, action) {
      state.walletTopUp.walletRechargeData = action.payload;
    },
    setRechargeSuccesWalletTopUp(state, action) {
      state.walletTopUp.rechargeSuccess = action.payload;
    },
    setNoOfTopUpButtonsWalletTopUp(state, action) {
      state.walletTopUp.numberOfTopUpButtons = action.payload;
    },
    setInputFieldEnableWalletTopUp(state, action) {
      state.walletTopUp.inputFieldEnable = action.payload;
    },
    setResetEnableWalletTopUp(state, action) {
      state.walletTopUp.resetEnable = action.payload;
    },
    setCashbacksWalletTopUp(state, action) {
      state.walletTopUp.cashbacks = action.payload;
    },
    setWalletTopUpReset(state, action) {
      state.walletTopUp = action.payload;
    },
    //Store Card
    setStoreCardCustomerDetails(state, action) {
      state.storeCard.customerDetailsData = action.payload;
    },
    setStoreCardOtpStatus(state, action) {
      state.storeCard.otpStatus = action.payload;
    },
    setStoreCardNumber(state, action) {
      state.storeCard.storeCardNumber = action.payload;
    },
    setStoreCardField(state, action) {
      state.storeCard.storeCardField = action.payload;
    },
    setStoreCardBalance(state, action) {
      state.storeCard.storeCardBalance = action.payload;
    },

    //More points
    setMorePointsUsableBalance(state, action) {
      state.morePointsBalance.usage_points = action.payload;
    },
    setMorePointsBalance(state, action) {
      state.morePointsBalance.balance_points = action.payload;
    },

    setMrPointsIsValidCustomerWalletTopUp(state, action) {
      state.moreRewardsRecharge.isValidCustomer = action.payload;
    },
    setMrPointsCustomerDetailsDataWalletTopUp(state, action) {
      state.moreRewardsRecharge.customerDetailsData = action.payload;
    },
    setMrPointsCustomerNumberWalletTopUp(state, action) {
      state.moreRewardsRecharge.customerNumber = action.payload;
    },
    setMrPointsWalletBalanceDataWalletTopUp(state, action) {
      state.moreRewardsRecharge.walletBalance = action.payload;
    },

    setMrPointsDifferentRechargeValueDataWalletTopUp(state, action) {
      state.moreRewardsRecharge.differentRechargeValueData = action.payload;
    },
    setMrPointsCashAmountForRechargeWalletTopUp(state, action) {
      state.moreRewardsRecharge.cashAmount = action.payload;
    },
    setMrPointsTotalCartValueWalletTopUp(state, action) {
      state.moreRewardsRecharge.totalCartValue = action.payload;
    },
    setMrPointsRechargeDataWalletTopUp(state, action) {
      state.moreRewardsRecharge.rechargeData = action.payload;
    },
    setMrPointsOtpStatusWalletTopUp(state, action) {
      state.moreRewardsRecharge.otpStatus = action.payload;
    },
    setMrPointsBasketIDWalletTopUp(state, action) {
      state.moreRewardsRecharge.basketID = action.payload;
    },
    setMrPointsWalletRechargeDataWalletTopUp(state, action) {
      state.moreRewardsRecharge.walletRechargeData = action.payload;
    },
    setMrPointsRechargeSuccesWalletTopUp(state, action) {
      state.moreRewardsRecharge.rechargeSuccess = action.payload;
    },
    setMrPointsNoOfTopUpButtonsWalletTopUp(state, action) {
      state.moreRewardsRecharge.numberOfTopUpButtons = action.payload;
    },
    setMrPointsInputFieldEnableWalletTopUp(state, action) {
      state.moreRewardsRecharge.inputFieldEnable = action.payload;
    },
    setMrPointsResetEnableWalletTopUp(state, action) {
      state.moreRewardsRecharge.resetEnable = action.payload;
    },
    setMrPointsCashbacksWalletTopUp(state, action) {
      state.moreRewardsRecharge.cashbacks = action.payload;
    },
    setMrPointsWalletTopUpReset(state, action) {
      state.moreRewardsRecharge = action.payload;
    },
  },
});

export const moreRewardsActions = moreRewardsSlice.actions;
export default moreRewardsSlice.reducer;
