import React, { useState } from "react";
import CustomerCheck from "../../../Common/CustomerCheck/CustomerCheck";
import appConfig from "../../../appconfig.json";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import OtpForWalletTopUp from "../OTP/OtpForWalletTopUp";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import { Modal } from "react-bootstrap";
import CustomerCheckStoreCard from "./CustomerCheckStoreCard";

const StoreCardBalance = () => {
  const address = appConfig.api_url;
  const dispatch = useDispatch();
  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.storeCard.customerDetailsData
  );

  //Store Card Details
  const storeCardNumberValue = useSelector(
    (state) => state.moreRewards.storeCard.storeCardNumber
  );
  const [storeCardNumber, setStoreCardNumber] = useState("");
  const storeCardBalance = useSelector(
    (state) => state.moreRewards.storeCard.storeCardBalance
  );
  const storeCardFieldValue = useSelector(
    (state) => state.moreRewards.storeCard.storeCardField
  );
  //Loaders
  const [loadingCustData, setLoadingCustData] = useState(false);
  const [getStCrdBlncLoader, setStCrdBlncLoader] = useState(false);
  const [reedemLoader, setReedemLoader] = useState(false);

  //For OTP
  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const handleCustomerOtpStatus = () => {
    setShowCustomerOTPState(!showCustomerOTPState);
  };
  const otpStatusForWalletTopUp = useSelector(
    (state) => state.moreRewards.storeCard.otpStatus
  );
  const handleOtpStatus = () => {
    dispatch(
      moreRewardsActions.setStoreCardOtpStatus(!otpStatusForWalletTopUp)
    );
  };

  const customerNumber = useSelector(
    (state) => state.moreRewards.storeCard.customerDetailsData.customer_number
  );

  //Api Calls
  const getStoreCardBalance = async () => {
    setStCrdBlncLoader(true);
    const payload = {
      store_card: storeCardNumber,
      customer_number: strCustomerDetails["customer_number"],
    };
    try {
      let response = await fetch(address + "getStoreCardBalance/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          dispatch(
            moreRewardsActions.setStoreCardBalance(response.data[0].balance)
          );
          dispatch(moreRewardsActions.setStoreCardField(true));
          setStCrdBlncLoader(false);
        } else {
          toast.error(response["message"], {
            autoClose: 2500,
          });
          setStoreCardNumber("");
          setStCrdBlncLoader(false);
        }
      } else {
        setStCrdBlncLoader(false);
        setStoreCardNumber("");
        console.log(
          "getStoreCardBalance-wallet-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      setStoreCardNumber(false);
      console.log("getStoreCardBalance-wallet :", err);
    }
  };

  const redeemStoreCardBalance = async () => {
    setReedemLoader(true);
    const payload = {
      store_card: storeCardNumber || storeCardNumberValue,
      customer_number: strCustomerDetails["customer_number"],
    };
    try {
      let response = await fetch(address + "postStoreCardtoWallet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          setReedemLoader(false);
          toast.success(response["message"], {
            autoClose: 2500,
          });
          setTimeout(() => {
            handleReset();
          }, 1000);
        } else {
          toast.error(response["message"], {
            autoClose: 2500,
          });
          dispatch(moreRewardsActions.setStoreCardBalance(0));
          setStoreCardNumber("");
          setReedemLoader(false);
          setReedemOpenClose(false);
        }
      } else {
        setReedemLoader(false);
        dispatch(moreRewardsActions.setStoreCardBalance(0));
        setStoreCardNumber("");
        setReedemOpenClose(false);
        console.log(
          "getStoreCardBalance-wallet-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      setReedemLoader(false);
      console.log("getStoreCardBalance-wallet :", err);
    }
  };

  //Reset
  const handleReset = () => {
    dispatch(moreRewardsActions.setCustomerDetailsData([]));
    dispatch(moreRewardsActions.walletBalanceData([]));
    dispatch(moreRewardsActions.setBasketID(""));
    dispatch(moreRewardsActions.setIsValidCustomer(false));
    dispatch(moreRewardsActions.setWalletRechargeData([]));
    dispatch(moreRewardsActions.setRechargeSucces(""));
    dispatch(moreRewardsActions.setRechargeData([]));
    dispatch(moreRewardsActions.setOtpStatus(false));
    dispatch(moreRewardsActions.setCashAmountForRecharge(""));
    dispatch(moreRewardsActions.setStoreCardBalance(0));
    dispatch(moreRewardsActions.setStoreCardNumber(""));
    dispatch(moreRewardsActions.setStoreCardField(false));
    dispatch(moreRewardsActions.setStoreCardOtpStatus(false));
    dispatch(moreRewardsActions.setStoreCardCustomerDetails([]));
    setStoreCardNumber("");
    setReedemOpenClose(false);
  };

  //Modal For Reedem
  const [reedemOpenClose, setReedemOpenClose] = useState(false);
  return (
    <>
      <div>
        <CustomerCheckStoreCard
          showCustomerName={true}
          loader={loadingCustData}
          setLoader={setLoadingCustData}
          setShowCustomerOTPState={setShowCustomerOTPState}
          reset={handleReset}
        />
      </div>

      {strCustomerDetails["isCustExisting"] && otpStatusForWalletTopUp && (
        <Box
          sx={{
            marginLeft: "-0.8rem",
          }}
        >
          <Box
            sx={{
              width: "23.8rem",
            }}
          >
            <input
              disabled={storeCardFieldValue}
              style={{ borderRadius: "0px" }}
              id="storeCardNumber"
              name="storeCardNumber"
              type="text"
              value={storeCardNumber || storeCardNumberValue}
              maxLength={12}
              className="form-control "
              placeholder="Store Card Number"
              onChange={(e) => {
                dispatch(moreRewardsActions.setStoreCardNumber(e.target.value));
                setStoreCardNumber(e.target.value);
              }}
            />

            {storeCardBalance > 0 && (
              <Typography
                mt={1}
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Balance :{" "}
                <span style={{ color: "#f04e23", fontSize: "20px" }}>
                  {storeCardBalance}
                </span>
              </Typography>
            )}

            <Box
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                disabled={storeCardBalance > 0 || storeCardNumber.length < 5}
                style={{
                  borderRadius: "0px",
                }}
                className="btn btn-primary"
                onClick={() => {
                  getStoreCardBalance();
                }}
              >
                {getStCrdBlncLoader ? (
                  <label
                    className="spinner-border"
                    style={{
                      width: ".8rem",
                      height: ".8rem",
                    }}
                  ></label>
                ) : (
                  <label>Get Card Balance</label>
                )}
              </button>
              <button
                disabled={storeCardBalance <= 0}
                style={{
                  borderRadius: "0px",
                }}
                className="btn btn-primary"
                onClick={() => {
                  setReedemOpenClose(true);
                }}
              >
                {reedemLoader ? (
                  <label
                    className="spinner-border"
                    style={{
                      width: ".8rem",
                      height: ".8rem",
                    }}
                  ></label>
                ) : (
                  <label>Reedem</label>
                )}
              </button>
            </Box>
          </Box>
        </Box>
      )}

      <OtpForWalletTopUp
        show={showCustomerOTPState}
        button_clicked={handleCustomerOtpStatus}
        customerNumber={customerNumber}
        setOtpStatusFunc={handleOtpStatus}
      />

      <>
        <Modal show={reedemOpenClose | false} className="modal-trans" centered>
          <Modal.Body>
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title">
                    Are you sure you want Reedem the StoreCard
                  </h5>
                </div>
                <div className="row justify-content-between mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary col-md-3"
                    onClick={() => {
                      setReedemOpenClose(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary col-md-3"
                    onClick={() => {
                      redeemStoreCardBalance();
                    }}
                  >
                    {reedemLoader ? (
                      <label
                        className="spinner-border"
                        style={{
                          width: ".8rem",
                          height: ".8rem",
                        }}
                      ></label>
                    ) : (
                      <label>Submit</label>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default StoreCardBalance;
