import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import appConfig from "../../../appconfig.json";
import { Box, Skeleton, Typography } from "@mui/material";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import WalletTopUpYesNoModal from "./WalletTopUpYesNoModal";
import { toast } from "react-toastify";
import { useCommonSelectors } from "../../../Common/selector";

const WalletOrderSummary = ({
  cashback,
  cashMoney,
  cardMoney,
  loadingForSummary = false,
}) => {
  const address = appConfig.api_url;
  const dispatch = useDispatch();
  const { loc, pos_id_mpos } = useCommonSelectors();

  const totalCartValue = useSelector(
    (state) => state.moreRewards.walletTopUp.totalCartValue
  );
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const basketId = useSelector(
    (state) => state.moreRewards?.walletTopUp.basketID
  );
  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.walletTopUp.customerDetailsData
  );
  const pos_id = useSelector((state) => state.login?.userDetailsData["pos_id"]);

  const [openOrClose, setOpenOrClose] = useState(false);
  const handleOpenOrClose = () => {
    setOpenOrClose(!openOrClose);
  };
  const [loading, setLoading] = useState(false);
  const [confirmRechargeData, setconfirmRechargeData] = useState([]);

  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const handleShowSuccessPopUp = () => {
    setShowSuccessPopUp(!showSuccessPopUp);
  };

  const mainFunction = async () => {
    setLoading(true);
    const payload = {
      basket_id: basketId,
      cashier_id: cashier_id,
      cust_id: strCustomerDetails.cust_id,
      customer_number:
        strCustomerDetails.customer_number ||
        document.getElementById("walletTop.searchCustomer").value,
      pos_id: pos_id,
      recharge_amount: Number(totalCartValue),
    };
    try {
      let result = await fetch(address + "postDsWalletRechargeUpdate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();
        if (result.status === "success") {
          toast.success(result.message, {
            autoClose: 1500,
          });
          setLoading(false);
          setconfirmRechargeData(result.data[0]);
          sndMsgOnSuccessRecharge();
          handleShowSuccessPopUp();
        } else {
          toast.error(result.message, {
            autoClose: 1500,
          });
          setLoading(false);
          console.log(
            "walletTopUpMoreRewards-postDsWalletRechargeUpdate-api :",
            result.status,
            result.statusText
          );
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(
        "walletTopUpMoreRewards-postDsWalletRechargeUpdate-api :",
        err
      );
    }
  };

  const sndMsgOnSuccessRecharge = async () => {
    const payload = {
      wallet_recharge: totalCartValue.toString(),
      basket_id: basketId,
      mobile_no:
        strCustomerDetails.customer_number ||
        document.getElementById("walletTop.searchCustomer").value,
    };
    try {
      let result = await fetch(address + "sendWalletRechargeSms/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();
        if (result.status === "success") {
        } else {
          toast.error(result.message, {
            autoClose: 1500,
          });

          console.log(
            "walletTopUpMoreRewards-sendWalletRechargeSms-api :",
            result.status,
            result.statusText
          );
        }
      }
    } catch (err) {
      setLoading(false);
      console.log("walletTopUpMoreRewards-sendWalletRechargeSms-api :", err);
    }
  };

  const sndWhtsMsgOnSuccessRecharge = async (cashbackAmount, walletAmount) => {
    const payload = {
      wallet_recharge: totalCartValue.toString(),
      customer_number: `91${
        strCustomerDetails.customer_number ||
        document.getElementById("walletTop.searchCustomer").value
      }`,
      expire_date: "90",
      customer_name: strCustomerDetails.custName,
      cashback_amount: cashbackAmount.toString(),
      total_balance: walletAmount.toString(),
    };
    try {
      let result = await fetch(address + "sendWalletRechargeWhatsapp/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();
        if (result.status === "success") {
        } else {
          toast.error(result.message, {
            autoClose: 1500,
          });

          console.log(
            "walletTopUpMoreRewards-sendWalletRechargeWhatsapp-api :",
            result.status,
            result.statusText
          );
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(
        "walletTopUpMoreRewards-sendWalletRechargeWhatsapp-api :",
        err
      );
    }
  };
  const walletRechargeData = useSelector(
    (state) => state.moreRewards?.walletTopUp?.walletRechargeData
  );
  const sucessMesage = useSelector(
    (state) => state.moreRewards?.walletTopUp?.rechargeSuccess
  );
  const voidBasket = async () => {
    try {
      let payload = {
        event: "basket_void",
        basket_id: basketId,
        sub_tran_type: "OFFLIN",
        slot: "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          handleReset();
        }
      } else {
        console.log(
          "walletTopUp-voidBasket-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("walletTopUp-voidBasket :", err);
    }
  };
  //Void payment
  const [voidInPaymet, setVoidInPayment] = useState(false);

  const handleVoidInPayment = () => {
    setVoidInPayment(!voidInPaymet);
  };
  const [showTypeOfWallet, setShowTypeOfWallet] = useState(false);
  const handleTypeOfVoidInPayment = () => {
    setShowTypeOfWallet(!showTypeOfWallet);
  };
  const handleReset = () => {
    dispatch(
      moreRewardsActions.setWalletTopUpReset({
        isValidCustomer: false,
        customerDetailsData: [],
        customerNumber: "",
        walletBalance: [],
        differentRechargeValueData: [],
        cashAmount: "",
        totalCartValue: "",
        rechargeData: [],
        otpStatus: false,
        basketID: "",
        walletRechargeData: [],
        rechargeSuccess: "",
        numberOfTopUpButtons: [],
        inputFieldEnable: true,
        resetEnable: false,
        cashbacks: [],
      })
    );
    document.getElementById("walletTopUp.searchCustomer").value = "";
  };

  return (
    <div>
      <div className="row">
        <table
          className="table"
          style={{
            marginBottom: "2px",
          }}
        >
          <tbody
            style={{
              fontWeight: "bold",
              fontSize: "0.9rem",
            }}
          >
            <tr>
              <td>Cart Value ( ₹ )</td>
              <td className="text-end">{totalCartValue || 0}</td>
            </tr>
            <tr>
              <td>Cashback for UPI/Cash ( ₹ )</td>
              {loadingForSummary ? (
                <td className="text-end">
                  <Skeleton variant="text" animation="wave" />
                </td>
              ) : (
                <td className="text-end">{cashMoney || 0}</td>
              )}
            </tr>
            <tr>
              <td>Cashback for Card ( ₹ )</td>
              {loadingForSummary ? (
                <td className="text-end">
                  <Skeleton variant="text" animation="wave" />
                </td>
              ) : (
                <td className="text-end">{cardMoney || 0}</td>
              )}
            </tr>
            <tr>
              <td>Balance Amount ( ₹ )</td>
              <td className="text-end">
                {walletRechargeData?.balanceAmt
                  ? walletRechargeData?.balanceAmt
                  : walletRechargeData?.balanceAmt === 0
                  ? 0
                  : totalCartValue}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            className={"numBtnCustomWalletTopUp btn"}
            onClick={() => {
              handleVoidInPayment();
            }}
            disabled={sucessMesage === "" || sucessMesage === "BILLSUCCESS"}
          >
            VOID
          </button>
          <button
            className={"numBtnCustomWalletTopUp btn"}
            onClick={() => handleOpenOrClose()}
            disabled={sucessMesage !== "BILLSUCCESS"}
          >
            Confirm Recharge
          </button>
        </div>
      </div>

      <Modal show={openOrClose | false} className="modal-trans" centered>
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h5 className="modal-title w-100">CONFIRM RECHARGE WALLET ?</h5>
              </div>
              <div className="modal-footer justify-content-center">
                <>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleOpenOrClose(!openOrClose);
                      }}
                    >
                      No
                    </button>
                  </div>
                  <button
                    id="yesButton"
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onClick={() => {
                      mainFunction();
                      handleOpenOrClose(!openOrClose);
                    }}
                  >
                    Yes
                  </button>
                </>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div className="forVoidInPayment  align-items-center justify-content-center">
          <div
            style={{
              position: "fixed",
              top: "35%",
              left: "50%",
              width: "30em",
              height: "18em",
              marginLeft: "-17em",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  placeSelf: "center",
                  marginLeft: "1.6rem",
                }}
              >
                Wallet Recharge in Progress, Please Wait !!!!
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal show={showSuccessPopUp | false} className="modal-trans" centered>
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div
                className="modal-header flex-column"
                style={{
                  marginBottom: "-2rem",
                }}
              >
                <h5
                  className="modal-title w-100"
                  style={{
                    fontSize: "1.3rem",
                  }}
                >
                  Wallet Details
                </h5>
                <div
                  className="header-flash-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1rem",
                    fontSize: "1.2rem",
                    color: "#f04e23",
                  }}
                >
                  <div>Recharge Successful</div>
                  &nbsp;
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <>
                  <Box>
                    <Typography>
                      Recharge Amount:{" "}
                      {confirmRechargeData.recharge_amount || 0}
                    </Typography>
                    <Typography>
                      Cashback Amount:{" "}
                      {confirmRechargeData.cashback_amount || 0}
                    </Typography>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleReset();
                        handleShowSuccessPopUp();
                      }}
                    >
                      Close Pop Up
                    </button>
                  </Box>
                </>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {voidInPaymet ? (
        <WalletTopUpYesNoModal
          handleClose={handleVoidInPayment}
          openOrClose={voidInPaymet}
          typeOfVoidPayment={showTypeOfWallet}
          voidFunction={voidBasket}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default WalletOrderSummary;
