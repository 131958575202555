import React from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const DataGridOnHold = ({ data, handleClick }) => {
  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="contained"
        // color="primary"
        // size="small"
        style={{
          fontSize: "0.9em",
          backgroundColor: "#d3455b",
          padding: "0.3em 0.3em",
        }}
        onClick={() => {
          handleClick(params.row.basket_id, params.row.customer_no);
        }}
      >
        {params.row.basket_id}
      </Button>
    );
  };

  const columns = [
    //Basket Date	Basket ID	Customer Number	Total Items	Total Qty	Total Amount ( ₹ )
    {
      field: "basket_date",
      headerName: "Basket Date",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "basket_id",
      headerName: "Basket ID",
      renderCell: renderDetailsButton,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customer_no",
      headerName: "Customer Number",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_items",
      headerName: "Total Items",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_qty",
      headerName: "Total Qty",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_amt",
      headerName: "Total Amount ( ₹ )",
      flex: 0.8,
      align: "right",
      headerAlign: "center",
    },
  ];

  return (
    <Box>
      <Box
        height={"68vh"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "11px",
            border: "0.1px solid lightgrey",
            textAlign: "center",
            alignSelf: "center",
            padding: "1px 1px",
          },
          "& .name-column--cell": {
            color: "#2e7c67",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#333",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#fff",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#fff",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#333",
            color: "#fff",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${"#141414"} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
            color: `${"#141414"} !important`,
          },
          "& .MuiToolbar-root": {
            color: "white",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            "::-webkit-scrollbar": {
              width: "0.8vw",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#7775",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#333",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "D3D3D3",
            },
            "::-webkit-scrollbar-track:hover": {
              backgroundColor: "#3333",
            },
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => generateRandom()}
          pagination
          {...data}
        />
      </Box>
    </Box>
  );
};

export default DataGridOnHold;
