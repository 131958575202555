import React, { useState, useEffect, useRef, useCallback } from "react";
import KeyboardLayout from "../KeyboardLayout";
import axios from "axios";
import appConfig from "../../appconfig.json";
import { connect } from "react-redux";
import { returnActions } from "../store/returnSaleStore";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import UserRegister from "../userRegister";
import OtpModal from "../otpModal";
import {
  SuccessPage,
  BillDataForm,
  BillForm,
  SearchForm,
  Loader,
  RefundDetails,
} from "./index";
import { useCommonSelectors } from "../../Common/selector";
const ReturnLayout = (props) => {
  useEffect(() => {
    document.documentElement.requestFullscreen();
  }, []);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const [otpStatus, setOtpStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [billDate, setBillDate] = useState();

  const [transactionData, setTransactionData] = useState(
    props.strTransactionData ?? {}
  );
  const [inputs, setInputs] = useState({
    sub_tran_type: props.strSubTranType,
    searchBy: "",
    searchText: props.strBillorCustomer,
  });
  const handleOtpStatus = () => {
    setOtpStatus(!otpStatus);
  };
  const [returnTableData, setReturnTableData] = useState(
    props.strReturnBillsData ?? []
  );
  const [returnBillData, setReturnBillData] = useState(
    props.strReturnBillItemsData ?? []
  );
  const [showForm, setShowForm] = useState(
    props.strReturnScreen ?? "SearchForm"
  );
  const [newCustomerdetails, setNewCustomer] = useState(
    props.strCustomerDetails ?? {}
  );
  const [refundDetails, setRefundDetail] = useState(
    props.strRefundDetails ?? {}
  );

  const [showAlertBoxForAMZ, setshowAlertBoxForAMZ] = useState(false);
  const searchtextref = useRef();

  const processSearchText = () => {};

  const handleBlurEvent = (e) => {
    searchtextref.current = e.target;
    const name = e.target.name;
    const value = e.target.value;
    setInputs(() => ({ ...inputs, [name]: value }));
    props.setInputData(() => ({ ...inputs, [name]: value }));
    props.setSubTransType(inputs.sub_tran_type);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value === "AMZNOW") {
      postSummary({
        sub_tran_type: "AMZNOW",
        searchBy: "cust",
        searchText: inputs.billno || "",
        loc: loc,
        pos_id: pos_id_mpos,
      });
    }
    setInputs(() => ({ ...inputs, [name]: value }));
    props.setInputData(() => ({ ...inputs, [name]: value }));
    props.setSubTransType(inputs.sub_tran_type);
  };

  const [fetchBtn, setFetchBtn] = useState(props.strFetchBtn ?? true);
  const [backBtn, setBackBtn] = useState(props.strBackBtn ?? true);
  const [paymentBtn, setPaymentBtn] = useState(props.strPaymentBtn ?? true);

  const [alertShow, setAlertShow] = useState(false);
  const [alertMsgShow, setAlertMsgShow] = useState("");
  const [alertFlagShow, setAlertFlagShow] = useState("info");

  const [returnBtn, setReturnButton] = useState(true);

  const address = appConfig.api_url;

  const [showCustomerState, setShowCustomerState] = useState(false);
  const buttonClickedHandler = () => {
    setShowCustomerState(
      (showCustomerState) => (showCustomerState = !showCustomerState)
    );
  };

  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  const creditHandler = () => {
    setTransactionData({});
    setOtpStatus(false);
    setShowCustomerOTPState(
      (showCustomerOTPState) => (showCustomerOTPState = !showCustomerOTPState)
    );
  };

  const handleCustomerNo = (event) => {
    try {
      const value = event.target.value;
      const name = event.target.name;
      if (value?.length == 10) {
        customerValidate({ searchText: value });
        setNewCustomer({ ...newCustomerdetails, [name]: value });
        props.setNewCustomer({ ...newCustomerdetails, [name]: value });
      } else if (value?.length <= 0) {
        customerValidate({ searchText: value });
        setNewCustomer({ ...newCustomerdetails, [name]: value });
        props.setNewCustomer({ ...newCustomerdetails, [name]: value });
        toast.error("Enter Valid Mobile Number", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log("returnLayout. : handleCustomerNo", err);
    }
  };

  useEffect(() => {
    if (otpStatus) {
      setShowLoader(true);
      setRefundDetail({});
      props.setRefundDetail({});
      try {
        var itemData = [];
        returnBillData.map((item, i) => {
          if (Number(item.return_qty) > 0) {
            itemData.push({
              ["cashier_id"]: props.strUserDetails.empCode,
              ["mobile_no"]: newCustomerdetails.mobile_no,
              ["cust_id"]: newCustomerdetails.cust_id,
              ["item"]: item.item,
              ["barcode"]: item.barcode,
              ["item_desc"]: item.item_desc,
              ["bill_qty"]: Number(item.bill_qty),
              ["mrp"]: Number(item.mrp),
              ["discount"]: Number(item.discount),
              ["tax"]: Number(item.tax),
              ["net_amt"]: Number(item.net_amt),
              ["return_qty"]: Number(item.return_qty),
              ["reuse_qty"]: Number(item.reuse_qty),
            });
          }
        });
        var customerPayload = { searchText: newCustomerdetails.mobile_no };
        axios
          .post(address + "getDsCustomer/", customerPayload)
          .then((response) => {
            response = response.data.data[0];

            if (response.isCustExisting) {
              returnTransactionWallet({
                org_bill_no: props.strBillNo,
                items: itemData,
                org_bill_date: inputs.billdate,
                sub_tran_type: props.strBillorCustomer.sub_tran_type,
                loc: loc,
                pos_id: pos_id_mpos,
              });
            } else {
              buttonClickedHandler();
            }
          });
      } catch (err) {
        console.log("returnLayout.handleAddWallet : ", err);
      }
    }
  }, [showCustomerOTPState]);

  const ReturnLayoutCurrentStatusRef = useRef();
  useEffect(() => {
    ReturnLayoutCurrentStatusRef.current = props.strReturnScreen;
  }, [props.strReturnScreen]);
  var pos = 0;

  const handleBack = (event) => {
    const pageStages = [
      "SearchForm",
      "billForm",
      "billDataForm",
      "successPage",
    ];

    if (props.strReturnScreen != pageStages[0]) {
      pos = pageStages.indexOf(props.strReturnScreen);
      pos = pos - 1;
    }

    if (props.strReturnScreen == pageStages[1]) {
      setReturnTableData([]);
      props.setReturnBillsListData([]);
      props.setReturnItemsListData([]);
      setReturnBillData([]);
      setInputs({});
      props.setInputData({});
      setNewCustomer({});
      props.setNewCustomer({});
      setRefundDetail({});
      props.setRefundDetail({});
      setTransactionData({});
      props.setTransactionData({});
    }
    setShowForm(event !== undefined ? event : pageStages[pos]);
    props.setReturnScreen(pageStages[pos]);
    setAlertShow(false);
    setAlertMsgShow("");
    setBackBtn(false);
    props.setBackBtn(false);

    if (props.strReturnScreen == pageStages[pageStages?.length - 1]) {
      setShowForm(event !== undefined ? event : pageStages[0]);
      props.setReturnScreen(pageStages[0]);
      setReturnTableData([]);
      props.setReturnBillsListData([]);
      props.setReturnItemsListData([]);
      setReturnBillData([]);
      setInputs({});
      props.setInputData({});
      setNewCustomer({});
      props.setNewCustomer({});
      setTransactionData({});
      props.setTransactionData({});
    }
    setRefundDetail({});
    props.setRefundDetail({});
  };

  const keyboardEventListenerNewSaleBilling = useCallback(
    (e) => {
      if (
        ReturnLayoutCurrentStatusRef.current !== "SearchForm" &&
        e.altKey &&
        e.code === "KeyB" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        const pageStages = [
          "SearchForm",
          "billForm",
          "billDataForm",
          "successPage",
        ];
        if (props.strReturnScreen != pageStages[0]) {
          pos = pageStages.indexOf(props.strReturnScreen);
          pos = pos - 1;
        }
        if (pos === 1) {
          handleBack("billForm");
        } else if (pos === 0) {
          handleBack("SearchForm");
        } else {
          handleBack("SearchForm");
        }
        return;
      } else if (
        ReturnLayoutCurrentStatusRef.current === "SearchForm" &&
        e.altKey &&
        e.code === "KeyS" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleSubmit();
        return;
      } else if (
        ReturnLayoutCurrentStatusRef.current === "billDataForm" &&
        returnBtn === false &&
        e.altKey &&
        e.code === "KeyS" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        returnSubmit(inputs.billno, inputs.sub_tran_type);
        return;
      } else if (
        ReturnLayoutCurrentStatusRef.current === "billDataForm" &&
        e.altKey &&
        e.code === "KeyA" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        props.setBillNo(inputs.billno);
        handleAddWallet();
        return;
      }
    },
    [
      props.strReturnScreen,
      inputs.billno,
      inputs.sub_tran_type,
      paymentBtn,
      returnBtn,
      returnBillData,
      refundDetails,
      inputs.customerNumber,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListenerNewSaleBilling);
    return () => {
      window.removeEventListener(
        "keydown",
        keyboardEventListenerNewSaleBilling
      );
    };
  }, [keyboardEventListenerNewSaleBilling]);

  useEffect(() => {
    try {
      var key;
      for (key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          if (inputs[key]?.length <= 0) {
            delete inputs[key];
          }
        }
      }
      if (Object.keys(inputs).length > 0) {
        setFetchBtn(false);
        props.setFetchBtn(false);
      } else {
        if (props.strReturnScreen != "SearchForm") {
          setBackBtn(false);
          props.setBackBtn(false);
        }
        setFetchBtn(true);
        props.setFetchBtn(true);
      }
    } catch (err) {
      console.log("returnLayout.useEffectInputs : ", err);
    }
  }, [inputs]);

  useEffect(() => {
    if (otpStatus) {
      const timer = setTimeout(() => {
        setShowCustomerOTPState(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [otpStatus]);

  useEffect(() => {
    returnBillData?.map((item, i) => {
      if (item.bill_qty > 0 && item.sub_tran_type === "AMZNOW") {
        setReturnButton(false);
      }
    });
  }, [returnBillData]);

  const postSummary = (payload) => {
    //Added This line since amazon doesnot have a customer id associated with it, only the order id/bill no
    if (inputs.sub_tran_type === "AMZNOW") {
      payload.searchText = inputs.billno;
    }
    if (inputs.sub_tran_type === "OFFLIN" && payload.searchText === undefined) {
      toast.error("Enter Customer Id / Bill Number", {
        autoClose: 1000,
      });
      setAlertShow(false);
      return;
    }
    try {
      axios.post(address + "getDsReturnSummary/", payload).then((response) => {
        if (response.data.status === "success") {
          setBillDate(response.data.data[0]["b_date"]);
          setReturnTableData(response.data.data);
          props.setReturnBillsListData(response.data.data);
          props.setReturnItemsListData([]);
          setReturnBillData([]);
          setFetchBtn(true);
          props.setFetchBtn(true);
          if (payload.searchBy == "bill" && payload.sub_tran_type == "OFFLIN") {
            if (response["data"]["data"]?.length > 0) {
              setShowLoader(true);
              billidSub(
                response.data.data[0]["bill_no"],
                response.data.data[0]["b_date"]
              );
            }
          } else {
            setShowForm("billForm");
            props.setReturnScreen("billForm");
            setAlertShow(false);
            setAlertMsgShow("");
            setBackBtn(false);
            props.setBackBtn(false);
          }
        } else {
          toast.error(response.data.message, {
            autoClose: 2000,
          });
          props.setReturnItemsListData([]);
          setReturnBillData([]);
          setAlertShow(false);
          setBackBtn(false);
          setFetchBtn(false);
          props.setBackBtn(false);
          setAlertFlagShow("danger");
          setAlertMsgShow(response["data"]["message"]);
        }
      });
    } catch (err) {
      console.log("returnLayout.postSummary : ", err);
    }
  };

  const handleSubmit = (event) => {
    setFetchBtn(true);
    props.setFetchBtn(true);
    setAlertShow(true);
    setAlertFlagShow("info");
    setAlertMsgShow("Searching...");
    var searchTextValue;
    if ("customerNumber" in inputs) {
      searchTextValue = inputs.customerNumber;
      var payload = {
        sub_tran_type: inputs.sub_tran_type,
        searchBy: "cust",
        searchText: searchTextValue,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      postSummary(payload);
    } else if ("billno" in inputs) {
      searchTextValue = inputs.billno;
      var payload = {
        sub_tran_type: inputs.sub_tran_type,
        searchBy: "bill",
        searchText: searchTextValue,
      };
      postSummary(payload);
    } else if (inputs.sub_tran_type === "AMZNOW" && inputs.billno !== "") {
      searchTextValue = inputs.billno;
      var payload = {
        sub_tran_type: "AMZNOW",
        searchBy: "bill",
        searchText: searchTextValue,
      };
      postSummary(payload);
    } else {
      searchTextValue = inputs.billno || inputs.customerNumber;
      var payload = {
        searchText: searchTextValue,
      };
      postSummary(payload);
    }
  };

  const customerValidate = (payload) => {
    try {
      axios.post(address + "getDsCustomer/", payload).then((response) => {
        if (response.status !== "failure") {
          if (inputs.sub_tran_type !== "AMZNOW") {
            setReturnButton(true);
          }
          var existFlag = response["data"]["data"][0]["isCustExisting"];
          var walletBal = response["data"]["data"][0]["wallet_bal"];
          var custId = response["data"]["data"][0]["cust_id"];
          setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
          props.setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
        } else {
          console.log(
            "returnLayout.customerValidate : ",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("returnLayout.customerValidate : ", err);
    }
  };

  const billidSub = (billNo, billDate, order_id) => {
    try {
      setShowForm("billDataForm");
      props.setReturnScreen("billDataForm");
      setBackBtn(false);
      props.setBackBtn(false);
      var payload = {
        sub_tran_type: inputs.sub_tran_type,
        order_id: order_id,
        b_date: billDate,
        bill_no: billNo,
      };
      props.setReturnItemsListData([]);
      setReturnBillData([]);
      props.setNewCustomer({});
      setNewCustomer({});
      axios.post(address + "getDsReturnBillData/", payload).then((response) => {
        setShowLoader(false);
        if (response.data.status !== "failure") {
          var resDict = [];
          response["data"]["data"].map((item) => {
            resDict.push({ ...item, ["return_qty"]: 0 });
          });
          setReturnBillData(resDict);
          props.setReturnItemsListData(resDict);
          var custNo = response["data"]["data"][0]["cust_id"];
          var mobile_no = response["data"]["data"][0]["mobile_no"];
          let orderId = response["data"]["data"][0]["order_id"];
          setInputs({
            ...inputs,
            ["customerNumber"]: custNo,
            ["billno"]: billNo,
            ["billdate"]: billDate,
            ["orderId"]: orderId,
          });
          props.setInputData({
            ...inputs,
            ["customerNumber"]: custNo,
            ["billno"]: billNo,
            ["billdate"]: billDate,
            ["orderId"]: orderId,
          });
          setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: mobile_no,
            ["cust_id"]: custNo,
          });
          props.setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: mobile_no,
            ["cust_id"]: custNo,
          });
          customerValidate({ searchText: mobile_no });
          setBackBtn(false);
          props.setBackBtn(false);
        } else {
          toast.error(response.data.message, {
            autoClose: 1000,
          });
          setShowForm("billForm");
          props.setReturnScreen("billForm");
        }
      });
      setBackBtn(false);
      props.setBackBtn(false);
    } catch (err) {
      console.log("returnLayout.billidSub : ", err);
    }
  };

  const returnDataChange = (event, item, currItemNo) => {
    setReturnButton(false);
    var returnItemData = { ...returnBillData };
    returnItemData = Object.keys(returnItemData).map(
      (key) => returnItemData[key]
    );
    var index = returnItemData.map((o) => o.item).indexOf(currItemNo);
    if (Number(event.target.value) >= 1) {
      returnItemData.splice(index, 1, {
        ...item,
        ["return_qty"]: Number(event.target.value),
        ["reuse_qty"]: Number(event.target.value),
      });
    } else if (Number(event.target.value) <= 0) {
      returnItemData.splice(index, 1, {
        ...item,
        ["return_qty"]: Number("0"),
        ["reuse_qty"]: Number("0"),
      });
    }

    setReturnBillData(returnItemData);
    props.setReturnItemsListData(returnItemData);
    if (refundDetails.refundAmount <= 0) {
      setPaymentBtn(true);
      props.setPaymentBtn(true);
    }
  };

  const returnReusableDataChange = (event, item, currItemNo) => {
    try {
      var returnItemData = { ...returnBillData };
      returnItemData = Object.keys(returnItemData).map(
        (key) => returnItemData[key]
      );
      var index = returnItemData.map((o) => o.item).indexOf(currItemNo);
      if (Number(event.target.value) >= 1) {
        returnItemData.splice(index, 1, {
          ...item,
          ["return_qty"]: document.getElementById(item.item).value,
          ["reuse_qty"]: Number(event.target.value),
        });
      } else if (Number(event.target.value) <= 0) {
        returnItemData.splice(index, 1, {
          ...item,
          ["reuse_qty"]: Number("0"),
        });
      }
      setReturnBillData(returnItemData);

      props.setReturnItemsListData(returnItemData);
      if (refundDetails.refundAmount <= 0) {
        setPaymentBtn(true);
        props.setPaymentBtn(true);
      }
    } catch (err) {
      console.log("returnLayout.returnReusableDataChange : ", err);
    }
  };

  const handleQtyBlurEvent = (event, item, currItemNo, currQty) => {
    searchtextref.current = event.target;
    if (Number(event.target.value) > Number(currQty)) {
      toast.error("Enter valid return quantity.", {
        toastId: "toast-qtyChange",
        autoClose: 1000,
      });
      document.getElementById(currItemNo).focus();
      document.getElementById(currItemNo).value = "0";
    } else if (event.target.value?.length >= 1) {
      returnDataChange(event, item, currItemNo);
    }
  };

  const handleReusableQtyBlurEvent = (event, item, currItemNo, currQty) => {
    searchtextref.current = event.target;
    if (Number(event.target.value) > Number(currQty)) {
      toast.error("Reusable qty can't be greater than Return qty", {
        toastId: "toast-reusableQtyChange",
        autoClose: 1000,
      });
      document.getElementById(currItemNo + "reusable").focus();
      document.getElementById(currItemNo + "reusable").value = "0";
    } else if (event.target.value?.length >= 1) {
      returnReusableDataChange(event, item, currItemNo);
    }
  };

  const returnSubmit = (bill_no = props.strBillorCustomer.billno, checkAMZ) => {
    if (checkAMZ === "AMZNOW") {
      btnSubmitClickForAmazon();
      return;
    }
    try {
      setRefundDetail({});
      props.setRefundDetail({});
      setTransactionData({});
      props.setTransactionData({});
      setPaymentBtn(true);
      props.setPaymentBtn(true);

      var itemData = [];
      returnBillData.map((item, i) => {
        if (Number(item.return_qty) > 0) {
          itemData.push({
            ["cashier_id"]: props.strUserDetails.empCode,
            ["mobile_no"]: newCustomerdetails.mobile_no,
            ["cust_id"]: newCustomerdetails.cust_id,
            ["item"]: item.item,
            ["barcode"]: item.barcode,
            ["item_desc"]: item.item_desc,
            ["bill_qty"]: Number(item.bill_qty),
            ["mrp"]: Number(item.mrp),
            ["discount"]: Number(item.discount),
            ["tax"]: Number(item.tax),
            ["net_amt"]: Number(item.net_amt),
            ["return_qty"]: Number(item.return_qty),
            ["reuse_qty"]: Number(item.reuse_qty),
          });
        }
      });
      var payload = { org_bill_no: bill_no, items: itemData };

      axios.post(address + "getDsReturnRefund/", payload).then((response) => {
        if (response.data.status === "success") {
          setPaymentBtn(false);
          props.setPaymentBtn(false);
          setRefundDetail({
            refundAmount: response["data"]["data"][0]["refundAmount"],
          });
          props.setRefundDetail({
            refundAmount: response["data"]["data"][0]["refundAmount"],
          });
        } else {
          toast.error(response.data.message, {
            autoClose: 1000,
          });
        }
      });
    } catch (err) {
      console.log("returnLayout.returnSubmit : ", err);
    }
  };

  const handleAddWallet = () => {
    try {
      var customerPayload = { searchText: newCustomerdetails.mobile_no };
      axios
        .post(address + "getDsCustomer/", customerPayload)
        .then((response) => {
          response = response.data.data[0];
          if (response.isCustExisting) {
            creditHandler();
          } else {
            buttonClickedHandler();
          }
        });
    } catch (err) {
      console.log("returnLayout.handleAddWallet : ", err);
    }
  };

  const returnTransactionWallet = (payload) => {
    try {
      axios.post(address + "postDsReturnSale/", payload).then((response) => {
        var response = response["data"];
        setShowLoader(false);
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            setInputs({
              ...inputs,
              ["billno"]: response.data[0].bill_no,
              ["basktId"]: response.data[0].basket_id,
              ["wallet_bal"]: response.data[0].wallet_bal,
            });
            props.setInputData({
              ...inputs,
              ["billno"]: response.data[0].bill_no,
              ["basketId"]: response.data[0].basket_id,
              ["wallet_bal"]: response.data[0].wallet_bal,
            });
            setShowForm("successPage");
            props.setReturnScreen("successPage");
          } else {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        } else {
          toast.error(response.message, {
            autoClose: 1000,
          });
        }
      });
    } catch (err) {
      console.log("returnLayout returnTransactionWallet :", err);
    }
  };

  function btnSubmitClickForAmazon() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="w-100 h5">Are you sure you want to submit?</h5>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onKeyDown={() => {
                      let x = checking();

                      if (x) {
                        setShowLoader(true);
                        setTimeout(() => {
                          onClose();
                          setShowLoader(false);
                        }, 2500);
                      }
                    }}
                    onClick={() => {
                      let x = checking();

                      if (x) {
                        setShowLoader(true);
                        setTimeout(() => {
                          onClose();
                          setShowLoader(false);
                        }, 2500);
                      }
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      },
    });
  }

  const checking = () => {
    var itemData = [];
    const restoftheData = {
      bill_no: "",
      order_id: "",
      org_bill_no: "",
      org_bill_date: "",
      sub_tran_type: "",
      items: itemData,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    returnBillData.map((item, i) => {
      if (Number(item.bill_qty) > 0) {
        itemData.push({
          ["cashier_id"]: props.strUserDetails.empCode,
          ["mobile_no"]: item.mobile_no,
          ["cust_id"]: item.cust_id,
          ["item"]: item.item,
          ["barcode"]: item.barcode,
          ["item_desc"]: item.item_desc,
          ["bill_qty"]: Number(item.bill_qty),
          ["mrp"]: Number(item.mrp),
          ["discount"]: Number(item.discount),
          ["tax"]: Number(item.tax),
          ["net_amt"]: Number(item.net_amt),
          ["return_qty"]: item.bill_qty,
          ["reuse_qty"]: item.reuse_qty === undefined ? 0 : item.reuse_qty,
        });
      }
      restoftheData.bill_no = item.bill_no;
      restoftheData.order_id = item.order_id;
      restoftheData.org_bill_no =
        item.org_bill_no === null ? "" : item.org_bill_no;
      restoftheData.org_bill_date = item.b_date;
      restoftheData.sub_tran_type = item.sub_tran_type;
    });
    returnTransactionWallet(restoftheData);
    return true;
  };

  return (
    <>
      {showLoader ? <Loader /> : ""}
      <div className="row trans-tab return">
        <div
          className={
            refundDetails.refundAmount && inputs.sub_tran_type !== "AMZNOW"
              ? "col-9"
              : "col-12"
          }
        >
          {props.strReturnScreen === "SearchForm" ? (
            <SearchForm
              handleChange={handleChange}
              handleBlurEvent={handleBlurEvent}
              handleSubmit={handleSubmit}
              inputs={inputs}
              searchtextref={searchtextref}
              alertShow={alertShow}
              fetchBtn={fetchBtn}
            />
          ) : (
            ""
          )}
          {showForm === "billForm" ? (
            <BillForm
              inputs={inputs}
              strSubTranType={props.strSubTranType}
              returnTableData={returnTableData}
              billidSub={billidSub}
              setFetchBtn={setFetchBtn}
              setShowLoader={setShowLoader}
            />
          ) : (
            ""
          )}
          {props.strReturnScreen === "billDataForm" ? (
            <BillDataForm
              inputs={inputs}
              newCustomerdetails={newCustomerdetails}
              strBillorCustomer={props.strBillorCustomer}
              returnBillData={returnBillData}
              handleQtyBlurEvent={handleQtyBlurEvent}
              returnSubmit={returnSubmit}
              returnDataChange={returnDataChange}
              returnReusableDataChange={returnReusableDataChange}
              handleReusableQtyBlurEvent={handleReusableQtyBlurEvent}
              setReturnButton={setReturnButton}
            />
          ) : (
            ""
          )}

          {props.strReturnScreen === "successPage" ? (
            <SuccessPage
              newCustomerdetails={newCustomerdetails}
              strBillorCustomer={props.strBillorCustomer}
            />
          ) : (
            ""
          )}
        </div>

        <div className="col-3">
          {refundDetails.refundAmount && inputs.sub_tran_type !== "AMZNOW" ? (
            <RefundDetails
              refundDetails={refundDetails}
              setBillNo={props.setBillNo}
              handleAddWallet={handleAddWallet}
              paymentBtn={paymentBtn}
              inputs={inputs}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-12"
          style={{
            position: "fixed",
            bottom: 0,
            // alignSelf: "flex-end",
            marginBottom: ".8rem",
          }}
        >
          {/* <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-12">
              <KeyboardLayout
                ref={searchtextref}
                fnCallBack={processSearchText}
              />
            </div>
          </div> */}
          <div className="row ">
            {props.strReturnScreen !== "SearchForm" ? (
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="col-md-12 d-flex flex-row"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        className="numBtnCustom btn btn-primary btn-lg col-3 "
                        disabled={backBtn}
                        onClick={() => handleBack()}
                      >
                        <u>B</u>ack
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.strReturnScreen === "billDataForm" ? (
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="col-md-12 d-flex flex-row-reverse"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        className="numBtnCustom btn btn-primary btn-lg col-3 ml-10"
                        disabled={returnBtn}
                        onClick={() =>
                          returnSubmit(inputs.billno, inputs.sub_tran_type)
                        }
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <u>S</u>ubmit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <UserRegister
        show={showCustomerState}
        button_clicked={() => {
          setShowCustomerState(!showCustomerState);
        }}
        customerValidate={customerValidate}
        getCustomerName={""}
        customerNumber={newCustomerdetails.mobile_no}
        disableField={newCustomerdetails.mobile_no !== "" ? true : false}
      />
      <OtpModal
        show={showCustomerOTPState}
        button_clicked={() => {
          setShowCustomerOTPState(!showCustomerOTPState);
        }}
        customerNumber={newCustomerdetails.mobile_no}
        setOtpStatusFunc={handleOtpStatus}
      />
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strReturnBillsData: reduxState?.returnSale?.returnBillsListData,
    strReturnBillItemsData: reduxState?.returnSale?.returnItemsListData,
    strReturnScreen: reduxState?.returnSale?.returnScreen,
    strBillorCustomer: reduxState?.returnSale?.BillorCustomer,
    strBackBtn: reduxState?.returnSale?.backBtn,
    strFetchBtn: reduxState?.returnSale?.fetchBtn,
    strReturnDataList: reduxState?.returnSale?.returnDataList,
    strCustomerDetails: reduxState?.returnSale?.newCustomerDetails,
    strUserDetails: reduxState?.login?.userDetailsData,
    strRefundDetails: reduxState?.returnSale?.refundDetails,
    strPaymentBtn: reduxState?.returnSale?.paymentBtn,
    strTransactionData: reduxState?.returnSale?.transactionData,
    strBillNo: reduxState?.returnSale?.billNo,
    strSubTranType: reduxState?.returnSale?.sub_tran_type_check,
    strReturnItemsListData: reduxState?.returnSale?.returnItemsListData,
    strCustomerDetailsData: reduxState?.customer?.customerDetailsData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setReturnBillsListData: (itemsList) =>
      dispatch(returnActions.setReturnBillsListData(itemsList)),
    setReturnItemsListData: (itemsList) =>
      dispatch(returnActions.setReturnItemsListData(itemsList)),
    setReturnDataList: (itemsList) =>
      dispatch(returnActions.setReturnDataList(itemsList)),
    setReturnScreen: (screenName) =>
      dispatch(returnActions.setReturnScreen(screenName)),
    setInputData: (inputData) =>
      dispatch(returnActions.setInputData(inputData)),
    setNewCustomer: (CustomerNo) =>
      dispatch(returnActions.setNewCustomer(CustomerNo)),
    setRefundDetail: (refundData) =>
      dispatch(returnActions.setRefundDetail(refundData)),
    setFetchBtn: (inputs) => dispatch(returnActions.setFetchBtn(inputs)),
    setBackBtn: (inputs) => dispatch(returnActions.setBackBtn(inputs)),
    setPaymentBtn: (inputs) => dispatch(returnActions.setPaymentBtn(inputs)),
    setBillNo: (billNo) => dispatch(returnActions.setBillNo(billNo)),
    setTransactionData: (refundTranData) =>
      dispatch(returnActions.setTransactionData(refundTranData)),
    setSubTransType: (inputSubTranValue) => {
      dispatch(returnActions.setSubTransType(inputSubTranValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLayout);
