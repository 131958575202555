import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../appconfig.json";
import { toast } from "react-toastify";
import { moreRewardsActions } from "../../components/store/MoreRewards/MoreRewardsStore";
import { useCommonSelectors } from "../selector";

const CustomerCheck = ({
  showCustomerName,
  extraFunctionCall = () => {},
  setShowCardUpi = "",
  handleCustomerOtpStatus = "",
  reset = "",
  loader,
  setLoader = "",
  setShowCustomerOTPState = () => {},
  showCustId,
}) => {
  //   const strIsValidCustomer = useSelector(
  //     (state) => state?.moreRewards?.isValidCustomer
  //   );
  const walletBalanceScreen = useSelector((state) => state.moreRewards.screen);
  //   const userDetails = useSelector((state) => state.login.userDetailsData);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.customerDetailsData
  );
  const isCustExisting = useSelector(
    (state) => state.moreRewards.customerDetailsData.isCustExisting
  );
  const otpStatusForWalletTopUp = useSelector(
    (state) => state.moreRewards.otpStatus
  );
  const sucessMesage = useSelector(
    (state) => state.moreRewards.rechargeSuccess
  );
  const dispatch = useDispatch();
  const address = appConfig.api_url;
  const [showCustomerState, setShowCustomerState] = useState(false);
  const [mobileNumber, setmobileNumber] = useState("");
  function frmCustomerSubmit(event) {
    event.preventDefault();
  }
  useEffect(() => {
    if (!showCustomerState) {
      setShowCustomerState(false);
    }
  }, [showCustomerState]);

  function validateSubmitCustomer() {
    const frmCustId = document.getElementById("walletTop.searchCustomer").value;
    if (frmCustId !== "" && frmCustId.length < 10) {
      toast.error("Enter the valid Customer Mobile Number", {
        autoClose: 1000,
      });
    } else {
      getCustomerDetails();
    }
  }
  async function getCustomerDetails(forNo) {
    try {
      var custid = document.getElementById("walletTop.searchCustomer").value;
      dispatch(moreRewardsActions.setCustomerNumber(custid));
      setLoader(true);
      let item = {
        searchText: custid,
        basket_id: "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let result = await fetch(address + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          if (result.data[0].isCustExisting === false) {
            setShowCustomerOTPState(false);
            setLoader(false);
            toast.error("Customer Not Yet Registered");

            return;
          }
          dispatch(moreRewardsActions.setIsValidCustomer(true));
          dispatch(
            moreRewardsActions.setCustomerDetailsData(result["data"][0])
          );
          setLoader(false);
          if (walletBalanceScreen === "checkWalletBalance") {
            extraFunctionCall({
              cust_id: result["data"][0]["cust_id"] || "",
              customer_number: result["data"][0]["customer_number"],
              tran_ref_number: "",
            });
          } else if (walletBalanceScreen === "walletTopUp") {
            extraFunctionCall();
            handleCustomerOtpStatus();
            getBasketIDForWalletTopUp();
          } else if (walletBalanceScreen === "referralProgram") {
            extraFunctionCall();
          }
          setShowCustomerOTPState(true);
          setLoader(false);
        } else {
          setShowCustomerOTPState(false);
          dispatch(moreRewardsActions.setIsValidCustomer(false));
          dispatch(moreRewardsActions.setCustomerDetailsData([]));
          toast.error(result["message"], {
            autoClose: 1000,
          });
          setLoader(false);
        }
      } else {
        setLoader(false);
        setShowCustomerOTPState(false);
        console.log(
          "searchLayout-getCustomerDetails-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-getCustomerDetails :", err);
    }
  }
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const basketId = useSelector((state) => state.moreRewards?.basketID);

  const getBasketIDForWalletTopUp = async () => {
    try {
      let payload = {
        event: "wallet_confirm",
        cust_id: strCustomerDetails.cust_id,
        customer_number: document.getElementById("walletTop.searchCustomer")
          .value,
        cashier_id: cashier_id,
        basket_id: "",
        sub_tran_type: "WALLET",
      };
      let result = await fetch(address + "postDsWalletRecharge/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          dispatch(moreRewardsActions.setBasketID(result.data[0].basket_id));
        }
      } else {
        console.log(
          "searchLayout-WalletTopUp-postDsWalletRecharge-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-WalletTopUp-postDsWalletRecharge :", err);
    }
  };

  const handleReset = () => {
    dispatch(moreRewardsActions.setCustomerDetailsData([]));
    dispatch(moreRewardsActions.walletBalanceData([]));
    setmobileNumber("");
    dispatch(moreRewardsActions.setBasketID(""));
    dispatch(moreRewardsActions.setIsValidCustomer(false));
    if (setShowCardUpi !== "") {
      setShowCardUpi();
    }
    dispatch(moreRewardsActions.setWalletRechargeData([]));
    dispatch(moreRewardsActions.setRechargeSucces(""));
    dispatch(moreRewardsActions.setRechargeData([]));
    dispatch(moreRewardsActions.setOtpStatus(false));
    dispatch(moreRewardsActions.setCashAmountForRecharge(""));
    dispatch(moreRewardsActions.morePointBalanceData([]));
    if (walletBalanceScreen === "referralProgram") {
      reset();
      setmobileNumber("");
      document.getElementById("walletTop.searchCustomer").value = "";
    }
    setLoader(false);
  };

  return (
    <div
      style={{
        marginLeft: "-1.4rem",
      }}
    >
      <div className="search-tab">
        <div className="row">
          <div className="col-md-5">
            <form onSubmit={frmCustomerSubmit}>
              <div className="input-group">
                <input
                  style={{ borderRadius: "0px" }}
                  id="walletTop.searchCustomer"
                  name="walletTop.searchCustomer"
                  type="number"
                  value={mobileNumber}
                  maxLength={10}
                  disabled={isCustExisting ? true : false}
                  className="form-control "
                  placeholder="Customer Number"
                  onChange={(e) => {
                    const check = e.target.value;
                    if (check.length === 11) {
                      return;
                    }
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setmobileNumber(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (mobileNumber.length !== 10) {
                        toast.error("Enter a Valid Mobile Number", {
                          autoClose: 1500,
                        });
                        return;
                      }
                      if (!loader) {
                        validateSubmitCustomer();
                      }
                    }
                  }}
                  autoComplete="off"
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  disabled={isCustExisting ? true : false}
                  onClick={() => {
                    if (mobileNumber.length !== 10) {
                      toast.error("Enter a Valid Mobile Number", {
                        autoClose: 1500,
                      });
                      return;
                    }
                    validateSubmitCustomer();
                  }}
                >
                  {loader ? (
                    <label
                      className="spinner-border"
                      style={{
                        width: ".8rem",
                        height: ".8rem",
                      }}
                    ></label>
                  ) : (
                    <label>Submit</label>
                  )}
                </button>
                <button
                  disabled={sucessMesage === "BILLBALANCE"}
                  className="btn btn-primary"
                  style={{
                    marginLeft: "1.1rem",
                    borderRadius: "0px",
                    width: "117px",
                    marginRight: "0.7rem",
                  }}
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: 50,
          }}
        >
          {showCustomerName && (
            <div>
              <div className="form-text">
                <b>
                  Customer :{" "}
                  <span style={{ color: "#f04e23" }} id="searchLayout.userName">
                    {strCustomerDetails["custName"]}
                  </span>
                </b>
              </div>
            </div>
          )}

          {showCustId && (
            <div>
              <div className="form-text">
                <b>
                  Customer ID :{" "}
                  <span style={{ color: "#f04e23" }} id="searchLayout.userName">
                    {strCustomerDetails["cust_id"]}
                  </span>
                </b>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerCheck;
