import React from "react";
import { toast } from "react-toastify";

const BillDataForm = ({
  inputs,
  newCustomerdetails,
  strBillorCustomer,
  returnBillData,
  handleQtyBlurEvent,
  returnSubmit,
  returnDataChange,
  returnReusableDataChange,
  handleReusableQtyBlurEvent,
  setReturnButton,
}) => {
  return (
    <>
      <div className="row" style={{ paddingTop: "15px" }}>
        <div className="col-12">
          <div className="row">
            <div className="col-4 text-field">
              {inputs.sub_tran_type !== "AMZNOW"
                ? `Customer Number :`
                : "Order Id : "}
              <span className="text-primary">
                {newCustomerdetails.mobile_no || inputs.orderId}
              </span>
            </div>
            <div className="col-4 text-field">
              Bill No :
              <span className="text-primary">
                {inputs.billno || strBillorCustomer.billno}
              </span>
            </div>
            <div className="col-4 text-field">
              Bill Date :
              <span className="text-primary">
                {inputs.billdate || strBillorCustomer.billdate}
              </span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                height: 70.7 + "vh",
                backgroundColor: "white",
                overflow: "auto",
                border: "solid 0px #000000",
              }}
            >
              <table className="table table-bordered table-sm newsaletable">
                <thead className="table-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Item Number</th>
                    <th scope="col">Desc</th>
                    <th scope="col">Qty</th>
                    {inputs.sub_tran_type !== "AMZNOW" && (
                      <th scope="col">
                        <span>Unit Price</span>
                        <span style={{ display: "inline-block" }}>( ₹ )</span>
                      </th>
                    )}
                    {inputs.sub_tran_type !== "AMZNOW" && (
                      <th scope="col">
                        Discount
                        <br /> ( ₹ )
                      </th>
                    )}
                    {inputs.sub_tran_type !== "AMZNOW" && (
                      <th scope="col">
                        Amt <br /> ( ₹ )
                      </th>
                    )}
                    <th scope="col">Return Qty</th>
                    <th scope="col">Reuseable Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {returnBillData?.map((object, i) => {
                    let returnField, reusableField;

                    // return qty Field
                    if (object.uom === "EA") {
                      returnField = (
                        <input
                          type="number"
                          className="form-control text-end"
                          id={object.item}
                          defaultValue={
                            object.sub_tran_type === "AMZNOW"
                              ? object.bill_qty
                              : object.return_qty || "0"
                          }
                          // value={ || ""}
                          disabled={object.sub_tran_type === "AMZNOW"}
                          onBlur={(event) => {
                            handleQtyBlurEvent(
                              event,
                              object,
                              object.item,
                              object.qty
                            );
                          }}
                          min={0}
                          onClick={() => {
                            document.getElementById(object.item).value = "0";
                            document.getElementById(object.item).select();
                          }}
                          max={Number(object.qty)}
                          onKeyDown={(event) => {
                            if (event.key === ".") {
                              event.preventDefault();
                            }
                            if (event.key === "Enter") {
                              returnSubmit(inputs.billno);
                            }
                          }}
                          onPaste={(event) => {
                            let pasteData = event.clipboardData.getData("text");
                            if (pasteData) {
                              pasteData.replace(/^[0-9]*$/g, "");
                            }
                          }}
                          onChange={(event) => {
                            if (
                              Number(event.target.value) > Number(object.qty)
                            ) {
                              toast.error("Enter valid return quantity.", {
                                toastId: "toast-qtyChange",
                                autoClose: 1000,
                              });
                              document.getElementById(object.item).focus();
                              document.getElementById(object.item).value = "0";
                            } else if (event.target.value?.length >= 1) {
                              setReturnButton(false);

                              returnDataChange(event, object, object.item);
                            }

                            document.getElementById(
                              object.item + "reusable"
                            ).value = document.getElementById(
                              object.item
                            ).value;

                            returnReusableDataChange(
                              event,
                              object,
                              object.item
                            );
                          }}
                        />
                      );
                    } else {
                      returnField = (
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-label="Sizing input"
                          id={object.item}
                          // defaultValue={object.return_qty || "0"}
                          defaultValue={
                            object.sub_tran_type === "AMZNOW"
                              ? object.bill_qty
                              : object.return_qty || "0"
                          }
                          disabled={object.sub_tran_type === "AMZNOW"}
                          // value={ || ""}
                          min={0}
                          onClick={() => {
                            document.getElementById(object.item).value = "0";
                            document.getElementById(object.item).select();
                          }}
                          onBlur={(event) => {
                            handleQtyBlurEvent(
                              event,
                              object,
                              object.item,
                              object.qty
                            );
                          }}
                          onPaste={(event) => {
                            let pasteData = event.clipboardData.getData("text");
                            if (pasteData) {
                              pasteData.replace(/^[0-9].*$/g, "");
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              returnSubmit(inputs.billno);
                            }
                          }}
                          onChange={(event) => {
                            if (
                              Number(event.target.value) > Number(object.qty)
                            ) {
                              toast.error("Enter valid return quantity.", {
                                toastId: "toast-qtyChange",
                                autoClose: 1000,
                              });
                              document.getElementById(object.item).value = "0";
                              document.getElementById(object.item).focus();
                            } else {
                              returnDataChange(event, object, object.item);
                            }

                            document.getElementById(
                              object.item + "reusable"
                            ).value = document.getElementById(
                              object.item
                            ).value;

                            returnReusableDataChange(
                              event,
                              object,
                              object.item
                            );
                          }}
                        />
                      );
                    }

                    // reusable qty Field
                    if (object.uom === "EA") {
                      reusableField = (
                        <input
                          type="number"
                          className="form-control text-end"
                          id={object.item + "reusable"}
                          defaultValue={object.reuse_qty || "0"}
                          // value={ || ""}
                          onBlur={(event) => {
                            handleReusableQtyBlurEvent(
                              event,
                              object,
                              object.item,
                              object.qty
                            );
                          }}
                          min={0}
                          onClick={() => {
                            document.getElementById(
                              object.item + "reusable"
                            ).value = "0";
                            document
                              .getElementById(object.item + "reusable")
                              .select();
                          }}
                          max={Number(object.qty)}
                          onKeyDown={(event) => {
                            if (event.key === ".") {
                              event.preventDefault();
                            }
                            if (event.key === "Enter") {
                              returnSubmit(inputs.billno, inputs.sub_tran_type);
                            }
                          }}
                          onPaste={(event) => {
                            let pasteData = event.clipboardData.getData("text");
                            if (pasteData) {
                              pasteData.replace(/^[0-9]*$/g, "");
                            }
                          }}
                          onChange={(event) => {
                            if (
                              Number(event.target.value) > Number(object.qty) ||
                              Number(event.target.value) >
                                Number(
                                  document.getElementById(object.item).value
                                )
                            ) {
                              toast.error(
                                "Reusable qty can't be greater than Return qty",
                                { toastId: "toast-qtyChange", autoClose: 1000 }
                              );
                              document
                                .getElementById(object.item + "reusable")
                                .focus();
                              document.getElementById(
                                object.item + "reusable"
                              ).value = "0";
                            } else if (event.target.value?.length >= 1) {
                              returnReusableDataChange(
                                event,
                                object,
                                object.item
                              );
                            }
                          }}
                        />
                      );
                    } else {
                      reusableField = (
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-label="Sizing input"
                          id={object.item + "reusable"}
                          defaultValue={object.reuse_qty || "0"}
                          min={0}
                          onClick={() => {
                            document.getElementById(
                              object.item + "reusable"
                            ).value = "0";
                            document
                              .getElementById(object.item + "reusable")
                              .select();
                          }}
                          onBlur={(event) => {
                            handleReusableQtyBlurEvent(
                              event,
                              object,
                              object.item,
                              object.qty
                            );
                          }}
                          onPaste={(event) => {
                            let pasteData = event.clipboardData.getData("text");
                            if (pasteData) {
                              pasteData.replace(/^[0-9].*$/g, "");
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              returnSubmit(inputs.billno, inputs.sub_tran_type);
                            }
                          }}
                          onChange={(event) => {
                            if (
                              Number(event.target.value) > Number(object.qty) ||
                              Number(event.target.value) >
                                Number(
                                  document.getElementById(object.item).value
                                )
                            ) {
                              toast.error(
                                "Reusable qty can't be greater than Return qty",
                                { toastId: "toast-qtyChange", autoClose: 1000 }
                              );
                              document
                                .getElementById(object.item + "reusable")
                                .focus();
                              document.getElementById(
                                object.item + "reusable"
                              ).value = "0";
                            } else if (event.target.value?.length >= 1) {
                              returnReusableDataChange(
                                event,
                                object,
                                object.item
                              );
                            }
                          }}
                        />
                      );
                    }
                    return (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{object.item}</td>
                        <td>{object.item_desc}</td>
                        <td className="text-center">{object.qty}</td>
                        {inputs.sub_tran_type !== "AMZNOW" && (
                          <td className="text-end">{object.mrp}</td>
                        )}
                        {inputs.sub_tran_type !== "AMZNOW" && (
                          <td className="text-end">{object.discount}</td>
                        )}
                        {inputs.sub_tran_type !== "AMZNOW" && (
                          <td className="text-end">{object.net_amt}</td>
                        )}
                        <td className="text-end">
                          <div className="col-xs-1">
                            <div className="input-group input-group-sm">
                              {returnField}
                              <span
                                className="input-group-text"
                                id="inputGroup-sizing-sm"
                              >
                                {object.uom}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <div className="col-xs-1">
                            <div className="input-group input-group-sm">
                              {reusableField}
                              <span
                                className="input-group-text"
                                id="inputGroup-sizing-sm"
                              >
                                {object.uom}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillDataForm;
