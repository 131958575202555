import {
  Box,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";

const ModalViewGst = ({ showGstModel, setShowGstModel, gstData }) => {
  return (
    <div className="viewGst">
      <Modal
        show={showGstModel || false}
        className="my-modal-gst"
        onHide={() => {
          setShowGstModel(!showGstModel);
        }}
      >
        <Modal.Header
          closeButton
          style={{
            width: "100%",
          }}
        >
          <Modal.Title
            style={{
              color: "#f04e23",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            GST Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            fontWeight: "500",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="center">Address 1</TableCell>
                  <TableCell align="center">Address 2</TableCell>
                  <TableCell align="center">City</TableCell>
                  <TableCell align="center">Pincode</TableCell>
                  <TableCell align="center">GSTIN Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gstData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      color: "#f04e23",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.company_name}
                    </TableCell>
                    <TableCell align="center">{row.address_1}</TableCell>
                    <TableCell align="center">{row.address_2}</TableCell>
                    <TableCell align="center">{row.city}</TableCell>
                    <TableCell align="center">{row.pincode}</TableCell>
                    <TableCell align="center">{row.gsttin_no}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <button
            style={{
              marginTop: "1rem",
            }}
            className="btn viewGstModal"
            onClick={() => {
              setShowGstModel(!showGstModel);
            }}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalViewGst;
