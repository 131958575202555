import React from "react";
import DataGridForReturnLayout from "./DataGridForReturnLayout";

const BillForm = ({
  inputs,
  strSubTranType,
  returnTableData,
  billidSub,
  setFetchBtn,
  setShowLoader,
}) => {
  return (
    <>
      <div className="row" style={{ paddingTop: "15px" }}>
        <div className="col-12">
          <div className="row">
            <div className="col-12 text-field">
              {strSubTranType !== "AMZNOW" ? `Customer Number / ID :` : ""}

              <span className="text-primary">{inputs.customerNumber}</span>
            </div>
          </div>
          {/*  <div className="row">
            <div
              className="col-md-12"
              style={{
                height: 65 + "vh",
                backgroundColor: "white",
                overflow: "auto",
                border: "solid 0px #000000",
              }}
            >
              <table className="table table-bordered table-sm newsaletable">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Bill Number</th>
                    {inputs.sub_tran_type === "AMZNOW" && (
                      <th scope="col">Order Id</th>
                    )}
                    {inputs.sub_tran_type !== "AMZNOW" && (
                      <>
                        <th scope="col">Customer Number</th>
                        <th scope="col">Bill Amount ( ₹ ) </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {returnTableData?.map((object, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">{object.b_date}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary btnTableButton"
                            onClick={() => {
                              billidSub(
                                object.bill_no,
                                object.b_date,
                                object.order_id
                              );
                              setFetchBtn(true);
                              setShowLoader(true);
                            }}
                          >
                            {object.bill_no}
                          </button>
                        </td>
                        {/* changing the value for order_id when inputs has AMZNOW   
                        {inputs.sub_tran_type === "AMZNOW" && (
                          <td className="text-center">{object.order_id}</td>
                        )}
                        {/* changing the value for order_id when inputs has AMZNOW   
                        {inputs.sub_tran_type !== "AMZNOW" && (
                          <>
                            <td className="text-center">{object.mobile_no}</td>
                            <td className="text-end">
                              {object.returnable_value}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div> */}

          <DataGridForReturnLayout
            inputs={inputs}
            returnTableData={returnTableData}
            billidSub={billidSub}
            setFetchBtn={setFetchBtn}
            setShowLoader={setShowLoader}
          />
        </div>
      </div>
    </>
  );
};

export default BillForm;
