import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletTopUp from "./WalletTopUp/WalletTopUp";
import ReferralProgram from "./ReferralProgram/ReferralProgram";
// import ChangeExistingNumber from "./ChangeExistingNumber/ChangeExistingNumber";
import CheckWalletBalance from "./CheckWalletBalance/CheckWalletBalance";
import Faq from "./Faq/Faq";
import { moreRewardsActions } from "../store/MoreRewards/MoreRewardsStore";
import StoreCardBalance from "./StoreCardBalance/StoreCardBalance";
import MorePlusRewards from "./MorePlusRewards/MorePlusRewards";

const MoreRewardsLayout = () => {
  useEffect(() => {
    document.documentElement.requestFullscreen();
  }, []);
  const [selectedPage, setSelectedPage] = useState("");
  const moreRewardScreen = useSelector((state) => state.moreRewards.screen);
  const sucessMesage = useSelector(
    (state) => state.moreRewards.walletTopUp.rechargeSuccess
  );

  const dispatch = useDispatch();
  const handleSelectedPage = (value) => {
    setSelectedPage(value);
    dispatch(moreRewardsActions.setRewardScreen(value));
    dispatch(moreRewardsActions.setLastRewardScreen(value));
    dispatch(moreRewardsActions.setIsValidCustomer(false));
    dispatch(moreRewardsActions.setCustomerDetailsData([]));
    dispatch(moreRewardsActions.setOtpStatus(false));
    dispatch(moreRewardsActions.walletBalanceData([]));
    dispatch(moreRewardsActions.setBasketID(""));
    dispatch(moreRewardsActions.setWalletRechargeData([]));
    dispatch(moreRewardsActions.setRechargeSucces(""));
    dispatch(moreRewardsActions.setRechargeData([]));
    dispatch(moreRewardsActions.setCashAmountForRecharge(""));
  };

  useEffect(() => {
    if (sucessMesage === "BILLBALANCE") {
      dispatch(moreRewardsActions.setRewardScreen("walletTopUp"));
      setSelectedPage("walletTopUp");
    }
    if (sucessMesage !== "BILLBALANCE") {
      dispatch(moreRewardsActions.setRewardScreen(""));
    }
    dispatch(moreRewardsActions.setIsValidCustomer(false));
    dispatch(moreRewardsActions.setOtpStatus(false));
  }, [dispatch, sucessMesage]);

  const btnStyling = {
    backgroundColor: "#f04e23",
    width: "15rem",
    height: "3rem",
    color: "white",
    borderRadius: "0px",
    fontSize: "0.6rem",
    "&: hover": {
      backgroundColor: "#f04e23",
    },
  };

  const btnStylingLight = {
    backgroundColor: "#e4e7ea",
    width: "15rem",
    height: "3rem",
    color: "#293845",
    fontSize: "0.6rem",

    "&: hover": {
      backgroundColor: "#e4e7ea",
    },
    borderRadius: "0px",
  };

  const [showFaq, setShowFaq] = useState(false);

  const handleShowFaq = () => {
    setShowFaq(!showFaq);
  };

  return (
    <>
      <Box
        mt={2}
        gap={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: "0.7rem",
          paddingRight: "1.8rem",
        }}
      >
        <Button
          disabled={true}
          onClick={() => {
            handleSelectedPage("walletTopUp");
          }}
          sx={moreRewardScreen === "walletTopUp" ? btnStyling : btnStylingLight}
        >
          Wallet Top Up
        </Button>
        <Button
          disabled={sucessMesage === "BILLBALANCE"}
          onClick={() => {
            handleSelectedPage("storeCardBalance");
          }}
          sx={
            moreRewardScreen === "storeCardBalance"
              ? btnStyling
              : btnStylingLight
          }
        >
          Store Card Reedem
        </Button>
        {/* <Button
          disabled={sucessMesage === "BILLBALANCE"}
          onClick={() => {
            handleSelectedPage("referralProgram");
          }}
          sx={
            moreRewardScreen === "referralProgram"
              ? btnStyling
              : btnStylingLight
          }
        >
          Referral Program
        </Button> */}
        {/* <Button
          onClick={() => {
            handleSelectedPage("changeExistingNumber");
          }}
          disabled={true}
          sx={
            moreRewardScreen === "changeExistingNumber"
              ? btnStyling
              : btnStylingLight
          }
        >
          Change Existing Number
        </Button> */}
        <Button
          disabled={sucessMesage === "BILLBALANCE"}
          onClick={() => {
            handleSelectedPage("checkWalletBalance");
          }}
          sx={
            moreRewardScreen === "checkWalletBalance"
              ? btnStyling
              : btnStylingLight
          }
        >
          Check Wallet/Points Balance
        </Button>
        <Button
          disabled={sucessMesage === "BILLBALANCE"}
          onClick={() => {
            handleSelectedPage("morePlusRewards");
          }}
          sx={
            moreRewardScreen === "morePlusRewards"
              ? btnStyling
              : btnStylingLight
          }
        >
          More+ Diamond Subscription
        </Button>
        <Button
          disabled={sucessMesage === "BILLBALANCE"}
          onClick={() => {
            handleSelectedPage("faq");
            handleShowFaq();
          }}
          sx={moreRewardScreen === "faq" ? btnStyling : btnStylingLight}
        >
          FAQ
        </Button>
      </Box>
      <Box pl={3}>
        {selectedPage === "walletTopUp" && <WalletTopUp />}
        {selectedPage === "referralProgram" && <ReferralProgram />}
        {/* {selectedPage === "changeExistingNumber" && <ChangeExistingNumber />} */}
        {selectedPage === "checkWalletBalance" && <CheckWalletBalance />}
        {selectedPage === "faq" && (
          <Faq showFaq={showFaq} handleFaqModal={handleShowFaq} />
        )}
        {selectedPage === "storeCardBalance" && <StoreCardBalance />}
        {selectedPage === "morePlusRewards" && <MorePlusRewards />}
      </Box>
    </>
  );
};

export default MoreRewardsLayout;
