import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import loginReducer from "./login";
import commonReducer from "./commonStore";
import customerReducer from "./customerStore";
import newSaleReducer from "./newSaleStore";
import returnReducer from "./returnSaleStore";
import moreReducer from "./MoreRewards/MoreRewardsStore";

const persistConfig = {
  key: "root",
  storage: storageSession,
  // storage: storage,
};

const rootReducer = combineReducers({
  login: loginReducer,
  common: commonReducer,
  customer: customerReducer,
  newSale: newSaleReducer,
  returnSale: returnReducer,
  moreRewards: moreReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const appStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
// export default appStore
export const persistor = persistStore(appStore);
