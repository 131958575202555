import React from "react";

const RefundDetails = ({
  refundDetails,
  setBillNo,
  handleAddWallet,
  paymentBtn,
  inputs,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <div className="refundAmountContainer col-12">
                Refund Amount
                <strong>( ₹ ) : {refundDetails.refundAmount}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                className="numBtnCustom btn btn-primary btn-lg col-12"
                disabled={paymentBtn}
                onClick={() => {
                  setBillNo(inputs.billno);
                  handleAddWallet();
                }}
              >
                <u>A</u>dd to Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundDetails;
