import React, { useEffect, useRef } from "react";
import c3 from "c3";
import "c3/c3.css";
const BarChartDiamondSubs = ({
  noOfVisits = 0,
  noOfSubs = 0,
  headerText = "",
  diamonPercentage = 0,
}) => {
  const chart1Ref = useRef(null);

  useEffect(() => {
    // Data for the first chart
    // noOfSubs = 4;
    // noOfVisits = 10;
    var colors = [
      "#267332",
      noOfSubs < 5
        ? "#8E1C15"
        : noOfSubs >= 5 && noOfSubs <= 10
        ? "#FFD700"
        : "#267332",
    ];
    // Data for the second chart
    const data1 = {
      columns: [["", noOfVisits, noOfSubs]],
      type: "bar",
      labels: true,
      color: function (color, d) {
        return colors[d.index];
      },
    };

    // Configuration for the first chart
    const chart1Config = {
      bindto: chart1Ref.current,
      data: data1,
      bar: {
        width: {
          ratio: 0.4, // this makes bar width 50% of length between ticks
        },
      },
      size: {
        width: 280, // Set the width of the chart
        height: 220, // Set the height of the chart
      },
      axis: {
        x: {
          label: {
            text: "",
            position: "outer-center",
          },

          type: "category",
          // categories: [10, 20, 30, 40, 50],
          categories: ["Customers Visited", "Customer Subscribed"], // define the x-axis categories
          tick: {
            multiline: false, // make sure the tick label is not multiline
          },
        },
        y: {
          label: {
            text: "",
            position: "outer-middle",
          },

          max: Math.ceil((noOfVisits + 10) / 100) * 100,
          min: 0,
          padding: {
            top: 0,
            bottom: 0,
          },
        },
      },
      legend: {
        show: false,
      },
    };
    const yAxisLine = chart1Ref.current.querySelector(".c3-axis-y path");
    if (yAxisLine) {
      yAxisLine.style.strokeWidth = "1px"; // Decrease the width of the y-axis line
    }

    // Customizing x-axis arrow
    const xAxisLine = chart1Ref.current.querySelector(".c3-axis-x path");
    if (xAxisLine) {
      xAxisLine.setAttribute("marker-end", "url(#arrowhead)"); // Add arrow marker to the end of x-axis
    }
    // Configuration for the second chart

    // Generate charts
    const chart1 = c3.generate(chart1Config);

    // Cleanup function to destroy the charts when the component unmounts
    return () => {
      chart1.destroy();
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div className="col rounded border border-dark p-0">
      <div className="fs-6 fw-bold px-2 mt-1">
        {headerText}
        <span
          style={{
            color: "#f04e23",
          }}
        >
          {diamonPercentage}%
        </span>
      </div>

      <div className="d-flex mt-1 justify-content-center">
        <div ref={chart1Ref}></div>
      </div>
    </div>
  );
};

export default BarChartDiamondSubs;
