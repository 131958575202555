import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import appConfig from "../../../appconfig.json";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";

const OtpForWalletTopUp = ({
  show,
  button_clicked,
  customerNumber,
  setOtpStatusFunc,
}) => {
  const [inputs, setInputs] = useState({});
  const [userOTP, setUserOTP] = useState("");
  const [sendOtpFlag, setOTPbtn] = useState(false);
  const [validOtpFlag, setValidOTPbtn] = useState(true);
  const [otpfieldFlag, setOtpField] = useState(true);
  const [timerSpan, setTimerDisplay] = useState("None");
  const [OTPtimer, setTimer] = useState("");
  const [alertFlag, setAlertFlag] = useState("info");
  const pos_id = useSelector((state) => state.login?.userDetailsData["pos_id"]);

  const dispatch = useDispatch();
  var submitBtn;

  function setSubmitButton(flag) {
    submitBtn = flag;
  }
  const address = appConfig.api_url;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const keyboardEventListenerNewSaleBilling = useCallback(
    (e) => {
      if (
        e.altKey &&
        e.code === "KeyD" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        setSubmitButton("send");

        handleSubmitForShortcutKey();
        return;
      } else if (
        e.altKey &&
        e.code === "KeyM" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        setSubmitButton("validate");
        handleSubmitForShortcutKey();
        return;
      }
    },
    [submitBtn]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListenerNewSaleBilling);
    return () => {
      window.removeEventListener(
        "keydown",
        keyboardEventListenerNewSaleBilling
      );
    };
  }, [keyboardEventListenerNewSaleBilling]);

  const intervalRef = useRef(null);

  const OTPCounter = (timexpries) => {
    try {
      intervalRef.current = setInterval(function () {
        if (timexpries <= 0) {
          setValidOTPbtn(true);
          setOTPbtn(false);
          setOtpField(true);
          setUserOTP("");
          setAlertFlag("danger");
          setTimer("OTP Expired.. Resend");
          clearInterval(intervalRef.current);
        }
        var min = 0;
        var sec = 0;
        min = ~~((timexpries % 3600) / 60);
        sec = ~~timexpries % 60;
        setAlertFlag("primary");
        setTimer("".concat("OTP Expires  ", min, ":", sec));
        timexpries -= 1;
        if (timexpries < 0) {
          setAlertFlag("warning");
          setTimer("OTP Expired.. Resend");
        }
      }, 1000);
    } catch (err) {
      console.log("otpModal.OTPCounter : ", err);
    }
  };

  const handleSubmitForShortcutKey = () => {
    try {
      if (submitBtn == "send") {
        var phoneNumber = document.getElementById("phoneNumber").value;
        var error = false;
        if (!error) {
          clearInterval(intervalRef.current);
          var timexpries, otp;
          axios
            .post(address + "send_otp/", {
              otp_for: "Wallet",
              customer_number: phoneNumber,
              message: "OTP_2",
              pos_id: pos_id.toString(),
            })
            .then((response) => {
              timexpries = Number(response.data.data[0]["otpExpInSecs"]);
              otp = Number(response.data.data[0]["otp"]);
              setUserOTP(otp);
              OTPCounter(timexpries);
              setTimeout(() => {
                document.getElementById("otp").focus();
              }, 500);
            });
          setOtpField(false);
          setOTPbtn(true);
          setAlertFlag("primary");
          setTimer("OTP Sending....");
          setValidOTPbtn(false);
          setTimerDisplay("block");
        }
      } else if (submitBtn == "validate") {
        if (typeof inputs.otp != "undefined") {
          var userInputOTP = inputs.otp;
          if (userInputOTP.length > 0) {
            userInputOTP = Number(userInputOTP);
            if (userInputOTP === userOTP) {
              setAlertFlag("success");
              setTimer("Valid OTP, please wait...");
              clearInterval(intervalRef.current);
              setValidOTPbtn(true);
              setOtpField(true);
              setAlertFlag("success");
              setInputs({});
              setTimeout(() => {
                setOtpStatusFunc();
                dispatch(moreRewardsActions.setOtpStatus(true));

                button_clicked();
              }, 2000);
            } else {
              setAlertFlag("danger");
              setTimer("Invalid OTP");
            }
          }
        }
      }
    } catch (err) {
      console.log("otpModal.handleSubmit : ", err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      if (submitBtn == "send") {
        var phoneNumber = document.getElementById("phoneNumber").value;
        var error = false;
        if (!error) {
          clearInterval(intervalRef.current);
          var timexpries, otp;
          axios
            .post(address + "send_otp/", {
              otp_for: "Wallet",
              customer_number: phoneNumber,
              pos_id: pos_id.toString(),
              message: "OTP_2",
            })
            .then((response) => {
              timexpries = Number(response.data.data[0]["otpExpInSecs"]);
              otp = Number(response.data.data[0]["otp"]);
              setUserOTP(otp);
              OTPCounter(timexpries);
              setTimeout(() => {
                document.getElementById("otp").focus();
              }, 500);
            });
          setOtpField(false);
          setOTPbtn(true);
          setAlertFlag("primary");
          setTimer("OTP Sending....");
          setValidOTPbtn(false);
          setTimerDisplay("block");
        }
      } else if (submitBtn == "validate") {
        if (typeof inputs.otp != "undefined") {
          var userInputOTP = inputs.otp;
          if (userInputOTP.length > 0) {
            userInputOTP = Number(userInputOTP);
            if (userInputOTP === userOTP) {
              setValidOTPbtn(true);
              setAlertFlag("success");
              setTimer("Valid OTP, please wait...");
              clearInterval(intervalRef.current);
              setOtpField(true);
              setAlertFlag("success");
              setInputs({});
              setTimeout(() => {
                setOtpStatusFunc();
                dispatch(moreRewardsActions.setOtpStatus(true));

                button_clicked();
              }, 2000);
            } else {
              setAlertFlag("danger");
              setTimer("Invalid OTP");
            }
          }
        }
      }
    } catch (err) {
      console.log("otpModal.handleSubmit : ", err);
    }
  };

  useEffect(() => {
    if (!show) {
      setAlertFlag("");
      setTimer("");
      clearInterval(intervalRef.current);
      setValidOTPbtn(true);
      setOtpField(true);
      setAlertFlag("");
      setInputs({});
      setOTPbtn(false);
      setTimerDisplay("none");
    }
  }, [show]);

  const closeModal = () => {
    setAlertFlag("");
    setTimer("");
    clearInterval(intervalRef.current);
    setValidOTPbtn(true);
    setOtpField(true);
    setAlertFlag("");
    setInputs({});
    setOTPbtn(false);
    setTimerDisplay("none");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal();
          button_clicked();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Phone Number Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <form method="POST" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <input
                      type="number"
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      value={customerNumber}
                      disabled={true}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <div className="input-group">
                      <input
                        type="text"
                        name="otp"
                        id="otp"
                        className="form-control"
                        placeholder="Enter OTP"
                        defaultValue={inputs.otp || ""}
                        onInput={handleChange}
                        pattern="[0-9]+"
                        title="OTP"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            setSubmitButton("validate");
                            document.getElementById("validate").click();
                          }
                        }}
                        disabled={otpfieldFlag}
                      />
                      <button
                        className="btn btn-primary"
                        name="sendOTP"
                        id="send"
                        disabled={sendOtpFlag}
                        onClick={() => setSubmitButton("send")}
                      >
                        Sen<u>d</u> OTP{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12 text-center">
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        display: timerSpan,
                      }}
                    >
                      <span> {OTPtimer}</span>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      name="validateOTP"
                      id="validate"
                      disabled={validOtpFlag}
                      onClick={() => setSubmitButton("validate")}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpForWalletTopUp;
