import { createSlice } from "@reduxjs/toolkit";

const commonInitialState = {
  currModule: "newsale", //(newsale, return, billsonhold, transactions)
  isWelcomeMsgViewed: false,
  dqrPortValue: "select",
};

const commonSlice = createSlice({
  name: "commonDs",
  initialState: commonInitialState,
  reducers: {
    setCurrModule(state, action) {
      state.currModule = action.payload;
    },
    setIsWelcomeMsgViewed(state, action) {
      state.isWelcomeMsgViewed = action.payload;
    },
    setDqrPortValue(state, action) {
      state.dqrPortValue = action.payload;
    },
  },
});

export const commonActions = commonSlice.actions;
export default commonSlice.reducer;
