import React from "react";
import { Modal } from "react-bootstrap";
import PieChart from "../CashierMetrics/PieChart";
import DonutChart from "../CashierMetrics/DonutChart";
import BarChart from "../CashierMetrics/BarChart";
import SemiDonutChart from "../CashierMetrics/SemiDonutChart";
import { useSelector } from "react-redux";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

import {
  Rank1Gold,
  Rank1Silver,
  Rank1Bronze,
  Rank0,
} from "../../assets/Badges/index";
import BarChartDiamondSubs from "../CashierMetrics/BarChartDiamondSubs";

const BadgesForCashier = ({ badgeValue, badge, title, className = "" }) => {
  return (
    <div
      title={title}
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <img
        className={className}
        src={badge}
        alt={title}
        title={title}
        height={35}
        width={25}
        style={{
          margin: 0,
          padding: 0,
        }}
      />
    </div>
  );
};

const CashierMetricsHeader1 = ({ name, values, valueDiff }) => {
  return (
    <div className="col border border-dark p-1 rounded">
      <div className="cashierMetricHeader">
        {name} : {values}
        <span
          style={{
            fontSize: "12px",
            color: Number(valueDiff) < 0 ? "#95191B" : "green",
          }}
        >
          {Number(valueDiff) < 0 ? (
            <FaArrowDown color="#95191B" />
          ) : (
            <FaArrowUp color="green" />
          )}

          {valueDiff}
        </span>
      </div>
    </div>
  );
};

const CashierMetricsHeaderBadges = ({ badgeValue, title }) => {
  return (
    <span>
      {badgeValue === 0 ? (
        <BadgesForCashier badge={Rank0} title={title} />
      ) : badgeValue === 1 ? (
        <BadgesForCashier badge={Rank1Silver} title={title} />
      ) : badgeValue === 2 ? (
        <BadgesForCashier badge={Rank1Bronze} title={title} />
      ) : (
        <BadgesForCashier badge={Rank1Gold} title={title} />
      )}
    </span>
  );
};
const ModalCashierMetrics = ({ open, handleClose }) => {
  const cashierMetricsData = useSelector(
    (state) => state.login.cashierMetricsData
  );

  return (
    <Modal
      show={open || false}
      centered
      className="modal_cashier"
      onHide={() => {
        handleClose(false);
      }}
    >
      <Modal.Header
        closeButton
        style={{
          border: "1px solid",
          padding: "5px 20px 5px 25px",
        }}
      >
        <Modal.Title
          style={{
            color: "#f04e23",
            fontSize: "1.2rem",
          }}
        >
          Cashier Metrics
          <span
            style={{
              fontSize: "16px",
              marginLeft: 8,
              color: "black",
            }}
          >
            (Last 7 Days)
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="container"
          style={{
            marginTop: "-0.5rem",
          }}
        >
          <div
            style={{
              marginBottom: "4px",
              fontSize: "16px",
              fontWeight: "600",
              color: "#f04e23",
            }}
            className="row gap-1"
          >
            <CashierMetricsHeader1
              values={cashierMetricsData.score}
              valueDiff={cashierMetricsData.score_diff}
              name={"Your Score"}
            />

            <CashierMetricsHeader1
              values={cashierMetricsData.rank}
              valueDiff={cashierMetricsData.rank_diff}
              name={"Your Rank"}
            />
            <div className="col border border-dark p-1 text-left d-flex justify-content-left rounded">
              <div className="cashierMetricHeader">Your Monthly Badges :</div>
              <div className="d-flex justify-content-center gap-2 px-1">
                <CashierMetricsHeaderBadges
                  badgeValue={cashierMetricsData.bill_print_badge}
                  title={"Bill Print"}
                />
                <CashierMetricsHeaderBadges
                  badgeValue={cashierMetricsData.billing_speed_badge}
                  title={"Billing Speed"}
                />
                <CashierMetricsHeaderBadges
                  badgeValue={cashierMetricsData.upi_badge}
                  title={"UPI"}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{
            marginBottom: "-0.8rem",
          }}
        >
          <div className="row gap-1 ">
            <DonutChart
              perecentage={cashierMetricsData.average_upi_perc}
              headerText="UPI %"
            />
            <SemiDonutChart billingSpeed={cashierMetricsData.avg_bill_speed} />
            <BarChart
              noPercent={cashierMetricsData.print_no_perc}
              yesPercent={cashierMetricsData.print_yes_perc}
              headerText={"Physical Bill Print %"}
            />
          </div>
          <div className="row gap-1 mt-1">
            <BarChart
              noPercent={cashierMetricsData.mobile_no_perc}
              yesPercent={cashierMetricsData.mobile_yes_perc}
              headerText={"% Bill With Mobile Number"}
            />
            <PieChart
              headerText={"% Coupon Redemption"}
              couponRedeemPerecentage={cashierMetricsData.coupon_redem_perc}
            />
            <BarChartDiamondSubs
              noOfSubs={cashierMetricsData.subscriptions}
              noOfVisits={cashierMetricsData.customer_visits}
              headerText={"Diamond Subscriptions - "}
              diamonPercentage={cashierMetricsData.diamond_perc}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCashierMetrics;
