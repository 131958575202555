import { createSlice } from "@reduxjs/toolkit";

const returnInitialState = {
  returnScreen: "SearchForm",
  fetchBtn: true,
  backBtn: true,
  paymentBtn: true,
  BillorCustomer: {},
  newCustomerDetails: {},
  returnBillsListData: [],
  returnItemsListData: [],
  returnDataList: [],
  refundDetails: {},
  transactionData: {},
  billNo: "",
  sub_tran_type_check: "OFFLIN",
};

const returnSlice = createSlice({
  name: "returnDs",
  initialState: returnInitialState,
  reducers: {
    setReturnScreen(state, action) {
      state.returnScreen = action.payload;
    },
    setFetchBtn(state, action) {
      state.fetchBtn = action.payload;
    },
    setBackBtn(state, action) {
      state.backBtn = action.payload;
    },
    setPaymentBtn(state, action) {
      state.paymentBtn = action.payload;
    },
    setInputData(state, action) {
      state.BillorCustomer = action.payload;
    },
    setNewCustomer(state, action) {
      state.newCustomerDetails = action.payload;
    },
    setReturnBillsListData(state, action) {
      state.returnBillsListData = action.payload;
    },
    setReturnItemsListData(state, action) {
      state.returnItemsListData = action.payload;
    },
    setReturnDataList(state, action) {
      state.returnDataList = action.payload;
    },
    setRefundDetail(state, action) {
      state.refundDetails = action.payload;
    },
    setTransactionData(state, action) {
      state.transactionData = action.payload;
    },
    setBillNo(state, action) {
      state.billNo = action.payload;
    },
    setSubTransType(state, action) {
      state.sub_tran_type_check = action.payload;
    },
  },
});

export const returnActions = returnSlice.actions;
export default returnSlice.reducer;
