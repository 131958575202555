import React from "react";
import { useState, useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import appConfig from "../appconfig.json";
import { loginActions } from "./store/login";
import axios from "axios";
import { lazy, Suspense } from "react";
import { IoFileTrayFullSharp } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import faqDetailImage from "../assets/pos_faq.jpeg";
import { ToastContainer, toast } from "react-toastify";
import ModalAlertForIT from "./Modal/ModalAlertForIT";
import ModalCashierMetrics from "./Modal/ModalCashierMetrics";
import { FaChartLine } from "react-icons/fa";
import { useCommonSelectors } from "../Common/selector";

const Admin = lazy(() => import("./Admin"));

const Header = (props) => {
  const [arrUserDetails, setArrUserDetails] = useState(
    props.strUserDetails ?? []
  );
  const [isLogOutInProgress, setIsLogOutInProgress] = useState(false);
  const [showLogoutLoader, setShowLogoutLoader] = useState(false);
  const [logoutLoaderMessage, setLogoutLoaderMessage] = useState("Loading...");
  const [showAdminPopUp, setShowAdminPopUp] = useState(false);
  const [sale, setSale] = useState();
  const [retun, setReturn] = useState();
  const [pendingSince, setPendingSince] = useState();
  const [pendingSale, setPendingSale] = useState(0);
  const { loc, pos_id_mpos } = useCommonSelectors();
  function btnAdminClick() {
    setShowAdminPopUp(true);
  }

  // Timer
  const [date, setDate] = useState(new Date());
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  useEffect(() => {
    const intervalId = setInterval(() => {
      let d = new Date();
      var h = d.getHours();
      var m = d.getMinutes().toString();
      var s = d.getSeconds().toString();
      h = h % 12 || 12;
      h = h.toString();
      var z =
        d.getDate().toString().padStart(2, "0") +
        " / " +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        " / " +
        d.getFullYear();
      setDate(z);
      setHours(h.padStart(2, "0"));
      setMinutes(m.padStart(2, "0"));
      setSeconds(s.padStart(2, "0"));
      return () => clearInterval(intervalId);
    }, 1000);
  }, [seconds, minutes, hours, date]);

  function btnLogoutClick() {
    pendingSaleCount();
  }

  const logout = async () => {
    try {
      deleteSessionLog();
      setIsLogOutInProgress(true);
      setShowLogoutLoader(true);
      setLogoutLoaderMessage("Logging Out. Please Wait");
      let payload = {
        userid: props.strUserDetails["loginID"],
        session_id: props.strUserDetails["aurSessionId"],
        pos_id: props.strUserDetails["pos_id"],
      };
      sessionStorage.clear();
      localStorage.clear();
      let response = await fetch(appConfig.api_url + "getDsLogout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setIsLogOutInProgress(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          window.location.reload();
        }
      } else {
        console.log(
          "header-logout-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("header-logout", err);
    }
  };

  const salesReturnCount = async () => {
    try {
      let payload = {
        event: "PENDING_ERP_PUSH_SUMMARY",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "getDsTransactions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          setSale(response["data"][0]["sale"]);
          setReturn(response["data"][0]["return"]);
          setPendingSince(response["data"][0]["pendingSince"]);
        }
      }
    } catch (err) {
      console.log("SalesReturn err", err);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      autoLogOut();
      salesReturnCount();
    }, 60000 * 10);

    return () => clearInterval(interval);
  }, []);

  const xlsxConvertFunc = (data, fileName) => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.log("TransactionLayout.xlsxConvertFunc ", err);
    }
  };

  const downloadSale_Return = async () => {
    try {
      let payload = {
        event: "PENDING_ERP_PUSH_SUMMARY_EXCEL",
        loc: loc,
        pos_id: pos_id_mpos,
      };

      axios
        .post(appConfig.api_url + "getDsTransactions/", payload, {
          method: "POST",
          responseType: "blob",
        })
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          xlsxConvertFunc(response.data, filename);
        });
    } catch (err) {
      console.log("header-logout", err);
    }
  };

  const pendingSaleCount = async () => {
    try {
      let payload = {
        event: "PENDING_ERP_PUSH_SUMMARY",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "getDsTransactions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["data"][0]["sale"] > 0) {
            setPendingSale(response["data"][0]["sale"]);
            handleOpenOrClose();
            // LogoutWarningPopUP(response["data"][0]["sale"]);
          } else {
            deleteSessionLog();
          }
        }
      }
    } catch (err) {
      console.log("pendingSalesCount err", err);
    }
  };

  //This is the function for open cashcounter infront of the cashier
  const btnCashOpen = async () => {
    try {
      let payload = {};
      let response = await fetch(appConfig.api_url + "dsOpenCashTray/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
        }
      }
    } catch (err) {
      console.log("btnCashOpen err", err);
    }
  };

  //Button for cashier metrics
  const cashierMetricsData =
    useSelector((state) => state.login.cashierMetricsData) || [];

  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  let metricsValue = isObjEmpty(cashierMetricsData) ? false : true;

  const [openCashierMetrics, setOpenCashierMetrics] = useState(metricsValue);
  useEffect(() => {
    setTimeout(() => {
      setOpenCashierMetrics(false);
    }, 10000);
  }, []);
  const openCashierMetricsModal = () => {
    if (Object.keys(cashierMetricsData).length === 0) {
      setOpenCashierMetrics(false);
    } else {
      setOpenCashierMetrics(true);
    }
  };

  //Delete session Logs
  const deleteSessionLog = async () => {
    try {
      let response = await fetch(appConfig.api_url + "deleteSessionLog/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      response = await response.json();
      if (response.status === "success") {
        logout();
      }
    } catch (err) {
      console.log("header-logout", err);
    }
  };

  //Faq Modal for helping cashier to understand POS System
  const [faqModal, setFaqModal] = useState(false);
  const handleFaqModal = () => {
    setFaqModal(!faqModal);
  };

  //Auto logout based on session 24Hr
  const autoLogOut = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    if (props.strUserDetails.sessionStartTime.slice(0, -9) !== today) {
      logout();
    }
  };

  const [openOrClose, setopenOrClose] = useState(false);

  const handleOpenOrClose = () => {
    setopenOrClose(!openOrClose);
  };

  const keyboardEventListenerNewSalePayment = useCallback(
    (e) => {
      //logic for shortcut key
      if (
        openOrClose &&
        e.altKey &&
        e.code === "KeyZ" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleOpenOrClose();
        return;
      } else if (
        (faqModal || !faqModal) &&
        e.altKey &&
        e.code === "KeyQ" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleFaqModal();
        return;
      } else if (
        e.keyCode === 27 &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey &&
        !e.altKey
      ) {
        setFaqModal(false);
        return;
      }
    },
    [openOrClose, faqModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListenerNewSalePayment);
    return () => {
      document.body.removeEventListener(
        "keydown",
        keyboardEventListenerNewSalePayment
      );
    };
  }, [keyboardEventListenerNewSalePayment]);

  const [showAlertModalIT, setShowAlertModalIT] = useState(false);

  useEffect(() => {
    if (
      Number(hours) % 1 === 0 &&
      minutes === "01" &&
      seconds === "01" &&
      sale !== 0 &&
      pendingSince !== undefined
    ) {
      if (
        Number(pendingSince.split(" ")[0]) >= 1 &&
        pendingSince.split(" ")[1] === "D"
      ) {
        setShowAlertModalIT(!showAlertModalIT);
      }
    }
  }, [pendingSince, hours, seconds, setShowAlertModalIT, showAlertModalIT]);
  return (
    <>
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {showLogoutLoader ? (
        <div className="overlay d-flex align-items-center justify-content-center">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
          <div style={{ color: "#ffffff", fontSize: "12px" }}>
            {logoutLoaderMessage}
          </div>
        </div>
      ) : (
        ""
      )}
      {faqModal ? (
        <Modal
          show={faqModal}
          centered
          // fullscreen={true}
          className="modal_faq"
          onHide={() => {
            handleFaqModal();
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={faqDetailImage}
              style={{
                width: "100%",
              }}
            />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      {openOrClose ? (
        <Modal show={openOrClose} className="modal-trans" centered>
          <Modal.Body>
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <span className="modal-text w-150">
                    Sales invoices ({pendingSale}) not synced to ERP, do you
                    want to logout?
                  </span>
                </div>
                <div className="modal-footer justify-content-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleOpenOrClose();
                      }}
                    >
                      No / (ALT + Z)
                    </button>
                  </div>
                  <button
                    id="yesButton"
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onClick={() => {
                      logout();
                      handleOpenOrClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      <div className="row nav-bg-orange">
        <div className="col-md-6">
          <div className="d-flex justify-content-start">
            <div className="p-2">
              <img
                className="mx-auto h-12 w-auto"
                src={require("../content/images/logo-1.png")}
                alt="More Retail Private Limited"
              />
            </div>
            <div className="title">
              <div>
                STORE : {loc} - {arrUserDetails["loc_name"]}
              </div>
              <div>POS ID : {pos_id_mpos}</div>
              <div style={{ fontSize: "10px" }}>
                DarkSaber - FE {props.strUserDetails["feversion"]}, BE{" "}
                {props.strUserDetails["beversion"]}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            <div className="p-2 title-1 mt-2">
              Welcome,{" "}
              <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                {arrUserDetails["empName"].slice(0, 16)}
              </span>
            </div>

            <div className="p-2 title-2" style={{ fontSize: "22px" }}>
              {props.strUserDetails["isAdmin"] ? (
                <button className="btn-icon">
                  <i
                    className="fa fa-user-shield"
                    title="Admin Login"
                    onClick={btnAdminClick}
                  ></i>
                </button>
              ) : (
                ""
              )}
              <button className="btn-icon">
                <i
                  className="fa fa-question-circle"
                  title="FAQ"
                  onClick={handleFaqModal}
                ></i>
              </button>
              <button className="btn-icon">
                <i title="Cash Drawer" onClick={btnCashOpen}>
                  <IoFileTrayFullSharp style={{ fontSize: "28px" }} />
                </i>
              </button>
              <button
                className="btn-icon"
                disabled={Object.keys(cashierMetricsData).length === 0}
              >
                <i title="Cashier Metrics" onClick={openCashierMetricsModal}>
                  <FaChartLine style={{ fontSize: "28px" }} />
                </i>
              </button>
              <button className="btn-icon">
                <i
                  className="fa fa-sign-out-alt"
                  title="Logout"
                  onClick={btnLogoutClick}
                ></i>
              </button>
            </div>
          </div>
          <div
            className="header-flash-button text-end"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <div
                className="btn btn-primary1 text-white "
                style={{
                  display: "inline",
                  verticalAlign: "top",
                }}
              >
                Clock : {hours}:{minutes}:{seconds}
              </div>
            </div>
            &nbsp;
            <div onClick={() => downloadSale_Return()}>
              <button
                className="btn btn-primary1 text-white "
                style={{
                  display: sale >= 1 ? "inline" : "none",
                  verticalAlign: "top",
                }}
              >
                Sales : {sale}{" "}
              </button>
              &nbsp;
              <button
                className="btn btn-primary1 text-white "
                style={{
                  display: retun >= 1 ? "inline" : "none",
                  verticalAlign: "top",
                }}
              >
                Return: {retun}
              </button>
              &nbsp;
              <button
                className="btn btn-primary1 text-white "
                style={{
                  display: sale || retun >= 1 ? "inline" : "none",
                  verticalAlign: "top",
                }}
              >
                {" "}
                {pendingSince}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showAdminPopUp ? (
        <Suspense
          fallback={
            <div className="overlay d-flex align-items-center justify-content-center">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div style={{ color: "#ffffff", fontSize: "12px" }}>
                Loading... Please Wait
              </div>
            </div>
          }
        >
          <Admin
            show={showAdminPopUp}
            close={() => setShowAdminPopUp(false)}
            button_clicked={showAdminPopUp}
          />
        </Suspense>
      ) : (
        ""
      )}

      <ModalAlertForIT
        setShowAlertModalIT={setShowAlertModalIT}
        showAlertModalIT={showAlertModalIT}
      />
      <ModalCashierMetrics
        open={openCashierMetrics}
        handleClose={setOpenCashierMetrics}
      />
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setIsAuthenticated: (isAuthenticated) =>
      dispatch(loginActions.setIsAuthenticated(isAuthenticated)),
    setUserDetailsData: (userData) =>
      dispatch(loginActions.setUserDetailsData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
