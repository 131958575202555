import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { newSaleActions } from "../store/newSaleStore";

import appConfig from "../../appconfig.json";

import { Modal } from "react-bootstrap";
import OtpModal from "../otpModal";
import ModalScanBarcode from "./ModalScanBarcode";
import axios from "axios";
import { useCommonSelectors } from "../../Common/selector";
// import { keyboard } from "@testing-library/user-event/dist/keyboard";

const ModalCoupon = (props) => {
  const [arrCouponList, setArrCouponList] = useState([]);
  // const [selectedCoupon, setSelectedCoupon] = useState("");

  //Checking if its an OMNI Store or Not
  const omni = useSelector((state) => state.login.userDetailsData.omni);
  const [loader, setLoader] = useState(false);
  const redemption_auth_type =
    useSelector((state) => state.login.userDetailsData.redemption_auth_type) ||
    "";
  const [perCouponDetails, setPerCouponDetails] = useState({});
  //Otp Modal Check
  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const handleOtpStatus = () => {
    setOtpStatus(!otpStatus);
  };
  const { loc, pos_id_mpos } = useCommonSelectors();

  //Scan Bar Code check
  const [openMorePointsModal, setOpenMorePointsModal] = useState(false);

  useEffect(() => {
    getCouponList();
    document.getElementById("txtCouponCode").focus();
  }, []);

  function validateCouponForm(event) {
    event.preventDefault();
    const frmCouponCode = document.getElementById("txtCouponCode").value;
    if (!frmCouponCode) {
      getCouponList();
    } else {
      getCouponList(frmCouponCode);
    }
  }

  function btnApplyRemoveCouponClick(coupon) {
    submitCoupon(coupon.coupon_no, coupon.coupon_action, coupon.offer_no);
  }

  const getCouponList = async (couponNo) => {
    if (couponNo !== undefined) {
      if (!props.strCustomerCoupon.includes(Number(couponNo))) {
        toast.error("Coupon is already Redeemed  / Expired...", {
          autoClose: 2000,
        });
      }
      return;
    }
    var paramBasketId = props.strNewSaleItemsBasketId
      ? props.strNewSaleItemsBasketId
      : "";
    var paramCustNumber = props.strCustomerDetails.customer_number
      ? props.strCustomerDetails.customer_number
      : "";
    let payload = {
      customer_number: paramCustNumber,
      basket_id: paramBasketId,
      coupon_no: couponNo ? couponNo : "",
    };
    let response = await fetch(appConfig.api_url + "getDsCouponList/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status <= 299) {
      response = await response.json();
      if (response["status"] === "success") {
        setArrCouponList(response["data"][0]["coupons"]);
      } else {
        toast.error(response["message"], { autoClose: 1000 });
      }
    } else {
      console.log(
        "ModalCoupon-getCouponList :",
        response.status,
        response.statusText
      );
    }
  };
  const submitCoupon = async (couponno, couponaction, couponofferno) => {
    setLoader(true);
    var paramBasketId = props.strNewSaleItemsBasketId
      ? props.strNewSaleItemsBasketId
      : "";
    var paramCustNumber = props.strCustomerDetails.customer_number
      ? props.strCustomerDetails.customer_number
      : "";

    let payload = {
      event: "basket_applycoupon",
      customer_number: paramCustNumber,
      basket_id: paramBasketId,
      coupon_no: couponno,
      offer_no: couponofferno,
      eventtype: couponaction,
      sub_tran_type: props.strTranType,
      slot: props.strSelectedDeliverySlot,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status <= 299) {
      response = await response.json();
      if (response["status"] === "success") {
        if (response["message"] === "FINAL") {
          props.setStrItemsCartValue(response["data"][0]["totalCart"]);
          props.setStrItemsTotalDiscount(response["data"][0]["totalDiscount"]);
          props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
          props.setStrItemsBillBalanceAmount(response["data"][0]["balanceAmt"]);
          props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
          props.setStrItemsListData(response["data"][0]["items"]);
          props.setRecommendOffer(response["data"][0]["recomOffers"]);
          props.setSlabOfferDesc(response["data"][0]["slab_offer_description"]);
          props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);

          if (couponaction === "Apply") {
            props.setStrItemsCouponNo(response["data"][0]["coupon_no"]);
            toast.success("Coupon Applied.", {
              autoClose: 1000,
            });
          }
          if (couponaction === "Remove") {
            props.setStrItemsCouponNo("");
            toast.success("Coupon Removed.", {
              autoClose: 1000,
            });
          }
          setLoader(false);
          props.close();
        }
      } else {
        toast.error(response["message"], {
          autoClose: 1000,
        });
        setLoader(false);
      }
    } else {
      setLoader(false);
      console.log(
        "ModalCoupon-getCouponList :",
        response.status,
        response.statusText
      );
    }
  };

  const basket_id = useSelector((state) => state?.newSale?.itemsbasketId);
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const pos_id = useSelector((state) => state.login?.userDetailsData["pos_id"]);

  const postRedemptionDetailsApi = async () => {
    const payload = {
      customer_number: props.strCustomerDetails.customer_number,
      pos_id: pos_id.toString(),
      basket_id: basket_id,
      cashier_id: cashier_id,
      redeem_source: "coupon",
      redeem_type: redemption_auth_type,
      skip_to_otp: "N",
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postRedemptionDetails/",
        payload
      );
      if (data.status === "success") {
      } else {
        console.log("ModalScanBarcode-postRedemptionDetails-api", data.message);
      }
    } catch (error) {
      console.log("ModalScanBarcode-postRedemptionDetails-api", error);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.button_clicked();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Coupons</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row coupon">
            <div className="col-md-12">
              <form onSubmit={validateCouponForm}>
                <div className="input-group mb-3">
                  <input
                    id="txtCouponCode"
                    name="txtCouponCode"
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    onChange={(e) => {
                      var value = e.target.value;
                      value = value.replace(/[^a-zA-Z0-9]/g, "");
                      document.getElementById("txtCouponCode").value = value;
                    }}
                    tabIndex={1}
                    autoFocus={true}
                    autoComplete="off"
                  />
                  <button
                    className="btn btn-outline-primary"
                    tabIndex={2}
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-12">
              {arrCouponList.map((coupon, index) => {
                const indexKey = coupon.coupon_no + coupon.coupon_action;
                return (
                  <>
                    {index == 0 ? (
                      <div className="fw-bold subTitle">Best Coupon</div>
                    ) : index == 1 ? (
                      <>
                        <div className="fw-bold subTitle">Other Coupon</div>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      className={
                        index == 0
                          ? "row couponItems itemBorder"
                          : "row couponItems "
                      }
                      key={indexKey}
                    >
                      <div
                        className={
                          coupon.coupon_action === "Apply"
                            ? "col-md-3 couponIconEnabled"
                            : "col-md-3 couponIconDisabled"
                        }
                      >
                        <i
                          className="fa fa-ticket-alt fa-2x ms-4 mt-2"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-6 align-self-center">
                        <div className="title fw-normal">
                          {coupon.coupon_desc}{" "}
                          {coupon.appl_discount_amount !== null ? (
                            <>
                              -{" "}
                              <span className="text fw-bolder">
                                Save ₹ {coupon.appl_discount_amount}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 text-end btnCol">
                        <button
                          disabled={loader}
                          key={index}
                          className={
                            coupon.coupon_action === "Apply"
                              ? "btn btn-xs btn-primary btnApply"
                              : "btn btn-xs  btn-primary btnRemove"
                          }
                          onClick={() => {
                            setPerCouponDetails(coupon);
                            if (coupon.coupon_action === "Remove") {
                              btnApplyRemoveCouponClick(coupon);
                              return;
                            }
                            if (
                              redemption_auth_type !== "barcode" &&
                              coupon.coupon_action === "Apply"
                            ) {
                              setShowCustomerOTPState(true);
                            } else {
                              setOpenMorePointsModal(true);
                            }
                          }}
                        >
                          {loader ? (
                            <label
                              className="spinner-border"
                              style={{
                                width: ".8rem",
                                height: ".8rem",
                              }}
                            ></label>
                          ) : (
                            coupon.coupon_action
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <OtpModal
        show={showCustomerOTPState}
        button_clicked={() => {
          setShowCustomerOTPState(!showCustomerOTPState);
        }}
        customerNumber={props.strCustomerDetails.customer_number || ""}
        setOtpStatusFunc={handleOtpStatus}
        couponModalFunction={btnApplyRemoveCouponClick}
        coupon={perCouponDetails}
        couponModal={true}
        postRedemptionDetailsApi={postRedemptionDetailsApi}
        loader={loader}
        setLoader={setLoader}
      />

      {openMorePointsModal && redemption_auth_type === "barcode" && (
        <ModalScanBarcode
          modalOpenClose={openMorePointsModal}
          handleClose={setOpenMorePointsModal}
          customerNumber={props.strCustomerDetails.customer_number}
          functionCall={btnApplyRemoveCouponClick}
          coupon={perCouponDetails}
          couponModal={true}
          setShowCustomerOTPState={setShowCustomerOTPState}
          headerText="Download More App & Scan barcode to Apply Coupon"
          loader={loader}
          setLoader={setLoader}
        />
      )}
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strTranType: reduxState?.newSale?.tranType,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
    strCustomerCoupon: reduxState?.newSale?.customerCoupon,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrItemsCouponNo: (couponNo) =>
      dispatch(newSaleActions.setItemsCoupon(couponNo)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrItemsBillBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCoupon);
