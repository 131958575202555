import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login, Dashboard } from "./pages";
import { connect, useSelector } from "react-redux";
import appConfig from "./appconfig.json";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";

const App = (props) => {
  const [isAuthenticated, setAuthState] = useState(
    props.isAuthenticated ?? false
  );

  const setLoginAuth = (status) => {
    setAuthState(status);
  };
  const [date, setDate] = useState(new Date());
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  const dbBackup = async () => {
    const payload = {
      pos_id: props.posID,
    };
    try {
      let response = await fetch(appConfig.api_url + "postDbBackup/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
      }
    } catch (err) {
      console.log("postDbBackup err", err);
    }
  };

  const autoShutdown = async () => {
    const payload = {};
    try {
      let response = await fetch(appConfig.api_url + "postAutoShutdown/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
      }
    } catch (err) {
      console.log("postAutoShutdown err", err);
    }
  };

  const postBasketHoldToVoid = async () => {
    const payload = {};
    try {
      let response = await fetch(appConfig.api_url + "postBasketHoldToVoid/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
      }
    } catch (err) {
      console.log("postAutoShutdown err", err);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      let d = new Date();
      var h = d.getHours();
      var m = d.getMinutes().toString();
      var s = d.getSeconds().toString();
      h = h % 12 || 12;
      h = h.toString();
      var z =
        d.getDate().toString().padStart(2, "0") +
        " / " +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        " / " +
        d.getFullYear();
      setDate(z);
      setHours(h.padStart(2, "0"));
      setMinutes(m.padStart(2, "0"));
      setSeconds(s.padStart(2, "0"));
      return () => clearInterval(intervalId);
    }, 1000);
  }, [seconds, minutes, hours, date]);

  useEffect(() => {
    if (Number(hours) % 4 === 0 && minutes === "01") {
      dbBackup();
      autoShutdown();
    }

    if (Number(hours) % 2 === 0 && minutes === "01") {
      autoShutdown();
    }

    if (Number(hours) % 6 === 0 && minutes === "01") {
      postBasketHoldToVoid();
    }
  }, [Number(hours), minutes]);

  return (
    <>
      <div className={"App"}>
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="/Dashboard" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login status={setAuthState} />} />
              <Route path="/Login" element={<Login status={setAuthState} />} />
              <Route path="*" element={<Navigate to="/Login" />} />
            </>
          )}
        </Routes>
      </div>
    </>
  );
};

const mapStateToProps = (reduxState) => {
  return {
    isAuthenticated: reduxState?.login?.isAuthenticated,
    posID: reduxState.login?.userDetailsData.pos_id,
  };
};

// export default App
export default connect(mapStateToProps)(App);
