import React, { useState, useEffect, Suspense, lazy, useCallback } from "react";
// import useKeyboardShortcut from "use-keyboard-shortcut";
import UserRegister from "./userRegister";
import { connect } from "react-redux";
import { commonActions } from "../components/store/commonStore";
import { GiLaurelsTrophy } from "react-icons/gi";
import { toast } from "react-toastify";
const GetPriceModal = lazy(() => import("./GetPrice"));

const Nav = (props) => {
  const [showPriceState, setShowPriceState] = useState(false);
  const [showCustomerState, setShowCustomerState] = useState(false);
  const buttonClickedHandler = (flag) => {
    switch (flag) {
      case "price":
        setShowPriceState(
          (showPriceState) => (showPriceState = !showPriceState)
        );
        break;
      case "customer":
        setShowCustomerState(
          (showCustomerState) => (showCustomerState = !showCustomerState)
        );
        break;
    }
  };

  let activeCardName = "newSaleOption";
  if (props.activePage != undefined) {
    activeCardName = props.activePage;
  }

  const [activeOption, setPosOption] = useState(activeCardName);
  const [userinfo, setUserInfo] = useState(
    sessionStorage.getItem("user-info") ?? ""
  );

  const [arrUserDetails, setArrUserDetails] = useState(
    userinfo !== "" ? JSON.parse(userinfo) : ""
  );

  const keyboardEventListener = useCallback(
    (e) => {
      if (
        e.altKey &&
        e.code === "KeyN" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        loadCurrModule("newsale");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyR" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        loadCurrModule("return");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyH" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        loadCurrModule("billsonhold");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyT" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        loadCurrModule("transactions");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyC" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
          toast.error(
            "Complete the Payment Transaction for proceeding to New Menu",
            {
              autoClose: 1500,
            }
          );
          return;
        }
        buttonClickedHandler("customer");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyV" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        setPosOption("voidOption");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyP" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
          toast.error(
            "Complete the Payment Transaction for proceeding to New Menu",
            {
              autoClose: 1500,
            }
          );
          return;
        }
        buttonClickedHandler("price");
        return;
      } else if (
        e.altKey &&
        e.code === "KeyC" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        setPosOption("couponOption");
        return;
      } else if (
        props.strNewSaleCurrentStatus !== "newSalePayment" &&
        e.altKey &&
        e.code === "KeyW" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        e.stopPropagation();
        e.preventDefault();
        loadCurrModule("moreRewards");
        return;
      }
    },
    [loadCurrModule, setPosOption]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListener);
    return () => {
      window.removeEventListener("keydown", keyboardEventListener);
    };
  }, [keyboardEventListener]);

  function loadCurrModule(module) {
    if (module === "newsale") {
      props.setStrCurrModule("newsale");
      setPosOption("newSaleOption");
    }
    if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
      toast.error(
        "Complete the Payment Transaction for proceeding to New Menu",
        {
          autoClose: 1500,
        }
      );
      return;
    }

    if (module === "return") {
      props.setStrCurrModule("return");
      setPosOption("returnOption");
    }
    if (module === "billsonhold") {
      props.setStrCurrModule("billsonhold");
      setPosOption("holdOption");
    }
    if (module === "transactions") {
      props.setStrCurrModule("transactions");
      setPosOption("transactionOption");
    }
    if (module === "moreRewards") {
      props.setStrCurrModule("moreRewards");
      setPosOption("moreRewards");
    }
  }

  return (
    <>
      <Suspense
        fallback={
          <div className="overlay d-flex align-items-center justify-content-center">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <div style={{ color: "#ffffff", fontSize: "12px" }}>
              Loading... Please Wait
            </div>
          </div>
        }
      >
        <GetPriceModal
          show={showPriceState}
          button_clicked={setShowPriceState}
          strTranType={props.strTranType}
          strSelectedDeliverySlot={props.strSelectedDeliverySlot}
        />
      </Suspense>
      <UserRegister
        show={showCustomerState}
        button_clicked={setShowCustomerState}
        customerValidate={""}
        getCustomerName={""}
      />
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "newsale" ? "cardactive" : "")
              }
              onClick={() => {
                loadCurrModule("newsale");
              }}
            >
              <span className="shortcutkeytitle">ALT + N</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-shopping-cart nav-icon"></i>
                  <span className="title">NEW SALE</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " + (props.strCurrModule === "return" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                loadCurrModule("return");
              }}
            >
              <span className="shortcutkeytitle">ALT + R</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-cart-arrow-down nav-icon"></i>
                  <span className="title">RETURN</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "billsonhold" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                loadCurrModule("billsonhold");
              }}
            >
              <span className="shortcutkeytitle">ALT + H</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-folder nav-icon"></i>
                  <span className="title">ON HOLD</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "transactions" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                loadCurrModule("transactions");
              }}
            >
              <span className="shortcutkeytitle">ALT + T</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-inbox nav-icon"></i>
                  <span className="title">TRANSACTIONS</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "customerOption" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                buttonClickedHandler("customer");
              }}
            >
              <span className="shortcutkeytitle">ALT + C</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-user nav-icon"></i>
                  <span className="title">CUSTOMERS</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "priceOption" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                buttonClickedHandler("price");
              }}
            >
              <span className="shortcutkeytitle">ALT + P</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa fa-info nav-icon"></i>
                  <span className="title">GET PRICE</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-nav">
            <div
              className={
                "card " +
                (props.strCurrModule === "moreRewards" ? "cardactive" : "")
              }
              onClick={() => {
                if (props.strBillBalanceOrSuccess === "BILLBALANCE") {
                  return;
                }
                loadCurrModule("moreRewards");
              }}
            >
              <span className="shortcutkeytitle">ALT + W</span>
              <div className="card-body">
                <div className="card-text text-center">
                  <i className="fa nav-icon">
                    <GiLaurelsTrophy />
                  </i>
                  <span className="title">REWARDS</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strCurrModule: reduxState?.common?.currModule,
    strTranType: reduxState?.newSale?.tranType,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
    strBillBalanceOrSuccess: reduxState?.newSale?.billSuccessOrBalance,
    strNewSaleCurrentStatus: reduxState?.newSale?.newSaleCurrentStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrCurrModule: (currModule) =>
      dispatch(commonActions.setCurrModule(currModule)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
