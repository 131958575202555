import React, { useState, useRef, useEffect, useCallback } from "react";
import KeyboardLayout from "./KeyboardLayout";
import appConfig from "../appconfig.json";
import { toast } from "react-toastify";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DataGridTable from "../Common/DataGridTable/DataGridTable";
import DataGridSingleDetailsTable from "../Common/DataGridSingleDetailsTable/DataGridSingleDetailsTable";
import { useSelector } from "react-redux";
import { useCommonSelectors } from "../Common/selector";

const BackButton = (props) => {
  return (
    <div
      className="col-sm-12 col-md-12 col-lg-12"
      style={{ marginLeft: "25px", marginTop: "25px" }}
    >
      <button
        className="btn btn-sm btn-primary"
        onClick={() => props.clearData()}
      >
        BACK
      </button>
    </div>
  );
};

const TransactionLayout = (props) => {
  useEffect(() => {
    document.documentElement.requestFullscreen();
  }, []);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const ifAdmin = useSelector((state) => state.login.userDetailsData.isAdmin);

  const [inputs, setFormDataInputs] = useState({
    toDate: new Date().toISOString().slice(0, 10),
    fromDate: new Date().toISOString().slice(0, 10),
  });
  const address = appConfig.api_url;
  const [screenName, setScreenName] = useState("formScreen");
  const [summaryList, setSummaryList] = useState([]);
  const [billData, setBillData] = useState([]);
  const [backScreen, setBackScreen] = useState("formScreen");
  const [msg, setMsg] = useState("");
  const [msgFlag, setMsgFlag] = useState(false);
  const [msgType, setMsgType] = useState("info");

  useEffect(() => {
    document.getElementById("customerNumber").value = "";
    document.getElementById("billNo").value = "";
    document.getElementById(
      "transactionLayout.deliveryMode"
    ).options[0].selected = true;
    document.getElementById("toDate").value = new Date()
      .toISOString()
      .slice(0, 10);
    document.getElementById("fromDate").value = new Date()
      .toISOString()
      .slice(0, 10);
  }, []);

  var getButton;

  const setGetButtonFlag = (flag) => {
    getButton = flag;
  };

  useEffect(() => {
    if (screenName === "tableList") {
      setBackScreen("formScreen");
    }
  }, [screenName]);

  const handleBlurEvent = (e) => {
    searchTextRef.current = e.target;
    const name = e.target.name;
    const value = e.target.value;
    if (
      name == "fromDate" ||
      name == "toDate" ||
      name === "transactionLayout.deliveryMode"
    ) {
      setFormDataInputs({ ...inputs, [name]: value });
    } else {
      if (name == "customerNumber") {
        setFormDataInputs({
          ...inputs,
          ["customerNumber"]: value,
          ["billNo"]: "",
          ["basket_id"]: "",
        });
        document.getElementById("billNo").value = "";
        document.getElementById("basket_id").value = "";
      } else if (name == "billNo") {
        setFormDataInputs({
          ...inputs,
          ["billNo"]: value,
          ["customerNumber"]: "",
          ["basket_id"]: "",
        });
        document.getElementById("customerNumber").value = "";
        document.getElementById("basket_id").value = "";
      } else if (name == "basket_id") {
        setFormDataInputs({
          ...inputs,
          ["basket_id"]: value,
          ["billNo"]: "",
          ["customerNumber"]: "",
        });
        document.getElementById("customerNumber").value = "";
        document.getElementById("billNo").value = "";
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    var value = event.target.value;

    if (
      name === "billNo" ||
      name === "customerNumber" ||
      name === "basket_id"
    ) {
      value = value.replace(/[^a-zA-Z0-9@. ]/g, "");
      document.getElementById(event.target.id).value = value;
    }

    if (
      name === "fromDate" ||
      name === "toDate" ||
      name === "transactionLayout.deliveryMode"
    ) {
      setFormDataInputs({ ...inputs, [name]: value });
    } else {
      if (name == "customerNumber") {
        setFormDataInputs({
          ...inputs,
          ["customerNumber"]: value,
          ["billNo"]: "",
          ["basket_id"]: "",
        });
        document.getElementById("billNo").value = "";
        document.getElementById("basket_id").value = "";
      } else if (name == "billNo") {
        setFormDataInputs({
          ...inputs,
          ["billNo"]: value,
          ["customerNumber"]: "",
          ["basket_id"]: "",
        });
        document.getElementById("customerNumber").value = "";
        document.getElementById("basket_id").value = "";
      } else if (name == "basket_id") {
        setFormDataInputs({
          ...inputs,
          ["basket_id"]: value,
          ["billNo"]: "",
          ["customerNumber"]: "",
        });
        document.getElementById("customerNumber").value = "";
        document.getElementById("billNo").value = "";
      }
    }
  };

  const clearData = () => {
    if (backScreen === "formScreen") {
      setMsg("");
      setMsgFlag(false);
      setMsgType("info");
      setFormDataInputs({});
      setGetButtonFlag(true);
      setSummaryList([]);
      document.getElementById("customerNumber").focus();
      document.getElementById("customerNumber").value = "";
      document.getElementById("billNo").value = "";
      setFormDataInputs({
        ...inputs,
        ["toDate"]: new Date().toISOString().slice(0, 10),
        ["fromDate"]: new Date().toISOString().slice(0, 10),
      });
      document.getElementById("fromDate").value = new Date()
        .toISOString()
        .slice(0, 10);
      document.getElementById("basket_id").value = "";
      document.getElementById(
        "transactionLayout.deliveryMode"
      ).options[0].selected = true;
    }
    setScreenName(backScreen);
  };

  useEffect(() => {
    setGetButtonFlag(false);
    if (document.getElementById("toDate").value !== "undefined") {
      setFormDataInputs({
        ...inputs,
        ["toDate"]: document.getElementById("toDate").value,
      });
    }
  }, []);

  useEffect(() => {
    setGetButtonFlag(true);

    var key = "";
    for (key in inputs) {
      if (inputs.hasOwnProperty(key)) {
        if (inputs[key].length <= 0) {
          delete inputs[key];
        }
      }
    }
    if (
      inputs.hasOwnProperty("billNo") ||
      inputs.hasOwnProperty("customerNumber") ||
      inputs.hasOwnProperty("basket_id")
    ) {
      setGetButtonFlag(false);
    }

    if (inputs.hasOwnProperty("toDate") && inputs.hasOwnProperty("fromDate")) {
      setGetButtonFlag(false);
    }
  }, [inputs]);

  const keyboardEventListenerNewSaleBilling = useCallback(
    (e) => {
      if (
        e.altKey &&
        e.code === "KeyB" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        clearData();
        return;
      } else if (
        e.altKey &&
        e.code === "KeyS" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleSubmit();
        return;
      }
    },
    [backScreen, inputs.toDate, inputs.fromDate, inputs.customerNumber]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListenerNewSaleBilling);
    return () => {
      window.removeEventListener(
        "keydown",
        keyboardEventListenerNewSaleBilling
      );
    };
  }, [keyboardEventListenerNewSaleBilling]);

  const handleSubmit = (event) => {
    setMsg("Searching...");
    setMsgFlag(true);
    setMsgType("info");
    if (inputs.hasOwnProperty("customerNumber")) {
      try {
        var error = false;
        if (typeof inputs.customerNumber == "undefined") {
          error = true;
          toast.error("Enter Valid Customer Number / ID", {
            autoClose: 2000,
          });
        }

        if (typeof inputs.toDate == "undefined") {
          error = true;
          toast.error("Select To Date", {
            autoClose: 2000,
          });
        }

        if (typeof inputs.fromDate == "undefined") {
          error = true;
          toast.error("Select From Date", {
            autoClose: 2000,
          });
        }

        if (!error) {
          if (Date.parse(inputs.fromDate) > Date.parse(inputs.toDate)) {
            error = true;
            toast.error("From Date should less than To Date", {
              autoClose: 2000,
            });
          }
        }

        if (!error) {
          var fromDate = inputs.fromDate;
          var toDate = inputs.toDate;
          fromDate = fromDate.split("-").reverse().join("-");
          toDate = toDate.split("-").reverse().join("-");

          var payload = {
            event: "SUMMARY",
            customer_number: inputs.customerNumber,
            from_date: fromDate,
            to_date: toDate,
            bill_no: "",
            sub_tran_type: inputs["transactionLayout.deliveryMode"]
              ? inputs["transactionLayout.deliveryMode"]
              : "",
            loc: loc,
            pos_id: pos_id_mpos,
          };
          axios
            .post(address + "getDsTransactions/", payload)
            .then((response) => {
              if (response.data.status != "failure") {
                setMsg("Loading...");
                setMsgFlag(true);
                setMsgType("success");
                setSummaryList(response.data.data);
                setScreenName("tableList");
                setBackScreen("formScreen");
              } else {
                setSummaryList([]);
                setMsg("Not Found...");
                setMsgFlag(true);
                setMsgType("danger");
              }
            });
        } else {
          setMsg("Not Found...");
          setMsgFlag(true);
          setMsgType("danger");
        }
      } catch (err) {
        console.log("TransactionLayout.handleSubmit ", err);
      }
    } else if (inputs.hasOwnProperty("billNo")) {
      viewBill(inputs.billNo, false);
    } else if (inputs.hasOwnProperty("basket_id")) {
      viewBill("", false, inputs.basket_id);
    } else if (
      inputs.hasOwnProperty("toDate") &&
      inputs.hasOwnProperty("fromDate")
    ) {
      try {
        if (typeof inputs.toDate == "undefined") {
          error = true;
          toast.error("Select To Date", {
            autoClose: 2000,
          });
        }

        if (typeof inputs.fromDate == "undefined") {
          error = true;
          toast.error("Select From Date", {
            autoClose: 2000,
          });
        }

        if (!error) {
          if (Date.parse(inputs.fromDate) > Date.parse(inputs.toDate)) {
            error = true;
            toast.error("From Date should less than To Date", {
              autoClose: 2000,
            });
          }
        }

        if (!error) {
          var fromDate = inputs.fromDate;
          var toDate = inputs.toDate;
          fromDate = fromDate.split("-").reverse().join("-");
          toDate = toDate.split("-").reverse().join("-");

          var payload = {
            event: "SUMMARY",
            customer_number: inputs.customerNumber,
            from_date: fromDate,
            to_date: toDate,
            bill_no: "",
            sub_tran_type: inputs["transactionLayout.deliveryMode"],
            loc: loc,
            pos_id: pos_id_mpos,
          };
          axios
            .post(address + "getDsTransactions/", payload)
            .then((response) => {
              if (response.data.status != "failure") {
                setMsg("Loading...");
                setMsgFlag(true);
                setMsgType("success");
                setSummaryList(response.data.data);
                setScreenName("tableList");
                setBackScreen("formScreen");
              } else {
                setSummaryList([]);
                setMsg("Not Found...");
                setMsgFlag(true);
                setMsgType("danger");
              }
            });
        } else {
          setMsgFlag(false);
        }
      } catch (err) {
        console.log("TransactionLayout.handleSubmit ", err);
      }
    } else {
      toast.error("Please Enter Details", {
        autoClose: 2000,
      });
      setMsg("Not Found...");
      setMsgFlag(true);
      setMsgType("danger");
    }
  };

  const viewBill = (billNo, sourceTableFlag, basket_id) => {
    var payload = {
      event: "BILLDETAILS",
      customer_number: "",
      from_date: "",
      to_date: "",
      bill_no: billNo,
      basket_id: basket_id,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    try {
      axios.post(address + "getDsTransactions/", payload).then((response) => {
        if (response.data.status != "failure") {
          setBillData(response.data.data);
          setScreenName("billData");
          if (sourceTableFlag) {
            setBackScreen("tableList");
          } else {
            setBackScreen("formScreen");
          }
        } else {
          setBillData([]);
          toast.error(response.data.message, {
            autoClose: 1000,
          });
        }
      });
    } catch (err) {
      console.log("TransactionLayout.viewBill ", err);
    }
  };

  const searchTextRef = useRef();
  const processSearchText = () => {};

  const xlsxConvertFunc = (data, fileName) => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.log("TransactionLayout.xlsxConvertFunc ", err);
    }
  };

  const summaryDownload = () => {
    try {
      if (screenName === "tableList") {
        var fromDate = inputs.fromDate;
        var toDate = inputs.toDate;
        fromDate = fromDate?.split("-").reverse().join("-");
        toDate = toDate?.split("-").reverse().join("-");

        var payload = {
          event: "SUMMARYEXCEL",
          customer_number: inputs.customerNumber,
          from_date: fromDate,
          to_date: toDate,
          bill_no: "",
          sub_tran_type: inputs["transactionLayout.deliveryMode"],
          loc: loc,
          pos_id: pos_id_mpos,
        };

        axios
          .post(address + "getDsTransactions/", payload, {
            method: "POST",
            responseType: "blob",
          })
          .then((response) => {
            xlsxConvertFunc(
              response.data,
              response.headers["content-disposition"]
                .split(";")
                .find((n) => n.includes("filename="))
                .replace("filename=", "")
                .trim()
            );
          });
      }
    } catch (err) {
      console.log("TransactionLayout.summaryDownload ", err);
    }
  };

  const summaryAllDownload = () => {
    try {
      if (screenName === "tableList") {
        var fromDate = inputs.fromDate;
        var toDate = inputs.toDate;
        fromDate = fromDate?.split("-").reverse().join("-");
        toDate = toDate?.split("-").reverse().join("-");

        var payload = {
          event: "ALLBILLDETAILSEXCEL",
          customer_number: inputs.customerNumber,
          from_date: fromDate,
          to_date: toDate,
          bill_no: "",
          sub_tran_type: inputs["transactionLayout.deliveryMode"],
          loc: loc,
          pos_id: pos_id_mpos,
        };

        axios
          .post(address + "getDsTransactions/", payload, {
            method: "POST",
            responseType: "blob",
          })
          .then((response) => {
            xlsxConvertFunc(
              response.data,
              response.headers["content-disposition"]
                .split(";")
                .find((n) => n.includes("filename="))
                .replace("filename=", "")
                .trim()
            );
          });
      }
    } catch (err) {
      console.log("TransactionLayout.summaryDownload ", err);
    }
  };

  const billDownload = (billNo) => {
    try {
      if (screenName === "billData") {
        var payload = {
          event: "BILLDETAILSEXCEL",
          customer_number: "",
          from_date: "",
          to_date: "",
          bill_no: billNo,
          loc: loc,
          pos_id: pos_id_mpos,
        };

        axios
          .post(address + "getDsTransactions/", payload, {
            method: "POST",
            responseType: "blob",
          })
          .then((response) => {
            xlsxConvertFunc(
              response.data,
              response.headers["content-disposition"]
                .split(";")
                .find((n) => n.includes("filename="))
                .replace("filename=", "")
                .trim()
            );
          });
      }
    } catch (err) {
      console.log("TransactionLayout.billDownload ", err);
    }
  };

  const receiptPrint = (billNo) => {
    try {
      var payload = {
        bill_no: billNo,
        gsttin_no: "",
        print_type: "TR",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      axios
        .post(address + "getDsReceiptPrint/", payload, {
          method: "POST",
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              autoClose: 1000,
            });
          } else if (response.data.status === "failure") {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      console.log("TransactionLayout.reprint ", err);
    }
  };

  const homeReceiptPrint = (billNo) => {
    try {
      var payload = {
        bill_no: billNo,
        gsttin_no: "",
        print_type: "TR",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      axios
        .post(address + "getDsHomeDelChallanPrint/", payload, {
          method: "POST",
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              autoClose: 1000,
            });
          } else if (response.data.status === "failure") {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      console.log("TransactionLayout.reprint ", err);
    }
  };

  return (
    <>
      <div className="row trans-tab transactions">
        <div className="col-md-12">
          <div className="row" style={{ paddingTop: "15px" }}>
            <div
              className="col-12"
              style={{
                display: screenName === "formScreen" ? "block" : "none",
              }}
            >
              <form method="post" onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-6">
                    <label className="form-input-label">Customer Number</label>
                    <input
                      ref={searchTextRef}
                      type="text"
                      name="customerNumber"
                      id="customerNumber"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlurEvent}
                      defaultValue={inputs.customerNumber || ""}
                      placeholder="Customer Number "
                      autoFocus={true}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-input-label">
                      {" "}
                      Sub Transaction Type
                    </label>
                    <select
                      className="form-control"
                      name="transactionLayout.deliveryMode"
                      id="transactionLayout.deliveryMode"
                      onChange={handleChange}
                      onBlur={handleBlurEvent}
                      selected={inputs["transactionLayout.deliveryMode"] || ""}
                    >
                      <option value={""}>Select Delivery Mode</option>
                      <option value={"OFFLIN"}>Self Pickup</option>
                      <option value={"HOMEDE"}>Home Delivery</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="form-input-label">
                      From Date{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "16px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      className="form-control"
                      max={new Date().toISOString().slice(0, 10)}
                      onChange={handleChange}
                      defaultValue={
                        inputs.fromDate || new Date().toISOString().slice(0, 10)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-input-label">
                      To Date{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "16px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      ref={searchTextRef}
                      type="date"
                      name="toDate"
                      id="toDate"
                      max={new Date().toISOString().slice(0, 10)}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlurEvent}
                      defaultValue={
                        inputs.toDate || new Date().toISOString().slice(0, 10)
                      }
                    />
                  </div>
                </div>

                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col-12 hr-line">
                    <hr style={{ width: "48%" }}></hr>
                    <span style={{ fontWeight: "bolder" }}>OR</span>
                    <hr style={{ width: "48%" }}></hr>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">
                    <label className="form-input-label">
                      Bill No{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "16px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      ref={searchTextRef}
                      type="text"
                      name="billNo"
                      id="billNo"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlurEvent}
                      defaultValue={inputs.billNo || ""}
                      placeholder="Bill No"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className="col-2 text-center align-self-end">
                    <span style={{ fontSize: "2em" }}>/</span>
                  </div>
                  <div className="col-5">
                    <label className="form-input-label">
                      Basket Id{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "16px" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      ref={searchTextRef}
                      type="text"
                      name="basket_id"
                      id="basket_id"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlurEvent}
                      defaultValue={inputs.basket_id || ""}
                      placeholder="Basket Id"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={getButton}
                      onClick={() => handleSubmit()}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    >
                      <u>S</u>ubmit
                    </button>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "20px" }}>
                  <div className="col-12">
                    <div
                      className={"alert alert-" + msgType}
                      style={{ display: msgFlag ? "block" : "none" }}
                    >
                      {msg}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="col-12"
              style={{
                display: screenName === "tableList" ? "block" : "none",
              }}
            >
              <div className="row">
                <div className="col-6">
                  {inputs.customerNumber !== "undefined" &&
                  inputs.customerNumber?.length >= 10 ? (
                    <span className="text-field">
                      Customer Number :{" "}
                      <span className="text-primary">
                        {inputs.customerNumber}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-6 text-center sumAlldownload">
                  <OverlayTrigger
                    key="2"
                    placement="left"
                    overlay={<Tooltip id="tooltip-left">Summary</Tooltip>}
                  >
                    <button
                      className="btn-icon-orange"
                      onClick={() => summaryDownload()}
                      style={{
                        marginRight: "4em",
                      }}
                    >
                      <i className="fa fa-file-excel fa-lg"></i>
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="1"
                    placement="left"
                    overlay={<Tooltip id="tooltip-left">Items</Tooltip>}
                  >
                    <button
                      className="btn-icon-orange"
                      onClick={() => summaryAllDownload()}
                    >
                      <i className="fa fa-file-excel fa-lg"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row mb-2">
                <div
                  className="col-md-12"
                  style={{
                    height: 72 + "vh",
                    width: "100%",
                    backgroundColor: "white",
                    overflow: "auto",
                    border: "solid 0px #000000",
                    marginTop: "-5px",
                  }}
                >
                  <DataGridTable
                    data={summaryList}
                    handleClickForBillNo={viewBill}
                    receiptPrint={receiptPrint}
                    homeReceiptPrint={homeReceiptPrint}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              style={{
                display: screenName === "billData" ? "block" : "none",
              }}
            >
              <div className="row bill-header">
                <div className="col-3">
                  Customer Number :{" "}
                  <span className="text-primary">
                    {" "}
                    {billData[0]?.customer_number ?? " "}
                  </span>
                </div>
                <div className="col-3">
                  Bill Number :{" "}
                  <span className="text-primary">
                    {" "}
                    {billData[0]?.bill_no ?? " "}
                  </span>
                </div>
                <div className="col-2">
                  Bill Date :{" "}
                  <span className="text-primary">
                    {" "}
                    {billData[0]?.bill_date ?? " "}
                  </span>
                </div>
                <div className="col-4 d-flex ">
                  <div className="col-8">
                    <span>
                      Sub Tran Type :{" "}
                      <span className=" text-primary text-capitalize">
                        {" "}
                        {billData[0]?.sub_tran_type ?? " "}
                        {billData[0]?.slot
                          ? "(" + billData[0]?.slot + ")"
                          : " "}
                      </span>
                    </span>
                  </div>
                  <div className="col-4 bill-icons">
                    <OverlayTrigger
                      key={"billItemsDownload"}
                      placement={"left"}
                      overlay={<Tooltip id={`tooltip-left`}>Items</Tooltip>}
                    >
                      <button
                        className="btn-icon-orange"
                        onClick={() =>
                          billDownload(billData[0]?.bill_no ?? " ")
                        }
                      >
                        <i className="fa fa-file-excel"></i>
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key={"receiptTooltip"}
                      placement={"right"}
                      overlay={<Tooltip id={`tooltip-right`}>Receipt</Tooltip>}
                    >
                      <button
                        className="btn-icon-orange"
                        // className={
                        //   ifAdmin ? "btn-icon-orange" : "btn-icon-orange-light2"
                        // }
                        style={{
                          color: ifAdmin ? "#f04e23" : "#f2af9d",
                        }}
                        disabled={ifAdmin ? false : true}
                        onClick={() =>
                          receiptPrint(billData[0]?.bill_no ?? " ")
                        }
                      >
                        <i className="fa fa-print"></i>
                      </button>
                    </OverlayTrigger>
                    {billData[0]?.sub_tran_type === "HOMEDE" ? (
                      <OverlayTrigger
                        key={"deliveryTooltip"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            Delivery Challan
                          </Tooltip>
                        }
                      >
                        <button
                          className="btn-icon-orange"
                          style={{ color: "#8a2be2e6 " }}
                          onClick={() =>
                            homeReceiptPrint(billData[0]?.bill_no ?? " ")
                          }
                        >
                          <i className="fa fa-print"></i>
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "12px" }}>
                <div
                  className="col-md-12"
                  style={{
                    height: 70.7 + "vh",
                    backgroundColor: "white",
                    overflow: "auto",
                    border: "solid 0px #000000",
                  }}
                >
                  <DataGridSingleDetailsTable data={billData} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            position: "fixed",
            bottom: 0,
            alignSelf: "flex-end",
            marginBottom: ".9rem",
          }}
        >
          {/* <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-12">
              <KeyboardLayout
                ref={searchTextRef}
                fnCallBack={processSearchText}
              />
            </div>
          </div> */}
          {screenName !== "formScreen" ? (
            <div className="row">
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <button
                        className="numBtnCustom btn-lg col-12"
                        onClick={clearData}
                      >
                        <u>B</u>ack
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionLayout;
