import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ModalQwikCilver = ({
  modalOpenClose,
  handleClose,
  props,
  qwikCardValue,
  qwickCardHandleValueChange,
  handleCardSubmit,
  getQwickCardBalance,
}) => {
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    value = value.replace(/[^0-9. ]/g, "");
    if (value === "") {
      toast.error("Enter only Number", {
        autoClose: 2000,
      });
      return;
    }
    qwickCardHandleValueChange({ ...qwikCardValue, [name]: value });

    if (name === "CardNumber") {
      if (qwikCardValue.CardNumber.length > 16) {
        toast.error("Value cannot be greater than 16 digit", {
          toastId: "something",
          autoClose: 2000,
        });
      }
    } else if (name === "pay") {
      let temp = value;
      if (Number(temp) > Number(props.strItemsBillBalanceAmount)) {
        toast.error("You have entered more than the Bill Amount", {
          autoClose: 2000,
        });
        qwickCardHandleValueChange({ ...qwikCardValue, pay: "" });
        document.getElementById("pay").value = "";
      } else if (Number(temp) <= 0) {
        toast.error("Enter an Amount equal or smaller than Bill Amount", {
          autoClose: 2000,
        });
        qwickCardHandleValueChange({ ...qwikCardValue, pay: "" });
      }
    }
  };

  const getWalletBalance = (e) => {
    if (qwikCardValue.CardNumber === "") {
      toast.error("Enter Card Number of 16 Digit to Proceed", {
        autoClose: 2000,
      });
      return;
    } else {
      getQwickCardBalance();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Number(qwikCardValue.pay) > Number(props.strItemsBillBalanceAmount)) {
      toast.error("You have entered more than the Bill Amount", {
        autoClose: 2000,
      });
      document.getElementById("pay").value = props.strItemsBillBalanceAmount;
      return;
    }
    handleCardSubmit(Number(qwikCardValue.pay));
    qwikCardValue.CardNumber = "";
    qwikCardValue.CardPin = "";
    qwikCardValue.pay = "";
    props.setVoucherCardBalance("");
    handleClose();
  };

  return (
    <>
      <Modal
        show={modalOpenClose | false}
        className="modal"
        role="document"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            className="w-100"
            style={{
              color: "#f04e23",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            QWIKCILVER GIFT CARD
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="p-.5rem"
          style={{
            fontWeight: "500",
          }}
        >
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="form-group row mb-4">
              <label className="col-sm-5 fs-5 font-weight-bold">
                Card Number
              </label>
              <div className="col-sm-7">
                <input
                  name="CardNumber"
                  type="text"
                  className="form-control"
                  readOnly={props.strVoucherCardBalance !== "" ? true : false}
                  required
                  maxLength={16}
                  onChange={handleChange}
                  defaultValue={qwikCardValue.CardNumber}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              {/* <div className="row"> */}
              <div className="col">
                <span className="fs-5">
                  <div
                    className={
                      props.strVoucherCardBalance !== ""
                        ? "text-left"
                        : "text-center"
                    }
                  >
                    <div>
                      {props.strVoucherCardBalance !== "" ? (
                        <span className="">
                          Card Balance ( ₹ ) : {props.strVoucherCardBalance}
                        </span>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={getWalletBalance}
                        >
                          Get Card Balance
                        </button>
                      )}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            {props.strVoucherCardBalance !== "" ? (
              <>
                <div className="form-group row">
                  <label className="col-sm-5 fs-5 font-weight-bold">
                    Card Pin
                  </label>
                  <div className="col-sm-7">
                    <input
                      name="CardPin"
                      type="password"
                      className="form-control"
                      required
                      onChange={handleChange}
                      defaultValue={qwikCardValue.CardPin}
                    />
                  </div>
                </div>
                <div
                  className="form-row"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginTop: "2rem",
                  }}
                >
                  <div className="form-group row">
                    <label className="col-sm-6 fs-5 font-weight-bold">
                      Bill Amount
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        className="form-control"
                        readOnly
                        onChange={handleChange}
                        value={props.strItemsBillBalanceAmount}
                        name="Amount"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-7 fs-5 font-weight-bold">
                      Amount to Pay
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="pay"
                        id="pay"
                        required
                        className="form-control"
                        onChange={handleChange}
                        defaultValue={qwikCardValue.pay}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginTop: "2rem",
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      if (
                        Number(qwikCardValue.pay) >
                        Number(props.strItemsBillBalanceAmount)
                      ) {
                        toast.error(
                          "You have entered more than the Bill Amount",
                          {
                            autoClose: 2000,
                          }
                        );
                        document.getElementById("pay").value =
                          props.strItemsBillBalanceAmount;
                      }
                    }}
                  >
                    Reedem
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-grey"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-grey"
                onClick={handleClose}
              >
                Close
              </button>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalQwikCilver;
