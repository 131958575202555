import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../../appconfig.json";
import { toast } from "react-toastify";
import { moreRewardsActions } from "../../../components/store/MoreRewards/MoreRewardsStore";
import { Typography } from "@mui/material";

const CustomerCheckStoreCard = ({
  showCustomerName,
  reset = "",
  loader,
  setLoader = "",
  setShowCustomerOTPState = "",
}) => {
  const walletBalanceScreen = useSelector((state) => state.moreRewards.screen);

  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.storeCard.customerDetailsData
  );
  const isCustExisting = useSelector(
    (state) => state.moreRewards.storeCard.customerDetailsData.isCustExisting
  );

  const dispatch = useDispatch();
  const address = appConfig.api_url;
  const [showCustomerState, setShowCustomerState] = useState(false);
  const [mobileNumber, setmobileNumber] = useState("");
  function frmCustomerSubmit(event) {
    event.preventDefault();
  }
  useEffect(() => {
    if (!showCustomerState) {
      setShowCustomerState(false);
    }
  }, [showCustomerState]);

  function validateSubmitCustomer() {
    const frmCustId = document.getElementById("walletTop.searchCustomer").value;
    if (frmCustId !== "" && frmCustId.length < 10) {
      toast.error("Enter the valid Customer Mobile Number", {
        autoClose: 1000,
      });
    } else {
      getCustomerDetails();
    }
  }
  async function getCustomerDetails(forNo) {
    try {
      var custid = document.getElementById("walletTop.searchCustomer").value;
      setLoader(true);
      let item = {
        searchText: custid,
        basket_id: "",
      };
      let result = await fetch(address + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          if (result.data[0].isCustExisting === false) {
            setShowCustomerOTPState(false);
            setLoader(false);
            toast.error("Customer Not Yet Registered");
            if (walletBalanceScreen === "storeCardBalance") {
              dispatch(moreRewardsActions.setStoreCardCustomerDetails([]));
            }
            return;
          }

          if (walletBalanceScreen === "storeCardBalance") {
            dispatch(
              moreRewardsActions.setStoreCardCustomerDetails(result["data"][0])
            );
          }

          setShowCustomerOTPState(true);
          setLoader(false);
        } else {
          setShowCustomerOTPState(false);
          dispatch(moreRewardsActions.setStoreCardCustomerDetails([]));
          toast.error(result["message"], {
            autoClose: 1000,
          });
          setLoader(false);
        }
      } else {
        setLoader(false);
        setShowCustomerOTPState(false);
        console.log(
          "searchLayout-getCustomerDetails-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-getCustomerDetails :", err);
    }
  }
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );

  const handleReset = () => {
    dispatch(moreRewardsActions.setCustomerDetailsData([]));
    dispatch(moreRewardsActions.walletBalanceData([]));
    setmobileNumber("");
    dispatch(moreRewardsActions.setBasketID(""));
    dispatch(moreRewardsActions.setIsValidCustomer(false));
    dispatch(moreRewardsActions.setWalletRechargeData([]));
    dispatch(moreRewardsActions.setRechargeSucces(""));
    dispatch(moreRewardsActions.setRechargeData([]));
    dispatch(moreRewardsActions.setOtpStatus(false));
    dispatch(moreRewardsActions.setCashAmountForRecharge(""));
    if (
      walletBalanceScreen === "referralProgram" ||
      walletBalanceScreen === "storeCardBalance"
    ) {
      reset();
      setmobileNumber("");
      document.getElementById("walletTop.searchCustomer").value = "";
    }
    setLoader(false);
  };
  return (
    <div
      style={{
        marginLeft: "-1.4rem",
      }}
    >
      <div className="search-tab">
        <div className="row">
          <div className="col-md-5">
            <form onSubmit={frmCustomerSubmit}>
              <div className="input-group">
                <input
                  style={{ borderRadius: "0px" }}
                  id="walletTop.searchCustomer"
                  name="walletTop.searchCustomer"
                  type="number"
                  value={mobileNumber || strCustomerDetails.customer_number}
                  maxLength={10}
                  disabled={isCustExisting ? true : false}
                  className="form-control "
                  placeholder="Customer Number"
                  onChange={(e) => {
                    const check = e.target.value;
                    if (check.length === 11) {
                      return;
                    }
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setmobileNumber(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (mobileNumber.length !== 10) {
                        toast.error("Enter a Valid Mobile Number", {
                          autoClose: 1500,
                        });
                        return;
                      }
                      if (!loader) {
                        validateSubmitCustomer();
                      }
                    }
                  }}
                  autoComplete="off"
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  disabled={isCustExisting ? true : false}
                  onClick={() => {
                    if (mobileNumber.length !== 10) {
                      toast.error("Enter a Valid Mobile Number", {
                        autoClose: 1500,
                      });
                      return;
                    }
                    validateSubmitCustomer();
                  }}
                >
                  {loader ? (
                    <label
                      className="spinner-border"
                      style={{
                        width: ".8rem",
                        height: ".8rem",
                      }}
                    ></label>
                  ) : (
                    <label>Submit</label>
                  )}
                </button>
                <button
                  className="btn btn-primary"
                  style={{
                    marginLeft: "1.1rem",
                    borderRadius: "0px",
                    width: "117px",
                    marginRight: "0.7rem",
                  }}
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>

        {showCustomerName && (
          <div className="col-5">
            <div className="form-text">
              <b>
                Customer :
                <span style={{ color: "#f04e23" }} id="searchLayout.userName">
                  {strCustomerDetails["custName"]}
                </span>
              </b>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerCheckStoreCard;
