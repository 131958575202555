import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { returnActions } from "./store/returnSaleStore";
import { customerActions } from "./store/customerStore";
import axios from "axios";
import { toast } from "react-toastify";
import appConfig from "../appconfig.json";
import { useCallback } from "react";
import ModalViewGst from "./Modal/ModalViewGst";
import { Switch } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useCommonSelectors } from "../Common/selector";
const UserRegister = (props) => {
  const [inputs, setInputs] = useState({
    phoneNumber: "",
    fname: "",
    lname: "",
    email: "",
    adressLine1: "",
    adressLine2: "",
    pincode: "",
    customer_referer: "",
    gstCompanyName: "",
    gstAddress1: "",
    gstAddress2: "",
    gstCity: "",
    gstPinCode: "",
    gstNumber: "",
  });
  const [userOTP, setUserOTP] = useState("");
  const [GST, setGST] = useState(false);
  const [viewGst, setViewGst] = useState(false);
  const [gstData, setGstData] = useState([]);

  const [sendOtpFlag, setOTPbtn] = useState(props.disableField || true);
  const [validOtpFlag, setValidOTPbtn] = useState(true);
  const [otpfieldFlag, setOtpField] = useState(true);

  const [phonefieldFlag, setphoneField] = useState(
    !props.disableField || false
  );
  const [fnamefieldFlag, setfnameField] = useState(!props.disableField || true);
  const [lnamefieldFlag, setlnameField] = useState(!props.disableField || true);

  const [timerSpan, setTimerDisplay] = useState("None");
  const [OTPtimer, setTimer] = useState("");

  const [existMobileNo, setExistMobileNo] = useState(null);
  const [customerId, setCustomerId] = useState();
  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  var submitBtn = "";

  const address = appConfig.api_url;
  const [alertFlag, setAlertFlag] = useState("info");

  const [refundDetails, setRefundDetail] = useState(
    props.strRefundDetails ?? {}
  );
  const [customerExisting, setCustomerExisting] = useState(false);
  const [newCustomer, setNewCustomer] = useState(
    props.strCustomerDetails ?? {}
  );

  const intervalRef = useRef(null);
  const otpFieldRef = useRef();

  function setSubmitButton(flag) {
    submitBtn = flag;
  }
  const { loc, pos_id_mpos } = useCommonSelectors();

  useEffect(() => {}, [otpfieldFlag]);

  function FormReset() {
    setEmail("");
    setAddress1("");
    setAddress2("");
    setInputs({
      phoneNumber: "",
      fname: "",
      lname: "",
      email: "",
      adressLine1: "",
      adressLine2: "",
      pincode: "",
      customer_referer: "",
      gstCompanyName: "",
      gstAddress1: "",
      gstAddress2: "",
      gstCity: "",
      gstPinCode: "",
      gstNumber: "",
    });
    setGST(false);
    setGstData([]);
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("email").value = "";
    document.getElementById("adressLine1").value = "";
    document.getElementById("adressLine2").value = "";
    document.getElementById("pincode").value = "";
    setCustomerId("");
    setExistMobileNo(null);
  }

  const phoneNumberFunc = () => {
    setInputs({ ...inputs, ["phoneNumber"]: props.customerNumber });
    setphoneField(true);
    if (alertFlag !== "success") {
      if (document.getElementById("phoneNumber")?.value.length === 10) {
        phoneCall(document.getElementById("phoneNumber").value);
      }
    }
  };

  useEffect(() => {
    try {
      if (props.show) {
        if (document.getElementById("phoneNumber")?.value.length === 10) {
          phoneCall(document.getElementById("phoneNumber").value);
        } else {
          setTimer("");
          setTimerDisplay("none");
          setSubmitButton("");
          setphoneField(false);
          setAlertFlag("info");
          setInputs({
            phoneNumber: "",
            fname: "",
            lname: "",
            email: "",
            adressLine1: "",
            adressLine2: "",
            pincode: "",
            customer_referer: "",
            gstCompanyName: "",
            gstAddress1: "",
            gstAddress2: "",
            gstCity: "",
            gstPinCode: "",
            gstNumber: "",
          });

          setUserOTP({});
          setOTPbtn(true);
          setValidOTPbtn(true);
          setOtpField(true);
          setNewCustomer({});
          setRefundDetail({});
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }
      }
    } catch (err) {
      console.log("userRegister.useEffectProps.show : ", err);
    }
  }, [props.show]);

  const phoneCall = (value) => {
    //This is where multiple calls are hapenning, check how it can be fixed
    try {
      FormReset();
      axios
        .post(address + "getDsCustomer/", {
          searchText: Number(value),
          loc: loc,
          pos_id: pos_id_mpos,
        })
        .then((response) => {
          if (response.data.data[0].isCustExisting === true) {
            setCustomerExisting(true);
            getGstData(response.data.data[0].cust_id);
            setInputs({
              ...inputs,
              phoneNumber:
                response.data.data[0].customer_number ||
                document.getElementById("phoneNumber").value,
              fname:
                response.data.data[0].first_name ||
                document.getElementById("fname").value,

              lname:
                response.data.data[0].last_name ||
                document.getElementById("lname").value,
            });
          }
          if (response.data.data[0].isCustExisting === false) {
            setCustomerExisting(false);

            setfnameField(false);
            setlnameField(false);
            setOTPbtn(false);
            setTimerDisplay("None");
          } else {
            if (props.strCustomerDetailsData?.customer_number === value) {
              props.setHomeDelivery(response.data.data[0].allowHomDel);
            }
            setfnameField(true);
            setlnameField(true);
            setTimerDisplay("block");
            setAlertFlag("danger");
            setTimer(
              "Customer Already Exist" +
                " ( " +
                response.data.data[0].cust_id +
                " - " +
                response.data.data[0].first_name +
                " ) "
            );

            setInputs({
              ...inputs,
              fname: response.data.data[0].first_name,
              lname: response.data.data[0].last_name,
              email: response.data.data[0].email_id || "",
              pincode: response.data.data[0].pincode,
              address1: response.data.data[0].address_line_1 || "",
              address2: response.data.data[0].address_line_2 || "",
              phoneNumber:
                response.data.data[0].customer_number ||
                document.getElementById("phoneNumber").value,
            });

            setEmail(response.data.data[0].email_id || null);
            setAddress1(response.data.data[0].address_line_1 || "");
            setAddress2(response.data.data[0].address_line_2 || "");
            setExistMobileNo(response.data.data[0].customer_number);
            setCustomerId(response.data.data[0].cust_id);
          }
        });
    } catch (err) {
      console.log("userRegister.phoneCall : ", err);
    }
  };

  useEffect(() => {
    if (props.disableField) {
      phoneNumberFunc();
    } else {
      setphoneField(false);
    }
  }, []);

  useEffect(() => {
    if (props.disableField) {
      phoneNumberFunc();
    } else {
      setphoneField(false);
    }
  }, [phonefieldFlag]);

  const handleChange = (event) => {
    const name = event.target.name;
    var value = event.target.value;

    value = value.replace(/[^a-zA-Z0-9 ]/g, "");

    // document.getElementById(event.target.id).value = value;

    setInputs({
      ...inputs,
      [name]: value,
    });
    if (name === "phoneNumber") {
      if (value.length === 10) {
        phoneCall(value);
      }
    }
  };

  const [email, setEmail] = useState();
  const handleChangeEmail = (event) => {
    var value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9@. ]/g, "");
    document.getElementById(event.target.id).value = value;
    setEmail(value);
  };
  const [address1, setAddress1] = useState(null);
  const handleChangeAddress1 = (event) => {
    setAddress1(event.target.value);
  };

  const [address2, setAddress2] = useState(null);
  const handleChangeAddress2 = (event) => {
    setAddress2(event.target.value);
  };
  const [pincode, setPincode] = useState("");
  const handleChangePincode = (event) => {
    if (event.target.value.length <= 6) {
      setPincode(event.target.value);
      setInputs({
        ...inputs,
        pincode: event.target.value,
      });
    } else {
      return "";
    }
  };

  const handleChangeForGstPinCode = (e) => {
    const regex = /^[0-9\b]+$/;
    const name = e.target.name;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setInputs({
        ...inputs,
        [name]: e.target.value,
      });
    }
  };
  const isValidGSTNo = (str) => {
    // Regex to check valid
    // GST CODE
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    // GST CODE
    // is empty return false
    if (str == null) {
      return false;
    }

    // Return true if the GST_CODE
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  };
  const OTPCounter = (timeExpries) => {
    try {
      intervalRef.current = setInterval(function () {
        if (timeExpries <= 0) {
          setValidOTPbtn(true);
          setOTPbtn(false);
          setOtpField(true);
          setphoneField(false);
          setUserOTP("");
          setAlertFlag("danger");
          setTimer("OTP Expired.. Resend");
          clearInterval(intervalRef.current);
        }
        var min = 0;
        var sec = 0;
        min = ~~((timeExpries % 3600) / 60);
        sec = ~~timeExpries % 60;
        setAlertFlag("primary");
        if (min < 1) {
          setAlertFlag("warning");
        }
        setTimer("".concat("OTP Expires  ", min, ":", sec));
        timeExpries -= 1;
        if (timeExpries < 0) {
          setAlertFlag("danger");
          setTimer("OTP Expired.. Resend");
        }
      }, 1000);
    } catch (err) {
      console.log("userRegister.OTPCounter : ", err);
    }
  };
  // const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputs.pincode !== "") {
      if (inputs.pincode.toString().length !== 6) {
        toast.error("Please Enter A Valid Pincode", {
          autoClose: 1500,
        });
        return;
      }
    }

    try {
      if (submitBtn === "send") {
        var error = false;
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        if (inputs.gstNumber !== "") {
          if (inputs.gstNumber.length < 15) {
            toast.error("GSTIN Number length should be equal to 15", {
              autoClose: 1500,
            });
            return;
          }
        }
        if (GST) {
          if (inputs.gstCompanyName === "") {
            toast.error("Enter Company Name to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstCity === "") {
            toast.error("Enter City Name to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstAddress1 === "") {
            toast.error("Enter Address Details to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstNumber === "") {
            toast.error("Enter GSTIN Number to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstPinCode === "") {
            toast.error("Enter Pincode to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstPinCode.length !== 6) {
            toast.error("Enter a Valid Pincode to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstNumber !== "") {
            let x = isValidGSTNo(inputs.gstNumber);
            if (x === false) {
              toast.error("Enter Valid GSTIN Number to Proceed", {
                autoClose: 1000,
              });
              return;
            }
          }
        }
        if (error) {
          if (document.getElementById("phoneNumber").value.length !== 10) {
            error = true;
            // setError(true);
            toast.error("Enter Valid mobile number", {
              autoClose: 1000,
            });
            return;
          }

          if (typeof inputs.fname != "undefined") {
            if (inputs.fname.length < 3) {
              // setError(true);
              error = true;
              toast.error("Enter Valid First Name", {
                autoClose: 1000,
              });
              return;
            }
          } else {
            // setError(true);
            error = true;
            toast.error("Enter Valid First Name", {
              autoClose: 1000,
            });
            return;
          }

          if (typeof inputs.lname != "undefined") {
            if (inputs.lname.length < 1) {
              // setError(true);
              error = true;
              toast.error("Enter Valid Last Name", {
                autoClose: 1000,
              });
              return;
            }
          } else {
            // setError(true);
            error = true;
            toast.error("Enter Valid Last Name", {
              autoClose: 1000,
            });
            return;
          }

          if (address1 !== "" || address2 !== "") {
            if (
              inputs.pincode === undefined ||
              inputs.pincode?.toString()?.length !== 6 ||
              inputs.pincode === "000000"
            ) {
              // setError(true);
              error = true;
              toast.error("Enter Valid Pincode", {
                autoClose: 1000,
              });
              return;
            }
          }

          if (document.getElementById("customer_referer")?.value !== "") {
            axios
              .post(address + "getCustReferralUserStatus/", {
                cust_ref:
                  inputs?.customer_referer ||
                  document.getElementById("customer_referer")?.value ||
                  "",
              })
              .then((response) => {
                if (response.data.data[0].exist !== "true") {
                  error = true;
                  document.getElementById("customer_referer").value = "";
                  toast.error("Invalid Referral Code", {
                    autoClose: 1000,
                  });
                  return;
                } else {
                  error = false;
                  senOtpDuringRegistration(error);
                  return;
                }
              });
          } else {
            error = false;
            senOtpDuringRegistration(error);
            return;
          }
        }

        senOtpDuringRegistration(error);
      } else if (submitBtn === "validate") {
        if (typeof inputs.otp != "undefined") {
          var userInputOTP = inputs.otp;
          if (userInputOTP.length >= 0) {
            userInputOTP = Number(userInputOTP);
            if (userInputOTP === userOTP) {
              setAlertFlag("success");
              setTimer("Valid OTP");
              if (intervalRef.current) {
                clearInterval(intervalRef.current);
                setDisableOtpBtn(false);
              }
              setValidOTPbtn(true);
              setOtpField(true);

              axios
                .post(address + "addCustomer/", {
                  first_name: inputs.fname,
                  last_name: inputs.lname,
                  customer_number:
                    parseInt(inputs.phoneNumber) ||
                    parseInt(existMobileNo) ||
                    parseInt(document.getElementById("phoneNumber").value),
                  email_id: email || "",
                  address_line_1: address1 || "",
                  address_line_2: address2 || "",
                  ...(pincode ? { pincode: parseInt(pincode) } : {}),
                  cashier_id: props.strUserDetails["empCode"],
                  cust_id: customerId === undefined ? "" : customerId,
                  customer_referer: inputs.customer_referer || "",
                  gst_flag: GST ? "true" : "false",
                  gst_data: {
                    companyName: inputs.gstCompanyName || "",
                    address1: inputs.gstAddress1 || "",
                    address2: inputs.gstAddress2 || "",
                    city: inputs.gstCity || "",
                    pincode: Number(inputs.gstPinCode) || 0,
                    gstinNo: inputs.gstNumber || "",
                  },
                  loc: loc,
                  pos_id: pos_id_mpos,
                })
                .then((response) => {
                  if (response.data.status === "failure") {
                    toast.error(response.data.message, {
                      autoClose: 2000,
                    });
                    return;
                  }

                  if (response.data.data[0].gst_status === "failure") {
                    toast.error(
                      "GST details not updated, Please retry to enter details",
                      {
                        autoClose: 1500,
                      }
                    );
                  }

                  setfnameField(true);
                  setlnameField(true);
                  setphoneField(false);
                  setRefundDetail({
                    ...refundDetails,
                    ["customerNumber"]: inputs.phoneNumber,
                    ["customerIdCreated"]: true,
                  });
                  setNewCustomer({
                    ...newCustomer,
                    ["mobile_no"]: inputs.phoneNumber,
                  });
                  props.setNewCustomer({
                    ...newCustomer,
                    ["mobile_no"]: inputs.phoneNumber,
                  });
                  props.setRefundDetail({
                    ...refundDetails,
                    ["customerNumber"]: inputs.phoneNumber,
                    ["customerIdCreated"]: true,
                  });
                  setAlertFlag("success");
                  if (
                    props.strCustomerDetailsData?.customer_number ===
                    document.getElementById("phoneNumber").value
                  ) {
                    props.setHomeDelivery(response.data.data[0].allowHomDel);
                    var newCustomerDetails = {
                      cust_id: response.data.data[0].cust_id,
                      customer_number:
                        props.strCustomerDetailsData?.customer_number,
                      custName: response.data.data[0].custName,
                      wallet_bal: response.data.data[0].wallet_bal,
                      isCustExisting: true,
                    };
                    props.setStrCustomerDetailsData(newCustomerDetails);
                  }
                  if (props.getCustomerName !== "") {
                    props.getCustomerName();
                  }
                  if (props.customerValidate !== "") {
                    props.customerValidate({
                      searchText: response.data.data[0].customer_number,
                    });
                  }
                  setTimer(
                    response.data.message +
                      " ( " +
                      " CustomerId :" +
                      response.data.data[0].cust_id +
                      " ) "
                  );
                  setInputs({
                    phoneNumber: "",
                    fname: "",
                    lname: "",
                    email: "",
                    adressLine1: "",
                    adressLine2: "",
                    pincode: "",
                    customer_referer: "",
                    gstCompanyName: "",
                    gstAddress1: "",
                    gstAddress2: "",
                    gstCity: "",
                    gstPinCode: "",
                    gstNumber: "",
                  });
                  if (response.data.data[0].gst_status === "success") {
                    toast.success("GST Details updated successfully", {
                      autoClose: 1500,
                    });
                  }
                  document.getElementById("phoneNumber").value = "";
                  setExistMobileNo(null);
                  setEmail("");
                  setAddress1("");
                  setAddress2("");
                  FormReset();
                });

              setTimeout(() => {
                closeModal();
                props.button_clicked();
              }, 10000);
            } else {
              setAlertFlag("danger");
              setTimer("Invalid OTP");
            }
          }
        }
      }
    } catch (err) {
      console.log("userRegister.handleSubmit : ", err);
    }
  };

  const senOtpDuringRegistration = (error) => {
    if (!error) {
      setDisableOtpBtn(true);
      axios
        .post(address + "send_otp/", {
          otp_for: "CUSTREG",
          customer_number:
            inputs.phoneNumber ||
            props.customerNumber ||
            document.getElementById("phoneNumber").value ||
            existMobileNo,
          message: "OTP",
          pos_id: props.pos_id.toString(),
        })
        .then((response) => {
          var timexpries = Number(response.data.data[0]["otpExpInSecs"]);
          var otp = Number(response.data.data[0]["otp"]);
          setOtpField(false);
          setphoneField(true);
          setfnameField(true);
          setlnameField(true);
          setUserOTP(otp);
          OTPCounter(timexpries);
          setTimeout(() => {
            document.getElementById("otp").focus();
          }, 1000);
        });
      setOTPbtn(true);
      setAlertFlag("primary");
      setTimer("Sending OTP. Please wait... ");
      setValidOTPbtn(false);
      setTimerDisplay("block");
    }
  };

  const closeModal = () => {
    setCustomerExisting(false);
    setTimer("");
    setTimerDisplay("none");
    setSubmitButton("");
    setphoneField(false);
    setAlertFlag("info");
    setInputs({
      phoneNumber: "",
      fname: "",
      lname: "",
      email: "",
      adressLine1: "",
      adressLine2: "",
      pincode: "",
      customer_referer: "",
      gstCompanyName: "",
      gstAddress1: "",
      gstAddress2: "",
      gstCity: "",
      gstPinCode: "",
      gstNumber: "",
    });
    setGST(false);
    setGstData([]);
    setUserOTP({});
    setOTPbtn(true);
    setValidOTPbtn(true);
    setOtpField(true);
    setNewCustomer({});
    setRefundDetail({});
    setEmail("");
    setAddress1("");
    setAddress2("");
    setExistMobileNo(null);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setDisableOtpBtn(false);
    }
  };

  const handleSubmitForShortcut = () => {
    if (inputs.pincode !== "") {
      if (inputs.pincode.toString().length !== 6) {
        toast.error("Please Enter A Valid Pincode", {
          autoClose: 1500,
        });
        return;
      }
    }
    try {
      if (submitBtn === "send") {
        var error = false;
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        if (inputs.gstNumber !== "") {
          if (inputs.gstNumber.length < 15) {
            toast.error("GSTIN Number length should be equal to 15", {
              autoClose: 1000,
            });
            return;
          }
        }
        if (GST) {
          if (inputs.gstCompanyName === "") {
            toast.error("Enter Company Name to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstCity === "") {
            toast.error("Enter City Name to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstAddress1 === "") {
            toast.error("Enter Address Details to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstNumber === "") {
            toast.error("Enter GSTIN Number to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstPinCode === "") {
            toast.error("Enter Pincode to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
          if (inputs.gstPinCode.length !== 6) {
            toast.error("Enter a Valid Pincode to proceed with GST", {
              autoClose: 1000,
            });
            return;
          }
        }
        if (error) {
          if (document.getElementById("phoneNumber").value.length !== 10) {
            error = true;
            // setError(true);
            toast.error("Enter Valid mobile number", {
              autoClose: 1000,
            });
            return;
          }
          if (typeof inputs.fname != "undefined") {
            if (inputs.fname.length < 3) {
              // setError(true);
              error = true;
              toast.error("Enter Valid First Name", {
                autoClose: 1000,
              });
              return;
            }
          } else {
            // setError(true);
            error = true;
            toast.error("Enter Valid First Name", {
              autoClose: 1000,
            });
            return;
          }

          if (typeof inputs.lname != "undefined") {
            if (inputs.lname.length < 1) {
              // setError(true);
              error = true;
              toast.error("Enter Valid Last Name", {
                autoClose: 1000,
              });
              return;
            }
          } else {
            // setError(true);
            error = true;
            toast.error("Enter Valid Last Name", {
              autoClose: 1000,
            });
            return;
          }

          if (address1 !== "" || address2 !== "") {
            if (
              inputs.pincode === undefined ||
              inputs.pincode?.toString()?.length !== 6 ||
              inputs.pincode === "000000"
            ) {
              // setError(true);
              error = true;
              toast.error("Enter Valid Pincode", {
                autoClose: 1000,
              });
              return;
            }
          }

          if (document.getElementById("customer_referer").value !== "") {
            axios
              .post(address + "getCustReferralUserStatus/", {
                cust_ref:
                  inputs.customer_referer ||
                  document.getElementById("customer_referer").value,
              })
              .then((response) => {
                if (response.data.data[0].exist !== "true") {
                  error = true;
                  document.getElementById("customer_referer").value = "";
                  toast.error("Enter Registered Refereal Number", {
                    autoClose: 1000,
                  });
                  return;
                } else {
                  error = false;
                  senOtpDuringRegistration(error);
                  return;
                }
              });
          } else {
            error = false;
            senOtpDuringRegistration(error);
            return;
          }
        }
        senOtpDuringRegistration(error);
      } else if (submitBtn === "validate") {
        if (typeof inputs.otp != "undefined") {
          var userInputOTP = inputs.otp;
          if (userInputOTP.length >= 0) {
            userInputOTP = Number(userInputOTP);
            if (userInputOTP === userOTP) {
              setAlertFlag("success");
              setTimer("Valid OTP");
              if (intervalRef.current) {
                clearInterval(intervalRef.current);
                setDisableOtpBtn(false);
              }
              setValidOTPbtn(true);
              setOtpField(true);

              axios
                .post(address + "addCustomer/", {
                  first_name: inputs.fname,
                  last_name: inputs.lname,
                  customer_number:
                    parseInt(inputs.phoneNumber) ||
                    parseInt(existMobileNo) ||
                    parseInt(document.getElementById("phoneNumber").value),
                  email_id: email || "",
                  address_line_1: address1 || "",
                  address_line_2: address2 || "",
                  ...(pincode ? { pincode: parseInt(pincode) } : {}),
                  cashier_id: props.strUserDetails["empCode"],
                  cust_id: customerId === undefined ? "" : customerId,
                  customer_referer: inputs.customer_referer || "",
                  gst_flag: GST ? "true" : "false",
                  gst_data: {
                    companyName: inputs.gstCompanyName || "",
                    address1: inputs.gstAddress1 || "",
                    address2: inputs.gstAddress2 || "",
                    city: inputs.gstCity || "",
                    pincode: Number(inputs.gstPinCode) || 0,
                    gstinNo: inputs.gstNumber || "",
                  },
                  loc: loc,
                  pos_id: pos_id_mpos,
                })
                .then((response) => {
                  if (response.data.status === "failure") {
                    toast.error(response.data.message, {
                      autoClose: 2000,
                    });
                    return;
                  }

                  if (response.data.data[0].gst_status === "failure") {
                    toast.error(
                      "GST details not updated, Please retry to enter details",
                      {
                        autoClose: 1500,
                      }
                    );
                  }
                  setfnameField(true);
                  setlnameField(true);
                  setphoneField(false);
                  setRefundDetail({
                    ...refundDetails,
                    ["customerNumber"]: inputs.phoneNumber,
                    ["customerIdCreated"]: true,
                  });
                  setNewCustomer({
                    ...newCustomer,
                    ["mobile_no"]: inputs.phoneNumber,
                  });
                  props.setNewCustomer({
                    ...newCustomer,
                    ["mobile_no"]: inputs.phoneNumber,
                  });
                  props.setRefundDetail({
                    ...refundDetails,
                    ["customerNumber"]: inputs.phoneNumber,
                    ["customerIdCreated"]: true,
                  });
                  setAlertFlag("success");
                  if (
                    props.strCustomerDetailsData?.customer_number ===
                    document.getElementById("phoneNumber").value
                  ) {
                    props.setHomeDelivery(response.data.data[0].allowHomDel);
                    var newCustomerDetails = {
                      cust_id: response.data.data[0].cust_id,
                      customer_number:
                        props.strCustomerDetailsData?.customer_number,
                      custName: response.data.data[0].custName,
                      wallet_bal: response.data.data[0].wallet_bal,
                      isCustExisting: true,
                    };
                    props.setStrCustomerDetailsData(newCustomerDetails);
                  }
                  if (props.getCustomerName !== "") {
                    props.getCustomerName();
                  }
                  if (props.customerValidate !== "") {
                    props.customerValidate({
                      searchText: response.data.data[0].customer_number,
                      loc: loc,
                      pos_id: pos_id_mpos,
                    });
                  }
                  setTimer(
                    response.data.message +
                      " ( " +
                      " CustomerId :" +
                      response.data.data[0].cust_id +
                      " ) "
                  );

                  setInputs({
                    phoneNumber: "",
                    fname: "",
                    lname: "",
                    email: "",
                    adressLine1: "",
                    adressLine2: "",
                    pincode: "",
                    customer_referer: "",
                    gstCompanyName: "",
                    gstAddress1: "",
                    gstAddress2: "",
                    gstCity: "",
                    gstPinCode: "",
                    gstNumber: "",
                  });

                  if (response.data.data[0].gst_status === "success") {
                    toast.success("GST Details updated successfully", {
                      autoClose: 1500,
                    });
                  }
                  document.getElementById("phoneNumber").value = "";
                  setExistMobileNo(null);
                  setEmail("");
                  setAddress1("");
                  setAddress2("");
                  FormReset();
                });

              setTimeout(() => {
                closeModal();
                props.button_clicked();
              }, 10000);
            } else {
              setAlertFlag("danger");
              setTimer("Invalid OTP");
            }
          }
        }
      }
    } catch (err) {
      console.log("userRegister.handleSubmit : ", err);
    }
  };

  const handleReset = () => {
    setCustomerExisting(false);
    setInputs({
      ...inputs,
      phoneNumber: "",
      fname: "",
      lname: "",
      email: "",
      adressLine1: "",
      adressLine2: "",
      pincode: "",
      customer_referer: "",
      gstCompanyName: "",
      gstAddress1: "",
      gstAddress2: "",
      gstCity: "",
      gstPinCode: "",
      gstNumber: "",
    });
    setGST(false);
    setGstData([]);
    setExistMobileNo(null);
    setphoneField(false);
    setfnameField(true);
    setlnameField(true);
    setValidOTPbtn(true);
    setOtpField(true);
    setOTPbtn(true);
    setTimerDisplay("none");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setDisableOtpBtn(false);
      setTimerDisplay("none");
    }
    setEmail("");
    setAddress1("");
    setAddress2("");
    setCustomerId("");
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = " ";
    document.getElementById("otp").value = " ";
    document.getElementById("phoneNumber").value = "";
    document.getElementById("customer_referer").value = "";

    if (props.disableField) {
      phoneCall(document.getElementById("phoneNumber").value);
      setInputs({ ["phoneNumber"]: props.customerNumber });
      setphoneField(true);
    } else {
      document.getElementById("phoneNumber").value = "";
      document.getElementById("phoneNumber").focus();
    }
  };

  const keyboardEventListenerNewSaleBilling = useCallback(
    (e) => {
      if (
        e.altKey &&
        e.code === "KeyO" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        setSubmitButton("send");
        handleSubmitForShortcut();
      }
    },
    [
      inputs.phoneNumber,
      props.customerNumber,
      existMobileNo,
      phonefieldFlag,
      disableOtpBtn,
      inputs.fname,
      inputs.lname,
      inputs.pincode,
      inputs.otp,
      otpFieldRef,
      otpfieldFlag,
      address1,
      address2,
      validOtpFlag,
      inputs.customer_referer,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListenerNewSaleBilling);
    return () => {
      window.removeEventListener(
        "keydown",
        keyboardEventListenerNewSaleBilling
      );
    };
  }, [keyboardEventListenerNewSaleBilling]);

  const getGstData = (cust_id) => {
    axios
      .post(address + "getDsCustGstData/", {
        cust_id: cust_id,
      })
      .then((response) => {
        setGstData(response.data.data);
      });
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          if (props.searchLayout || false) {
            props.noCustomerDetails();
          }
          closeModal();
          props.button_clicked();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <form method="POST" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <span className="inputRequired">*</span>
                    <input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Mobile Number"
                      id="phoneNumber"
                      defaultValue={
                        inputs.phoneNumber ||
                        props.customerNumber ||
                        existMobileNo ||
                        ""
                      }
                      onChange={handleChange}
                      onLoad={handleChange}
                      pattern="[0-9]{10}"
                      disabled={phonefieldFlag}
                      autoFocus={true}
                      required
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <span className="inputRequired">*</span>
                    <input
                      required
                      type="text"
                      name="fname"
                      id="fname"
                      className="form-control"
                      minLength="3"
                      value={inputs.fname || ""}
                      placeholder="First Name"
                      onInput={handleChange}
                      disabled={disableOtpBtn}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <span className="inputRequired">*</span>
                    <input
                      required
                      type="text"
                      name="lname"
                      id="lname"
                      minLength="1"
                      className="form-control"
                      placeholder="Last Name"
                      value={inputs.lname || ""}
                      onInput={handleChange}
                      disabled={disableOtpBtn}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    {GST && <span className="inputRequired">*</span>}
                    <input
                      type="email"
                      name="email"
                      id="email"
                      minLength="1"
                      className="form-control"
                      placeholder="Email Id"
                      value={email || ""}
                      onInput={handleChangeEmail}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      required={GST && true}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <input
                      type="text"
                      name="adressLine1"
                      id="adressLine1"
                      minLength="4"
                      maxLength="80"
                      className="form-control"
                      placeholder="Address Line 1"
                      value={address1 || ""}
                      onInput={handleChangeAddress1}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <input
                      type="text"
                      name="adressLine2"
                      id="adressLine2"
                      minLength="4"
                      maxLength="80"
                      className="form-control"
                      placeholder="Address Line 2"
                      value={address2 || ""}
                      onInput={handleChangeAddress2}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <input
                      type="number"
                      name="pincode"
                      id="pincode"
                      minLength={6}
                      maxLength={6}
                      className="form-control"
                      placeholder="Pin Code"
                      value={inputs.pincode || ""}
                      onInput={handleChangePincode}
                      pattern="[0-9]{10}"
                    />
                  </div>
                </div>
                {!customerExisting ? (
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-12">
                      <input
                        type="number"
                        name="customer_referer"
                        id="customer_referer"
                        className="form-control"
                        minLength="3"
                        value={inputs.customer_referer || ""}
                        placeholder="Referral Code"
                        onInput={handleChange}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <Switch
                      checked={GST}
                      onChange={(e) => {
                        setGST(e.target.checked);
                      }}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#f04e23",
                          "&:hover": {
                            backgroundColor: alpha("#f04e23"),
                          },
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#f04e23",
                          },
                      }}
                    />
                    <label htmlFor="checkboxForGST">GST</label>
                  </div>
                </div>
                {GST && (
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-12">
                      <div
                        className="row gstInputFields"
                        style={{
                          gap: "1rem",
                          padding: "1rem",
                        }}
                      >
                        <input
                          required={true}
                          type="text"
                          name="gstCompanyName"
                          id="gstCompanyName"
                          className="col-md-3 gstForm"
                          placeholder="Company Name"
                          value={inputs.gstCompanyName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          name="gstAddress1"
                          id="gstAddress1"
                          className="col-md-3 gstForm"
                          placeholder="Address 1"
                          minLength={3}
                          maxLength={80}
                          value={inputs.gstAddress1}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          name="gstAddress2"
                          id="gstAddress2"
                          className="col-md-3 gstForm"
                          placeholder="Address 2"
                          minLength={3}
                          maxLength={80}
                          value={inputs.gstAddress2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <input
                          required={true}
                          type="text"
                          name="gstCity"
                          id="gstCity"
                          className="col-md-3 gstForm"
                          placeholder="City"
                          value={inputs.gstCity}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <input
                          required={true}
                          type="text"
                          name="gstPinCode"
                          id="gstPinCode"
                          minLength={6}
                          maxLength={6}
                          className="col-md-3 gstForm"
                          placeholder="Pincode"
                          pattern="[0-9]*"
                          value={inputs.gstPinCode}
                          onChange={(e) => {
                            handleChangeForGstPinCode(e);
                          }}
                        />
                        <input
                          required={true}
                          type="text"
                          name="gstNumber"
                          id="gstNumber"
                          maxLength={15}
                          className="col-md-3 gstForm"
                          placeholder="GSTIN No."
                          value={inputs.gstNumber}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12">
                    <div className="input-group">
                      <input
                        ref={otpFieldRef}
                        type="number"
                        name="otp"
                        id="otp"
                        className="form-control"
                        placeholder="Enter OTP"
                        value={inputs.otp || ""}
                        onInput={handleChange}
                        pattern="[0-9]+"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            setSubmitButton("validate");
                            handleSubmit(event);
                            event.preventDefault();
                          }
                        }}
                        disabled={otpfieldFlag}
                        autoFocus={true}
                      />
                      <button
                        className="btn btn-primary"
                        name="sendOTP"
                        id="send"
                        disabled={disableOtpBtn ? true : ""}
                        onClick={() => setSubmitButton("send")}
                      >
                        Send <u>O</u>TP
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-12 text-center">
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        display: timerSpan,
                      }}
                    >
                      <span style={{ color: "red" }}> {OTPtimer}</span>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-primary btn-grey"
                      name="clearData"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>{" "}
                    <button
                      className="btn btn-primary"
                      name="validateOTP"
                      id="validate"
                      disabled={validOtpFlag}
                      onClick={() => setSubmitButton("validate")}
                    >
                      Submit
                    </button>
                    {gstData.length > 0 && (
                      <button
                        className="btn btn-primary btn-grey viewGstModal"
                        name="viewGST"
                        onClick={() => setViewGst(!viewGst)}
                        disabled={gstData.length === 0}
                        style={{
                          marginLeft: "3px",
                        }}
                      >
                        View GST
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {viewGst && (
        <ModalViewGst
          setShowGstModel={setViewGst}
          showGstModel={viewGst}
          gstData={gstData}
        />
      )}
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strCustomerDetails: reduxState?.returnSale?.newCustomerDetails,
    strCustomerDetailsData: reduxState?.customer?.customerDetailsData,
    strRefundDetails: reduxState?.returnSale?.refundDetails,
    strUserDetails: reduxState?.login?.userDetailsData,
    pos_id: reduxState?.login?.userDetailsData.pos_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setNewCustomer: (CustomerNo) =>
      dispatch(returnActions.setNewCustomer(CustomerNo)),
    setRefundDetail: (refundData) =>
      dispatch(returnActions.setRefundDetail(refundData)),
    setHomeDelivery: (flag) => dispatch(customerActions.setHomeDelivery(flag)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegister);
