import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { newSaleActions } from "../store/newSaleStore";
import { toast } from "react-toastify";

import appConfig from "../../appconfig.json";
import { Modal, Card, Button } from "react-bootstrap";
import { useCommonSelectors } from "../../Common/selector";

const ModalMultipleMRP = (props) => {
  const { loc, pos_id_mpos } = useCommonSelectors();

  const [mrpValue, setMRPValue] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.show) {
      document.getElementById("txtmrp").focus();
    }
  }, [props.show]);

  const getItemsDetails = async () => {
    var paramCustId = props.strCustomerDetails.cust_id
      ? props.strCustomerDetails.cust_id
      : "";
    var paramCustNumber = props.strCustomerDetails.customer_number
      ? props.strCustomerDetails.customer_number
      : "";
    var paramBasketId = props.strNewSaleItemsBasketId
      ? props.strNewSaleItemsBasketId
      : "";
    let payload = {
      event: "basket_cart",
      cust_id: paramCustId,
      customer_number: paramCustNumber,
      cashier_id: props.strUserDetails.empCode,
      basket_id: paramBasketId,
      item: props.multipleMRPItems[0]["item"],
      barcode: props.multipleMRPItemBarcode,
      item_desc: props.multipleMRPItems[0]["item_desc"],
      qty: 1,
      mrp: mrpValue,
      sub_tran_type: props.strTranType,
      slot: props.strSelectedDeliverySlot,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status <= 299) {
      response = await response.json();
      if (response["status"] === "success") {
        if (response["message"] === "FINAL") {
          props.setStrItemsListData([]);
          props.setStrItemsBasketId(response["data"][0]["basket_id"]);
          props.setStrItemsCartValue(response["data"][0]["totalCart"]);
          props.setStrItemsTotalDiscount(response["data"][0]["totalDiscount"]);
          props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
          props.setStrItemsBillBalanceAmount(response["data"][0]["balanceAmt"]);
          props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
          props.setRecommendOffer(response["data"][0]["recomOffers"]);
          props.setSlabOfferDesc(response["data"][0]["slab_offer_description"]);
          props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
          setTimeout(
            () => props.setStrItemsListData(response["data"][0]["items"]),
            1
          );
          props.close();
        }
      } else {
        toast.error("Error Occurred.", {
          autoClose: 1000,
        });
      }
    } else {
      console.log(
        "ModalMultipleMRP-getItemsDetails :",
        response.status,
        response.statusText
      );
    }
  };

  function validateForm(event) {
    event.preventDefault();
    let hasMRPValue = props.multipleMRPItems.some(
      (e) => e.mrp === parseFloat(mrpValue)
    );
    if (!mrpValue) {
      toast.error("Unit Price cannot be blank.", {
        autoClose: 1000,
      });
    } else if (!hasMRPValue) {
      toast.error("Enter valid MRP.", {
        autoClose: 1000,
      });
    } else {
      getItemsDetails();
    }
  }

  return props.multipleMRPItems.length === 0 ? (
    ""
  ) : (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.button_clicked();
        }}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>MRP Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={validateForm}
            id="frmMultipleMRP"
            name="frmMultipleMRP"
          >
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-sm">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Item Number</th>
                      <th scope="col">Description</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props.multipleMRPItems[0]["item"]}</td>
                      <td>{props.multipleMRPItems[0]["item_desc"]}</td>
                      <td>
                        {props.multipleMRPItems[0]["qty"]}{" "}
                        {props.multipleMRPItems[0]["uom"]}
                      </td>
                      <td>
                        <div className="input-group input-group-sm">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-sm"
                            style={{ paddingTop: "5px" }}
                          >
                            <i className="fa fa-rupee-sign"></i>
                          </span>
                          <input
                            id="txtmrp"
                            tabIndex={1}
                            autoFocus={true}
                            name="txtmrp"
                            type="number"
                            step=".0001"
                            className="form-control text-end"
                            autoComplete="off"
                            onChange={(e) => setMRPValue(e.target.value)}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <small className="text-errormsg">{errMessage}</small>
              </div>
              <div className="col-md-6 text-end ">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strUserDetails: reduxState?.login?.userDetailsData,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strTranType: reduxState?.newSale?.tranType,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrItemsBasketId: (itemsbasketId) =>
      dispatch(newSaleActions.setItemsBasketId(itemsbasketId)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrItemsBillBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
    setTranType: (flag) => dispatch(newSaleActions.setTranType(flag)),
    setSelectedDeliverySlot: (flag) =>
      dispatch(newSaleActions.setSelectedDeliverySlot(flag)),
    setAvailableDeliverySlot: (slotList) =>
      dispatch(newSaleActions.setAvailableDeliverySlot(slotList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalMultipleMRP);
