import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "react-bootstrap/Button";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import UserRegister from "../userRegister";
import axios from "axios";
import appConfig from "../../appconfig.json";
import { newSaleActions } from "../store/newSaleStore";
import { customerActions } from "../store/customerStore";
import SearchLayout from "../searchLayout";
import NewSaleBilling from "./newSaleBilling";
import NewSalePayment from "./newSalePayment";
import NewSaleComplete from "./newSaleComplete";
import ModalCoupon from "../Modal/ModalCoupon";
import NewSaleOrderSummary from "./newSaleOrderSummary";
import NewSaleYesNoModal from "./NewSaleYesNoModal";
import { commonActions } from "../store/commonStore";
import { moreRewardsActions } from "../store/MoreRewards/MoreRewardsStore";
import { useCommonSelectors } from "../../Common/selector";

const NewSale = (props) => {
  useEffect(() => {
    // document.documentElement.requestFullscreen();
  }, []);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const [showModalCoupon, setShowModalCoupon] = useState(false);

  const [mobileNumber, setmobileNumber] = useState("");
  const [showCustomerState, setShowCustomerState] = useState(false);
  const tendList = useSelector((state) => state.newSale.itemsListTend);

  const tendListRef = useRef();
  tendListRef.current = tendList;

  useEffect(() => {
    if (!showCustomerState) {
      setShowCustomerState(false);
    }
  }, [showCustomerState]);

  const buttonClickedHandler = () => {
    setmobileNumber(document.getElementById("searchItem.txtCustItem").value);

    if (showCustomerState === false) {
      if (props.strCustomerDetails.customer_number?.length === 10) {
        setShowCustomerState(true);
      } else {
        toast.error("Enter Customer Number ", {
          autoClose: 1000,
        });
      }
    }
  };

  const [newCustomerdetails, setNewCustomer] = useState(
    props.strCustomerDetails ?? {}
  );

  const address = appConfig.api_url;

  const customerValidate = (payload) => {
    try {
      axios.post(address + "getDsCustomer/", payload).then((response) => {
        if (response.status !== "failure") {
          var existFlag = response["data"]["data"][0]["isCustExisting"];
          var walletBal = response["data"]["data"][0]["wallet_bal"];
          var custId = response["data"]["data"][0]["cust_id"];
          setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
          props.setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
        } else {
          console.log(
            "returnLayout.customerValidate : ",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("returnLayout.customerValidate : ", err);
    }
  };

  //Void for more points
  const paymentSummary = useSelector((state) => state.newSale.itemsListTend);

  const [ifVoid, setIfVoid] = useState(false);
  const [ifHold, setIfHold] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", keyboardEventListenerNewSaleBilling);
    return () => {
      window.removeEventListener(
        "keydown",
        keyboardEventListenerNewSaleBilling
      );
    };
  }, []);

  const valueRef = useRef();
  const slotDeliveryRef = useRef();
  const deliveryModeRef = useRef();
  const BasketIdRef = useRef();
  const NewSaleCurrentStatusRef = useRef();
  const isValidRef = useRef();

  useEffect(() => {
    valueRef.current = props.strCustomerDetails;
    slotDeliveryRef.current = props.strSelectedDeliverySlot;
    deliveryModeRef.current = props.strTranType;
    BasketIdRef.current = props.strNewSaleItemsBasketId;
    NewSaleCurrentStatusRef.current = props.strNewSaleCurrentStatus;
    isValidRef.current = props.strIsValidCustomer;
  }, [
    props.strCustomerDetails,
    props.strTranType,
    props.strSelectedDeliverySlot,
    props.strNewSaleItemsBasketId,
    props.strNewSaleCurrentStatus,
    props.strIsValidCustomer,
  ]);

  const keyboardEventListenerNewSaleBilling = useCallback(
    (e) => {
      if (
        NewSaleCurrentStatusRef.current === "newSaleBilling" &&
        e.altKey &&
        e.code === "KeyV" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        if (isValidRef.current) {
          handleSetVoid();
        }
        return;
      } else if (
        NewSaleCurrentStatusRef.current === "newSaleBilling" &&
        e.altKey &&
        e.code === "KeyO" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        if (BasketIdRef.current !== "") {
          btnCouponClick();
          return;
        }
        return;
      } else if (
        (NewSaleCurrentStatusRef.current === "newSaleBilling" ||
          NewSaleCurrentStatusRef.current === "newSalePayment") &&
        e.altKey &&
        e.code === "KeyL" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        if (BasketIdRef.current !== "") {
          handleSetHold();
          return;
        }
        return;
      } else if (
        NewSaleCurrentStatusRef.current === "newSaleBilling" &&
        e.altKey &&
        e.code === "KeyY" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        if (BasketIdRef.current !== "") {
          if (deliveryModeRef.current === "HOMEDE") {
            if (slotDeliveryRef.current !== "" && slotDeliveryRef.current) {
              if (
                valueRef.current?.isCustExisting === true ||
                valueRef.current?.cust_id?.length > 0
              ) {
                btnProceedForPaymentClick();
                return;
              } else {
                btnProceedForPaymentClick();
                return;
              }
            } else {
              toast.error("Select Delivery Slot", {
                autoClose: 1000,
              });
            }
          } else {
            if (
              valueRef.current?.isCustExisting === true ||
              valueRef.current?.cust_id?.length > 0
            ) {
              btnProceedForPaymentClick();
              return;
            } else {
              // btnRemainder();
              btnProceedForPaymentClick();
              return;
            }
          }
          return;
        }
        return;
      } else if (
        e.altKey === true &&
        e.code === "KeyB" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        btnBackClick();
      } else if (
        (NewSaleCurrentStatusRef.current === "newSalePayment" ||
          props.strNewSaleTendList.length !== 0) &&
        e.altKey &&
        e.code === "KeyD" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        handleVoidInPayment();
      }
    },
    [
      props.strCustomerDetails,
      props.strTranType,
      props.strSelectedDeliverySlot,
      props.strNewSaleItemsBasketId,
      props.strNewSaleCurrentStatus,
      props.strIsValidCustomer,
      props.strNewSaleTendList.length,
      props.strBillBalanceOrSuccess,
    ]
  );

  const getNewSaleItemsList = async () => {
    try {
      let payload = {
        event: "basket_confirm",
        basket_id: BasketIdRef.current,
        sub_tran_type: deliveryModeRef.current,
        slot: slotDeliveryRef.current,
        cust_id: props.strCustomerDetails?.cust_id
          ? props.strCustomerDetails?.cust_id
          : "",
        customer_number: props.strCustomerDetails?.customer_number
          ? props.strCustomerDetails?.customer_number
          : "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsBasketSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();

        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            if (response["data"].length > 0) {
              props.setCancelAllowed(response["data"][0]["isEdcCancelAllowed"]);
              props.setStrItemsListData(response["data"][0]["items"]);
              props.setRecommendOffer(response["data"][0]["recomOffers"]);
              props.setSlabOfferDesc(
                response["data"][0]["slab_offer_description"]
              );
              props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
              props.setStrTotalFoodValue(response["data"][0]["totalFoodValue"]);
              props.setStrTotalNonFoodValue(
                response["data"][0]["totalNonFoodValue"]
              );
              props.setStrNewSaleCurrentStatus("newSalePayment");
            } else {
              toast.error("Please add atleast one item....", {
                autoClose: 1000,
              });
            }
          }
        } else {
          toast.error(response["message"], {
            autoClose: 1000,
          });
          window.location.reload();
        }
      } else {
        console.log(
          "newSaleLayout-getNewSaleItemsList-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleLayout-getNewSaleItemsList :", err);
    }
  };

  const btnProceedForPaymentClick = () => {
    if (props.strNewSaleItemsList?.length === 0) {
      toast.error("Please add atleast one item... ", {
        autoClose: 1000,
      });
      window.location.reload();
    } else {
      getNewSaleItemsList();
      PeripheralConfigPayload();
    }
  };

  const PeripheralConfigPayload = async () => {
    let payload = { userid: props.strUserDetails["loginID"] };

    let response = await fetch(appConfig.api_url + "getDsConfigFileInfo/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status <= 299) {
      response = await response.json();

      if (response["status"] === "success") {
        props.setDqrPortValue(response["data"][0]["dqr"][0]["dqrvendortype"]);
      } else {
        toast.error(response["message"], { autoClose: 1000 });
      }
    } else {
    }
  };
  function btnCouponClick() {
    if (props.strCustomerDetails.cust_id === "") {
      toast.error(
        "Kindly register the mobile number to avail the coupon redemption"
      );
      return;
    }
    setShowModalCoupon(true);
  }

  const handleSetVoid = () => {
    setIfVoid(!ifVoid);
  };

  const handleSetHold = () => {
    setIfHold(!ifHold);
  };

  let btnToCheckHoldOrVoid = "";
  function btnVoidClick() {
    confirmAlert({
      customUI: ({ onClose }) => {
        btnToCheckHoldOrVoid = "void";
        return (
          <>
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title w-100">Confirm Void?</h5>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onClick={() => {
                      voidBasket();
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      },
    });
  }

  function btnRemainder() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <div
              className="modal-dialog modal-confirm"
              style={{ width: "500px" }}
            >
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title w-150"></h5>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "30px",
                  }}
                >
                  Do you want to register yourself ?<br />
                  <br /> Do you want a Home Delivery for this Order ?<br />
                  <br />
                  <b>Benefits:{"    "}</b>
                  <br />
                  <ol type="1">
                    <li key={"benefit " + 1}>
                      You can return products as per return policy
                    </li>
                    <li key={"benefit " + 2}>
                      Home delivery is possible only for register customers
                    </li>
                    <li key={"benefit " + 3}>
                      You will get e invoice on WhatsApp
                    </li>
                    <li key={"benefit " + 4}> Get promotion notification</li>
                  </ol>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      btnProceedForPaymentClick();
                      onClose();
                    }}
                  >
                    No, proceed to payment
                  </button>
                  <button
                    type="button"
                    autoFocus
                    className="btn btn-primary"
                    onClick={() => {
                      setShowCustomerState(false);
                      buttonClickedHandler();
                      onClose();
                    }}
                  >
                    Yes, Register
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      },
    });
  }

  async function btnHoldClick() {
    const paramBasketId = props.strNewSaleItemsBasketId
      ? props.strNewSaleItemsBasketId
      : "";
    const cashier_id = props.strUserDetails["empCode"];
    try {
      let payload = {
        basket_id: paramBasketId,
        cashier_id: cashier_id,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsBasketHold/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        toast.success(response.message, {
          autoClose: 1000,
        });
      } else {
        console.log(
          "newSaleLayout-onHold-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleLayout-onHold :", err);
    }

    props.setStrIsValidCustomer(false);
    props.setStrCustomerDetailsData([]);
    props.setHomeDelivery("N");
    props.setStrItemsBasketId("");
    props.setCardTransactionStatus(false);
    props.setTranType("OFFLIN");
    props.setSelectedDeliverySlot("");
    props.setAvailableDeliverySlot([]);
    props.setStrItemsBillNo("");
    props.setStrItemsCartValue("0");
    props.setStrItemsTotalDiscount("0");
    props.setStrItemsFinalCartValue("0");
    props.setStrTotalFoodValue(0);
    props.setStrTotalNonFoodValue(0);
    props.setStrItemsCouponNo("");
    props.setStrItemsBillNo("");
    props.setStrItemsBalanceAmount(0);
    props.setStrItemsBillTendAmount(0);
    props.setStrItemsListData([]);
    props.setRecommendOffer([]);
    props.setSlabOfferDesc("");
    props.setCouponCnt(0);
    props.setStrItemsListTend([]);
    props.setCancelAllowed("N");
    props.setStrNewSaleCurrentStatus("newSaleBilling");
    props.setIfCashOnDelivery(false);
    props.setBillSuccessOrBalance("");
    props.setMorePointsBalance(0);
    props.setMorePointsUsableBalance(0);
    props.setCustomerTier("");
    document.getElementById("searchItem.txtCustItem").value = ""; //It is inside the "searchLayout" component
  }

  const btnBackClick = () => {
    if (tendListRef.current?.length !== 0) {
      toast.error(
        "You cannot go to New sale as you have initiated the transaction",
        {
          autoClose: 1500,
        }
      );
      return;
    }
    props.setStrNewSaleCurrentStatus("newSaleBilling");
  };

  const voidBasket = async () => {
    try {
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      let payload = {
        event: "basket_void",
        basket_id: paramBasketId,
        sub_tran_type: props.strTranType,
        slot: props.strSelectedDeliverySlot,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          props.setStrIsValidCustomer(false);
          props.setStrCustomerDetailsData([]);
          props.setHomeDelivery("N");
          props.setStrItemsBasketId("");
          props.setCardTransactionStatus(false);
          props.setTranType("OFFLIN");
          props.setSelectedDeliverySlot("");
          props.setAvailableDeliverySlot([]);
          props.setStrItemsCartValue("0");
          props.setStrItemsTotalDiscount("0");
          props.setStrItemsFinalCartValue("0");
          props.setStrTotalFoodValue(0);
          props.setStrTotalNonFoodValue(0);
          props.setStrItemsCouponNo("");
          props.setStrItemsBillNo("");
          props.setStrItemsBalanceAmount(0);
          props.setStrItemsBillTendAmount(0);
          props.setStrItemsListData([]);
          props.setRecommendOffer([]);
          props.setSlabOfferDesc("");
          props.setCouponCnt(0);
          props.setStrItemsListTend([]);
          props.setCancelAllowed("N");
          props.setIfCashOnDelivery(false);
          props.setBillSuccessOrBalance("");
          document.getElementById("searchItem.txtCustItem").value = "";
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          props.setCustomerTier("");
        }
      } else {
        console.log(
          "newSaleLayout-voidBasket-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleLayout-voidBasket :", err);
    }
  };

  useEffect(() => {}, []);

  const searchTextRef = useRef();
  const typeahead = useRef();
  const processSearchText = () => {};

  //New Changes for void in Payment
  const [voidInPaymet, setVoidInPayment] = useState(false);

  const handleVoidInPayment = () => {
    setVoidInPayment(!voidInPaymet);
  };
  const [showTypeOfWallet, setShowTypeOfWallet] = useState(false);
  const handleTypeOfVoidInPayment = () => {
    setShowTypeOfWallet(!showTypeOfWallet);
  };
  const getOmniRestrictItems = async () => {
    const payload = {
      basket_id: props.strNewSaleItemsBasketId || "",
    };

    try {
      const { data } = await axios.post(
        address + "getOmniRestrictItems/",
        payload
      );
      if (data.status === "failure") {
        props.setNotDeliverableCodItems(data.data);
        toast.error(data.message, {
          autoClose: 1500,
        });
        return true;
      }
      props.setNotDeliverableCodItems([]);
      return false;
    } catch (error) {
      console.log("newSaleBilling.getOmniRestrictItems");
    }
  };

  if (props.strNewSaleCurrentStatus === "newSaleComplete") {
    return (
      <>
        <SearchLayout ref={searchTextRef} />

        <div className="row trans-tab">
          <NewSaleComplete />
          <div className="col-md-4">
            {/* <div className="row">
              <div className="col-md-12">
                <KeyboardLayout
                  ref={searchTextRef}
                  fnCallBack={processSearchText}
                ></KeyboardLayout>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  } else if (props.strNewSaleCurrentStatus === "newSalePayment") {
    return (
      <>
        <SearchLayout ref={searchTextRef} />
        <div className="row trans-tab">
          <NewSalePayment ref={searchTextRef} />

          <div className="col-md-3">
            {/* <div className="row">
              <div className="col-md-12">
                <KeyboardLayout
                  ref={searchTextRef}
                  fnCallBack={processSearchText}
                ></KeyboardLayout>
              </div>
            </div> */}
            <NewSaleOrderSummary />
            <div className="row" style={{ marginTop: "6rem" }}>
              {props.strNewSaleTendList.length === 0 ? (
                <div className="col-md-6">
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className={
                            "numBtnCustom btn-lg col-12 " +
                            (parseFloat(props.strItemsBillBalanceAmount) <
                              parseFloat(props.strItemsFinalCartValue) ||
                            props.strCardTransactionStatus
                              ? "disabled"
                              : "")
                          }
                          onClick={() => btnBackClick()}
                        >
                          <u>B</u>ack
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  props.strNewSaleTendList.length === 0
                    ? "col-md-6"
                    : "col-md-12"
                }
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <Button
                        className={"numBtnCustom btn-lg col-12"}
                        onClick={() => {
                          handleVoidInPayment();
                        }}
                        disabled={props.strNewSaleTendList.length === 0}
                      >
                        voi<u>d</u>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {voidInPaymet ? (
          <NewSaleYesNoModal
            heading="VoidForPayment"
            handleClose={handleVoidInPayment}
            openOrClose={voidInPaymet}
            mainFunction={handleTypeOfVoidInPayment}
            typeOfVoidPayment={showTypeOfWallet}
            voidFunction={voidBasket}
            voidForMorePoints={
              paymentSummary[0].ttype === "POINTS" &&
              paymentSummary.length === 1 &&
              true
            }
          />
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      <>
        {showCustomerState ? (
          <UserRegister
            show={showCustomerState}
            button_clicked={setShowCustomerState}
            customerValidate={customerValidate}
            customerNumber={props.strCustomerDetails.customer_number}
            disableField={true}
            getCustomerName={""}
          />
        ) : (
          <></>
        )}
        <ToastContainer
          className="toast-position"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <SearchLayout
          ref={searchTextRef}
          isItemSearchEnabled={true}
          typeahead={typeahead}
        />
        <div className="row trans-tab">
          <div
            className=" row col-md-9"
            style={{ marginTop: "-0.7%", height: "8%" }}
          >
            <NewSaleBilling ref={searchTextRef} typeahead={typeahead} />
            {props.strRecommendOffer.map((offer, index) => {
              return (
                <>
                  <div
                    key={index}
                    className=" col-lg-6 col-sm-6 col-md-6"
                    style={{
                      marginTop: "0.1%",
                      height: "0%",
                      display:
                        offer.offer_alert_status === "show" ? "inline" : "none",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundColor:
                            offer.offer_alert_colour || "#f7dade",
                          borderRadius: 3,
                        }}
                      >
                        <div>
                          <span className="fa-stack fa-xs flash ">
                            <i
                              className="fa fa-certificate fa-stack-2x"
                              style={{
                                color: offer.offer_icon_color || "#dc143c",
                              }}
                            ></i>
                            {offer.offer_type == "rs" ? (
                              <i className="fa fa-inr fa-stack-1x text-white ">
                                ₹
                              </i>
                            ) : offer.offer_type == "%" ? (
                              <i
                                className="fa fa-percent fa-stack-1x  text-white"
                                style={{ fontSize: "10px" }}
                              ></i>
                            ) : offer.offer_type == "free" ? (
                              <i className="fa fa-duotone fa-f fa-stack-1x text-white">
                                F
                              </i>
                            ) : (
                              ""
                            )}
                          </span>
                          <span
                            style={{
                              paddingLeft: 15,
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#111111",
                            }}
                          >
                            {offer.offer_desc}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-md-3">
            {/* <div className="row">
              <div className="col-md-12">
                <KeyboardLayout
                  ref={searchTextRef}
                  fnCallBack={processSearchText}
                ></KeyboardLayout>
              </div>
            </div> */}

            <NewSaleOrderSummary />
            <div
              style={{
                marginBottom: "6rem",
              }}
            ></div>
            <div className="row">
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <Button
                        className={
                          "numBtnCustom btn-lg col-12 " +
                          (!props.strIsValidCustomer ? "disabled" : "")
                        }
                        onClick={() => handleSetVoid()}
                      >
                        <u>V</u>oid
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <Button
                        className={
                          "numBtnCustom btn-lg col-12 " +
                          (props.strNewSaleItemsBasketId === ""
                            ? "disabled"
                            : "")
                        }
                        onClick={() => handleSetHold()}
                      >
                        Ho<u>l</u>d
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <Button
                        className={
                          "numBtnCustom btn-lg col-12 " +
                          (props.strNewSaleItemsBasketId === ""
                            ? "disabled"
                            : "")
                        }
                        onClick={() => btnCouponClick()}
                      >
                        c<u>o</u>upon{" "}
                        {props.strCouponCnt > 0 ? (
                          <span
                            className="fa-stack fa-2x flash"
                            style={{ fontSize: "10px", color: "#dc143c" }}
                          >
                            <i
                              className="fa fa-certificate fa-stack-2x"
                              style={{
                                fontSize: "x-large",
                                marginTop: "-2px",
                              }}
                            ></i>
                            <i className="fa fa-inverse fa-stack-1x">
                              {props.strCouponCnt}
                            </i>
                          </span>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <Button
                        className={
                          "numBtnCustom btn-lg col-12 " +
                          (props.strNewSaleItemsBasketId === ""
                            ? "disabled"
                            : "")
                        }
                        onClick={() => {
                          if (props.strTranType !== "HOMECD") {
                            btnProceedForPaymentClick();
                          } else {
                            let x = getOmniRestrictItems();
                            x.then((response) => {
                              if (!response) {
                                btnProceedForPaymentClick();
                              }
                            });
                          }
                        }}
                      >
                        Pa<u>y</u>ment
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showModalCoupon ? (
              <ModalCoupon
                show={showModalCoupon}
                close={() => setShowModalCoupon(false)}
                button_clicked={setShowModalCoupon}
              />
            ) : (
              ""
            )}
            {ifVoid ? (
              <NewSaleYesNoModal
                heading="Void"
                handleClose={handleSetVoid}
                openOrClose={ifVoid}
                mainFunction={voidBasket}
              />
            ) : (
              ""
            )}
            {ifHold ? (
              <NewSaleYesNoModal
                heading="Hold"
                handleClose={handleSetHold}
                openOrClose={ifHold}
                mainFunction={btnHoldClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (reduxState) => {
  return {
    strNewSaleCurrentStatus: reduxState?.newSale?.newSaleCurrentStatus,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strItemsBillBalanceAmount: reduxState?.newSale?.itemsBillBalanceAmount,
    strItemsFinalCartValue: reduxState?.newSale?.itemsFinalCartValue,
    strNewSaleItemsList: reduxState?.newSale?.itemsListData,
    strNewSaleTendList: reduxState?.newSale?.itemsListTend,
    strIsValidCustomer: reduxState?.customer?.isValidCustomer,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strUserDetails: reduxState?.login?.userDetailsData,
    strRecommendOffer: reduxState?.newSale?.recommendOffer,
    strCouponCnt: reduxState?.newSale?.couponCnt,
    strTranType: reduxState?.newSale?.tranType,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
    strCardTransactionStatus: reduxState?.newSale?.cardTransactionStatus,
    strMoreRewardsRechargeData: reduxState.moreRewards.rechargeData,
    strBillBalanceOrSuccess: reduxState?.newSale?.billSuccessOrBalance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStrNewSaleCurrentStatus: (newSaleCurrStatus) =>
      dispatch(newSaleActions.setNewSaleCurrentStatus(newSaleCurrStatus)),
    setStrItemsBasketId: (basketId) =>
      dispatch(newSaleActions.setItemsBasketId(basketId)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrTotalFoodValue: (foodValue) =>
      dispatch(newSaleActions.setTotalFoodValue(foodValue)),
    setStrTotalNonFoodValue: (nonFoodValue) =>
      dispatch(newSaleActions.setTotalNonFoodValue(nonFoodValue)),
    setStrItemsCouponNo: (couponNo) =>
      dispatch(newSaleActions.setItemsCoupon(couponNo)),
    setStrItemsBillNo: (billNo) =>
      dispatch(newSaleActions.setItemsBillNo(billNo)),
    setStrItemsBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setStrItemsListTend: (tendList) =>
      dispatch(newSaleActions.setItemsListTend(tendList)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setStrIsValidCustomer: (isValidCustomer) =>
      dispatch(customerActions.setIsValidCustomer(isValidCustomer)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
    setHomeDelivery: (flag) => dispatch(customerActions.setHomeDelivery(flag)),
    setTranType: (flag) => dispatch(newSaleActions.setTranType(flag)),
    setSelectedDeliverySlot: (flag) =>
      dispatch(newSaleActions.setSelectedDeliverySlot(flag)),
    setAvailableDeliverySlot: (slotList) =>
      dispatch(newSaleActions.setAvailableDeliverySlot(slotList)),
    setCardTransactionStatus: (flag) =>
      dispatch(newSaleActions.setCardTransactionStatus(flag)),
    setIfCashOnDelivery: (flag) =>
      dispatch(newSaleActions.setIfCashOnDelivery(flag)),
    setBillSuccessOrBalance: (billBalanceOrSuccess) =>
      dispatch(newSaleActions.setBillSuccessOrBalance(billBalanceOrSuccess)),
    setDqrPortValue: (dqrPortValue) =>
      dispatch(commonActions.setDqrPortValue(dqrPortValue)),
    setNotDeliverableCodItems: (itemCode) =>
      dispatch(newSaleActions.setNotDeliverableCodItems(itemCode)),
    setMorePointsUsableBalance: (usablePoints) =>
      dispatch(moreRewardsActions.setMorePointsUsableBalance(usablePoints)),
    setMorePointsBalance: (balancePoints) =>
      dispatch(moreRewardsActions.setMorePointsBalance(balancePoints)),
    setCustomerTier: (tier) => dispatch(customerActions.setCustomerTier(tier)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSale);
