import React from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";

const DataGridTable = ({
  data,
  handleClickForBillNo,
  receiptPrint,
  homeReceiptPrint,
  billDownload,
}) => {
  const ifAdmin = useSelector((state) => state.login.userDetailsData.isAdmin);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="contained"
        // color="primary"
        // size="small"
        style={{
          fontSize: "0.9em",
          backgroundColor: "#d3455b",
          padding: "0.3em 0.3em",
        }}
        onClick={() => {
          handleClickForBillNo(params.row.bill_no, true);
        }}
      >
        {params.row.bill_no}
      </Button>
    );
  };

  const challanAndReceiptBtns = (params) => {
    return (
      <>
        <>
          <OverlayTrigger
            key={"left"}
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                {ifAdmin ? "Receipt" : null}
              </Tooltip>
            }
          >
            <button
              className={ifAdmin ? "btn-icon-orange" : "btn-icon-orange-light"}
              onClick={() => receiptPrint(params.row.bill_no)}
              disabled={ifAdmin ? false : true}
            >
              <i className="fa fa-print"></i>
            </button>
          </OverlayTrigger>
        </>
        <>
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-right`}>
                {ifAdmin ? "Delivery Challan" : null}
              </Tooltip>
            }
          >
            <button
              className={
                ifAdmin ? "btn-icon-orange active" : "btn-icon-orange-light2"
              }
              onClick={() => homeReceiptPrint(params.row.bill_no)}
              disabled={
                params.row.sub_tran_type === "HOMEDE" && ifAdmin ? false : true
              }
              style={{
                float: "right",
                color:
                  params.row.sub_tran_type === "HOMEDE" && ifAdmin
                    ? "#8545d3"
                    : "#c09fe9",
              }}
            >
              {params.row.sub_tran_type === "HOMEDE" ? (
                <i className="fa fa-print"></i>
              ) : (
                <></>
              )}{" "}
            </button>
          </OverlayTrigger>
        </>
      </>
    );
  };
  const columns = [
    {
      field: "bill_date",
      headerName: "Bill Date",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "basket_id",
      headerName: "Basket ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bill_no",
      headerName: "Bill No.",
      flex: 2,
      renderCell: renderDetailsButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tran_type",
      headerName: "Tran Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sub_tran_type",
      headerName: "Sub Tran Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "slot",
      headerName: "Slot",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "customer_number",
      headerName: "Customer No",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_items",
      headerName: "Total Itm",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_qty",
      headerName: "Tot Qty",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tran_amt",
      headerName: "Tot Amt (₹)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "print",
      headerName: "Print",
      flex: 1.5,
      justifyContent: "space-between",
      headerAlign: "center",
      renderCell: challanAndReceiptBtns,
    },
  ];

  return (
    <Box
      height={"62vh"}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-cell": {
          fontSize: "11px",
          border: "0.1px solid lightgrey",
          textAlign: "center",
          alignSelf: "center",
          padding: "0.2vw 0.2vw",
        },
        "& .name-column--cell": {
          color: "#2e7c67",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#333",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#fff",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          color: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "#fff",
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "#333",
          color: "#fff",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${"#141414"} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
          color: `${"#141414"} !important`,
        },
        "& .MuiToolbar-root": {
          color: "white",
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
          "::-webkit-scrollbar": {
            width: "0.8vw",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#7775",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#333",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "D3D3D3",
          },
          "::-webkit-scrollbar-track:hover": {
            backgroundColor: "#3333",
          },
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        getRowId={(row) => generateRandom()}
        pagination
        {...data}
      />
    </Box>
  );
};

export default DataGridTable;
