import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import appConfig from "../appconfig.json";
import { useCommonSelectors } from "../Common/selector";

const GetPriceModal = (props, keyRef) => {
  const [itemDisplay, setItemDisplay] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [mrpList, setMrpPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const typeahead = useRef(null);
  const { loc, pos_id_mpos } = useCommonSelectors();
  const closeModal = () => {
    setItemDisplay(false);
    setMrpPrice([]);
    setIsLoading(false);
    setOptions([]);
    setSelected([]);
  };

  const address = appConfig.api_url;

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    getItemsList(selectedOptions[0].item_desc);
    setItemDisplay(true);
    setTimeout(() => typeahead.current.clear(), 1);
  };

  const handleKeyPress = (target) => {
    if (target.keyCode === 13) {
      getItemsList(target.target.value, "", "");
    }
  };

  const getItemsList = async (query) => {
    setIsLoading(true);
    let payload = {
      event: "item_search",
      searchText: `${query}`,
      cust_id: "",
      cashier_id: "",
      basket_id: "",
      item_desc: "",
      item: "",
      barcode: "",
      eventtype: "getprice",
      sub_tran_type: props.strTranType,
      slot: props.strSelectedDeliverySlot,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    try {
      let response = await fetch(address + "getDsItemSearch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            setItemDisplay(true);
            setMrpPrice([]);
            for (let i = 0; i < response.data[0].items.length; i++) {
              setSelected([
                {
                  item: response.data[0].items[i].item,
                  item_desc: response.data[0].items[i].item_desc,
                },
              ]);
              setMrpPrice((mrpList) => [
                ...mrpList,
                response.data[0].items[i]["mrp"],
              ]);
            }
            setItemList(response.data[0].items);
            setOfferList(response.data[0].offers);
            setTimeout(() => typeahead.current.clear(), 1);
          } else if (response["message"] === "MULTIPLEMRP") {
            setMrpPrice([]);
            for (let i = 0; i < response.data[0].items.length; i++) {
              setSelected([
                {
                  item: response.data[0].items[i].item,
                  item_desc: response.data[0].items[i].item_desc,
                },
              ]);
              setMrpPrice((mrpList) => [
                ...mrpList,
                response.data[0].items[i]["mrp"],
              ]);
            }
            setItemList(response.data[0].items);
            setOfferList(response.data[0].offers);
            setTimeout(() => typeahead.current.clear(), 1);
          } else if (response["message"] === "AUTOPOPULATE") {
            setMrpPrice([]);
            setOptions(response["data"][0]["items"]);
          }
        }
      } else {
        console.log(
          "getPrice.getItemsList :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("getPrice.getItemsList :", err);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          closeModal();
          props.button_clicked();
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Get Price</Modal.Title>
        </Modal.Header>
        <Modal.Body className="getprice">
          <div className="row">
            <div className="col-12">
              <AsyncTypeahead
                ref={typeahead}
                id="searchItem.txtSearchItem"
                name="searchItem.txtSearchItem"
                isLoading={isLoading}
                labelKey={(option) => `${option.item_desc}`}
                onSearch={getItemsList}
                options={options}
                placeholder="Search Item..."
                onChange={handleChange}
                minLength={3}
                useCache={false}
                autoFocus={true}
              />
              <div className="row">
                <div className="col-12">
                  <div className="form-text text-end">
                    <i className="getPricebull">
                      <span
                        className="fa fa-bullhorn"
                        style={{ color: "#f04e23" }}
                      ></span>
                      &nbsp;&nbsp;(Search barcode, plu code (format: &lt;plu
                      code&gt;*), item code (format: i#&lt;item code&gt;) or
                      description)
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selected.length >= 1 ? (
            <>
              <hr />
              <div className="row">
                <div className="col-12 header">
                  {selected.length >= 1
                    ? selected[0].item_desc + " (" + selected[0].item + ") "
                    : ""}
                </div>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-12">
                  <table className="table table-bordered table-sm newsaletable">
                    <tbody>
                      <tr>
                        <td
                          className="text-center align-middle"
                          style={{ width: "120px" }}
                        >
                          MRP
                        </td>
                        <td>
                          {itemList.map(function (item, i) {
                            return (
                              <button
                                key={i}
                                type="button"
                                className="btn btn-primary pricebutton"
                              >
                                {item.mrp}
                              </button>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-center align-middle"
                          style={{ width: "120px" }}
                        >
                          MSP
                        </td>
                        <td>
                          {itemList.map(function (item, i) {
                            return (
                              <button
                                key={i}
                                type="button"
                                className="btn btn-primary pricebutton"
                              >
                                {item.msp}
                              </button>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-center align-middle"
                          style={{ width: "120px" }}
                        >
                          OFFERS
                        </td>
                        <td>
                          {offerList.map(function (offer, i) {
                            return (
                              <button
                                key={i}
                                type="button"
                                className="btn btn-primary pricebutton"
                                style={{
                                  backgroundColor:
                                    offer.offer_color + " !important",
                                }}
                              >
                                {offer.offer_type} - {offer.offer_desc}
                              </button>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetPriceModal;
