import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import appConfig from "../../appconfig.json";
import Loader from "../Loader";

const ModalVouchagram = ({
  modalOpenClose,
  handleClose,
  props,
  handleCardSubmit,
  loadingVoucha,
  setLoadingVoucha,
  vouchaPay,
  otpError,
  setOtpError,
  vouchagram,
  setVouchagram,
  OtpSent,
  setOtpSent,
  intervalRefVouchagram,
}) => {
  const address = appConfig.api_url;
  const [OTPtimer, setTimer] = useState("");

  const [otpStatus, setOtpStatus] = useState(false);

  //This state is just for checking if the otp is sent or not
  const [otpMessageSent, setOtpMessageSent] = useState(false);

  //This state is enbaling to proceed user to send otp and submit the cart value
  const [showGetCardBalance, setShowGetCardBalance] = useState(false);

  //Getting customer mobile number for redux store
  const customerMobileNumber = useSelector(
    (state) => state.customer?.customerDetailsData?.customer_number
  );
  //getting basket id from redux store
  const basketId = useSelector((state) => state.newSale?.itemsbasketId);
  const [cardBalance, setCardBalance] = useState("");
  const [rechargeVcardLoader, setRechargeVcardLoader] = useState(false);

  const balanceAmount = useSelector(
    (state) => state?.newSale?.itemsBillBalanceAmount
  );
  //States managed for vouchagram
  // const [vouchagram, setVouchagram] = useState({
  //   mobileNumber: customerMobileNumber || "",
  //   voucherNumber: "",
  //   otpValue: "",
  //   totalAmount: props.strItemsBillBalanceAmount,
  //   tendAmount: vouchaPay || "",
  // });

  //as the name suggests, this function is an api call used for recharging the
  // wallet associated with  the number
  const voucherWalletRecharge = () => {
    if (vouchagram.mobileNumber === "") {
      toast.error("Enter Mobile Number to Proceed", {
        autoClose: 1500,
      });
      return;
    }
    setRechargeVcardLoader(true);
    try {
      axios
        .post(address + "postVoucherWalletRecharge/", {
          MOBILE: vouchagram.mobileNumber,
          VOUCHERNUMBER: vouchagram.voucherNumber || "",
          PORDERID: basketId,
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success("Wallet Recharge Successful", {
              autoClose: 1500,
            });
            setVouchagram({
              ...vouchagram,
              voucherNumber: "",
            });
            getVoucherCardBalance();
          } else {
            toast.error(response.data.message, {
              autoClose: 1500,
            });
            setVouchagram({
              ...vouchagram,
              voucherNumber: "",
            });
            setRechargeVcardLoader(false);
          }
        });
    } catch (err) {
      setRechargeVcardLoader(false);
      console.log("modalVouchagram-postVoucherWalletRecharge : ", err);
    }
  };
  const handleOtpStatus = () => {
    setOtpStatus(!otpStatus);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  //This function is getting the card balance and setting the showGetCardBalance true
  //So that the user can proceed with the recharge
  const getVoucherCardBalance = async () => {
    try {
      axios
        .post(address + "getVoucherWalletBalance/", {
          MOBILE: vouchagram.mobileNumber,
        })
        .then((response) => {
          if (response.data.status === "success") {
            setCardBalance(response.data.data[0].balance);
            setShowGetCardBalance(true);
            setRechargeVcardLoader(false);
          } else {
            toast.error(response.data.message, {
              autoClose: 1500,
            });
            setShowGetCardBalance(false);
            setRechargeVcardLoader(false);
          }
        });
    } catch (err) {
      setShowGetCardBalance(false);
      setRechargeVcardLoader(false);
      console.log("modalVouchagram-getVoucherWalletBalance : ", err);
    }
  };

  //This function is used for sending the OTP for vouchagram
  const getOtpForVouchagram = async () => {
    setOtpMessageSent(true);
    try {
      axios
        .post(address + "postVoucherWalletOtp/", {
          MOBILE: vouchagram.mobileNumber,
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success("OTP sent successfully to the mobile number", {
              autoClose: 1500,
            });
            setOtpMessageSent(false);
            OTPCounter(900);
            setOtpSent(true);
          } else {
            toast.error(response.data.message, {
              autoClose: 1500,
            });
            setOtpMessageSent(false);
            setOtpSent(false);
          }
        });
    } catch (err) {
      setOtpMessageSent(false);
      console.log("modalVouchagram-postVoucherWalletOtp : ", err);
    }
  };

  //This is the final submission, which is calling another api
  //postDsBasketSubmit which return and shows the respected frontend according to
  //the response it recieves (billsuccess or billpatial something)
  const submitRechargeWallet = () => {
    setLoadingVoucha(true);
    const payload = {
      ttype: "VOUCHERGRAM",
    };

    if (vouchagram.otpValue === "") {
      toast.error("Enter OTP to Proceed", {
        autoClose: 1500,
      });
      setLoadingVoucha(false);
      return;
    } else if (vouchagram.tendAmount === "") {
      toast.error("Enter Payable Amount to Proceed", {
        autoClose: 1500,
      });
      setLoadingVoucha(false);
    } else {
      handleCardSubmit(Number(vouchagram.tendAmount), true, payload);
    }
  };

  useEffect(() => {
    setVouchagram({
      ...vouchagram,
      tendAmount: props.strItemsBillBalanceAmount,
      otpValue: "",
    });
  }, [props.strItemsBillBalanceAmount]);
  const OTPCounter = (timexpries = 900) => {
    try {
      intervalRefVouchagram.current = setInterval(function () {
        if (timexpries <= 0) {
          setTimer("OTP Expired.. Resend");
          setOtpSent(false);
          clearInterval(intervalRefVouchagram.current);
        }
        var min = 0;
        var sec = 0;
        min = ~~((timexpries % 3600) / 60);
        sec = ~~timexpries % 60;
        setTimer("".concat("OTP Expires  ", min, ":", sec));
        timexpries -= 1;
        if (timexpries < 0) {
          setOtpSent(false);
          setTimer("OTP Expired.. Resend");
        }
      }, 1000);
    } catch (err) {
      setOtpSent(false);
      console.log("otpModal.OTPCounter : ", err);
    }
  };
  useEffect(() => {
    clearInterval(intervalRefVouchagram.current);
  }, []);

  return (
    <>
      <Modal
        show={modalOpenClose || false}
        className="modal"
        role="document"
        backdrop="static"
        onHide={() => {
          handleClose();
          clearInterval(intervalRefVouchagram.current);
        }}
        style={{
          width: !showGetCardBalance && "45%",
          left: !showGetCardBalance && "30%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="w-100"
            style={{
              color: "#f04e23",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            GyFTR GIFT CARD
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="p-.5rem"
          style={{
            fontWeight: "500",
          }}
        >
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="row" style={{ marginTop: "20px" }}>
              <div className={!showGetCardBalance ? "col-6" : "col-4"}>
                <label className="font-weight-bold mb-2">Mobile Number</label>
                <span
                  style={{
                    color: "#f04e23",
                    marginLeft: "10px",
                  }}
                >
                  *
                </span>
                <div className="form-group mb-4">
                  <div className="">
                    <input
                      disabled={showGetCardBalance}
                      name="mobileNumber"
                      type="text"
                      className="form-control"
                      required
                      maxLength={10}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setVouchagram({
                            ...vouchagram,
                            mobileNumber: e.target.value,
                          });
                        }
                      }}
                      value={vouchagram.mobileNumber}
                    />
                  </div>
                </div>
              </div>
              {!showGetCardBalance && (
                <>
                  <div className="col-6" style={{ marginTop: "30px" }}>
                    <div className="">
                      <div>
                        <button
                          className="btn btn-primary float-end"
                          onClick={() => {
                            // getCustomerWalletBalance();
                            getVoucherCardBalance();
                          }}
                          disabled={
                            vouchagram.mobileNumber === "" ||
                            vouchagram.mobileNumber.length < 10
                          }
                        >
                          Get ePay Balance
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-primary btn-grey"
                          onClick={() => {
                            handleClose();
                            clearInterval(intervalRefVouchagram.current);
                          }}
                        >
                          Close
                        </button> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {showGetCardBalance && (
                <div className="col-4">
                  <label className="font-weight-bold mb-2">
                    Voucher Number
                  </label>
                  <div className="form-group mb-4">
                    <div className="">
                      <input
                        disabled={rechargeVcardLoader}
                        name="voucherNumber"
                        type="text"
                        className="form-control"
                        maxLength={16}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            regex.test(e.target.value)
                          ) {
                            setVouchagram({
                              ...vouchagram,
                              voucherNumber: e.target.value,
                            });
                          }
                        }}
                        value={vouchagram.voucherNumber}
                      />
                    </div>
                  </div>
                </div>
              )}

              {showGetCardBalance && (
                <div className="col-4" style={{ marginTop: "30px" }}>
                  <div className="" style={{ textAlign: "right" }}>
                    <div>
                      <button
                        className="btn btn-primary"
                        disabled={rechargeVcardLoader}
                        onClick={() => {
                          if (vouchagram.voucherNumber.length < 5) {
                            toast.error("Enter a valid Voucher Number", {
                              autoClose: 1500,
                            });
                            return;
                          }
                          voucherWalletRecharge();
                        }}
                        // disabled={
                        //   vouchagram.voucherNumber === "" ||
                        //   vouchagram.voucherNumber.length < 5
                        // }
                      >
                        Recharge V-Card
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {showGetCardBalance && (
              <>
                <div className="newSalePaymentCard mb-2">
                  <span style={{ fontSize: "15px" }}>
                    <div className="capsule">
                      Card Balance ( ₹ ) : {cardBalance || 0}
                    </div>
                  </span>
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="font-weight-bold mb-2">
                      Total Amount
                    </label>
                    <div className="form-group mb-4">
                      <input
                        disabled={rechargeVcardLoader}
                        name="strItemsBillBalanceAmount"
                        type="text"
                        readOnly
                        className="form-control"
                        value={props.strItemsBillBalanceAmount}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <label className="font-weight-bold mb-2">
                      Payable Amount
                    </label>
                    <div className="form-group mb-4">
                      <div className="">
                        <input
                          // disabled={rechargeVcardLoader}
                          name="tendAmount"
                          type="text"
                          className="form-control"
                          disabled={OtpSent || rechargeVcardLoader}
                          onChange={(e) => {
                            var value = e.target.value;
                            value = value.replace(/[^0-9. ]/g, "");

                            setVouchagram({
                              ...vouchagram,
                              tendAmount: value,
                            });

                            let newVal = e.target.value;

                            if (
                              Number(newVal) >
                              Number(
                                props.strItemsBillBalanceAmount ||
                                  vouchagram.totalAmount
                              )
                            ) {
                              toast.error(
                                "Payable Amount should be less than Total Amount",
                                {
                                  autoClose: 1500,
                                }
                              );
                              setVouchagram({
                                ...vouchagram,
                                tendAmount: props.strItemsBillBalanceAmount,
                              });
                            }
                          }}
                          value={vouchagram.tendAmount}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <label className="font-weight-bold mb-2">Enter OTP</label>
                    <div className="form-group mb-4">
                      <div className="">
                        <input
                          disabled={rechargeVcardLoader}
                          name="otpValue"
                          type="text"
                          className="form-control"
                          maxLength={4}
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              regex.test(e.target.value)
                            ) {
                              setVouchagram({
                                ...vouchagram,
                                otpValue: e.target.value,
                              });
                            }
                          }}
                          value={vouchagram.otpValue}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        marginTop: "-1rem",
                      }}
                    >
                      <div className="col-12 text-center">
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#f04e23",
                            display: OtpSent ? "inline" : "none",
                          }}
                        >
                          <span>{OTPtimer}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-3"
                    style={{
                      marginTop: "1.9rem",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      disabled={OtpSent || rechargeVcardLoader}
                      onClick={() => {
                        if (vouchagram.tendAmount === "") {
                          toast.error("Enter a valid Payable Amount", {
                            autoClose: 1500,
                          });
                          return;
                        } else if (
                          Number(cardBalance) < Number(vouchagram.tendAmount)
                        ) {
                          toast.error(
                            "Payable should not be greater than the card balance",
                            {
                              autoClose: 1500,
                            }
                          );
                          setVouchagram({
                            ...vouchagram,
                            tendAmount: cardBalance,
                          });
                          return;
                        }
                        getOtpForVouchagram();
                      }}
                    >
                      {otpMessageSent ? (
                        <label
                          className="spinner-border"
                          style={{
                            width: "1rem",
                            height: "1rem",
                          }}
                        ></label>
                      ) : (
                        <label>Send Otp</label>
                      )}
                    </button>
                  </div>

                  <div
                    className="col-3"
                    style={{
                      marginTop: "1.9rem",
                    }}
                  >
                    <button
                      disabled={vouchagram.otpValue.length < 3 || loadingVoucha}
                      className="btn btn-primary float-end"
                      onClick={() => {
                        submitRechargeWallet();
                      }}
                    >
                      {loadingVoucha ? "Loading..." : "Reedem"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </Modal.Body>
      </Modal>

      <Loader open={rechargeVcardLoader} text="GyFtr Recharge in Progress" />
    </>
  );
};

export default ModalVouchagram;
