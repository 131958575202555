import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

const Loader = ({ open, setOpen, text = "" }) => {
  // Function to close the loading modal
  const closeLoadingModal = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // Close the loading modal if clicked outside
    >
      <CircularProgress color="inherit" />
      <Typography
        css={{
          marginLeft: "1rem",
        }}
      >
        {text}
      </Typography>
    </Backdrop>
  );
};

export default Loader;
