import { createSlice } from "@reduxjs/toolkit";

const newSaleInitialState = {
  newSaleCurrentStatus: "newSaleBilling", // (newSaleBilling, newSalePayment, newSaleComplete)
  itemsbasketId: "",
  itemsCartValue: "0",
  itemsTotalDiscount: "0",
  itemsFinalCartValue: "0",
  totalFoodValue: 0,
  totalNonFoodValue: 0,
  itemsCoupon: "",
  itemsBillNo: "",
  itemsBillBalanceAmount: "",
  itemsBillTendAmount: "",
  itemsListData: [],
  itemsListTend: [],
  cancelAllowed: "N",
  balanceAmount: 0.0,
  recommendOffer: [],
  couponCnt: 0,
  slabOfferDesc: "",
  tranType: "OFFLIN",
  selectedDeliverySlot: "",
  availableDeliverySlot: [],
  cardTransactionStatus: false,
  voucherCardBalance: "",
  ifCashOnDelivery: false,
  billSuccessOrBalance: "",
  customerCoupon: [],
  notDeliverableCodItems: {
    data: [],
    message: "",
  },
};

const newSaleSlice = createSlice({
  name: "itemsDs",
  initialState: newSaleInitialState,
  reducers: {
    setNewSaleCurrentStatus(state, action) {
      state.newSaleCurrentStatus = action.payload;
    },
    setItemsBasketId(state, action) {
      state.itemsbasketId = action.payload;
    },
    setItemsCartValue(state, action) {
      state.itemsCartValue = action.payload;
    },
    setItemsTotalDiscount(state, action) {
      state.itemsTotalDiscount = action.payload;
    },
    setItemsFinalCartValue(state, action) {
      state.itemsFinalCartValue = action.payload;
    },
    setItemsCoupon(state, action) {
      state.itemsCoupon = action.payload;
    },
    setItemsBillNo(state, action) {
      state.itemsBillNo = action.payload;
    },
    setItemsBillBalanceAmount(state, action) {
      state.itemsBillBalanceAmount = action.payload;
    },
    setItemsBillTendAmount(state, action) {
      state.itemsBillTendAmount = action.payload;
    },
    setItemsListData(state, action) {
      state.itemsListData = action.payload;
    },
    setItemsListTend(state, action) {
      state.itemsListTend = action.payload;
    },
    setCancelAllowed(state, action) {
      state.cancelAllowed = action.payload;
    },
    setBalanceAmount(state, action) {
      state.balanceAmount = action.payload;
    },
    setRecommendOffer(state, action) {
      state.recommendOffer = action.payload;
    },
    setCouponCnt(state, action) {
      state.couponCnt = action.payload;
    },
    setSlabOfferDesc(state, action) {
      state.slabOfferDesc = action.payload;
    },
    setTranType(state, action) {
      state.tranType = action.payload;
    },
    setSelectedDeliverySlot(state, action) {
      state.selectedDeliverySlot = action.payload;
    },
    setAvailableDeliverySlot(state, action) {
      state.availableDeliverySlot = action.payload;
    },
    setCardTransactionStatus(state, action) {
      state.cardTransactionStatus = action.payload;
    },
    setVoucherCardBalance(state, action) {
      state.voucherCardBalance = action.payload;
    },
    setTotalFoodValue(state, action) {
      state.totalFoodValue = action.payload;
    },
    setTotalNonFoodValue(state, action) {
      state.totalNonFoodValue = action.payload;
    },
    setIfCashOnDelivery(state, action) {
      state.ifCashOnDelivery = action.payload;
    },
    setBillSuccessOrBalance(state, action) {
      state.billSuccessOrBalance = action.payload;
    },
    setCustomerCoupon(state, action) {
      state.customerCoupon = action.payload;
    },
    setNotDeliverableCodItems(state, action) {
      state.notDeliverableCodItems.data = action.payload;
    },
    setNotDeliverableCodItemsMessage(state, action) {
      state.notDeliverableCodItems.message = action.payload;
    },
  },
});

export const newSaleActions = newSaleSlice.actions;
export default newSaleSlice.reducer;
