import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import NamaskarLogo from "../../assets/namaskar_logo.png";
import { useSelector } from "react-redux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import appConfig from "../../appconfig.json";
import { RxCross2 } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { useCommonSelectors } from "../../Common/selector";

const AskCustomerModal = ({
  askCustModel,
  setAskCustModel,
  btnTransactionCompleteClick,
}) => {
  const address = appConfig.api_url;
  const { loc, pos_id_mpos } = useCommonSelectors();
  const [showSearchBar, setShowSearchBar] = useState(false);

  const custNumber = useSelector(
    (state) => state.customer?.customerDetailsData?.customer_number
  );

  const [mobileNumber, setMobileNumber] = useState("" || custNumber);

  const [itemDisplay, setItemDisplay] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [mrpList, setMrpPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [itemData, setItemData] = useState([]);
  const typeahead = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [disableAddBtn, setDisableAddBtn] = useState(true);
  const loginDetails = useSelector((state) => state.login.userDetailsData);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    getItemsList(
      selectedOptions[0].item_desc,
      "selectedItem",
      selectedOptions[0].item
    );
    setItemDisplay(true);
    setTimeout(() => typeahead.current.clear(), 1);
  };

  const getItemsList = async (query, ifSelectedPresent, itemNumber) => {
    setIsLoading(true);
    let payload = {
      event: ifSelectedPresent ? "item_selected" : "item_search",
      searchText: `${query}`,
      cust_id: "",
      cashier_id: "",
      basket_id: "",
      item_desc: "",
      item: ifSelectedPresent ? itemNumber : "",
      barcode: "",
      eventtype: "feedback",
      sub_tran_type: "",
      slot: "",
      loc: loc,
      pos_id: pos_id_mpos,
    };
    try {
      let response = await fetch(address + "getDsItemSearch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            for (let i = 0; i < response.data[0].items.length; i++) {
              setSelected([
                {
                  item: response.data[0].items[i].item,
                  item_desc: response.data[0].items[i].item_desc,
                },
              ]);
              setMrpPrice((mrpList) => [
                ...mrpList,
                response.data[0].items[i]["mrp"],
              ]);
            }
            setItemList(response.data[0].items);
            setOfferList(response.data[0].offers);
            setOptions(response["data"][0]["items"]);
            setTimeout(() => typeahead.current.clear(), 1);
          } else if (response["message"] === "MULTIPLEMRP") {
            setMrpPrice([]);
            for (let i = 0; i < response.data[0].items.length; i++) {
              setSelected([
                {
                  item: response.data[0].items[i].item,
                  item_desc: response.data[0].items[i].item_desc,
                },
              ]);
              setMrpPrice((mrpList) => [
                ...mrpList,
                response.data[0].items[i]["mrp"],
              ]);
            }
            setItemList(response.data[0].items);
            setItemData([...itemData, response.data[0].items[0]]);
            setOptions(response["data"][0]["items"]);
            setTimeout(() => typeahead.current.clear(), 1);
          } else if (response["message"] === "AUTOPOPULATE") {
            setMrpPrice([]);
            setOptions(response.data[0].items);
            if (response["data"][0]["items"].length <= 0) {
              setDisableAddBtn(false);
              setSearchText(query);
            } else {
              setDisableAddBtn(true);
              setSearchText(query);
            }
          }
        }
      } else {
        console.log(
          "getPrice.getItemsList :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("getPrice.getItemsList :", err);
    }
  };
  const handleDeleteFromItemData = (id) => {
    const remainingTasks = itemData.filter((task) => id !== task.item_desc);
    setItemData(remainingTasks);
  };

  const handleAddToList = () => {
    if (typeahead.current.state.text === "") {
      toast.error("Enter an item in search field");
      return;
    }
    setItemData([
      ...itemData,
      { item_desc: searchText || typeahead.current.state.text, soh: "NA" },
    ]);
    setDisableAddBtn(true);
    setSearchText("");
    typeahead.current.clear();
  };

  const submitItemData = async (itemData) => {
    const changeInItemdata = itemData.map((object) => {
      return {
        ...object,
        item_status:
          object.soh > 0
            ? "In Stock"
            : object.soh === 0
            ? "Out of Stock"
            : "Not Available",
      };
    });
    const payload = {
      loc: loginDetails.loc_id,
      posid: loginDetails.pos_id,
      item: changeInItemdata,
      customer_number: Number(mobileNumber),
      cashierid: loginDetails.empCode,
    };
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const { data } = await axios.post(
        address + "postItemFeedback",
        payload,
        config
      );
      if (data.status === "success") {
        toast.success(
          data.message === "" ? "Successfully sent the data" : data.message,
          {
            autoClose: 1500,
          }
        );
        btnTransactionCompleteClick();
        setAskCustModel(false);
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
      }
    } catch (error) {
      setAskCustModel(false);

      console.log("submitItemDara :", error.message);
    }
  };

  useEffect(() => {
    if (typeahead.current && mobileNumber.length >= 10) {
      typeahead.current.focus();
    }
  }, [mobileNumber]);
  return (
    <Modal
      show={askCustModel}
      centered
      onHide={() => {
        btnTransactionCompleteClick();
        setAskCustModel(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!showSearchBar && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={NamaskarLogo}
                alt="Namaskar Logo"
                style={{
                  height: "100px",
                  width: "100px",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "1.3rem",
                  color: "#2A88D9",
                }}
              >
                Hello!{" "}
                <span
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  We hope you had a pleasant shopping experience with us. Could
                  you please inform us about the items you were unable to locate
                  in the store where you intend to make a purchase?
                </span>
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Button
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#FE7A56",
                  width: "30%",
                  textAlign: "center",
                  mt: "1rem",
                  fontWeight: "bold",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#FE7A56",
                  },
                }}
                onClick={() => {
                  setShowSearchBar(true);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  btnTransactionCompleteClick();
                  setAskCustModel(false);
                }}
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#FE7A56",
                  width: "30%",
                  textAlign: "center",
                  mt: "1rem",
                  fontWeight: "bold",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#FE7A56",
                  },
                }}
              >
                No
              </Button>
            </div>
          </>
        )}

        {showSearchBar && (
          <>
            <div
              className="row search-tab"
              style={{
                padding: 0,
                paddingBottom: "1rem",
              }}
            >
              <div className="input-group ">
                <div
                  className="input-group "
                  style={{
                    width: "40%",
                  }}
                >
                  <input
                    className="form-control "
                    placeholder="Customer Number / Id"
                    value={custNumber || mobileNumber}
                    onChange={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setMobileNumber(e.target.value);
                      }
                    }}
                    autoFocus={true}
                    disabled={mobileNumber.length === 10}
                  />
                  <button
                    disabled={mobileNumber.length >= 10}
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (mobileNumber.length < 10) {
                        toast.error("Enter correct mobile number", {
                          autoClose: 1500,
                        });
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div
                  style={{
                    width: "50%",
                    marginLeft: "1.7rem",
                  }}
                >
                  <AsyncTypeahead
                    disabled={mobileNumber.length < 10}
                    ref={typeahead}
                    id="searchItem.txtSearchItem"
                    name="searchItem.txtSearchItem"
                    isLoading={isLoading}
                    labelKey={(option) => `${option.item_desc}`}
                    onSearch={getItemsList}
                    options={options}
                    placeholder="Search Item..."
                    onChange={handleChange}
                    minLength={3}
                    useCache={false}
                    autoFocus={mobileNumber.length >= 10}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && options.length === 0) {
                        handleAddToList();
                      }
                    }}
                  />
                </div>
                <button
                  // disabled={disableAddBtn}
                  style={{
                    backgroundColor: disableAddBtn ? "white" : "white",
                    borderLeft: "none",
                    border: "1px solid #bdbdbd",
                    borderEndEndRadius: "4px",
                    borderTopRightRadius: "4px",
                    lineHeight: "1.6px",
                    marginLeft: "-3px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (typeahead.current.state.text === "") {
                      toast.error("Enter an item in search field");
                      return;
                    }
                    if (options.length !== 0) {
                      toast.error(
                        "Please select from the items available in the list"
                      );
                      return;
                    }
                    handleAddToList();
                  }}
                >
                  <IoMdAdd
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      color: "black",
                    }}
                  />
                </button>
              </div>
            </div>

            {/* Table for showing the items  */}

            <table
              className="table table-bordered table-sm newsaletable"
              style={{ marginTop: "0.5%" }}
            >
              <thead className="table-dark">
                <tr>
                  <th scope="col">Item Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">SOH</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>

              <tbody>
                {itemData?.map((elem, index) => (
                  <tr key={index}>
                    <td className="text-left">{elem.item_desc}</td>
                    <td className="text-left">
                      {elem.soh > 0
                        ? "In Stock"
                        : elem.soh === 0
                        ? "Out of Stock"
                        : "Not Available"}
                    </td>
                    <td className="text-left">{elem.soh}</td>
                    <th scope="col">
                      <i
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleDeleteFromItemData(elem.item_desc);
                        }}
                      >
                        <RxCross2
                          style={{
                            color: "red",
                            fontSize: "1rem",
                            fontWeight: "700",
                          }}
                        />
                      </i>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                onClick={() => {
                  submitItemData(itemData);
                }}
                disabled={itemData.length <= 0}
                sx={{
                  height: "32px",
                  borderRadius: 0,
                  backgroundColor: itemData.length <= 0 ? "white" : "#FE7A56",
                  width: "20%",
                  textAlign: "center",
                  mt: "1rem",
                  border: itemData.length <= 0 && "1px solid #bdbdbd",
                  color: "white",
                  borderRadius: "4px",
                  ":hover": {
                    backgroundColor: "#FE7A56",
                  },
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AskCustomerModal;
