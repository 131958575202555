import React from "react";
import { Modal } from "react-bootstrap";
import walletFaq from "../../../assets/rewardsFaq.png";

import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import { useDispatch } from "react-redux";
const Faq = ({ showFaq = false, handleFaqModal }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={showFaq}
      centered
      className="modal_faq"
      onHide={() => {
        handleFaqModal();
        dispatch(
          moreRewardsActions.setWalletTopUpReset({
            isValidCustomer: false,
            customerDetailsData: [],
            customerNumber: "",
            walletBalance: [],
            differentRechargeValueData: [],
            cashAmount: "",
            totalCartValue: "",
            rechargeData: [],
            otpStatus: false,
            basketID: "",
            walletRechargeData: [],
            rechargeSuccess: "",
            numberOfTopUpButtons: [],
            inputFieldEnable: true,
            resetEnable: false,
            cashbacks: [],
          })
        );
      }}
    >
      <Modal.Header
        closeButton
        onClick={() => {
          dispatch(moreRewardsActions.setRewardScreen(""));
          dispatch(
            moreRewardsActions.setWalletTopUpReset({
              isValidCustomer: false,
              customerDetailsData: [],
              customerNumber: "",
              walletBalance: [],
              differentRechargeValueData: [],
              cashAmount: "",
              totalCartValue: "",
              rechargeData: [],
              otpStatus: false,
              basketID: "",
              walletRechargeData: [],
              rechargeSuccess: "",
              numberOfTopUpButtons: [],
              inputFieldEnable: true,
              resetEnable: false,
              cashbacks: [],
            })
          );
        }}
      ></Modal.Header>
      <Modal.Body
        style={{
          width: "100%",
        }}
      >
        <img
          src={walletFaq}
          style={{
            width: "inherit",
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Faq;
