import React, { useState } from "react";
import CustomerCheck from "../../../Common/CustomerCheck/CustomerCheck";
import { Box, Checkbox, Skeleton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../../appconfig.json";
import axios from "axios";
import { toast } from "react-toastify";

const ReferralProgram = () => {
  const address = appConfig.api_url;
  const dispatch = useDispatch();
  const [refereeNumber, setRefereeNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCustData, setLoadingCustData] = useState(false);

  const handleReferreNumber = (value) => {
    setRefereeNumber(value);
    setRefereeSubmitBtn(false);
  };
  const cust_id = useSelector(
    (state) => state.moreRewards.customerDetailsData.cust_id || ""
  );

  const customerNumber = useSelector(
    (state) => state.moreRewards.customerNumber
  );
  const [refereeSubmitBtn, setRefereeSubmitBtn] = useState(false);
  const userDetailsData = useSelector((state) => state?.login?.userDetailsData);
  const [referralData, setReferralData] = useState({
    custreffered: 0,
    totalreferesgained: 0,
    totaleligiblerevards: 0,
    totalrewardsexp: 0,
    reffer_mobile_no: [],
  });
  const customerValidate = (referalNumber) => {
    const payload = {
      searchText: Number(referalNumber),
    };
    try {
      axios.post(address + "getDsCustomer/", payload).then((response) => {
        if (response.message !== "failure") {
          setLoading(true);
          var existFlag = response["data"]["data"][0]["isCustExisting"];
          if (existFlag) {
            toast.error("Customer Exist", {
              autoClose: 1000,
            });
            setRefereeNumber("");
            setRefereeSubmitBtn(true);
            setLoading(false);
          } else {
            setTimeout(() => {
              customerReferralExtraDetails();
            }, 2000);
            customerReferralSubmit();
            setRefereeNumber("");
            setRefereeSubmitBtn(true);
          }
        } else {
          setLoading(false);
          console.log(
            "referralProgram.customerValidate : ",
            response.message,
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      setLoading(false);

      console.log("referralProgram.customerValidate : ", err);
    }
  };

  const customerReferralExtraDetails = () => {
    setLoading(true);
    const payload = {
      customer_number: Number(
        document.getElementById("walletTop.searchCustomer").value
      ),
    };
    try {
      axios
        .post(address + "getDsCustReferralDetails/", payload)
        .then((response) => {
          if (response.data.status !== "failure") {
            setReferralData({
              ...referralData,
              custreffered: response.data.data[0].custreffered,
              totalreferesgained: response.data.data[0].totalreferesgained,
              totaleligiblerevards: response.data.data[0].totaleligiblerevards,
              totalrewardsexp: response.data.data[0].totalrewardsexp,
              reffer_mobile_no: response.data.data[0].reffer_mobile_no,
            });
            setRefereeNumber("");
            setRefereeSubmitBtn(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(response.data.message, {
              autoClose: 1500,
            });
            console.log(
              "referralProgram.CustomerReferralDetails : ",
              response.message,
              response.data.status,
              response.statusText
            );
          }
        });
    } catch (err) {
      setLoading(false);
      toast.error("error in backend", {
        autoClose: 1500,
      });

      console.log("referralProgram.CustomerReferralDetails : ", err);
    }
  };

  const customerReferralSubmit = () => {
    setLoading(true);
    const value = refereeNumber.split(",");
    if (value.length > 10) {
      toast.error("Only 10 referral numbers can be added");
      return;
    }
    const payload = {
      mobile_number: value,
      cust_id: cust_id,
      loc: userDetailsData.loc_id,
    };
    try {
      axios.post(address + "postDsCustReferral/", payload).then((response) => {
        if (response.data.status !== "failure") {
          setTimeout(() => {}, 2000);
          setRefereeNumber("");
          setRefereeSubmitBtn(true);
          toast.success("Customer registered successfully", {
            autoClose: 1000,
          });
          setLoading(false);
          customerReferralExtraDetails();
        } else {
          setLoading(false);
          toast.error(response.data.message, {
            autoClose: 1500,
          });
          console.log(
            "referralProgram.customerReferralSubmit : ",
            response.data.message,
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("referralProgram.customerReferralSubmit : ", err);
    }
  };

  const handleReset = () => {};

  return (
    <>
      <div>
        <CustomerCheck
          showCustomerName={true}
          extraFunctionCall={customerReferralExtraDetails}
          reset={handleReset}
          loader={loadingCustData}
          setLoader={setLoadingCustData}
        />

        {cust_id !== "" && (
          <div
            style={{
              marginLeft: "-0.8rem",
            }}
          >
            <div className="row">
              <div className="col-md-3">
                <Typography
                  sx={{
                    width: "20rem",
                    padding: "1rem",
                    backgroundColor: "#788896",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1rem",
                  }}
                  mb={2}
                >
                  Add Referree
                </Typography>
                <div className="input-group">
                  <input
                    id="addReferree.input"
                    name="addReferree.input"
                    type="text"
                    className="form-control"
                    value={refereeNumber}
                    style={{
                      borderRadius: "0px",
                    }}
                    placeholder="Referree Number"
                    onChange={(e) => {
                      var value = e.target.value;
                      // value = value.replace(/[^a-zA-Z0-9]/g, "");
                      handleReferreNumber(value);
                    }}
                    onKeyDown={(event) => {
                      var value = event.target.value;
                      // value = value.replace(/[^a-zA-Z0-9]/g, "");
                      handleReferreNumber(value);

                      if (event.key === "Enter") {
                        // validateSubmitCustomer();
                      }
                    }}
                  />
                </div>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "orange",
                  }}
                >
                  Note: To add extra number for referral use "," seperator
                </Typography>
                <button
                  style={{
                    marginTop: "1rem",
                    borderRadius: "0px",
                  }}
                  className="btn btn-primary"
                  type="button"
                  disabled={refereeSubmitBtn ? true : false}
                  onClick={() => {
                    customerReferralSubmit();
                  }}
                >
                  Submit
                </button>
              </div>

              <div
                className="col-md-4"
                style={{
                  marginLeft: "6rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#f04e23",
                    fontWeight: "bold",
                  }}
                >
                  Details
                </Typography>
                {loading ? (
                  <Stack gap={2}>
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" />
                    <Skeleton variant="rectangular" />
                  </Stack>
                ) : (
                  <>
                    <Typography>
                      Number of Customer referred to :
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {referralData.custreffered}
                      </span>
                    </Typography>
                    <Typography>
                      Referrees gained through invite :
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {referralData.totalreferesgained}
                      </span>
                    </Typography>
                    <Typography>
                      Total Referrees gained :
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {referralData.totalreferesgained}
                      </span>
                    </Typography>
                    <Typography>
                      Total Eliggible rewards :
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {referralData.totaleligiblerevards}
                      </span>
                    </Typography>
                    <Typography>
                      Total Referral rewards expired :
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {referralData.totalrewardsexp}
                      </span>
                    </Typography>
                  </>
                )}
              </div>
              <div className="col-md-3">
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#f04e23",
                    fontWeight: "bold",
                  }}
                >
                  List of Customers Sent Invite
                </Typography>
                <Box
                  sx={{
                    height: "15rem",
                    overflow: "scroll",
                  }}
                >
                  {loading ? (
                    <Stack gap={2}>
                      <Skeleton variant="rectangular" />
                      <Skeleton variant="rectangular" />
                      <Skeleton variant="rectangular" />
                    </Stack>
                  ) : (
                    <>
                      {referralData.reffer_mobile_no.map(
                        (elem, index) =>
                          elem !== null && (
                            <Box key={index}>
                              <Typography>
                                {index + 1}: {elem.reffer_mobile_no}
                                <Checkbox
                                  checked={
                                    elem.reffer_status === "true" ? true : false
                                  }
                                  sx={{
                                    color: "green",
                                    "&.Mui-checked": {
                                      color: "green",
                                    },
                                  }}
                                />
                              </Typography>
                            </Box>
                          )
                      )}
                    </>
                  )}
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <div className="forVoidInPayment  align-items-center justify-content-center">
          <div
            style={{
              position: "fixed",
              top: "35%",
              left: "50%",
              width: "30em",
              height: "18em",
              marginLeft: "-17em",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  placeSelf: "center",
                  marginLeft: "1.6rem",
                }}
              >
                Fetching Details Please Wait !!!!
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ReferralProgram;
