import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { connect, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { Modal } from "react-bootstrap";
import appConfig from "../appconfig.json";
import { customerActions } from "../components/store/customerStore";
import { newSaleActions } from "../components/store/newSaleStore";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ModalMultipleMRP from "./Modal/ModalMultipleMRP";
import axios from "axios";
// import { confirmAlert } from "react-confirm-alert";
import UserRegister from "./userRegister";
import { moreRewardsActions } from "./store/MoreRewards/MoreRewardsStore";
import { useCommonSelectors } from "../Common/selector";
const SearchLayout = forwardRef((props, keyRef) => {
  const address = appConfig.api_url;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showModalMultipleMRP, setShowModalMultipleMRP] = useState(false);
  const [multipleMRPItems, setMultipleMRPItems] = useState([]);
  const [multipleMRPItemBarcode, setMultipleMRPItemBarcode] = useState("");
  const [loadingCustData, setLoadingCustData] = useState(false);
  const typeaheadRef = useRef(null);
  const typeahead = useRef(null);
  const deliverySlot = useRef(null);
  const deliveryMode = useRef(null);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const handleBlurEvent = (e) => {
    keyRef.current = e.target;
  };

  function btnResetNewSale() {
    props.setStrNewSaleCurrentStatus("newSaleBilling");
    props.setStrItemsBasketId("");
    props.setCardTransactionStatus(false);
    props.setTranType("OFFLIN");
    props.setSelectedDeliverySlot("");
    props.setAvailableDeliverySlot([]);
    props.setStrItemsCartValue("0");
    props.setStrItemsTotalDiscount("0");
    props.setStrItemsFinalCartValue("0");
    props.setStrTotalFoodValue(0);
    props.setStrTotalNonFoodValue(0);
    props.setStrItemsCouponNo("");
    props.setStrItemsBillNo("");
    props.setStrItemsBalanceAmount(0);
    props.setStrItemsBillTendAmount(0);
    props.setStrItemsListData([]);
    props.setStrItemsListTend([]);
    props.setRecommendOffer([]);
    props.setSlabOfferDesc("");
    props.setCouponCnt(0);
    props.setCancelAllowed("N");
    props.setCustomerTier("");
  }

  useEffect(() => {
    keyRef.current = document.getElementById("searchItem.txtCustItem");
    keyRef.current.focus();
  }, []);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    getItemsList(
      "",
      selectedOptions[0].item,
      selectedOptions[0].barcode,
      "item_selected"
    );
    setTimeout(() => props.typeahead.current.clear(), 1);
  };

  const setTransMode = async (tranType, slot) => {
    if (tranType !== "HOMEDE") {
      props.setSelectedDeliverySlot("");
      document.getElementById("deliverySlot").select = "";
    } else if (tranType !== "HOMECD") {
      props.setSelectedDeliverySlot("");
      document.getElementById("deliverySlot").select = "";
    }
    if (tranType === "HOMEDE" && slot === "SLOT") {
      toast.info(
        props.strCustomerDetails?.address_line_1.length > 0
          ? props.strCustomerDetails?.address_line_2.length > 0
            ? "Delivery Address : " +
              props.strCustomerDetails?.address_line_1 +
              " " +
              props.strCustomerDetails?.address_line_2
            : "Delivery Address : " + props.strCustomerDetails?.address_line_1
          : props.strCustomerDetails?.address_line_2.length > 0
          ? "Delivery Address : " + ""
          : "",
        { autoClose: 5000, closeOnClick: false, rtl: false }
      );
    }
    if (tranType === "HOMECD" && slot === "SLOT") {
      toast.info(
        props.strCustomerDetails?.address_line_1.length > 0
          ? props.strCustomerDetails?.address_line_2.length > 0
            ? "Delivery Address : " +
              props.strCustomerDetails?.address_line_1 +
              " " +
              props.strCustomerDetails?.address_line_2
            : "Delivery Address : " + props.strCustomerDetails?.address_line_1
          : props.strCustomerDetails?.address_line_2.length > 0
          ? "Delivery Address : " + ""
          : "",
        { autoClose: 5000, closeOnClick: false, rtl: false }
      );
    }

    try {
      let payload = {
        basket_id: props.strNewSaleItemsBasketId,
        sub_tran_type: tranType,
        slot:
          tranType === "HOMEDE" || "HOMECD"
            ? document.getElementById("deliverySlot").value || ""
            : "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "putSaleSubTranType/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          props.setAvailableDeliverySlot(response.data[0].hdAvailSlots);
          props.setTranType(tranType);
          toast.success(response.message, {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "searchLayout-setTransMode-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-setTransMode :", err);
    }
  };

  const handleKeyPress = (target) => {
    if (target.keyCode === 13) {
    }
  };

  const getItemsDetails = (payload) => {
    try {
      axios.post(address + "postDsItemSubmit/", payload).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.status === "success") {
            btnResetNewSale();
            if (response.data.message === "FINAL") {
              response = response.data;
              props.setAvailableDeliverySlot(
                response["data"][0]["hdAvailSlots"]
              );
              response["data"][0]["sub_tran_type"] === "HOMEDE" &&
              props.strCustomerDetails.isCustExisting
                ? props.setSelectedDeliverySlot(response["data"][0]["slot"])
                : props.setSelectedDeliverySlot("");

              props.setTranType(response["data"][0]["sub_tran_type"]);
              props.setCancelAllowed(response["data"][0]["isEdcCancelAllowed"]);
              props.setStrItemsBasketId(response["data"][0]["basket_id"]);
              props.setCardTransactionStatus(
                response["data"][0]["statusCheck"]
              );
              props.setStrItemsCartValue(response["data"][0]["totalCart"]);
              props.setStrItemsTotalDiscount(
                response["data"][0]["totalDiscount"]
              );
              props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
              props.setStrTotalFoodValue(response["data"][0]["totalFoodValue"]);
              props.setStrTotalNonFoodValue(
                response["data"][0]["totalNonFoodValue"]
              );
              props.setStrItemsBillBalanceAmount(
                response["data"][0]["balanceAmt"]
              );
              props.setStrItemsListData(response["data"][0]["items"]);
              props.setRecommendOffer(response["data"][0]["recomOffers"]);
              props.setSlabOfferDesc(
                response["data"][0]["slab_offer_description"]
              );
              props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
              if (response["data"][0]["tendAmt"] > 0) {
                props.setStrNewSaleCurrentStatus("newSalePayment");
                props.setStrItemsListTend(response["data"][0]["tend"]);
              }
            }
          } else {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        } else {
          console.log(
            "searchLayout.getItemsDetails",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("searchLayout.getItemsDetails : ", err);
    }
  };

  const getItemsList = async (query, item, barcode, eventtype) => {
    props.strNewSaleItemsList.map((elem, index) => {
      if (elem.item === query.slice(2)) {
        if (elem.qty > elem.max_qty) {
          toast.error(`Max ${item.max_qty} Quantity allowed for this Item.`, {
            toastId: "toast-qtyChange",
            autoClose: 1000,
          });
          return;
        }
      }
    });
    try {
      setIsLoading(true);
      item = item === undefined ? "" : item;
      barcode = barcode === undefined ? "" : barcode;
      eventtype = eventtype === undefined ? "item_search" : eventtype;
      var paramCustId = props.strCustomerDetails.cust_id
        ? props.strCustomerDetails.cust_id
        : "";
      var paramCustNumber = props.strCustomerDetails.customer_number
        ? props.strCustomerDetails.customer_number
        : "";
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      let payload = {
        event: eventtype,
        searchText: `${query}`,
        cust_id: paramCustId,
        customer_number: paramCustNumber,
        cashier_id: props.strUserDetails.empCode,
        basket_id: paramBasketId,
        item_desc: "",
        item: item,
        barcode: barcode,
        sub_tran_type: props.strTranType,
        slot: props.strSelectedDeliverySlot,
        loc: loc,
        pos_id: pos_id_mpos,
      };

      let response = await fetch(appConfig.api_url + "getDsItemSearch/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();

        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            props.setStrItemsListData([]);
            props.setRecommendOffer([]);
            props.setSlabOfferDesc("");
            props.setCouponCnt(0);
            props.setCancelAllowed("N");
            props.setStrItemsBasketId(response["data"][0]["basket_id"]);
            props.setCardTransactionStatus(response["data"][0]["statusCheck"]);
            props.setStrItemsCartValue(response["data"][0]["totalCart"]);
            props.setStrItemsTotalDiscount(
              response["data"][0]["totalDiscount"]
            );
            props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
            props.setStrTotalFoodValue(response["data"][0]["totalFoodValue"]);
            props.setStrTotalNonFoodValue(
              response["data"][0]["totalNonFoodValue"]
            );
            props.setStrItemsBillBalanceAmount(
              response["data"][0]["balanceAmt"]
            );
            props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
            props.setRecommendOffer(response["data"][0]["recomOffers"]);
            props.setSlabOfferDesc(
              response["data"][0]["slab_offer_description"]
            );
            props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);

            setTimeout(
              () => props.setStrItemsListData(response["data"][0]["items"]),
              1
            );

            setSelected([]);
            setTimeout(() => props.typeahead.current.clear(), 1);
          } else if (response["message"] === "MULTIPLEMRP") {
            setMultipleMRPItems(response["data"][0]["items"]);
            setMultipleMRPItemBarcode(response["data"][0]["barcode"]);
            setShowModalMultipleMRP(true);
            setTimeout(() => props.typeahead.current.clear(), 1);
          } else if (response["message"] === "AUTOPOPULATE") {
            setOptions(response["data"][0]["items"].slice(0, 10));
          }
        }
      } else {
        console.log(
          "searchLayout-getItemsList-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-getItemsList :", err);
    }
  };

  const CustomerCoupon = async (payload) => {
    try {
      axios.post(address + "getDsCustCoupon/", payload).then((response) => {
        if (response.data.status === "success") {
          props.setCustomerCoupon(response.data?.data[0]?.offer_no);
        } else {
          toast.error(response.data.message, {
            autoClose: 1500,
          });
          console.log("searchLayout.getDsCustCoupon : ", response.data.message);
        }
      });
    } catch (err) {
      console.log("searchLayout.getDsCustCoupon : ", err);
    }
  };
  const customerTier =
    useSelector((state) => state.customer.customerDetailsData.tier) || "";

  async function getCustomerDetails(forNo) {
    try {
      var custid = document.getElementById("searchItem.txtCustItem").value;
      let item = {
        searchText: custid,
        basket_id:
          props.strNewSaleItemsBasketId?.length > 0
            ? props.strNewSaleItemsBasketId
            : "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      setLoadingCustData(true);
      let result = await fetch(appConfig.api_url + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          CustomerCoupon({
            customer_number: result.data[0].customer_number,
            loc: props.strUserDetails["loc_id"],
          });
          if (
            result.data[0].isCustExisting === false &&
            result.data[0].isInternetConnected === true
          ) {
            setLoadingCustData(true);
            handleOpenOrClose();
            return;
          }
          props.setStrIsValidCustomer(true);
          props.setStrCustomerDetailsData(result["data"][0]);
          props.setHomeDelivery("N");
          document.getElementById("deliverySlot").select = "";
          props.setSelectedDeliverySlot("");
          props.setCustomerTier(result.data[0].tier);
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          if (props.strNewSaleItemsBasketId.length > 0) {
            getItemsDetails({
              event: "basket_getdata",
              basket_id: props.strNewSaleItemsBasketId,
            });

            if (!result.data[0].isCustExisting) {
              props.setHomeDelivery("N");
              document.getElementById("deliverySlot").select = "";
              props.setSelectedDeliverySlot("");
            }
          }
          setTimeout(() => props.typeahead.current.focus(), 1);

          if (result.data[0].isCustExisting) {
            props.setHomeDelivery(result.data[0]["allowHomDel"]);
            setTimeout(() => deliveryMode.current.focus(), 1);
          }
          setLoadingCustData(false);
        } else {
          props.setStrIsValidCustomer(false);
          props.setStrCustomerDetailsData([]);
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          props.setHomeDelivery("N");
          props.setSelectedDeliverySlot("");
          document.getElementById("deliverySlot").select = "";
          toast.error(result["message"], {
            autoClose: 1000,
          });
          setLoadingCustData(false);
        }
      } else {
        setLoadingCustData(false);

        console.log(
          "searchLayout-getCustomerDetails-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-getCustomerDetails :", err);
    }
  }

  async function getNoCustomerDetails() {
    try {
      var custid = document.getElementById("searchItem.txtCustItem").value;
      let item = {
        searchText: custid,
        basket_id:
          props.strNewSaleItemsBasketId?.length > 0
            ? props.strNewSaleItemsBasketId
            : "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      setLoadingCustData(true);
      let result = await fetch(appConfig.api_url + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          props.setStrIsValidCustomer(true);
          props.setStrCustomerDetailsData(result["data"][0]);
          props.setHomeDelivery("N");
          document.getElementById("deliverySlot").select = "";
          props.setSelectedDeliverySlot("");
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          if (props.strNewSaleItemsBasketId.length > 0) {
            getItemsDetails({
              event: "basket_getdata",
              basket_id: props.strNewSaleItemsBasketId,
              loc: loc,
              pos_id: pos_id_mpos,
            });

            if (!result.data[0].isCustExisting) {
              props.setHomeDelivery("N");
              document.getElementById("deliverySlot").select = "";
              props.setSelectedDeliverySlot("");
            }
          }
          setTimeout(() => props.typeahead.current.focus(), 1);

          if (result.data[0].isCustExisting) {
            props.setHomeDelivery(result.data[0]["allowHomDel"]);
            setTimeout(() => deliveryMode.current.focus(), 1);
          }
          setLoadingCustData(false);
        } else {
          props.setStrIsValidCustomer(false);
          props.setStrCustomerDetailsData([]);
          props.setHomeDelivery("N");
          props.setSelectedDeliverySlot("");
          document.getElementById("deliverySlot").select = "";
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          toast.error(result["message"], {
            autoClose: 1000,
          });
          setLoadingCustData(false);
        }
      } else {
        setLoadingCustData(false);
        console.log(
          "searchLayout-getCustomerDetails-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("searchLayout-getCustomerDetails :", err);
    }
  }
  function frmCustomerSubmit(event) {
    event.preventDefault();
  }

  function validateSubmitCustomer() {
    const frmCustId = document.getElementById("searchItem.txtCustItem").value;
    if (frmCustId !== "" && frmCustId.length < 10) {
      toast.error("Enter Valid Customer Id or Mobile Number", {
        autoClose: 1000,
      });
    } else {
      getCustomerDetails();
    }
  }

  function validateNoSubmitCustomer() {
    const frmCustId = document.getElementById("searchItem.txtCustItem").value;
    if (frmCustId !== "" && frmCustId.length < 10) {
      toast.error("Enter Valid Customer Id or Mobile Number", {
        autoClose: 1000,
      });
    } else {
      getNoCustomerDetails();
    }
  }
  //Changes
  const [showCustomerState, setShowCustomerState] = useState(false);
  const [mobileNumber, setmobileNumber] = useState("");
  useEffect(() => {
    if (!showCustomerState) {
      setShowCustomerState(false);
    }
  }, [showCustomerState]);

  const buttonClickedHandler = () => {
    setmobileNumber(document.getElementById("searchItem.txtCustItem").value);

    if (showCustomerState === false) {
      if (
        document.getElementById("searchItem.txtCustItem").value.length === 10
      ) {
        setShowCustomerState(true);
      } else {
        toast.error("Enter Customer Number ", {
          autoClose: 1000,
        });
      }
    }
  };
  const [newCustomerdetails, setNewCustomer] = useState(
    props.strCustomerDetails ?? {}
  );
  const customerValidate = (payload) => {
    try {
      axios.post(address + "getDsCustomer/", payload).then((response) => {
        if (response.status !== "failure") {
          var existFlag = response["data"]["data"][0]["isCustExisting"];
          var walletBal = response["data"]["data"][0]["wallet_bal"];
          var custId = response["data"]["data"][0]["cust_id"];
          setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
          props.setNewCustomer({
            ...newCustomerdetails,
            ["mobile_no"]: payload.searchText,
            ["isCustExisting"]: existFlag,
            ["wallet_bal"]: walletBal,
            ["cust_id"]: custId,
          });
        } else {
          console.log(
            "returnLayout.customerValidate : ",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("returnLayout.customerValidate : ", err);
    }
  };

  //For pop up and keyboard event
  const [openOrClose, setopenOrClose] = useState(false);

  const handleOpenOrClose = () => {
    setopenOrClose(!openOrClose);
  };

  const keyboardEventListenerNewSalePayment = useCallback(
    (e) => {
      //logic for shortcut key
      if (
        openOrClose &&
        e.altKey &&
        e.code === "KeyZ" &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey
      ) {
        validateNoSubmitCustomer();
        handleOpenOrClose();
        return;
      }
    },
    [openOrClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListenerNewSalePayment);
    return () => {
      document.body.removeEventListener(
        "keydown",
        keyboardEventListenerNewSalePayment
      );
    };
  }, [keyboardEventListenerNewSalePayment]);

  return (
    <>
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {showCustomerState ? (
        <UserRegister
          show={showCustomerState}
          button_clicked={setShowCustomerState}
          customerValidate={customerValidate}
          customerNumber={
            document.getElementById("searchItem.txtCustItem").value
          }
          getCustomerName={getCustomerDetails}
          disableField={true}
          searchLayout={true}
          noCustomerDetails={getNoCustomerDetails}
        />
      ) : (
        <></>
      )}
      {openOrClose ? (
        <Modal show={openOrClose} className="modal-trans" centered>
          <Modal.Body>
            <div
              className="modal-dialog modal-confirm"
              style={{
                width: "500px",
              }}
            >
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title w-150"></h5>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "30px",
                  }}
                >
                  Do you want to register yourself ?<br />
                  <br /> Do you want a Home Delivery for this Order ?<br />
                  <br />
                  <b>Benefits:{"    "}</b>
                  <br />
                  <ol type="1">
                    <li key={"benefit " + 1}>
                      You can return products as per return policy
                    </li>
                    <li key={"benefit " + 2}>
                      Home delivery is possible only for register customers
                    </li>
                    <li key={"benefit " + 3}>
                      You will get e invoice on WhatsApp
                    </li>
                    <li key={"benefit " + 4}> Get promotion notification</li>
                  </ol>
                </div>
                <div className="modal-footer justify-content-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setLoadingCustData(false);
                        validateNoSubmitCustomer();
                        handleOpenOrClose();
                      }}
                    >
                      No, Proceed to Sales / (ALT + Z)
                    </button>
                  </div>
                  <button
                    id="yesButton"
                    type="button"
                    autoFocus={true}
                    className="btn btn-danger"
                    onClick={() => {
                      setShowCustomerState(false);
                      buttonClickedHandler();
                      handleOpenOrClose();
                    }}
                  >
                    Yes, Register
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      <div className="row search-tab">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-5">
              <form onSubmit={frmCustomerSubmit}>
                <div className="input-group">
                  <input
                    id="searchItem.txtCustItem"
                    name="searchItem.txtCustItem"
                    type="text"
                    className="form-control "
                    placeholder="Customer Number / Id"
                    defaultValue={
                      props.strIsValidCustomer === true
                        ? props.strCustomerDetails.customer_number
                        : ""
                    }
                    onBlur={handleBlurEvent}
                    onChange={(e) => {
                      var value = e.target.value;
                      value = value.replace(/[^a-zA-Z0-9]/g, "");
                      document.getElementById("searchItem.txtCustItem").value =
                        value;
                    }}
                    onKeyDown={(event) => {
                      var value = event.target.value;
                      value = value.replace(/[^a-zA-Z0-9]/g, "");
                      document.getElementById("searchItem.txtCustItem").value =
                        value;
                      if (event.key === "Enter") {
                        if (!loadingCustData) {
                          validateSubmitCustomer();
                        }
                      }
                    }}
                    disabled={
                      props.strNewSaleCurrentStatus === "newSaleComplete" ||
                      props.strBillBalanceOrSuccess === "BILLBALANCE"
                        ? true
                        : false
                    }
                    autoComplete="off"
                  />
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => {
                      props.strTranType === "HOMEDE"
                        ? props.strSelectedDeliverySlot !== "" &&
                          props.strSelectedDeliverySlot
                          ? props.strCustomerDetails.isCustExisting === true
                            ? validateSubmitCustomer()
                            : handleOpenOrClose()
                          : toast.error("Select Delivery Slot", {
                              autoClose: 1000,
                            })
                        : document.getElementById("searchItem.txtCustItem")
                            .value !== ""
                        ? validateSubmitCustomer()
                        : handleOpenOrClose();
                    }}
                    disabled={
                      props.strNewSaleCurrentStatus === "newSaleComplete" ||
                      props.strBillBalanceOrSuccess === "BILLBALANCE"
                        ? true
                        : false
                    }
                  >
                    {loadingCustData ? (
                      <label
                        className="spinner-border"
                        style={{
                          width: ".8rem",
                          height: ".8rem",
                        }}
                      ></label>
                    ) : (
                      <label>Submit</label>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-3">
              <select
                ref={deliveryMode}
                className="form-control"
                name="deliveryMode"
                id="deliveryMode"
                selected={props.strTranType || "OFFLIN"}
                value={props.strTranType || "OFFLIN"}
                style={{ height: "98%" }}
                onSelect={(event) => {
                  if (
                    props.strNewSaleCurrentStatus === "newSalePayment" &&
                    event.target.value === "HOMECD" &&
                    props.strBillBalanceOrSuccess !== "BILLBALANCE"
                  ) {
                    toast.error(
                      "Click Back button to select Home Delivery - COD",
                      {
                        autoClose: 1500,
                      }
                    );
                    return;
                  }
                  if (
                    event.target.value === "HOMECD" &&
                    props.strBillBalanceOrSuccess === "BILLBALANCE"
                  ) {
                    toast.error(
                      "You have already made a payment, hence you cannot proceed with COD",
                      {
                        autoClose: 1500,
                      }
                    );
                    return;
                  }
                  if (event.target.value === "HOMECD") {
                    props.setIfCashOnDelivery(true);
                  } else {
                    props.setIfCashOnDelivery(false);
                  }
                  if (props.strNewSaleItemsBasketId) {
                    props.setTranType(event.target.value);
                    setTransMode(event.target.value, "SLOT");
                    if (event.target.value === "OFFLIN") {
                      setTimeout(() => props.typeahead.current.focus(), 1);
                    } else {
                      setTimeout(() => deliverySlot.current.focus(), 1);
                    }
                  }
                }}
                onChange={(event) => {
                  if (
                    props.strNewSaleCurrentStatus === "newSalePayment" &&
                    event.target.value === "HOMECD" &&
                    props.strBillBalanceOrSuccess !== "BILLBALANCE"
                  ) {
                    toast.error(
                      "Click Back button to select Home Delivery - COD",
                      {
                        autoClose: 1500,
                      }
                    );
                    return;
                  }
                  if (
                    event.target.value === "HOMECD" &&
                    props.strBillBalanceOrSuccess === "BILLBALANCE"
                  ) {
                    toast.error(
                      "You have already made a payment, hence you cannot proceed with COD",
                      {
                        autoClose: 1500,
                      }
                    );
                    return;
                  }
                  if (event.target.value === "HOMECD") {
                    props.setIfCashOnDelivery(true);
                  } else {
                    props.setIfCashOnDelivery(false);
                  }

                  if (props.strNewSaleItemsBasketId) {
                    props.setTranType(event.target.value);
                    setTransMode(event.target.value, "SLOT");
                    if (event.target.value === "OFFLIN") {
                      setTimeout(() => props.typeahead.current.focus(), 1);
                    } else {
                      setTimeout(() => deliverySlot.current.focus(), 1);
                    }
                  }
                }}
                disabled={
                  props.strNewSaleCurrentStatus !== "newSaleComplete"
                    ? props.strNewSaleItemsBasketId.length > 0
                      ? !props.strIsValidCustomer
                      : true
                    : true
                }
              >
                <option value="OFFLIN">Self Pickup</option>
                {props.strHomeDelivery === "Y" ? (
                  <option value="HOMEDE">Home Delivery-Prepaid</option>
                ) : (
                  <></>
                )}
                {props.strCustomerDetails.omni_del === "Yes" &&
                props.strHomeDelivery === "Y" ? (
                  <option value="HOMECD">Home Delivery-COD</option>
                ) : (
                  <></>
                )}
              </select>
            </div>
            <div className="col-md-4">
              <select
                ref={deliverySlot}
                className="form-control"
                name="deliverySlot"
                id="deliverySlot"
                selected={props.strSelectedDeliverySlot || ""}
                value={props.strSelectedDeliverySlot || ""}
                style={{ height: "98%" }}
                onSelect={(event) => {
                  if (props.strNewSaleItemsBasketId.length > 0) {
                    if (props.strTranType === "HOMEDE") {
                      props.setTranType("HOMEDE");
                      setTransMode("HOMEDE", "");
                    }
                    if (props.strTranType === "HOMECD") {
                      props.setTranType("HOMECD");
                      setTransMode("HOMECD", "");
                    }
                    props.setSelectedDeliverySlot(event.target.value);

                    setTimeout(() => props.typeahead.current.focus(), 1);
                  }
                }}
                onChange={(event) => {
                  if (props.strNewSaleItemsBasketId.length > 0) {
                    if (props.strTranType === "HOMEDE") {
                      props.setTranType("HOMEDE");
                      setTransMode("HOMEDE", "");
                    }
                    if (props.strTranType === "HOMECD") {
                      props.setTranType("HOMECD");
                      setTransMode("HOMECD", "");
                    }
                    props.setSelectedDeliverySlot(event.target.value);
                    setTimeout(() => props.typeahead.current.focus(), 1);
                  }
                }}
                disabled={
                  props.strNewSaleCurrentStatus !== "newSaleComplete"
                    ? props.strHomeDelivery === "Y" &&
                      (props.strTranType === "HOMEDE" ||
                        props.strTranType === "HOMECD")
                      ? false
                      : true
                    : true
                }
              >
                <option
                  value=""
                  key={-1 + " slot"}
                  selected={props.strSelectedDeliverySlot === "" ? true : false}
                >
                  Select Slot
                </option>
                {props.strAvailableDeliverySlot?.map((object, i) => {
                  return (
                    <>
                      <option value={object.val}>{object.key}</option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-text">
                <b style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>
                    Customer :{" "}
                    <span
                      style={{ color: "#f04e23" }}
                      id="searchLayout.userName"
                    >
                      {props.strCustomerDetails["custName"]}
                    </span>
                    <span
                      style={{
                        color:
                          customerTier === "diamond"
                            ? "#612FB8"
                            : customerTier === "gold"
                            ? "#D78014"
                            : customerTier === "silver"
                            ? "A6A6A6"
                            : "#7488AB",
                      }}
                      id="searchLayout.userName"
                    >
                      {customerTier !== "" &&
                        ` (${customerTier.toUpperCase()})`}
                    </span>
                  </span>
                </b>
              </div>
            </div>
            <div
              className="col-3"
              style={{
                marginLeft: "-16px",
              }}
            >
              <div className="form-text">
                <b>
                  Basket Id :{" "}
                  <span style={{ color: "#f04e23" }}>
                    {props.strNewSaleItemsBasketId}
                  </span>
                </b>
              </div>
            </div>
            <div className="col-3 text-end">
              <div className="form-text">
                <b>
                  Item / Qty :{" "}
                  <span style={{ color: "#f04e23" }}>
                    {props.strNewSaleItemsList.length} /{" "}
                    {props.strNewSaleItemsList
                      .reduce((accumulator, object) => {
                        return accumulator + Math.round(object.qty * 100) / 100;
                      }, 0)
                      .toFixed(2)}
                  </span>
                </b>
              </div>
            </div>
          </div>
        </div>
        {props.isItemSearchEnabled ? (
          <div className="col-md-6">
            <AsyncTypeahead
              ref={props.typeahead || typeahead}
              id="searchItem.txtSearchItem"
              name="searchItem.txtSearchItem"
              isLoading={isLoading}
              labelKey={(option) => `${option.item_desc}`}
              onSearch={getItemsList}
              options={options}
              placeholder="Search Item..."
              onChange={handleChange}
              onBlur={handleBlurEvent}
              minLength={2}
              useCache={false}
              disabled={!props.strIsValidCustomer ? true : false}
            />
            {showModalMultipleMRP ? (
              <ModalMultipleMRP
                show={showModalMultipleMRP}
                close={() => setShowModalMultipleMRP(false)}
                multipleMRPItems={multipleMRPItems}
                multipleMRPItemBarcode={multipleMRPItemBarcode}
                button_clicked={setShowModalMultipleMRP}
              />
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-12">
                <div className="form-text text-end">
                  <i className="searchLa">
                    <span
                      className="fa fa-bullhorn"
                      style={{ color: "#f04e23" }}
                    ></span>
                    &nbsp;&nbsp;(Search barcode, plu code (format: &lt;plu
                    code&gt;*), item code (format: i#&lt;item code&gt;) or
                    description)
                  </i>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
});

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
    strIsValidCustomer: reduxState?.customer?.isValidCustomer,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strHomeDelivery: reduxState?.customer?.homeDelivery,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strNewSaleItemsList: reduxState?.newSale?.itemsListData,
    strTranType: reduxState?.newSale?.tranType,
    strAvailableDeliverySlot: reduxState?.newSale?.availableDeliverySlot,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
    strNewSaleCurrentStatus: reduxState?.newSale?.newSaleCurrentStatus,
    strIfCashOnDelivery: reduxState?.newSale?.ifCashOnDelivery,
    strBillBalanceOrSuccess: reduxState?.newSale?.billSuccessOrBalance,
    strCustomerCoupon: reduxState?.newSale?.customerCoupon,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrIsValidCustomer: (isValidCustomer) =>
      dispatch(customerActions.setIsValidCustomer(isValidCustomer)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
    setStrItemsBasketId: (itemsbasketId) =>
      dispatch(newSaleActions.setItemsBasketId(itemsbasketId)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrTotalFoodValue: (foodValue) =>
      dispatch(newSaleActions.setTotalFoodValue(foodValue)),
    setStrTotalNonFoodValue: (nonFoodValue) =>
      dispatch(newSaleActions.setTotalNonFoodValue(nonFoodValue)),
    setStrItemsBillBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
    setHomeDelivery: (flag) => dispatch(customerActions.setHomeDelivery(flag)),
    setTranType: (flag) => dispatch(newSaleActions.setTranType(flag)),
    setSelectedDeliverySlot: (flag) =>
      dispatch(newSaleActions.setSelectedDeliverySlot(flag)),
    setAvailableDeliverySlot: (slotList) =>
      dispatch(newSaleActions.setAvailableDeliverySlot(slotList)),
    setStrItemsCouponNo: (couponNo) =>
      dispatch(newSaleActions.setItemsCoupon(couponNo)),
    setStrNewSaleCurrentStatus: (newSaleCurrStatus) =>
      dispatch(newSaleActions.setNewSaleCurrentStatus(newSaleCurrStatus)),
    setStrItemsBillNo: (billNo) =>
      dispatch(newSaleActions.setItemsBillNo(billNo)),
    setStrItemsBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsListTend: (tendList) =>
      dispatch(newSaleActions.setItemsListTend(tendList)),
    setCardTransactionStatus: (flag) =>
      dispatch(newSaleActions.setCardTransactionStatus(flag)),
    setIfCashOnDelivery: (flag) =>
      dispatch(newSaleActions.setIfCashOnDelivery(flag)),
    setCustomerCoupon: (arrayOfCoupons) =>
      dispatch(newSaleActions.setCustomerCoupon(arrayOfCoupons)),
    setCustomerTier: (tier) => dispatch(customerActions.setCustomerTier(tier)),
    setMorePointsUsableBalance: (usablePoints) =>
      dispatch(moreRewardsActions.setMorePointsUsableBalance(usablePoints)),
    setMorePointsBalance: (balancePoints) =>
      dispatch(moreRewardsActions.setMorePointsBalance(balancePoints)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SearchLayout);
