import { createSlice } from "@reduxjs/toolkit";

const customerInitialState = {
  isValidCustomer: false,
  customerDetailsData: [],
  homeDelivery: "N",
  customerGstData: [],
  customerTier: "",
};

const customerSlice = createSlice({
  name: "customerDs",
  initialState: customerInitialState,
  reducers: {
    setIsValidCustomer(state, action) {
      state.isValidCustomer = action.payload;
    },
    setCustomerDetailsData(state, action) {
      state.customerDetailsData = action.payload;
    },
    setNewGstData(state, action) {
      state.customerGstData = action.payload;
    },
    setHomeDelivery(state, action) {
      state.homeDelivery = action.payload;
    },
    setCustomerTier(state, action) {
      state.customerTier = action.payload;
    },
  },
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;
