import React from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const DataGridForReturnLayout = ({
  inputs,
  returnTableData,
  billidSub,
  setFetchBtn,
  setShowLoader,
}) => {
  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const renderDetailsButton = (object) => {
    return (
      <Button
        variant="contained"
        style={{
          fontSize: "0.9em",
          backgroundColor: "#d3455b",
          padding: "0.3em 0.3em",
        }}
        onClick={() => {
          billidSub(object.row.bill_no, object.row.b_date, object.row.order_id);
          setFetchBtn(true);
          setShowLoader(true);
        }}
      >
        {object.row.bill_no}
      </Button>
    );
  };
  const columnsAmazon = [
    {
      field: "b_date",
      headerName: "Date",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bill_no",
      headerName: "Bill Number",
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },

    {
      field: inputs.sub_tran_type === "AMZNOW" ? "order_id" : "mobile_no",
      headerName:
        inputs.sub_tran_type === "AMZNOW" ? "Order Id" : "Customer Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const columnsOffline = [
    {
      field: "b_date",
      headerName: "Date",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "bill_no",
      headerName: "Bill Number",
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },

    {
      field: inputs.sub_tran_type === "AMZNOW" ? "order_id" : "mobile_no",
      headerName:
        inputs.sub_tran_type === "AMZNOW" ? "Order Id" : "Customer Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: inputs.sub_tran_type !== "AMZNOW" && "returnable_value",
      headerName: inputs.sub_tran_type !== "AMZNOW" && "Bill Amount ( ₹ ) ",
      flex: inputs.sub_tran_type !== "AMZNOW" && 0.8,
      align: inputs.sub_tran_type !== "AMZNOW" && "center",
      headerAlign: inputs.sub_tran_type !== "AMZNOW" && "center",
    },
  ];

  return (
    <Box
      mb={2}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-cell": {
          fontSize: ".8rem",
          border: "0.1px solid lightgrey",
          textAlign: "center",
          alignSelf: "center",
          padding: "0.2vw 0.2vw",
        },
        "& .name-column--cell": {
          color: "#2e7c67",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#333",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#fff",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          color: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "#fff",
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "#333",
          color: "#fff",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${"#141414"} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
          color: `${"#141414"} !important`,
        },
        "& .MuiToolbar-root": {
          color: "white",
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
          "::-webkit-scrollbar": {
            width: "0.8vw",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#7775",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#333",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "D3D3D3",
          },
          "::-webkit-scrollbar-track:hover": {
            backgroundColor: "#3333",
          },
        },
      }}
    >
      <DataGrid
        rows={returnTableData}
        columns={
          inputs.sub_tran_type !== "AMZNOW" ? columnsOffline : columnsAmazon
        }
        components={{ Toolbar: GridToolbar }}
        getRowId={(row) => generateRandom()}
        disableColumnSelector
        disableDensitySelector
        pagination
        {...returnTableData}
        sx={{
          height: "62vh",
        }}
      />
    </Box>
  );
};

export default DataGridForReturnLayout;
