import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ModalGstSelect = ({
  openOrClose,
  printBill,
  homeReceiptPrint,
  gstNumSelect,
  setGstNumSelect,
  closeModal,
  gstData,
  sendEbill,
  postFortuneDetails,
  changeFortune,
}) => {
  const gstDetailsData = useSelector((state) => state.customer.customerGstData);

  return (
    <>
      <Modal show={openOrClose | false} className="modal-trans" centered>
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h5 className="modal-title w-100">GST Number Select</h5>
              </div>
              <div className="modal-footer justify-content-center">
                <select
                  name="gstSelect"
                  id="gstSelect"
                  className="form-control"
                  onChange={(e) => {
                    setGstNumSelect(e.target.value);
                  }}
                >
                  <option value={""}>Select Your GST Number</option>
                  {gstDetailsData.map((elem, index) => (
                    <option key={index} value={elem.gsttin_no}>
                      {elem.gsttin_no}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    if (Object.keys(changeFortune).length !== 0) {
                      postFortuneDetails();
                    }
                    printBill();
                    closeModal();
                    sendEbill("Y");
                  }}
                >
                  Submit <br /> (Print Bill)
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalGstSelect;
