import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import appConfig from "../../appconfig.json";
import { newSaleActions } from "../store/newSaleStore";
import { toast } from "react-toastify";
import { useCommonSelectors } from "../../Common/selector";

const NewSaleBilling = forwardRef((props, keyRef) => {
  const address = appConfig.api_url;
  const kgBtn = useRef();
  const [kgLoading, setKgLoading] = useState(false);
  const itemsQtyBlurEvent = (e, item) => {
    keyRef.current = e.target;
  };
  const { loc, pos_id_mpos } = useCommonSelectors();

  useEffect(() => {}, []);

  function inputChangedHandler(event, item) {
    var newQty = event.target.value;
    if (item["uom"] === "KG") {
      if (!newQty.match(/^\d{1,}(\.\d{0,3})?$/)) {
        toast.error("Enter valid Quantity.", {
          toastId: "toast-qtyChange",
          autoClose: 1000,
        });
        return;
      }
    } else {
      if (!newQty.match(/^[0-9]\d*$/)) {
        toast.error("Enter valid Quantity.", {
          toastId: "toast-qtyChange",
          autoClose: 1000,
        });
        return;
      }
    }
    getQtyChangeItemsList(item, newQty);
  }

  function removeItem(item) {
    removeItemFromList(item);
  }
  const getQtyChangeItemsList = async (qtyItem, newQty) => {
    //We can check and change the quating update here as well, but its done while onchange itself
    try {
      var paramCustId = props.strCustomerDetails.cust_id
        ? props.strCustomerDetails.cust_id
        : "";
      var paramCustNumber = props.strCustomerDetails.customer_number
        ? props.strCustomerDetails.customer_number
        : "";
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      let payload = {
        event: "basket_qtychange",
        cust_id: paramCustId,
        customer_number: paramCustNumber,
        cashier_id: props.strUserDetails.empCode,
        basket_id: paramBasketId,
        item: qtyItem["item"],
        barcode: qtyItem["barcode"],
        item_desc: qtyItem["item_desc"],
        qty: newQty,
        mrp: qtyItem["mrp"],
        sub_tran_type: props.strTranType,
        slot: props.strSelectedDeliverySlot,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            props.setStrItemsCartValue(response["data"][0]["totalCart"]);
            props.setStrItemsTotalDiscount(
              response["data"][0]["totalDiscount"]
            );
            props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
            props.setStrItemsBillBalanceAmount(
              response["data"][0]["balanceAmt"]
            );
            props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
            props.setStrItemsListData(response["data"][0]["items"]);
            props.setRecommendOffer(response["data"][0]["recomOffers"]);
            props.setSlabOfferDesc(
              response["data"][0]["slab_offer_description"]
            );
            props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
          }
        } else {
          toast.error(response["message"], {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "newSaleBilling-getQtyChangeItemsList-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleBilling-getQtyChangeItemsList :", err);
    }
    props.typeahead.current.focus();
  };

  const removeItemFromList = async (removeItem) => {
    try {
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      let payload = {
        event: "basket_removeitem",
        basket_id: paramBasketId,
        item: removeItem["item"],
        mrp: removeItem["mrp"],
        sub_tran_type: props.strTranType,
        slot: props.strSelectedDeliverySlot,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            if (response["data"].length > 0) {
              props.setStrItemsCartValue(response["data"][0]["totalCart"]);
              props.setStrItemsTotalDiscount(
                response["data"][0]["totalDiscount"]
              );
              props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
              props.setStrItemsBillBalanceAmount(
                response["data"][0]["balanceAmt"]
              );
              props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
              props.setStrItemsListData(response["data"][0]["items"]);
              props.setRecommendOffer(response["data"][0]["recomOffers"]);
              props.setSlabOfferDesc(
                response["data"][0]["slab_offer_description"]
              );
              props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
            } else {
              props.setStrItemsCartValue("0");
              props.setStrItemsTotalDiscount("0");
              props.setStrItemsFinalCartValue("0");
              props.setStrItemsBillBalanceAmount("0");
              props.setStrItemsBillTendAmount("0");
              props.setStrItemsListData([]);
              props.setRecommendOffer([]);
              props.setSlabOfferDesc("");
              props.setCouponCnt(0);
              props.setCancelAllowed("N");
            }
          }
        } else {
          toast.error(response["message"], {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "newSaleBilling-removeItemFromList-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleBilling-removeItemFromList :", err);
    }
  };
  const getQtyChangeItemsWeight = async (qtyItem) => {
    try {
      var paramCustId = props.strCustomerDetails.cust_id
        ? props.strCustomerDetails.cust_id
        : "";
      var paramCustNumber = props.strCustomerDetails.customer_number
        ? props.strCustomerDetails.customer_number
        : "";
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      let payload = {
        event: "basket_fetchweight",
        cust_id: paramCustId,
        customer_number: paramCustNumber,
        cashier_id: props.strUserDetails.empCode,
        basket_id: paramBasketId,
        item: qtyItem["item"],
        barcode: qtyItem["barcode"],
        item_desc: qtyItem["item_desc"],
        qty: qtyItem["qty"],
        mrp: qtyItem["mrp"],
        sub_tran_type: props.strTranType,
        slot: props.strSelectedDeliverySlot,
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsItemSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          if (response["message"] === "FINAL") {
            props.setStrItemsListData([]);
            props.setRecommendOffer([]);
            props.setSlabOfferDesc("");
            props.setCouponCnt(0);
            props.setStrItemsCartValue(response["data"][0]["totalCart"]);
            props.setStrItemsTotalDiscount(
              response["data"][0]["totalDiscount"]
            );
            props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
            props.setStrItemsBillBalanceAmount(
              response["data"][0]["balanceAmt"]
            );
            props.setStrItemsBillTendAmount(response["data"][0]["tendAmt"]);
            setTimeout(
              () => props.setStrItemsListData(response["data"][0]["items"]),
              1
            );
            props.setRecommendOffer(response["data"][0]["recomOffers"]);
            props.setSlabOfferDesc(
              response["data"][0]["slab_offer_description"]
            );
            props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
          }
        } else {
          toast.error(response["message"], {
            autoClose: 1000,
          });
        }
      } else {
        console.log(
          "newSaleBilling-getQtyChangeItemsList-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSaleBilling-getQtyChangeItemsList :", err);
    }
  };

  return (
    <>
      <div
        className="col-md-12 newSaleBilling"
        style={{
          height: 55 + "vh",
          backgroundColor: "white",
          overflow: "auto",
          border: "solid 0px #000000",
          marginBottom: "12px",
        }}
      >
        <table
          className="table table-bordered table-sm newsaletable"
          style={{ marginTop: "0.2%" }}
        >
          <thead
            className="table-dark"
            style={{
              position: "sticky",
              top: 0,
              zIndex: 99,
            }}
          >
            <tr>
              <th scope="col"></th>
              <th scope="col">Item</th>
              <th scope="col" style={{ width: "40%" }}>
                Description
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Quantity
              </th>
              <th scope="col">
                Unit Price <br />( ₹ ){" "}
              </th>
              <th scope="col">
                Discount <br /> ( ₹ )
              </th>
              <th scope="col">
                Amount <br /> ( ₹ ){" "}
              </th>
              <th scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            {props.strNewSaleItemsList.map((item, index) => {
              var index = index + 1;
              var itemKey = item.item + "-" + index;

              return (
                <tr key={itemKey}>
                  <td className="text-center align-middle">{index}</td>
                  <td className="text-center align-middle">{item.item}</td>
                  <td className="align-middle " style={{ fontSize: "14px" }}>
                    {item.item_desc}

                    {item.offers.map((offer, offerIndex) => {
                      var offerIndex = offerIndex + 1;
                      var offerItemKey = offer.offer_no + "-" + offerIndex;
                      return (
                        <>
                          <br />
                          <span
                            style={{ color: offer.offer_color }}
                            className="float-end"
                            key={offerItemKey}
                          >
                            {offer.offer_type} - {offer.offer_desc}
                          </span>
                        </>
                      );
                    })}
                    <br />

                    {props.strTranType === "HOMECD" && item.item_message && (
                      <span
                        key={index}
                        className="header-flash-button"
                        style={{
                          color: "red",
                          textAlign: "right",
                          marginTop: "1rem",
                          fontWeight: "600",
                        }}
                      >
                        {item.item_message}
                      </span>
                    )}
                  </td>
                  <td className="columnQty  align-middle">
                    <div className="col-xs-1">
                      <div className="input-group input-group-sm">
                        {item.uom === "EA" ? (
                          <input
                            type="number"
                            id={item.item}
                            name={item.item}
                            className="form-control text-end col-2 inputQty"
                            defaultValue={item.qty}
                            onBlur={(event) => {
                              itemsQtyBlurEvent(event, item);
                              props.typeahead.current.focus();
                            }}
                            onFocus={(event) => {
                              if (Number(event.target.value) === 0) {
                                document.getElementById(item.item).value = "";
                                document.getElementById(item.item).focus();
                              }
                            }}
                            min={0}
                            onKeyDown={(event) => {
                              if (event.key === ".") {
                                event.preventDefault();
                              } else if (event.target.value.length >= 1) {
                                if (event.key === "Enter") {
                                  inputChangedHandler(event, item);
                                } else if (!/[0-9]/.test(event.key)) {
                                  event.target.value = event.target.value;
                                }
                              }
                            }}
                            onPaste={(event) => {
                              let pasteData =
                                event.clipboardData.getData("text");
                              if (pasteData) {
                                pasteData.replace(/^[0-9]*$/g, "");
                                props.typeahead.current.focus();
                              }
                            }}
                            onChange={(event) => {
                              if (Number(event.target.value) > item.max_qty) {
                                toast.error(
                                  `Max ${item.max_qty} Quantity allowed for this Item.`,
                                  {
                                    toastId: "toast-qtyChange",
                                    autoClose: 1000,
                                  }
                                );
                                document.getElementById(item.item).focus();
                                document.getElementById(item.item).value =
                                  item.max_qty;
                              } else if (
                                !event.target.value.match(/^[0-9]\d*$/)
                              ) {
                                document.getElementById(item.item).value =
                                  event.target.value.substring(
                                    0,
                                    event.target.value.length - 1
                                  );
                              }
                            }}
                          />
                        ) : (
                          <input
                            type="text"
                            id={item.item}
                            name={item.item}
                            className="form-control text-end inputQty"
                            aria-label="Sizing example input"
                            defaultValue={item.qty}
                            onBlur={(event) => {
                              itemsQtyBlurEvent(event, item);
                            }}
                            min={0}
                            onFocus={(event) => {
                              if (Number(event.target.value) === 0) {
                                document.getElementById(item.item).value = "";
                                document.getElementById(item.item).focus();
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.target.value.length >= 1) {
                                if (event.key === "Enter") {
                                  inputChangedHandler(event, item);
                                }
                              }
                            }}
                            onPaste={(event) => {
                              let pasteData =
                                event.clipboardData.getData("text");
                              if (pasteData) {
                                pasteData.replace(/^[0-9].*$/g, "");
                              }
                            }}
                            onChange={(event) => {
                              if (Number(event.target.value) > item.max_qty) {
                                toast.error(
                                  `Max ${item.max_qty} Quantity allowed for this Item.`,
                                  {
                                    toastId: "toast-qtyChange",
                                    autoClose: 1000,
                                  }
                                );
                                document.getElementById(item.item).focus();
                                document.getElementById(item.item).value =
                                  item.max_qty;
                              } else if (
                                !event.target.value.match(
                                  /^\d{1,}(\.\d{0,3})?$/
                                )
                              ) {
                                document.getElementById(item.item).value =
                                  event.target.value.substring(
                                    0,
                                    event.target.value.length - 1
                                  );
                              }
                            }}
                          />
                        )}
                        {item.uom === "EA" ? (
                          <span
                            className="input-group-text inputQty"
                            id="inputGroup-sizing-sm"
                          >
                            {item.uom}
                          </span>
                        ) : (
                          <>
                            <span
                              ref={kgBtn}
                              onClick={() => {
                                setKgLoading(true);
                                getQtyChangeItemsWeight(item);
                                setKgLoading(false);
                              }}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#f3c19d",
                                color: "#000000",
                              }}
                              className="input-group-text inputQty"
                              id="inputGroup-sizing-sm kg-btn"
                            >
                              {item.uom}
                              {kgLoading ? (
                                <span
                                  className="spinner-border spinner-border-sm spinner-white"
                                  style={{
                                    color: "#ffffff",
                                    marginLeft: "5px",
                                  }}
                                  role="status"
                                ></span>
                              ) : (
                                <></>
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="text-end align-middle">{item.mrp}</td>
                  <td className="text-end align-middle">{item.discount}</td>
                  <td className="text-end align-middle">{item.net_amt}</td>
                  <td className="text-center align-middle">
                    <button
                      type="button"
                      className="btn btn-link btn-sm px-3"
                      style={{ color: "crimson" }}
                      data-ripple-color="dark"
                      onClick={() => {
                        removeItem(item);
                        props.typeahead.current.focus();
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
});

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strNewSaleItemsList: reduxState?.newSale?.itemsListData,
    strTranType: reduxState?.newSale?.tranType,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrItemsBillBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(NewSaleBilling);
