import React from "react";

const SearchForm = ({
  handleChange,
  handleBlurEvent,
  handleSubmit,
  inputs,
  searchtextref,
  alertShow,
  fetchBtn,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <form method="POST">
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-3">
                <select
                  className="form-control h-100"
                  name="sub_tran_type"
                  id="sub_tran_type"
                  onChange={handleChange}
                  onBlur={handleBlurEvent}
                  autoFocus={true}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <option value={"OFFLIN"}>Offline</option>
                  <option value={"AMZNOW"}>Amazon</option>
                </select>
              </div>
              <div className="col-3">
                <input
                  type="text"
                  className="form-control"
                  name="customerNumber"
                  id="customerNumber"
                  value={inputs.customerNumber || ""}
                  onChange={(e) => {
                    var newValue = e.target.value.replace(
                      new RegExp(/'^[0-9]+$'/, "")
                    );
                    e.target.value = newValue;
                    handleChange(e);
                  }}
                  placeholder={"Customer Number / ID"}
                  autoFocus={true}
                  onBlur={(e) => handleBlurEvent(e)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  ref={searchtextref}
                  disabled={
                    inputs.sub_tran_type === "AMZNOW"
                      ? true
                      : alertShow && fetchBtn
                  }
                />
              </div>
              <div
                className="col-1 text-center"
                style={{
                  paddingTop: "8px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                OR
              </div>
              <div className="col-3">
                <input
                  className="form-control"
                  type="text"
                  name="billno"
                  id="billno"
                  value={inputs.billno || ""}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={
                    inputs.sub_tran_type !== "AMZNOW"
                      ? "Bill Number"
                      : "Amazon Order ID"
                  }
                  onBlur={(e) => handleBlurEvent(e)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  ref={searchtextref}
                  disabled={alertShow && fetchBtn}
                />
              </div>
              <div className="col-2">
                <button
                  className="btn btn-primary"
                  disabled={fetchBtn}
                  onClick={() => handleSubmit()}
                  style={{ width: "10em" }}
                >
                  <u>S</u>ubmit {"   "}
                  {alertShow ? (
                    <span
                      className="spinner-border spinner-border-sm spinner-white"
                      style={{ color: "#ffffff" }}
                      role="status"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchForm;
