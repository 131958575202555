import React from "react";
import { Modal } from "react-bootstrap";
import Alert from "../../assets/Alert.png";
const ModalAlertForIT = ({ showAlertModalIT, setShowAlertModalIT }) => {
  return (
    <Modal
      centered
      show={showAlertModalIT || false}
      onHide={() => {
        setShowAlertModalIT(!showAlertModalIT);
      }}
    >
      <Modal.Header
        style={{
          backgroundColor: "#f04e23",
        }}
        closeButton
      ></Modal.Header>
      <Modal.Body
        style={{
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        <div>
          <div
            className=""
            style={{
              textAlign: "left",
              marginBottom: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="header-flash-button text-end">
              <img
                style={{
                  height: "100px",
                  width: "100px",
                }}
                src={Alert}
                alt="Alert"
              />
            </div>
            <div
              style={{
                marginTop: "1.2rem",
                fontSize: "1.3rem",
                textAlign: "center",
              }}
            >
              Communicate with Regional IT to push the pending Transaction
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAlertForIT;
