import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import appConfig from "../../../appconfig.json";
import { useSelector } from "react-redux";

const WalletTopUpYesNoModal = ({ openOrClose, voidFunction, handleClose }) => {
  const address = appConfig.api_url;
  //Setting in which type of payment do user need
  const [typeOfPaymentUserNeed, setTypeofPaymentUserNeed] = useState("");
  const handleTypeOfPaymentUserNeed = (value) => {
    setTypeofPaymentUserNeed(value);
  };
  const [loading, setLoading] = useState(false);
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const basketId = useSelector(
    (state) => state.moreRewards?.walletTopUp.basketID
  );
  const walletRechargetDataTend =
    useSelector(
      (state) => state.moreRewards.walletTopUp.walletRechargeData.tend
    ) || [];
  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.walletTopUp.customerDetailsData
  );
  const posID = useSelector((state) => state.login?.userDetailsData.pos_id);

  let totalAmount = 0;
  walletRechargetDataTend.map((elem, index) => {
    totalAmount += elem.tend_amount;
  });

  //Common function for postBasketPartialVoid
  const postBasketPartailVoidFn = (payload) => {
    axios
      .post(address + "postDsBasketPartialVoid/", payload)
      .then((response) => {
        if (response["data"]["status"] === "success") {
          setLoading(true);
          handleSubmitForWallet();
        }
      })
      .catch((err) => {
        console.log("walletTopUpYesNoModal-postDsBasketPartialVoid", err);
      });
  };
  // Common Function ends here

  const handleSubmitForWallet = () => {
    try {
      const payload = {
        cust_id: strCustomerDetails.cust_id,
        customer_number: strCustomerDetails.customer_number,
        pos_id: posID,
        tran_amt: totalAmount,
        tran_ref_number: basketId,
      };

      axios
        .post(address + "postCustVoidWalletBalance/", payload)
        .then((response) => {
          if (response["data"]["status"] === "success") {
            setLoading(true);
            toast.success(
              response["data"]["message"] +
                " - " +
                response["data"]["data"][0].wallet_balance,
              {
                autoClose: 1500,
              }
            );
            handleTypeOfPaymentUserNeed("");

            setTimeout(() => {
              handleClose();
              voidFunction();
              setLoading(false);
            }, 1500);
          } else {
            setLoading(false);
            toast.success(response["data"]["message"], {
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          toast.error("Something wrong with", err);
        });
    } catch (err) {
      console.log("walletTopUpYesNoModal-postCustVoidWalletBalance-api", err);
    }
  };

  //submit function for CASH OR EDC
  const handleSubmitForCashOrEDC = () => {
    try {
      const payload = {
        cashier_id: cashier_id,
        basket_id: basketId,
        ttype: walletRechargetDataTend[0].ttype,
        tend_amount: -walletRechargetDataTend[0].tend_amount,
      };

      axios
        .post(address + "postDsBasketPartialVoid/", payload)
        .then((response) => {
          if (response["data"]["status"] === "success") {
            setLoading(true);
            toast.success(response["data"]["message"], {
              autoClose: 1500,
            });
            handleTypeOfPaymentUserNeed("");
            setTimeout(() => {
              handleClose();
              voidFunction();
              setLoading(false);
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error("Something wrong with", err);
        });
    } catch (err) {
      console.log("walletTopUpYesNoModal-postDsBasketPartialVoid-api : ", err);
    }
  };
  //submit function for CASH ends here
  const handleMainSubmit = () => {
    if (typeOfPaymentUserNeed === "WALLET") {
      // handleSubmitForWallet();
      const payload = {
        cashier_id: cashier_id,
        basket_id: basketId,
        ttype: "WALLET",
        tend_amount: -totalAmount,
      };
      postBasketPartailVoidFn(payload);
    } else if (
      typeOfPaymentUserNeed === "CASH" ||
      typeOfPaymentUserNeed === "CARD"
    ) {
      handleSubmitForCashOrEDC();
    }
  };
  return (
    <>
      <Modal show={openOrClose | false} className="modal-trans" centered>
        <Modal.Body>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h5 className="modal-title w-100">
                  Do You want to Void the basket
                </h5>
              </div>
              <div className="modal-footer justify-content-center">
                <>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      // disabled={}
                      onClick={() => {
                        handleTypeOfPaymentUserNeed("WALLET");
                      }}
                    >
                      WAllET
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={
                        (walletRechargetDataTend[0].ttype === "WALLET"
                          ? true
                          : "") ||
                        (walletRechargetDataTend[0].ttype === "CASH"
                          ? true
                          : "") ||
                        (walletRechargetDataTend.length > 1 ? true : "")
                      }
                      onClick={() => {
                        handleTypeOfPaymentUserNeed("CARD");
                      }}
                    >
                      CARD
                    </button>

                    <button
                      id="yesButton"
                      type="button"
                      autoFocus={true}
                      disabled={
                        (walletRechargetDataTend[0].ttype === "WALLET"
                          ? true
                          : "") ||
                        (walletRechargetDataTend[0].ttype === "EDC"
                          ? true
                          : "") ||
                        (walletRechargetDataTend.length > 1 ? true : "")
                      }
                      className="btn btn-secondary"
                      onClick={() => {
                        handleTypeOfPaymentUserNeed("CASH");
                      }}
                    >
                      CASH
                    </button>
                  </div>
                </>
              </div>

              <>
                <div className="row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-secondary col-md-5"
                    style={{}}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary col-md-5"
                    disabled={typeOfPaymentUserNeed === "" || loading === true}
                    onClick={() => {
                      if (typeOfPaymentUserNeed === "") {
                        toast.error(
                          "Please select type or return payment user requested",
                          {
                            autoClose: 2000,
                          }
                        );
                      } else {
                        handleMainSubmit();
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#f04e23",
                    marginTop: "20px",
                  }}
                >
                  You have to return {totalAmount} ( ₹ )
                </p>
              </>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletTopUpYesNoModal;
