import React, { useEffect, useRef } from "react";
import c3 from "c3";
import "c3/c3.css";
const DonutChart = ({ perecentage = 0, headerText = "Donut Chart" }) => {
  const chart1Ref = useRef(null);

  useEffect(() => {
    // Data for the first chart

    // Data for the second chart
    let target = 50;
    const data2 = {
      columns: [
        ["Current", Number(perecentage)],
        ["Remaining", 100 - Number(perecentage)],
      ],
      type: "donut",

      // donut: {
      //   title: "Iris Petal Width",
      // },
      colors: {
        Current: Number(perecentage) >= target ? "#267332" : "#95191B",
        Remaining: "grey",
      },
    };

    // Configuration for the second chart
    const chart1Config = {
      bindto: chart1Ref.current,
      data: data2,
      tooltip: {
        show: false,
      },
      size: {
        width: 220, // Set the width of the chart
        height: 220, // Set the height of the chart
      },
      donut: {
        title: "Target: 50%",
      },
    };

    // Generate charts

    const chart1 = c3.generate(chart1Config);

    // Cleanup function to destroy the charts when the component unmounts
    return () => {
      chart1.destroy();
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div className="col rounded border border-dark mw-100 p-0">
      <div className="fs-6 fw-bold px-2 mt-1">{headerText}</div>

      <div className="d-flex justify-content-center" style={{}}>
        <div ref={chart1Ref}></div>
      </div>
    </div>
  );
};

export default DonutChart;
