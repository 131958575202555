import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../Loader";

const ModalScanBarcodeOtp = ({
  receivedOtp,
  mainFunction,
  show,
  button_clicked,
  postRedemptionDetailsApi = () => {},
  loader,
  setLoader,
}) => {
  const [enteredOtp, setEnteredOtp] = useState("");

  const autoFocus = useRef(true);
  const handleSubmit = () => {
    if (enteredOtp === receivedOtp) {
      postRedemptionDetailsApi();
      autoFocus.current = false;
      mainFunction();
    } else {
      toast.error("Enter correct otp");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          button_clicked();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ textTransform: "capitalize" }}>
              Please Enter OTP sent in Mobile for Verification
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              name="otp"
              id="otp"
              className="form-control"
              placeholder="Enter OTP"
              defaultValue={enteredOtp || ""}
              onChange={(e) => {
                setEnteredOtp(e.target.value);
              }}
              pattern="[0-9]+"
              title="OTP"
              autoFocus={autoFocus.current}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!loader) {
                    setLoader(true);
                    handleSubmit();
                  }
                }
              }}
            />
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-12 text-center">
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                ></div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-12 text-center">
                <button
                  disabled={loader}
                  type="submit"
                  className="btn btn-primary"
                  name="validateOTP"
                  id="validate"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loader ? (
                    <label
                      className="spinner-border"
                      style={{
                        width: ".8rem",
                        height: ".8rem",
                      }}
                    ></label>
                  ) : (
                    `Submit`
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalScanBarcodeOtp;
