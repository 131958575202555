import { useState, lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";

import ModalWelcomeMessage from "../components/Modal/ModalWelcomeMessage";
import { Nav, NewSale, OnHoldLayout } from "../components";
import MoreRewardsLayout from "../components/MoreRewardsLayout/MoreRewardsLayout";

const Header = lazy(() => import("../components/header"));
const ReturnLayout = lazy(() =>
  import("../components/ReturnLayout/ReturnLayout")
);
const TransactionLayout = lazy(() => import("../components/TransactionLayout"));

const Dashboard = (props) => {
  const [showModalWelcomeMsg, setShowModalWelcomeMsg] = useState(true);
  const [arrUserDetails, setArrUserDetails] = useState(
    props.strUserDetails ?? []
  );

  const currModule = () => {
    switch (props.strCurrModule) {
      case "newsale":
        return <NewSale />;
      case "billsonhold":
        return <OnHoldLayout />;
      case "return":
        return (
          <Suspense
            fallback={
              <div className="overlay d-flex align-items-center justify-content-center">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <div style={{ color: "#ffffff", fontSize: "12px" }}>
                  Loading... Please Wait
                </div>
              </div>
            }
          >
            <ReturnLayout />
          </Suspense>
        );
      case "transactions":
        return (
          <Suspense
            fallback={
              <div className="overlay d-flex align-items-center justify-content-center">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <div style={{ color: "#ffffff", fontSize: "12px" }}>
                  Loading... Please Wait
                </div>
              </div>
            }
          >
            <TransactionLayout />
          </Suspense>
        );
      case "moreRewards":
        return (
          <Suspense
            fallback={
              <div className="overlay d-flex align-items-center justify-content-center">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <div style={{ color: "#ffffff", fontSize: "12px" }}>
                  Loading... Please Wait
                </div>
              </div>
            }
          >
            <MoreRewardsLayout />
          </Suspense>
        );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Suspense
          fallback={
            <div className="overlay d-flex align-items-center justify-content-center">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div style={{ color: "#ffffff", fontSize: "12px" }}>
                Loading... Please Wait
              </div>
            </div>
          }
        >
          <Header />
        </Suspense>
        {arrUserDetails["pos_id"] !== 0 && arrUserDetails["pos_id"] !== null ? (
          <Nav />
        ) : (
          ""
        )}

        {arrUserDetails["pos_id"] !== 0 && arrUserDetails["pos_id"] !== null ? (
          currModule()
        ) : (
          <div className="alert alert-danger" role="alert">
            POS not registered.
          </div>
        )}
        <ToastContainer
          className="toast-position"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {!props.strIsWelcomeMsgViewed && arrUserDetails["customMsg"] !== "" ? (
          <ModalWelcomeMessage
            show={showModalWelcomeMsg}
            close={() => setShowModalWelcomeMsg(false)}
            button_clicked={setShowModalWelcomeMsg}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

// export default Dashboard;
const mapStateToProps = (reduxState, oProps) => {
  return {
    strCurrModule: reduxState?.common?.currModule,
    strIsWelcomeMsgViewed: reduxState?.common?.isWelcomeMsgViewed,
    strUserDetails: reduxState?.login?.userDetailsData,
  };
};

export default connect(mapStateToProps)(Dashboard);
