import React from "react";
import { connect, useSelector } from "react-redux";

const NewSaleOrderSummary = (props) => {
  const newSaleCurrentStatus = useSelector(
    (state) => state.newSale.newSaleCurrentStatus
  );

  return (
    <>
      <div
        className="row newSaleOrderSummary"
        style={{ paddingTop: "10px", paddingLeft: "10px" }}
      >
        <div className="col-12">
          <table
            className="table table-sm"
            style={{
              marginBottom: "2px",
            }}
          >
            <tbody style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
              <tr>
                <td>Cart Value ( ₹ )</td>
                <td className="text-end">{props.strItemsCartValue}</td>
              </tr>
              <tr>
                <td>Total Discount ( ₹ )</td>
                <td className="text-end">{props.strItemsTotalDiscount}</td>
              </tr>
              <tr>
                <td>Final Cart Value ( ₹ )</td>
                <td className="text-end">{props.strItemsFinalCartValue}</td>
              </tr>
              {newSaleCurrentStatus === "newSalePayment" && (
                <>
                  <tr className="textColorBlue">
                    <td>Food Value ( ₹ )</td>
                    <td className="text-end">{props.strTotalFoodValue}</td>
                  </tr>
                  <tr className="textColorBlue">
                    <td>Non-Food Value ( ₹ )</td>
                    <td className="text-end">{props.strTotalNonFoodValue}</td>
                  </tr>
                </>
              )}
              <tr>
                <td>Balance Amount to Pay ( ₹ )</td>
                <td className="text-end">{props.strItemsBillBalanceAmount}</td>
              </tr>
              {props.strSlabOfferDesc ? (
                <tr>
                  <td colSpan={2} className="border-0">
                    <span style={{ color: "#228B22" }}>
                      <i className="fa fa-check" aria-hidden="true"></i>
                      {"    "}&nbsp; Offer "{props.strSlabOfferDesc}" applied
                    </span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={2} className="border-0">
                    <br />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strItemsCartValue: reduxState?.newSale?.itemsCartValue,
    strItemsTotalDiscount: reduxState?.newSale?.itemsTotalDiscount,
    strItemsFinalCartValue: reduxState?.newSale?.itemsFinalCartValue,
    strTotalFoodValue: reduxState?.newSale?.totalFoodValue,
    strTotalNonFoodValue: reduxState?.newSale?.totalNonFoodValue,
    strItemsBalanceAmount: reduxState?.newSale?.itemsCartValue,
    strSlabOfferDesc: reduxState?.newSale?.slabOfferDesc,
    strItemsBillBalanceAmount: reduxState?.newSale?.itemsBillBalanceAmount,
  };
};

// export default NewSaleOrderSummary
export default connect(mapStateToProps)(NewSaleOrderSummary);
