import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
const SemiDonutChart = ({
  billingSpeed,
  headerText = "Billing Speed (sec/item)",
}) => {
  const speed = billingSpeed > 30 ? 30 : billingSpeed;
  return (
    <div
      className="col rounded border border-dark p-0"
      style={{
        backgroundColor: "white",
      }}
    >
      <div className="fs-6 fw-bold px-2 mt-1">{headerText}</div>

      <div className="d-flex justify-content-center pt-4">
        <ReactSpeedometer
          maxValue={0}
          minValue={30}
          value={speed}
          needleColor="black"
          startColor="#8E1C15"
          segments={10}
          endColor="#267332"
          textColor={"Red"}
          height={200}
          width={260}
          maxSegmentLabels={"sec"}
        />
      </div>
    </div>
  );
};

export default SemiDonutChart;
