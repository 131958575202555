import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useReducer,
  useCallback,
} from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { zeroPad } from "react-countdown";
import appConfig from "../../appconfig.json";
import { newSaleActions } from "../store/newSaleStore";
import { customerActions } from "../store/customerStore";
import SodexoImage from "../../assets/sodexo_logo.png";
import UpiImage from "../../assets/upi_logo.png";
import DebitCredit from "../../assets/cards_logo.png";
import ModalCancelAlert from "../Modal/ModalCancelPayment";
import ModalQwikCilver from "../Modal/ModalQwikCilver";
import NewSaleYesNoModal from "./NewSaleYesNoModal";
import QwikCilverLogo from "../../assets/qwikcilver_logo.png";
import VouchagramLogo from "../../assets/gyftr_logo.png";
import PaytmLogo from "../../assets/paytm_upi_logo.png";
import moreWallet from "../../assets/morewallet_logo.png";
import MorePointsLogo from "../../assets/more-points-icon.png";
import ModalVouchagram from "../Modal/ModalVouchagram";
import ModalWallet from "../Modal/ModalWallet";
import { TbTruckDelivery } from "react-icons/tb";
import { BsCreditCardFill } from "react-icons/bs";
import { BiQrScan } from "react-icons/bi";
import axios from "axios";
import { moreRewardsActions } from "../store/MoreRewards/MoreRewardsStore";
import ModalScanBarcode from "../Modal/ModalScanBarcode";
import { useCommonSelectors } from "../../Common/selector";

const NewSalePayment = forwardRef((props, keyRef) => {
  const loginDetails = useSelector((state) => state.login.userDetailsData);
  const { loc, pos_id_mpos } = useCommonSelectors();

  const mode_ref = useRef("");
  const [arrCustDetails, setArrCustDetails] = useState(
    props.strCustomerDetails ?? []
  );
  const [isPaymentInProgress, setPaymentInProgress] = useState(false);
  const [paymentOption, setPaymentOption] = useState("upi");
  // const [paymentBalance, setPaymentBalance] = useState(0);

  const [isWalletOTPInProgress, setIsWalletOTPInProgress] = useState(false);
  const [walletOTPCode, setWalletOTPCode] = useState("");
  const [walletOTPExpiryTime, setWalletOTPExpiryTime] = useState(0);
  const [walletOTPExpiryStatus, setWalletOTPStatus] = useState(""); // Sent, Valid, Invalid, Success
  const [morePointsOtpModal, setMorePointsOptModal] = useState(false); //as the state name suggests
  const [morePointsLoader, setMorePointsLoader] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [isWalletBalanceInProgress, setWalletBalanceInProgress] =
    useState(false);
  const [walletOtpSubmitLoader, setWalletOtpSubmitLoader] = useState(false);
  const [cancelAlertShow, setCancelAlertShow] = useReducer((state) => {
    return !state;
  }, false);

  const [cancelPayment, setCancelPayment] = useReducer((state) => {
    return !state;
  }, false);

  let cardOption = "";

  const [qwikCardValue, setqwikCardValue] = useState({
    CardNumber: "",
    walletBalance: "",
    CardPin: "",
    Amount: props.strItemsBillBalanceAmount,
    pay: "",
  });

  //Vouchagram
  const [VouchagramPay, setVouchagrampay] = useState(
    props.strItemsBillBalanceAmount || ""
  );
  const [vouchagram, setVouchagram] = useState({
    mobileNumber: props.strCustomerDetails.customer_number || "",
    voucherNumber: "",
    otpValue: "",
    totalAmount: props.strItemsBillBalanceAmount,
    tendAmount: props.strItemsBillBalanceAmount || "",
  });

  const [OtpSent, setOtpSent] = useState(false); //To show timer for 15 min
  const intervalRefVouchagram = useRef(null); //to clear the interval

  //Error in OTP vochagram
  const [otpErrorVouchagram, setOtpErrorVouchagram] = useState(false);

  const [loadingVoucha, setLoadingVoucha] = useState(false);

  //For More points barcode modal
  const [openMorePointsModal, setOpenMorePointsModal] = useState(false);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (props.strNewSaleCurrentStatus === "newSalePayment") {
        if (
          isWalletBalanceInProgress ||
          isWalletOTPInProgress ||
          isPaymentInProgress
        ) {
          return "Are you sure, you want to close?";
        }
      }

      if (
        isWalletBalanceInProgress ||
        isWalletOTPInProgress ||
        isPaymentInProgress
      ) {
        window.addEventListener("keydown", (e) => {
          if (
            ((e.ctrlKey === true || e.metaKey === true) &&
              (e.key === "r" || e.key === "R")) ||
            e.code === "F5" ||
            (e.which || e.keyCode) === 116 ||
            (e.which || e.keyCode) === 82
          ) {
            e.preventDefault();
            toast.error(
              "Transaction is in progress, please do not press F5 or do not click Back",
              {
                toastId: "refreshAlert",
                autoClose: 2500,
              }
            );
          }
        });
      }
    }
  });

  const [cashOption, setCashOption] = useState("CASH");

  const valueRef = useRef();
  const billBalanceAmountRef = useRef();
  const fieldCashRef = useRef(null);
  const fieldUpiRef = useRef(null);
  const fieldCardRef = useRef(null);

  const fieldVoucherRef = useRef(null);
  const slotDeliveryRef = useRef();
  const deliveryModeRef = useRef();

  useEffect(() => {
    valueRef.current = props.strCustomerDetails;
    billBalanceAmountRef.current = props.strItemsBillBalanceAmount;
    slotDeliveryRef.current = props.strSelectedDeliverySlot;
    deliveryModeRef.current = props.strTranType;
    setVouchagrampay(props.strItemsBillBalanceAmount);
  }, [
    props.strItemsBillBalanceAmount,
    props.strCustomerDetails,
    props.strTranType,
    props.strSelectedDeliverySlot,
  ]);

  useEffect(() => {
    if (props.strCardTransactionStatus) {
      setPaymentOption("upi");
      document.getElementById("txtUpiAmount").focus();
      document.getElementById("txtUpiAmount").select();
    } else {
      setPaymentOption("upi");
      document.getElementById("txtUpiAmount").focus();
      document.getElementById("txtUpiAmount").select();
    }
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleBlurEvent = (e) => {
    keyRef.current = e.target;
  };

  useEffect(() => {
    if (!isPaymentInProgress && cancelAlertShow) {
      setCancelAlertShow();
      if (cancelPayment) {
        setCancelPayment();
      }
    }
  }, [isPaymentInProgress]);

  const [ifDQR, setIfDQR] = useState("");
  useEffect(() => {
    if (cancelPayment) {
      cancelPaymentTransaction({
        basket_id: props.strNewSaleItemsBasketId,
        tend_amount: props.strCardTransactionStatus
          ? ifDQR === "DQR"
            ? Number(document.getElementById("txtCardAmount").value)
            : 0
          : Number(document.getElementById("txtCardAmount").value),
        ttype: ifDQR,
        pos_id: props.pos_id.toString(),
      });
    }
  }, [cancelPayment]);

  async function cancelPaymentTransaction(payload) {
    let response = await fetch(appConfig.api_url + "dsCancelEdcTran/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status >= 200 && response.status <= 299) {
      response = await response.json();
      if (response["status"] === "success") {
        toast.success(response["message"], {
          autoClose: 2500,
        });
        setCancelPayment();
        setPaymentInProgress(false);
        props.setCardTransactionStatus(false);
        setIfDQR("");
      } else {
        toast.error(response["message"], {
          autoClose: 2500,
        });
        props.setCardTransactionStatus(
          response.data[0]?.statusCheck ? response.data[0]?.statusCheck : false
        );
      }
    } else {
      console.log("newSalePayment :", response.status, response.statusText);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyboardEventListenerNewSalePayment);
    return () => {
      document.body.removeEventListener(
        "keydown",
        keyboardEventListenerNewSalePayment
      );
    };
  }, []);

  const NewSaleCurrentStatusRef = useRef();

  useEffect(() => {
    NewSaleCurrentStatusRef.current = props.strNewSaleCurrentStatus;
  }, [props.strNewSaleCurrentStatus]);

  const keyboardEventListenerNewSalePayment = useCallback((e) => {
    if (
      NewSaleCurrentStatusRef.current === "newSalePayment" &&
      !props.strIfCashOnDelivery &&
      e.altKey &&
      e.code === "KeyU" &&
      !e.ctrlKey &&
      !e.shiftKey &&
      !e.metaKey
    ) {
      loadPaymentType("upi");
      window.setTimeout(function () {
        document.getElementById("txtUpiAmount").focus();
        document.getElementById("txtUpiAmount").select();
      }, 0);
      return;
    } else if (
      NewSaleCurrentStatusRef.current === "newSalePayment" &&
      !props.strIfCashOnDelivery &&
      props.strCurrModule === "newsale" &&
      e.altKey &&
      e.code === "KeyW" &&
      !e.ctrlKey &&
      !e.shiftKey &&
      !e.metaKey &&
      !props.strCardTransactionStatus
    ) {
      loadPaymentType("card");
      window.setTimeout(function () {
        document.getElementById("txtCardAmount").focus();
        document.getElementById("txtCardAmount").select();
      }, 0);
      return;
    } else if (
      NewSaleCurrentStatusRef.current === "newSalePayment" &&
      !props.strIfCashOnDelivery &&
      e.altKey &&
      e.code === "KeyA" &&
      !e.ctrlKey &&
      !e.shiftKey &&
      !e.metaKey &&
      !props.strCardTransactionStatus
    ) {
      loadPaymentType("cash");
      window.setTimeout(function () {
        document.getElementById("txtCashAmount").focus();
        document.getElementById("txtCashAmount").select();
      }, 0);
      return;
    } else if (
      NewSaleCurrentStatusRef.current === "newSalePayment" &&
      !props.strIfCashOnDelivery &&
      e.altKey &&
      e.code === "KeyV" &&
      !e.ctrlKey &&
      !e.shiftKey &&
      !e.metaKey &&
      !props.strCardTransactionStatus
    ) {
      loadPaymentType("vcoup");
      window.setTimeout(function () {
        document.getElementById("txtVoucherAmount").focus();
        document.getElementById("txtVoucherAmount").select();
      }, 0);
      return;
    } else if (e.keyCode === 116) {
      toast.error(
        "Page Cannot be Refreshed untill the Transaction is completed",
        {
          autoClose: 1500,
        }
      );
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  });

  async function getWalletBalance() {
    try {
      setWalletBalanceInProgress(true);
      let payload = {
        cust_id: props.strCustomerDetails["cust_id"] || "",
        customer_number: props.strCustomerDetails["customer_number"],
        tran_ref_number: "",
      };
      let response = await fetch(appConfig.api_url + "wallet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setWalletBalanceInProgress(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          props.setStrCustomerDetailsData(response["data"][0]);
        } else {
          toast.error(response["message"], {
            autoClose: 2500,
          });
        }
      } else {
        console.log(
          "newSalePayment-getWalletBalance-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSalePayment-getWalletBalance :", err);
    }
  }

  async function getVoucherCardBalance() {
    try {
      setWalletBalanceInProgress(true);
      let payload = {
        cashier_id: props.strUserDetails["empCode"],
        vCard_No: qwikCardValue.CardNumber,
        sub_tran_type: "qwikcilver",
      };

      let response = await fetch(
        appConfig.api_url + "dsGetQwikcilverCardBalance/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      setWalletBalanceInProgress(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();

        if (response["status"] === "success") {
          props.setVoucherCardBalance(response["data"][0].card_balance);
        } else {
          toast.error(response["message"], {
            autoClose: 2500,
          });
        }
      } else {
        console.log(
          "newSalePayment-dsGetQwikcilverCardBalance-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("newSalePayment-dsGetQwikcilverCardBalance :", err);
    }
  }
  // props.strUserDetails.employeeCode
  function frmPaymentCashSubmit(event) {
    event.preventDefault();
  }

  function validateSubmitCashPayment() {
    try {
      let paymentAmount = 0;
      paymentAmount = parseFloat(
        document.getElementById("txtCashAmount").value
      );

      if (paymentAmount > 0) {
        let balAmount = parseFloat(props.strItemsBillBalanceAmount);
        let finalCartAmount = parseFloat(props.strItemsFinalCartValue);

        if (cashOption === "CASH") {
          submitPayment(paymentAmount);
        } else {
          if (paymentAmount > balAmount) {
            toast.error("Enter amount less than Balance Amount.", {
              autoClose: 2500,
            });
          } else {
            var flag = true;
            if (mode_ref.current.value) {
              if (
                typeof mode_ref.current.value === "string" &&
                mode_ref.current.value.trim()
              ) {
                var ref_no = mode_ref.current.value;
                if (cashOption === "AMAZONKIOSK" || cashOption === "RTGS") {
                  var regex = new RegExp(/^[a-z0-9]+$/i);
                  if (!regex.test(ref_no)) {
                    flag = false;
                    toast.error(
                      cashOption === "AMAZONKIOSK"
                        ? "Enter Amazon Employee ID"
                        : "Enter valid " +
                            capitalizeFirstLetter(cashOption) +
                            " No",
                      {
                        autoClose: 2500,
                      }
                    );
                  }
                } else if (cashOption === "CHEQUE") {
                  var regex = new RegExp(/^\+?([0-9]\d*)$/);
                  if (!regex.test(ref_no)) {
                    flag = false;
                    toast.error(
                      "Enter valid " +
                        capitalizeFirstLetter(cashOption) +
                        " No",
                      {
                        autoClose: 2500,
                      }
                    );
                  }
                }
              } else {
                flag = false;
                toast.error(
                  "Enter " + capitalizeFirstLetter(cashOption) + " No",
                  {
                    autoClose: 2500,
                  }
                );
              }
            } else {
              flag = false;
              toast.error(
                "Enter " + capitalizeFirstLetter(cashOption) + " No",
                {
                  autoClose: 2500,
                }
              );
            }
            if (flag) {
              submitPayment(paymentAmount);
            }
          }
        }
      } else {
        toast.error("Enter amount.", {
          autoClose: 2500,
        });
      }
    } catch (err) {
      console.log("newSalePayment-validateSubmitCashPayment", err);
    }
  }
  const handleClickForUpiCardSudexo = (value) => {
    cardOption = value;
    setIfDQR(value);
    validateSubmitCardPayment();
  };
  function validateSubmitCardPayment() {
    //Card and Upi Payments
    props.setCardTransactionStatus(true);
    try {
      let paymentAmount = 0;

      if (cardOption === "UPI" || cardOption === "DQR") {
        paymentAmount = parseFloat(
          document.getElementById("txtUpiAmount").value
        );
      } else {
        paymentAmount = parseFloat(
          document.getElementById("txtCardAmount").value
        );
      }
      if (paymentAmount > 0) {
        let balAmount = parseFloat(props.strItemsBillBalanceAmount);
        let finalCartAmount = parseFloat(props.strItemsFinalCartValue);
        let foodValue = props.strTotalFoodValue;
        if (paymentAmount > balAmount) {
          toast.error("Enter amount less than Balance Amount.", {
            autoClose: 2500,
          });
          props.setCardTransactionStatus(false);
        } else {
          if (
            cardOption === "UPI" ||
            cardOption === "CARD" ||
            cardOption === "SODEXO" ||
            cardOption === "DQR"
          ) {
            if (cardOption === "SODEXO" && paymentAmount > foodValue) {
              toast.error(
                "Payable Amount should be less than the Food Value for SODEXO card Transaction",
                {
                  autoClose: 2000,
                }
              );
              props.setCardTransactionStatus(false);
              return;
            }
            submitPayment(paymentAmount);
          }
        }
      } else {
        toast.error("Enter amount.", {
          autoClose: 2500,
        });
        props.setCardTransactionStatus(false);
      }
    } catch (err) {
      console.log("newSalePayment-validateSubmitCardPayment", err);
    }
  }

  function frmSendOTPSubmit(event) {
    event.preventDefault();
  }

  function validateSendOTP() {
    //Setting wallet in voucher
    if (morePointsOtpModal) {
      sendOTP();
      return;
    }
    try {
      if (valueRef.current.wallet_bal !== null) {
        let walletBalance = parseFloat(valueRef.current.wallet_bal);
        let walletAmount = parseFloat(
          document.getElementById("txtVoucherAmount").value
        );
        if (walletBalance < walletAmount) {
          toast.error("Insufficient Wallet Balance.", {
            autoClose: 2500,
          });
        } else if (walletAmount > 0) {
          sendOTP();
        } else {
          toast.error("Enter amount.", {
            autoClose: 2500,
          });
        }
      } else {
        toast.error("Get Customer Wallet Balance.", {
          autoClose: 2500,
        });
      }
    } catch (err) {
      console.log("newSalePayment-validateSendOTP", err);
    }
  }

  const [otpForWallet, setOtpForWallet] = useState("");
  function validateOTPSubmit() {
    try {
      let walletAmount = parseFloat(
        document.getElementById("txtVoucherAmount").value
      );
      if (openMorePointsModal) {
        submitPayment(walletAmount, "", "", openMorePointsModal);
        return;
      }
      let custOTP =
        otpForWallet || document.getElementById("txtWalletOtp").value;
      if (morePointsOtpModal) {
        if (custOTP === walletOTPCode) {
          setOtpForWallet("");
          submitPayment(walletAmount, "", "", morePointsOtpModal);
          return;
        } else {
          setOtpForWallet("");
          toast.error("Enter valid OTP.", {
            autoClose: 2500,
          });
          return;
        }
      }
      let balAmount = parseFloat(props.strItemsBillBalanceAmount);
      if (walletAmount > balAmount) {
        toast.error("Enter amount less than Balance Amount.", {
          autoClose: 2500,
        });
      } else if (custOTP === walletOTPCode) {
        setOtpForWallet("");
        submitPayment(walletAmount);
      } else {
        toast.error("Enter valid OTP.", {
          autoClose: 2500,
        });
      }
    } catch (err) {
      console.log("newSalePayment-validateOTPSubmit", err);
    }
  }

  const postCouponRedemptionApi = async (billNumber) => {
    if (props.strCustomerDetails.customer_number === "") {
      return;
    }
    const payload = {
      customer_number: props.strCustomerDetails.customer_number,
      bill_no: billNumber,
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "postCouponRedemption/",
        payload
      );
      if (data.status === "success") {
      }
    } catch (error) {
      console.log("newSaleComplete-postCouponRedemption :", error);
    }
  };
  const submitPayment = async (
    amount,
    voucher = false,
    voucherValues,
    morePoints
  ) => {
    try {
      props.setBalanceAmount(Number(0.0));
      setPaymentInProgress(true);
      var paramBasketId = props.strNewSaleItemsBasketId
        ? props.strNewSaleItemsBasketId
        : "";
      var ttype = paymentOption.toUpperCase();
      var cardMode = "";
      var ref_no = "";
      if (paymentOption.toUpperCase() === "CARD") {
        cardMode = cardOption;
      }

      if (paymentOption.toUpperCase() === "CASH") {
        ttype = cashOption;
      }

      if (
        ttype.toUpperCase() === "CHEQUE" ||
        ttype.toUpperCase() === "AMAZONKIOSK" ||
        ttype.toUpperCase() === "RTGS"
      ) {
        ref_no = mode_ref.current.value;
      }
      if (paymentOption.toUpperCase() === "UPI") {
        cardMode = cardOption;
        ttype = "CARD";
      }
      setLoadingVoucha(true);
      let payload = {
        event: "basket_paymentconfirm",
        basket_id: paramBasketId,
        ttype: morePoints
          ? "MOREPOINTS"
          : voucherValues?.ttype === "VOUCHERGRAM"
          ? "VOUCHERGRAM"
          : otpForWallet !== ""
          ? "WALLET"
          : ttype.toUpperCase(),
        card_mode: qwikCardValue.CardNumber
          ? "qwikcilver"
          : voucher
          ? "voucher"
          : cardMode.toUpperCase(),
        tend_amount: props.strCardTransactionStatus ? 0 : amount,
        pay_ref_no: props.strCardTransactionStatus ? "" : ref_no,
        // sub_tran_type: qwikCardValue.CardNumber
        //   ? "QWIKCILVER"
        //   : deliveryModeRef.current,
        sub_tran_type: deliveryModeRef.current,
        slot: slotDeliveryRef.current,
        statusCheck: props.strCardTransactionStatus,
        //Changes for Voucher
        vCard_No: qwikCardValue.CardNumber,
        vCard_Pin: qwikCardValue.CardPin,
        vCard_Amt: qwikCardValue.pay,

        //Vouchagram
        MOBILE: vouchagram.mobileNumber || "",
        AMOUNT: Number(vouchagram.tendAmount) || "",
        OTP: Number(vouchagram.otpValue) || "",
        BILLVALUE: vouchagram.tendAmount || "",
        PORDERID: paramBasketId || "",
        BILLNO: paramBasketId || "",
        loc: loc,
        pos_id: pos_id_mpos,
      };
      let response = await fetch(appConfig.api_url + "postDsBasketSubmit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      setPaymentInProgress(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();

        if (response["status"] === "success") {
          props.setBillSuccessOrBalance(response.message);
          if (response["message"] === "BILLBALANCE") {
            toast.success("Payment Added", {
              autoClose: 2500,
            });

            setLoadingVoucha(false);
            props.setStrItemsBalanceAmount(response["data"][0]["balanceAmt"]);
            props.setStrItemsBillNo(response["data"][0]["billno"]);
            props.setStrItemsListTend(response["data"][0]["tend"]);
            resetWalletOTPControls();

            if (paymentOption === "cash") {
              document.getElementById("txtCashAmount").value =
                response["data"][0]["balanceAmt"];
            } else if (paymentOption === "card") {
              document.getElementById("txtCardAmount").value =
                response["data"][0]["balanceAmt"];
            } else if (paymentOption === "vcoup") {
              document.getElementById("txtVoucherAmount").value =
                response["data"][0]["balanceAmt"];
            } else if (paymentOption === "upi") {
              document.getElementById("txtUpiAmount").value =
                response["data"][0]["balanceAmt"];
            }
            handleCloseVouchagram();
          } else if (response["message"] === "BILLSUCCESS") {
            toast.success("Payment Success", {
              autoClose: 2500,
            });
            props.setStrItemsBalanceAmount(0);
            props.setStrItemsBillTendAmount(0);
            props.setStrItemsBillNo(response["data"][0]["billno"]);
            props.setStrItemsListTend(response["data"][0]["tend"]);
            postCouponRedemptionApi(response["data"][0]["billno"]);
            props.setStrNewSaleCurrentStatus("newSaleComplete");
            props.setBalanceAmount(response.data[0].returnCashAmtToCust);
            resetWalletOTPControls();
            handleCloseVouchagram();
          }
          setWalletOtpModal(false);
          setMorePointsOptModal(false);
          setOpenMorePointsModal(false);
          setMorePointsLoader(false);
          setWalletOTPStatus("");
          setWalletOtpSubmitLoader(false);
          props.setCardTransactionStatus(false);
        } else {
          toast.error(response["message"], { autoClose: 3000 });
          props.setCardTransactionStatus(
            response.data[0]?.statusCheck
              ? response.data[0]?.statusCheck
              : false
          );

          //Vouchagram
          setLoadingVoucha(false);
          // setOtpErrorVouchagram(true);
          clearInterval(intervalRefVouchagram.current);
          setVouchagram({
            ...vouchagram,
            otpValue: "",
            tendAmount: props.strItemsBillBalanceAmount,
          });
          setOtpSent(false);
          setWalletOtpModal(false);
          setMorePointsOptModal(false);
          setOpenMorePointsModal(false);
          setMorePointsLoader(false);
          setWalletOTPStatus("");
          setWalletOtpSubmitLoader(false);
        }
      } else {
        console.log(
          "newSalePayment-submitPayment-api :",
          response.status,
          response.statusText
        );
        setLoadingVoucha(false);
        setWalletOtpModal(false);
        setMorePointsLoader(false);
        setWalletOTPStatus("");
        setWalletOtpSubmitLoader(false);
      }
    } catch (err) {
      console.log("newSalePayment-submitPayment", err);
      setLoadingVoucha(false);
      setWalletOtpModal(false);
      setMorePointsLoader(false);
      setWalletOTPStatus("");
      setWalletOtpSubmitLoader(false);
    }
  };

  const sendOTP = async () => {
    try {
      setIsWalletOTPInProgress(true);
      let payload = {
        otp_for: "WALLETPAY",
        customer_number: props.strCustomerDetails["customer_number"],
        message: "OTP_2",
        pos_id: props.pos_id.toString(),
      };
      let response = await fetch(appConfig.api_url + "send_otp/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      setIsWalletOTPInProgress(false);
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          setWalletOTPCode(response["data"][0]["otp"]);
          setWalletOTPExpiryTime(
            parseInt(response["data"][0]["otpExpInSecs"]) * 1000
          );
          setWalletOTPStatus("sent");
          window.setTimeout(function () {
            document.getElementById("txtWalletOtp").focus();
          }, 0);

          toast.success("OTP Sent Successfully.", {
            autoClose: 2500,
          });
        } else {
          setWalletOTPStatus("");
          toast.error(response["message"], { autoClose: 2500 });
        }
      } else {
        setWalletOTPStatus("");
        console.log(
          "newSalePayment-sendOTP-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      setWalletOTPStatus("");
      console.log("newSalePayment-sendOTP :", err);
    }
  };

  const otpRenderer = ({ minutes, seconds }) => (
    <span className="message">
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  const otpOnComplete = () => {
    resetWalletOTPControls();
    toast.error("OTP Expired. Generate new OTP", {
      autoClose: 2500,
    });
  };

  function loadPaymentType(module) {
    try {
      if (module === "cash") {
        if (paymentOption !== "cash") {
          fieldCashRef.current.value =
            billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
          window.setTimeout(function () {
            document.getElementById("txtCashAmount").focus();
            document.getElementById("txtCashAmount").select();
          }, 0);
        }
        setPaymentOption("cash");
        fieldCardRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldVoucherRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldUpiRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        resetWalletOTPControls();
      } else if (module === "card") {
        if (paymentOption !== "card") {
          fieldCardRef.current.value =
            billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
          window.setTimeout(function () {
            document.getElementById("txtCardAmount").focus();
            document.getElementById("txtCardAmount").select();
          }, 0);
        }
        setPaymentOption("card");
        fieldCashRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldVoucherRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldUpiRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        resetWalletOTPControls();
      } else if (module === "vcoup") {
        if (paymentOption !== "vcoup") {
          fieldVoucherRef.current.value =
            billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
          window.setTimeout(function () {
            document.getElementById("txtVoucherAmount").focus();
            document.getElementById("txtVoucherAmount").select();
          }, 0);
        }
        setPaymentOption("vcoup");
        fieldCashRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldCardRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldUpiRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
      } else if (module === "upi") {
        if (paymentOption !== "upi") {
          fieldUpiRef.current.value =
            billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
          window.setTimeout(function () {
            document.getElementById("txtUpiAmount").focus();
            document.getElementById("txtUpiAmount").select();
          }, 0);
        }
        setPaymentOption("upi");
        fieldCardRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        fieldVoucherRef.current.value =
          billBalanceAmountRef.current || props.strItemsBillBalanceAmount;
        resetWalletOTPControls();
      }
    } catch (err) {
      console.log("newSalePayment-loadPaymentType", err);
    }
  }

  function resetWalletOTPControls() {
    setWalletOTPCode("");
    setWalletOTPExpiryTime("");
    setWalletOTPStatus("");
    setWalletOtpSubmitLoader(false);
  }

  //QwikCilver

  const [showQwik, setShowQwik] = useState(false);

  //Vouchagram
  const [showVouch, setShowVouch] = useState(false);

  const handleCloseQwikCilver = () => {
    setShowQwik(false);
    qwikCardValue.CardNumber = "";
    qwikCardValue.CardPin = "";
    qwikCardValue.pay = "";
    props.setVoucherCardBalance("");
  };

  const handleCloseVouchagram = () => {
    setVouchagram({
      ...vouchagram,
      mobileNumber: props.strCustomerDetails.customer_number || "",
      voucherNumber: "",
      otpValue: "",
      totalAmount: props.strItemsBillBalanceAmount,
      tendAmount: props.strItemsBillBalanceAmount,
    });
    setOtpErrorVouchagram(false);
    setOtpSent(false);
    setShowVouch(false);
  };

  const handleShow = (value) => {
    if (value === "qwik") {
      setShowQwik(true);
    } else {
      setShowVouch(true);
    }
  };

  //This Values are for confirming the payment if completed
  const [paymentOpenOrClose, setPaymentopenOrClose] = useState(false);

  const handlePaymentOpenOrClose = () => {
    setPaymentopenOrClose(!paymentOpenOrClose);
  };

  //Total Tend Amount Values
  let totalSpentValue = 0;
  props.strNewSaleTendList.map((tend, index) => {
    totalSpentValue += Number(tend.tend_amount);
  });

  const SMorHM = props.strUserDetails.loc_type;

  useEffect(() => {
    if (props.strIfCashOnDelivery) {
      setPaymentOption("COD");
    }
  }, [props.strIfCashOnDelivery]);

  //For wallet Modal
  const [walletOtpModal, setWalletOtpModal] = useState(false);

  //For More Reward Points Balance

  const [morePtsBlLoading, setMorePtsBlLoading] = useState(false);
  const morePointsGetBalance = async () => {
    setMorePtsBlLoading(true);
    const payload = {
      customer_number: props.strCustomerDetails.customer_number,
      bill_value: props.strItemsFinalCartValue,
    };
    try {
      const { data } = await axios.post(
        appConfig.api_url + "getCustMorePoints",
        payload
      );
      if (data.status === "success") {
        setMorePtsBlLoading(false);
        if (data.data[0].balance_points === 0) {
          toast.error("Customer doesnot have morePoints", {
            autoClose: 1500,
          });
          props.setMorePointsBalance(0);
          props.setMorePointsUsableBalance(0);
          return;
        }
        props.setMorePointsBalance(data.data[0].balance_points);
        props.setMorePointsUsableBalance(data.data[0].usage_points);
      } else {
        toast.error("Cannot get Usable Point Balance", {
          autoClose: 1500,
        });
        setMorePtsBlLoading(false);
      }
    } catch (error) {
      setMorePtsBlLoading(false);
      console.log("newSalePayment-getCustMorePoints", error);
    }
  };

  //For More Points barcode modal omni check
  const omni = useSelector((state) => state.login.userDetailsData.omni);
  const redemption_auth_type =
    useSelector((state) => state.login.userDetailsData.redemption_auth_type) ||
    "";
  let checking = props.strNewSaleTendList.some((e) => {
    return e.ttype === "POINTS";
  });

  // useffect section

  useEffect(() => {
    if (props.strCustomerDetails["wallet_bal"] !== null) {
      getWalletBalance();
    }
    if (props.strMorePointsBalance?.usage_points !== 0) {
      morePointsGetBalance();
    }
  }, [props.strCustomerDetails["wallet_bal"]]);

  return (
    <>
      {paymentOpenOrClose && (
        <NewSaleYesNoModal
          heading="Payment"
          handleClose={handlePaymentOpenOrClose}
          openOrClose={paymentOpenOrClose}
          mainFunction={validateSubmitCashPayment}
          cashOption={cashOption || ""}
        />
      )}
      <div className="col-md-9 newSalePaymentCard">
        <div className="row">
          <div className="col-6">
            <span style={{ fontSize: "15px" }}>
              <div className="capsule">
                Wallet Balance{" "}
                <strong>
                  ( ₹ ) :{" "}
                  {props.strCustomerDetails["wallet_bal"] !== null ? (
                    <span>{props.strCustomerDetails["wallet_bal"]}</span>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary btnGetWalletBalance"
                      disabled={isWalletBalanceInProgress ? "disabled" : ""}
                      onClick={getWalletBalance}
                    >
                      {isWalletBalanceInProgress ? (
                        <span
                          className="spinner-border spinner-border-sm spinner-white"
                          style={{ color: "#ffffff" }}
                          role="status"
                        ></span>
                      ) : (
                        ""
                      )}{" "}
                      Get Wallet Balance
                    </button>
                  )}
                </strong>
              </div>
            </span>
          </div>

          {isWalletBalanceInProgress ||
          isWalletOTPInProgress ||
          isPaymentInProgress ||
          morePtsBlLoading ||
          morePointsLoader ? (
            <div
              className=" overlay  align-items-center justify-content-center"
              style={{ zIndex: "1035" }}
            >
              <div
                style={{
                  position: "fixed",
                  top: "35%",
                  left: "50%",
                  width: "30em",
                  height: "18em",
                  marginLeft: "-15em",
                }}
              >
                <div style={{ display: "inline-flex" }}>
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                  <div
                    style={{
                      color: "#ffffff",
                      fontSize: "18px",
                      placeSelf: "center",
                      marginLeft: "1.6rem",
                    }}
                  >
                    Transaction is in progress, please do not press F5 or do not
                    click Back or close the Browser
                  </div>
                </div>
                {(props.strCancelAllowed === "Y" || ifDQR === "DQR") &&
                (paymentOption === "card" ||
                  (paymentOption === "upi" && ifDQR === "DQR")) ? (
                  <div style={{ marginLeft: "12px" }}>
                    <button
                      style={{
                        background: "#f04e23",
                        borderRadius: "14px",
                        color: "#fff",
                        padding: "2px 12px",
                        border: "1px solid #f04e23",
                        fontSize: "14px",
                      }}
                      onClick={() => setCancelAlertShow()}
                    >
                      CANCEL TXN
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <ModalCancelAlert
            button_clicked={setCancelAlertShow}
            show={cancelAlertShow}
            cancelConfirm={setCancelPayment}
          />

          <div className="col-6 morePointsGetBalanceCard">
            <div className="capsule d-flex gap-1">
              <div className="w-lg-100">More Points (Usable) : </div>
              {props.strMorePointsBalance?.balance_points !== 0 ? (
                <div className="morePointsBalanceCard">
                  <div> {` ${props.strMorePointsBalance?.usage_points}`}</div>
                  <div
                    style={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      borderRadius: "8px",
                      backgroundColor: "#e6b1bb",
                    }}
                  >
                    Total : {props.strMorePointsBalance?.balance_points}
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btnGetWalletBalance"
                  onClick={() => {
                    morePointsGetBalance();
                  }}
                >
                  {morePtsBlLoading ? (
                    <span
                      className="spinner-border spinner-border-sm spinner-white"
                      style={{ color: "#ffffff" }}
                      role="status"
                    ></span>
                  ) : (
                    ""
                  )}
                  Get Balance
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          {/* UPI CARD Start  */}
          <div className="col-3 upiCards">
            <div
              className={
                "card " + (paymentOption === "upi" ? "cardactive" : "")
              }
              onClick={() =>
                !props.strIfCashOnDelivery ? loadPaymentType("upi") : ""
              }
            >
              <span className="shortcutkeytitle">ALT + U</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <span
                    className="title"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    UPI
                  </span>
                  <i className="fa fa-3x ms-1">
                    <BiQrScan />
                  </i>
                  <div className="input-group input-group-sm ">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      ₹
                    </span>
                    <input
                      ref={fieldUpiRef}
                      type="text"
                      id="txtUpiAmount"
                      name="txtUpiAmount"
                      className="form-control text-end"
                      aria-label="Sizing example input"
                      defaultValue={
                        billBalanceAmountRef.current ||
                        props.strItemsBillBalanceAmount
                      }
                      onChange={(event) => {
                        var value = event.target.value;
                        value = value.replace(/[^0-9. ]/g, "");
                        document.getElementById(event.target.id).value = value;

                        let newValue = value;
                        if (
                          totalSpentValue + Number(newValue) >
                          Number(props.strItemsFinalCartValue)
                        ) {
                          toast.error(
                            "Amount should be less then Final Cart Amout",
                            {
                              autoClose: 1500,
                            }
                          );
                          document.getElementById(event.target.id).value =
                            billBalanceAmountRef.current ||
                            props.strItemsBillBalanceAmount;
                          return;
                        }
                      }}
                      disabled={paymentOption === "upi" ? "" : true}
                      autoComplete="off"
                    />
                  </div>
                  {paymentOption === "upi" ? (
                    <div
                      style={{
                        marginTop: "12px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <button
                        className="btn d-flex justify-content-center align-items-center bg-white w-100"
                        onClick={() => {
                          handleClickForUpiCardSudexo("UPI");
                        }}
                      >
                        <img
                          src={UpiImage}
                          alt="Upi Logo"
                          style={{
                            height: "auto",
                            width: "100px",
                          }}
                        />
                      </button>

                      {(props.strDqrPortValue !== "select" ||
                        props.strDqrPortValue !== "") && (
                        <button
                          className="btn d-flex justify-content-center align-items-center bg-white w-100"
                          onClick={() => {
                            handleClickForUpiCardSudexo("DQR");
                          }}
                        >
                          <img
                            src={PaytmLogo}
                            alt="Paytm DQR"
                            style={{
                              height: "auto",
                              width: "100px",
                            }}
                          />
                        </button>
                      )}
                      {props.strCardTransactionStatus ? (
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-12">
                            <div className="form-text txnMessage text-black d-flex">
                              <i className="getPricebull">
                                <span
                                  className="fa fa-bullhorn"
                                  style={{ color: "#f04e23" }}
                                ></span>
                              </i>
                              &nbsp;&nbsp;
                              <p
                                className="txnMessage mr-0"
                                style={{ marginBottom: "0px" }}
                              >
                                ( Previous transaction is in progress)
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Debit Credit Card Start  */}
          <div className="col-3 debitCards">
            <div
              className={
                "card " +
                (paymentOption === "card"
                  ? props.strCardTransactionStatus
                    ? ""
                    : "cardactive"
                  : "")
              }
              onClick={() =>
                !props.strIfCashOnDelivery
                  ? props.strCardTransactionStatus
                    ? ""
                    : loadPaymentType("card")
                  : ""
              }
            >
              <span className="shortcutkeytitle">ALT + W</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <form onSubmit={frmSendOTPSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <span
                          className="title"
                          style={{
                            fontSize: "1.1rem",
                          }}
                        >
                          CARDS
                        </span>
                        <i className="fa fa-3x ms-1">
                          <BsCreditCardFill />
                        </i>

                        <div className="input-group input-group-sm">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-sm"
                          >
                            ₹
                          </span>

                          <input
                            ref={fieldCardRef}
                            type="text"
                            id="txtCardAmount"
                            name="txtCardAmount"
                            className="form-control text-end "
                            defaultValue={
                              billBalanceAmountRef.current ||
                              props.strItemsBillBalanceAmount
                            }
                            onBlur={handleBlurEvent}
                            onChange={(event) => {
                              var value = event.target.value;
                              value = value.replace(/[^0-9. ]/g, "");
                              document.getElementById(event.target.id).value =
                                value;
                              let newValue = value;
                              if (
                                totalSpentValue + Number(newValue) >
                                Number(props.strItemsFinalCartValue)
                              ) {
                                toast.error(
                                  "Amount should be less then Final Cart Amout",
                                  {
                                    autoClose: 1500,
                                  }
                                );
                                document.getElementById(event.target.id).value =
                                  billBalanceAmountRef.current ||
                                  props.strItemsBillBalanceAmount;
                                return;
                              }
                            }}
                            disabled={
                              paymentOption !== "card" ||
                              walletOTPExpiryStatus === "sent"
                                ? true
                                : false
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    {paymentOption === "card" && (
                      <div
                        style={{
                          marginTop: "12px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <button
                          onClick={() => {
                            handleClickForUpiCardSudexo("CARD");
                          }}
                          className="btn d-flex justify-content-center align-items-center bg-white w-100"
                        >
                          <img
                            src={DebitCredit}
                            alt="DebitCards Logo"
                            style={{
                              height: "auto",
                              width: "100px",
                            }}
                          />
                        </button>

                        <button
                          className="btn d-flex justify-content-center align-items-center bg-white w-100"
                          onClick={() => {
                            handleClickForUpiCardSudexo("SODEXO");
                          }}
                        >
                          <img
                            src={SodexoImage}
                            alt="Sodexo Logo"
                            style={{
                              height: "auto",
                              width: "100px",
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* CASH Card Start  */}
          <div className="col-3">
            <div
              className={
                "card " +
                (paymentOption === "cash"
                  ? props.strCardTransactionStatus
                    ? ""
                    : "cardactive"
                  : "")
              }
              onClick={() =>
                !props.strIfCashOnDelivery
                  ? props.strCardTransactionStatus
                    ? ""
                    : loadPaymentType("cash")
                  : ""
              }
            >
              <span className="shortcutkeytitle">ALT + A</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <form onSubmit={frmPaymentCashSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <span
                          className="title"
                          style={{
                            fontSize: "1.1rem",
                          }}
                        >
                          CASH
                        </span>
                        <i
                          className="fa fa-money-bill fa-3x ms-1"
                          style={{
                            marginTop: "0.2rem",
                          }}
                        ></i>

                        <div className="input-group input-group-sm">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-sm"
                          >
                            ₹
                          </span>
                          <input
                            ref={fieldCashRef}
                            type="text"
                            id="txtCashAmount"
                            name="txtCashAmount"
                            className="form-control text-end"
                            defaultValue={
                              billBalanceAmountRef.current ||
                              props.strItemsBillBalanceAmount
                            }
                            tabIndex={1}
                            onBlur={handleBlurEvent}
                            onChange={(event) => {
                              if (paymentOption === "cash") {
                                var value = event.target.value;
                                value = value.replace(/[^0-9. ]/g, "");
                                document.getElementById(event.target.id).value =
                                  value;
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.target.value.length >= 1) {
                                if (event.key === "Enter") {
                                  handlePaymentOpenOrClose();
                                }
                              }
                            }}
                            disabled={
                              paymentOption === "cash" ? "" : "disabled"
                            }
                            autoComplete="off"
                          />
                        </div>

                        {paymentOption === "cash" ? (
                          <>
                            <div
                              className="input-group input-group-sm"
                              style={{
                                marginTop: "5px",
                              }}
                            >
                              <select
                                tabIndex={1}
                                className="form-control "
                                name="cashModeType"
                                id="cashModeType"
                                aria-label="Sizing example input"
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    document
                                      .getElementById("txtCashRef")
                                      .focus();
                                    document.getElementById(
                                      "txtCashRef"
                                    ).value = "";
                                  }
                                }}
                                onSelect={(event) => {
                                  setCashOption(event.target.value);
                                }}
                                onChange={(event) => {
                                  setCashOption(event.target.value);
                                  if (cashOption !== "CASH") {
                                    document
                                      .getElementById("txtCashRef")
                                      .focus();
                                    document.getElementById(
                                      "txtCashRef"
                                    ).value = "";
                                  }
                                }}
                                defaultValue={cashOption || "CASH"}
                                disabled={
                                  paymentOption === "cash" ? "" : "disabled"
                                }
                              >
                                <option value={"CASH"}>Cash</option>
                                {SMorHM === "HM" ? (
                                  <>
                                    <option value={"CHEQUE"}>Cheque</option>
                                    <option value={"RTGS"}>RTGS</option>
                                  </>
                                ) : (
                                  ""
                                )}

                                <option value={"AMAZONKIOSK"}>
                                  Amazon Kiosk
                                </option>
                              </select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {cashOption === "CASH" ? (
                          <></>
                        ) : paymentOption === "cash" ? (
                          <>
                            <div
                              className="input-group input-group-sm"
                              style={{
                                marginTop: "5px",
                              }}
                            >
                              <input
                                ref={mode_ref}
                                onBlur={handleBlurEvent}
                                type="text"
                                id="txtCashRef"
                                name="txtCashRef"
                                className="form-control "
                                aria-label="Sizing example input"
                                tabIndex={1}
                                disabled={
                                  paymentOption === "cash" ? "" : "disabled"
                                }
                                pattern={
                                  cashOption === "CHEQUE"
                                    ? "[0-9]+"
                                    : cashOption === "RTGS"
                                    ? "[a-zA-Z0-9]+"
                                    : cashOption === "AMAZONKIOSK"
                                    ? "[a-zA-Z0-9]+"
                                    : ""
                                }
                                placeholder={
                                  cashOption === "CHEQUE"
                                    ? "Enter Cheque No"
                                    : cashOption === "RTGS"
                                    ? "Enter RTGS No"
                                    : cashOption === "AMAZONKIOSK"
                                    ? "Enter Amazon Employee ID"
                                    : ""
                                }
                                autoComplete="on"
                                required
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {paymentOption === "cash" ? (
                      <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={isPaymentInProgress ? "disabled" : ""}
                            onClick={() => {
                              handlePaymentOpenOrClose();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Qwikcilver, Vouchagram, & Wallet Card Start  */}
          <div className="col-3 walletCards">
            <div
              className={
                "card " +
                (paymentOption === "vcoup"
                  ? props.strCardTransactionStatus
                    ? ""
                    : "cardactive"
                  : "")
              }
              onClick={() =>
                !props.strIfCashOnDelivery
                  ? props.strCardTransactionStatus
                    ? ""
                    : loadPaymentType("vcoup")
                  : ""
              }
            >
              <span className="shortcutkeytitle">ALT + V</span>
              <div className="card-body text-center">
                <div className="card-text">
                  <span
                    className="title"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    WALLETS
                  </span>
                  <i
                    className="fa fa-cube fa-3x ms-1"
                    style={{
                      marginTop: "0.2rem",
                    }}
                  ></i>
                  <div className="input-group input-group-sm">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      ₹
                    </span>
                    <input
                      ref={fieldVoucherRef}
                      type="text"
                      id="txtVoucherAmount"
                      name="txtVoucherAmount"
                      className="form-control text-end"
                      aria-label="Sizing example input"
                      defaultValue={
                        billBalanceAmountRef.current ||
                        props.strItemsBillBalanceAmount
                      }
                      onBlur={handleBlurEvent}
                      onChange={(event) => {
                        var value = event.target.value;
                        value = value.replace(/[^0-9. ]/g, "");
                        document.getElementById(event.target.id).value = value;
                        let newValue = value;
                        if (
                          totalSpentValue + Number(newValue) >
                          Number(props.strItemsFinalCartValue)
                        ) {
                          toast.error(
                            "Amount should be less then Final Cart Amout",
                            {
                              autoClose: 1500,
                            }
                          );
                          document.getElementById(event.target.id).value =
                            billBalanceAmountRef.current ||
                            props.strItemsBillBalanceAmount;
                          return;
                        }
                        setVouchagrampay(value);
                      }}
                      disabled={paymentOption === "vcoup" ? "" : "disabled"}
                      autoComplete="off"
                    />
                  </div>
                  {paymentOption === "vcoup" ? (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <button
                        className="btn d-flex justify-content-center align-items-center bg-white"
                        onClick={() => {
                          if (valueRef.current.wallet_bal === null) {
                            toast.error("Get Customer Wallet Balance.", {
                              autoClose: 2500,
                            });
                            return;
                          }
                          setWalletOtpModal(true);
                        }}
                      >
                        <img
                          style={{
                            width: "100px",
                            height: "auto",
                          }}
                          src={moreWallet}
                          alt="moreWalletLogo"
                        />
                      </button>
                      <button
                        className="w-100 btn d-flex justify-content-center align-items-center bg-white"
                        onClick={() => {
                          handleShow("qwik");
                        }}
                      >
                        <img
                          src={QwikCilverLogo}
                          alt="qwikCilverLogo"
                          style={{
                            width: "100px",
                            height: "auto",
                          }}
                        />
                      </button>
                      <button
                        className="w-100 btn d-flex justify-content-center align-items-center bg-white"
                        onClick={() => {
                          handleShow("vouch");
                        }}
                      >
                        <img
                          src={VouchagramLogo}
                          alt="VouchagramLogo"
                          style={{
                            width: "100px",
                            height: "auto",
                          }}
                        />
                      </button>
                      <button
                        className="w-100 btn d-flex justify-content-center align-items-center bg-white"
                        disabled={checking}
                        onClick={() => {
                          if (
                            props.strMorePointsBalance?.balance_points === 0
                          ) {
                            toast.error(
                              "Please get the More Point balance to proceed"
                            );
                          } else if (
                            Number(fieldVoucherRef.current.value) >
                            props.strMorePointsBalance?.usage_points
                          ) {
                            toast.error(
                              "Enter a value less than usable points"
                            );
                          } else {
                            if (redemption_auth_type === "barcode") {
                              setOpenMorePointsModal(true);
                            } else {
                              setMorePointsOptModal(true);
                            }
                          }
                        }}
                      >
                        <img
                          src={MorePointsLogo}
                          alt="MoreRewardPoints Logo"
                          style={{
                            width: "100px",
                            height: "auto",
                          }}
                        />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Qwickcilver  */}
        <div>
          {showQwik ? (
            <ModalQwikCilver
              modalOpenClose={showQwik}
              handleClose={handleCloseQwikCilver}
              props={props}
              qwikCardValue={qwikCardValue}
              qwickCardHandleValueChange={setqwikCardValue}
              handleCardSubmit={submitPayment}
              getQwickCardBalance={getVoucherCardBalance}
            />
          ) : (
            ""
          )}
        </div>
        {/* Modal for Vouchagram  */}

        <div>
          {showVouch ? (
            <ModalVouchagram
              loadingVoucha={loadingVoucha}
              setLoadingVoucha={setLoadingVoucha}
              modalOpenClose={showVouch}
              handleClose={handleCloseVouchagram}
              props={props}
              handleCardSubmit={submitPayment}
              vouchaPay={VouchagramPay}
              otpError={otpErrorVouchagram}
              setOtpError={setOtpErrorVouchagram}
              vouchagram={vouchagram}
              setVouchagram={setVouchagram}
              OtpSent={OtpSent}
              setOtpSent={setOtpSent}
              intervalRefVouchagram={intervalRefVouchagram}
            />
          ) : (
            ""
          )}
        </div>
        {/* Modal for Wallet  */}
        <div>
          {(walletOtpModal || morePointsOtpModal) && (
            <ModalWallet
              openModal={walletOtpModal}
              closeModal={setWalletOtpModal}
              validateSendOTP={validateSendOTP}
              isWalletOTPInProgress={isWalletOTPInProgress}
              walletOTPExpiryStatus={walletOTPExpiryStatus}
              setWalletOTPStatus={setWalletOTPStatus}
              otpForWallet={otpForWallet}
              setOtpForWallet={setOtpForWallet}
              validateOTPSubmit={validateOTPSubmit}
              walletOTPExpiryTime={walletOTPExpiryTime}
              otpRenderer={otpRenderer}
              otpOnComplete={otpOnComplete}
              isPaymentInProgress={isPaymentInProgress}
              mobileNumber={props.strCustomerDetails.customer_number}
              morePointsModal={morePointsOtpModal}
              setMorePointsOptModal={setMorePointsOptModal}
              setWalletOtpSubmitLoader={setWalletOtpSubmitLoader}
              walletOtpSubmitLoader={walletOtpSubmitLoader}
            />
          )}
          {/* Modal for More Points  */}

          {openMorePointsModal && (
            <ModalScanBarcode
              modalOpenClose={openMorePointsModal}
              handleClose={setOpenMorePointsModal}
              customerNumber={props.strCustomerDetails.customer_number}
              functionCall={validateOTPSubmit}
              loader={morePointsLoader}
              setLoader={setMorePointsLoader}
              paymentModal={true}
            />
          )}
        </div>
        {props.strIfCashOnDelivery && (
          <div className="col-3 mt-2">
            <div
              className={
                "card " + (paymentOption === "COD" ? "cardactive" : "")
              }
            >
              <div className="card-body text-center">
                <div className="card-text">
                  <i className="fa nav-icon">
                    <TbTruckDelivery fontSize={40} />
                  </i>
                  <span className="title">CASH ON DELIVERY</span>
                  {paymentOption === "COD" ? (
                    <>
                      <div>
                        <button
                          disabled={
                            props.strBillBalanceOrSuccess === "BILLBALANCE"
                          }
                          className="w-100 btn btn-primary"
                          onClick={() => {
                            submitPayment(props.strItemsBillBalanceAmount);
                          }}
                        >
                          CONFIRM
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-4" style={{ paddingTop: "20px" }}>
            {props.strNewSaleTendList.length > 0 ? (
              <table className="table table-bordered table-sm">
                <thead className="table-dark">
                  <tr>
                    <th colSpan={2} className="title text-center">
                      Payment Summary
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.strNewSaleTendList.map((tend, index) => {
                    return (
                      <tr key={tend.ttype} style={{ fontSize: "1.1rem" }}>
                        <td>{tend.ttype} ( ₹ )</td>
                        <td className="text-end">{tend.tend_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
});

const mapStateToProps = (reduxState, oProps) => {
  return {
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strItemsFinalCartValue: reduxState?.newSale?.itemsFinalCartValue,
    strItemsBillBalanceAmount: reduxState?.newSale?.itemsBillBalanceAmount,
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strNewSaleTendList: reduxState?.newSale?.itemsListTend,
    strCancelAllowed: reduxState?.newSale?.cancelAllowed,
    strSelectedDeliverySlot: reduxState?.newSale?.selectedDeliverySlot,
    strNewSaleCurrentStatus: reduxState?.newSale?.newSaleCurrentStatus,
    strCardTransactionStatus: reduxState?.newSale?.cardTransactionStatus,
    strVoucherCardBalance: reduxState?.newSale?.voucherCardBalance,
    strUserDetails: reduxState?.login?.userDetailsData,
    strTotalFoodValue: reduxState?.newSale?.totalFoodValue,
    strIfCashOnDelivery: reduxState?.newSale?.ifCashOnDelivery,
    pos_id: reduxState?.login?.userDetailsData.pos_id,
    strBillBalanceOrSuccess: reduxState?.newSale?.billSuccessOrBalance,
    strDqrPortValue: reduxState?.common.dqrPortValue,
    strCurrModule: reduxState?.common.currModule,
    strMorePointsBalance: reduxState?.moreRewards.morePointsBalance,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrNewSaleCurrentStatus: (newSaleCurrStatus) =>
      dispatch(newSaleActions.setNewSaleCurrentStatus(newSaleCurrStatus)),
    setStrItemsBillNo: (billNo) =>
      dispatch(newSaleActions.setItemsBillNo(billNo)),
    setStrItemsBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsBillTendAmount: (tendAmount) =>
      dispatch(newSaleActions.setItemsBillTendAmount(tendAmount)),
    setStrItemsListTend: (tend) =>
      dispatch(newSaleActions.setItemsListTend(tend)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
    setBalanceAmount: (balance) =>
      dispatch(newSaleActions.setBalanceAmount(balance)),
    setCardTransactionStatus: (flag) =>
      dispatch(newSaleActions.setCardTransactionStatus(flag)),
    setVoucherCardBalance: (voucherCardBalance) =>
      dispatch(newSaleActions.setVoucherCardBalance(voucherCardBalance)),
    setBillSuccessOrBalance: (billBalanceOrSuccess) =>
      dispatch(newSaleActions.setBillSuccessOrBalance(billBalanceOrSuccess)),
    setMorePointsUsableBalance: (usablePoints) =>
      dispatch(moreRewardsActions.setMorePointsUsableBalance(usablePoints)),
    setMorePointsBalance: (balancePoints) =>
      dispatch(moreRewardsActions.setMorePointsBalance(balancePoints)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(NewSalePayment);
