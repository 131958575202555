import React from "react";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";

const ModalWallet = ({
  openModal,
  closeModal,
  validateSendOTP,
  isWalletOTPInProgress,
  walletOTPExpiryStatus,
  setWalletOTPStatus,
  otpForWallet,
  setOtpForWallet,
  validateOTPSubmit,
  walletOTPExpiryTime,
  otpRenderer,
  otpOnComplete,
  isPaymentInProgress,
  mobileNumber,
  morePointsModal,
  setMorePointsOptModal,
  setWalletOtpSubmitLoader = () => {},
  walletOtpSubmitLoader = false,
}) => {
  return (
    <Modal
      show={openModal || morePointsModal || false}
      className="modal"
      role="document"
      backdrop="static"
      onHide={() => {
        closeModal(false);
        setMorePointsOptModal(false);
        setWalletOTPStatus("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="w-100"
          style={{
            color: "#f04e23",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          OTP FOR {morePointsModal ? "MOREPOINTS" : "WALLET"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="p-.5rem"
        style={{
          fontWeight: "500",
        }}
      >
        <div className="row">
          <div className="col-12">
            <input
              type="number"
              name="phoneNumber"
              className="form-control"
              id="phoneNumber"
              value={mobileNumber}
              disabled={true}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-12">
            <div className="input-group">
              <input
                id="txtWalletOtp"
                name="txtWalletOtp"
                type="text"
                maxLength={6}
                className="form-control"
                placeholder="Enter OTP"
                onChange={(event) => {
                  setOtpForWallet(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.target.value.length >= 1) {
                    if (event.key === "Enter") {
                      if (!walletOtpSubmitLoader) {
                        setWalletOtpSubmitLoader(true);
                        validateOTPSubmit();
                      }
                    }
                  }
                }}
                autoComplete="off"
              />
              {walletOTPExpiryStatus !== "sent" ? (
                <button
                  className="btn btn-primary"
                  name="sendOTP"
                  id="send"
                  onClick={() => {
                    validateSendOTP();
                  }}
                  disabled={isWalletOTPInProgress}
                >
                  {isWalletOTPInProgress && (
                    <span
                      className="spinner-border spinner-border-sm spinner-white"
                      style={{ color: "#ffffff" }}
                      role="status"
                    ></span>
                  )}
                  Send OTP
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={isPaymentInProgress}
                  onClick={validateOTPSubmit}
                >
                  {walletOtpSubmitLoader && (
                    <span
                      className="spinner-border spinner-border-sm spinner-white"
                      style={{ color: "#ffffff" }}
                      role="status"
                    ></span>
                  )}
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
        {walletOTPExpiryStatus === "sent" ? (
          <>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-12 text-center">
                <span>
                  OTP Expires in{" "}
                  <Countdown
                    date={Date.now() + walletOTPExpiryTime}
                    renderer={otpRenderer}
                    onComplete={otpOnComplete}
                  />
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalWallet;
