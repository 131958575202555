import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import appConfig from "../../../appconfig.json";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import MorePlusTopUpTransaction from "./MorePlusTopUpTransaction";
import MorePlusOrderSummary from "./MorePlusOrderSummary";
import MorePlusCustomerCheck from "./MorePlusCustomerCheck";
import { toast } from "react-toastify";
import OtpForWalletTopUp from "../OTP/OtpForWalletTopUp";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
const MorePlusRewards = () => {
  const address = appConfig.api_url;
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setmobileNumber] = useState("");

  const cashAmount = useSelector(
    (state) => state.moreRewards.moreRewardsRecharge.cashAmount
  );
  const [inputButtonValues, setInputButtonValiues] = useState(cashAmount || "");

  useEffect(() => {
    setInputButtonValiues(cashAmount);
  }, [cashAmount]);
  const strIsValidCustomer = useSelector(
    (state) => state?.moreRewards?.moreRewardsRecharge?.isValidCustomer
  );

  const strCustomerDetails = useSelector(
    (state) => state.moreRewards?.moreRewardsRecharge?.customerDetailsData
  );

  const buttonDataForWalletTopUp = useSelector(
    (state) => state.moreRewards.moreRewardsRecharge?.differentRechargeValueData
  );
  const dispatch = useDispatch();

  const [getButtonData, setButtonData] = useState(buttonDataForWalletTopUp);

  const sucessMesage = useSelector(
    (state) => state.moreRewards.moreRewardsRecharge?.rechargeSuccess
  );
  const otpStatusForWalletTopUp = useSelector(
    (state) => state.moreRewards.moreRewardsRecharge?.otpStatus
  );

  const [currentMonthCashBack, setCurrentMonthCashback] = useState(0);
  const basketId = useSelector(
    (state) => state.moreRewards?.moreRewardsRecharge?.basketID
  );

  const cashBackWalletTopUp = useSelector(
    (state) => state.moreRewards?.moreRewardsRecharge?.cashbacks
  );

  const [loadingForSummary, setLoadingForSummary] = useState(false);

  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  const [otpStatus, setOtpStatus] = useState(otpStatusForWalletTopUp);

  const handleCustomerOtpStatus = () => {
    setShowCustomerOTPState(!showCustomerOTPState);
  };

  const handleSetOtpStatus = () => {
    dispatch(
      moreRewardsActions.setMrPointsOtpStatusWalletTopUp(
        !otpStatusForWalletTopUp
      )
    );
  };
  const customerMobileNumber = useSelector(
    (state) =>
      state.moreRewards?.moreRewardsRecharge?.customerDetailsData
        ?.customer_number
  );

  useEffect(() => {
    if (!strIsValidCustomer) {
      setInputButtonValiues("");
      setButtonData([]);
      dispatch(
        moreRewardsActions.setMrPointsDifferentRechargeValueDataWalletTopUp([])
      );
    }
  }, [strIsValidCustomer]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
          marginRight: "2rem",
          marginLeft: "-0.8rem",
        }}
      >
        <MorePlusCustomerCheck
          showCustomerName={true}
          setShowCustomerOTPState={setShowCustomerOTPState}
          handleCustomerOtpStatus={handleCustomerOtpStatus}
          showBasketID=""
          showCustomerOTPState={showCustomerOTPState}
          mobileNumber={mobileNumber}
          setmobileNumber={setmobileNumber}
        />

        {otpStatusForWalletTopUp && (
          <div
            style={{
              marginLeft: "-0.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                className="col-md-9"
                style={
                  {
                    // width: "60%",
                  }
                }
              >
                {strCustomerDetails["isCustExisting"] && (
                  <Box className="row" gap={2} ml={1}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{
                          color: "#F04E23",
                        }}
                      >
                        Subscription
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        defaultValue={cashAmount}
                        onChange={(e) => {
                          dispatch(
                            moreRewardsActions.setMrPointsCashAmountForRechargeWalletTopUp(
                              e.target.defaultValue
                            )
                          );
                          dispatch(
                            moreRewardsActions.setMrPointsTotalCartValueWalletTopUp(
                              e.target.defaultValue
                            )
                          );
                          dispatch(
                            moreRewardsActions.setMrPointsCashbacksWalletTopUp(
                              []
                            )
                          );
                          dispatch(
                            moreRewardsActions.setMrPointsRechargeDataWalletTopUp(
                              []
                            )
                          );
                          dispatch(
                            moreRewardsActions.setMrPointsInputFieldEnableWalletTopUp(
                              false
                            )
                          );
                        }}
                      >
                        <FormControlLabel
                          value="199"
                          control={
                            <Radio
                              sx={{
                                color: "#F04E23",
                                "&, &.Mui-checked": {
                                  color: "F04E23",
                                },
                              }}
                            />
                          }
                          label="199"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}

                {otpStatusForWalletTopUp && <MorePlusTopUpTransaction />}
              </div>

              <div
                className="col-md-3"
                style={{
                  marginTop: "-3rem",
                  marginLeft: 10,
                }}
              >
                {otpStatusForWalletTopUp && (
                  <MorePlusOrderSummary
                    cashMoney={cashBackWalletTopUp?.cash}
                    cardMoney={cashBackWalletTopUp?.card}
                    loadingForSummary={loadingForSummary}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <OtpForWalletTopUp
          show={showCustomerOTPState}
          button_clicked={handleCustomerOtpStatus}
          customerNumber={customerMobileNumber || mobileNumber}
          setOtpStatusFunc={handleSetOtpStatus}
        />
      </div>
      {loadingForSummary ? (
        <div
          className=" forWallet  align-items-center justify-content-center"
          style={{ zIndex: "1035" }}
        >
          <div
            style={{
              position: "fixed",
              top: "35%",
              left: "50%",
              width: "30em",
              height: "18em",
              marginLeft: "-15em",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  placeSelf: "center",
                  marginLeft: "1.6rem",
                }}
              >
                Getting Cashback details in Progress
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MorePlusRewards;
