import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { commonActions } from "../store/commonStore";

const ModalCancelAlert = (props) => {
  const handleYes = () => {
    props.cancelConfirm();
    props.button_clicked();
  };

  return (
    <>
      <Modal
        show={props.show | false}
        onHide={() => {
          props.button_clicked();
        }}
        backdrop="static"
        className="modal-confirm cancel-modal"
        style={{ width: "100%" }}
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            Cancel Payment Transaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-evenly",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="col-sm-6 col-lg-6 col-md-6"
                style={{ paddingTop: "20px" }}
              >
                <div className="col-12 ">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleYes()}
                    autoFocus={true}
                  >
                    Yes
                  </button>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-6 col-md-6"
                style={{ paddingTop: "20px" }}
              >
                <div className="col-12 text-left">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.button_clicked()}
                  >
                    No / (ALT + Z)
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strUserDetails: reduxState?.login?.userDetailsData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    strSetIsWelcomeMsgViewed: (isViewed) =>
      dispatch(commonActions.setIsWelcomeMsgViewed(isViewed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCancelAlert);
