import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import KeyboardLayout from "./KeyboardLayout";
import appConfig from "../appconfig.json";
import axios from "axios";
import { toast } from "react-toastify";
import { newSaleActions } from "./store/newSaleStore";
import { customerActions } from "./store/customerStore";
import { commonActions } from "./store/commonStore";
import DataGridOnHold from "../Common/DataGridForOnHold/DataGridOnHold";
import { useCommonSelectors } from "../Common/selector";

const OnHoldLayout = (props) => {
  const { loc, pos_id_mpos } = useCommonSelectors();

  useEffect(() => {
    document.documentElement.requestFullscreen();
  }, []);
  const address = appConfig.api_url;

  const [basketOnHold, setBasketList] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [filterBtn, setFilterBtn] = useState(true);
  const searchTextRef = useRef();

  const processSearchText = (event) => {};

  useEffect(() => {
    getBasketList();
  }, []);

  useEffect(() => {
    if (filterData.length >= 10) {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  }, [filterData]);

  const handleChange = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    value = value.replace(/[^a-z0-9A-Z ]/g, "");
    document.getElementById(event.target.id).value = value;
    setFilterData(value);
  };

  const handleSubmit = (event) => {
    var temp;
    if (
      searchTextRef.current?.value.length == 0 ||
      searchTextRef.current?.value.length == 10
    ) {
      setFilterBtn(false);
      getHoldList({
        searchText: searchTextRef.current?.value,
        loc: loc,
        pos_id: pos_id_mpos,
      });
    } else {
      setFilterBtn(true);
      toast.error("Enter Vaild Customer Number / ID", {
        autoClose: 1000,
      });
    }
  };

  const getCustomerDetails = (payload) => {
    try {
      axios.post(address + "getDsCustomer/", payload).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.status === "success") {
            response = response.data;
            props.setStrIsValidCustomer(true);
            props.setStrCustomerDetailsData(response["data"][0]);
            if (response.data[0].isCustExisting) {
              props.setHomeDelivery(response["data"][0]["allowHomDel"]);
            } else {
              props.setHomeDelivery("N");
            }
          } else {
            props.setStrIsValidCustomer(false);
            props.setStrCustomerDetailsData([]);
            props.setHomeDelivery("N");
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        } else {
          console.log(
            "Bills on Hold : Customer Details",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("OnHoldLayout.getCustomerDetails : ", err);
    }
  };

  const getItemsDetails = (payload) => {
    try {
      axios.post(address + "postDsItemSubmit/", payload).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.status === "success") {
            if (response.data.message === "FINAL") {
              response = response.data;
              props.setAvailableDeliverySlot(
                response["data"][0]["hdAvailSlots"]
              );
              props.setSelectedDeliverySlot(response["data"][0]["slot"]);
              props.setCardTransactionStatus(
                response["data"][0]["statusCheck"]
              );
              props.setTranType(response["data"][0]["sub_tran_type"]);
              props.setCancelAllowed(response["data"][0]["isEdcCancelAllowed"]);
              props.setStrItemsBasketId(response["data"][0]["basket_id"]);
              props.setStrItemsCartValue(response["data"][0]["totalCart"]);
              props.setStrItemsTotalDiscount(
                response["data"][0]["totalDiscount"]
              );
              props.setStrItemsFinalCartValue(response["data"][0]["finalCart"]);
              props.setStrTotalFoodValue(response["data"][0]["totalFoodValue"]);
              props.setStrTotalNonFoodValue(
                response["data"][0]["totalNonFoodValue"]
              );
              props.setStrItemsBillBalanceAmount(
                response["data"][0]["balanceAmt"]
              );
              props.setStrItemsListData(response["data"][0]["items"]);
              props.setRecommendOffer(response["data"][0]["recomOffers"]);
              props.setSlabOfferDesc(
                response["data"][0]["slab_offer_description"]
              );
              props.setCouponCnt(response["data"][0]["eligCouponsCnt"]);
              if (
                response["data"][0]["tendAmt"] > 0 ||
                response["data"][0]["statusCheck"]
              ) {
                props.setStrNewSaleCurrentStatus("newSalePayment");
                props.setStrItemsListTend(response["data"][0]["tend"]);
              }
            }
          } else {
            toast.error(response.data.message, {
              autoClose: 1000,
            });
          }
        } else {
          console.log(
            "OnHoldLayout.getItemsDetails",
            response.status,
            response.statusText
          );
        }
      });
    } catch (err) {
      console.log("OnHoldLayout.getItemsDetails : ", err);
    }
  };

  const proceedBasket = (basketId, mobileNumber) => {
    btnTransactionCompleteClick();
    var payload;
    payload = {
      event: "basket_getdata",
      basket_id: basketId,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    getItemsDetails(payload);
    props.setStrCurrModule("newsale");
    var payload = {
      searchText: mobileNumber,
      loc: loc,
      pos_id: pos_id_mpos,
    };
    getCustomerDetails(payload);
  };

  const handleClear = () => {
    setFilterData("");
    getHoldList({
      searchText: "",
      loc: loc,
      pos_id: pos_id_mpos,
    });
    document.getElementById("searchText").value = "";
    document.getElementById("searchText").focus();
  };

  const getHoldList = (payload) => {
    try {
      axios.post(address + "getDsBillHoldlist/", payload).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.status != "failure") {
            setBasketList(response.data.data);
          } else {
            toast.error(response.data.message, {
              toastId: "billsOnHold-tooltip",
              autoClose: 1000,
            });
            setBasketList([]);
          }
        }
      });
    } catch (err) {
      console.log("OnHoldLayout.getItemsDetails : ", err);
    }
  };

  const getBasketList = () => {
    getHoldList({
      searchText: "",
      loc: loc,
      pos_id: pos_id_mpos,
    });
  };

  const btnTransactionCompleteClick = () => {
    props.setStrIsValidCustomer(false);
    props.setHomeDelivery("N");
    props.setStrCustomerDetailsData([]);
    props.setStrNewSaleCurrentStatus("newSaleBilling");
    props.setStrItemsListData([]);
    props.setRecommendOffer([]);
    props.setSlabOfferDesc("");
    props.setCouponCnt(0);
    props.setCancelAllowed("N");
    props.setStrItemsBasketId("");
    props.setCardTransactionStatus(false);
    props.setTranType("OFFLIN");
    props.setSelectedDeliverySlot("");
    props.setAvailableDeliverySlot([]);
    props.setStrItemsBillNo("");
    props.setStrItemsCouponNo("");
    props.setStrItemsCartValue("0");
    props.setStrItemsTotalDiscount("0");
    props.setStrItemsFinalCartValue("0");
    props.setStrItemsListTend([]);
  };
  return (
    <>
      <div className="row trans-tab onHold">
        <div className="col-md-6">
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col-12">
              <form
                method="POST"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="input-group">
                  <input
                    ref={searchTextRef}
                    type="text"
                    name="searchText"
                    id="searchText"
                    className="form-control"
                    onChange={handleChange}
                    onInput={handleChange}
                    onFocus={handleChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    defaultValue={filterData || ""}
                    placeholder={"Customer Number / ID"}
                    autoFocus={true}
                  />
                  <button
                    name="search"
                    id="search"
                    className="btn btn-outline-primary"
                    onClick={() => handleSubmit()}
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row trans-tab onHold">
        <div className="col-md-12">
          <div className="row" style={{ paddingTop: "15px" }}>
            <div
              className="col-md-12"
              style={{
                height: 72 + "vh",
                backgroundColor: "white",
                overflow: "auto",
                border: "solid 0px #000000",
              }}
            >
              <DataGridOnHold data={basketOnHold} handleClick={proceedBasket} />
            </div>
          </div>
        </div>
        {/* <div className="col-md-4"> */}
        {/* <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-12">
              <KeyboardLayout
                ref={searchTextRef}
                fnCallBack={processSearchText}
              />
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    strCustomerDetails: reduxState?.customer?.customerDetailsData,
    strNewSaleItemsBasketId: reduxState?.newSale?.itemsbasketId,
    strNewSaleItemsList: reduxState?.newSale?.itemsListData,
    strNewSaleCurrentStatus: reduxState?.newSale?.newSaleCurrentStatus,
    strCurrModule: reduxState?.common?.currModule,
    strTranType: reduxState?.newSale?.tranType,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStrIsValidCustomer: (isValidCustomer) =>
      dispatch(customerActions.setIsValidCustomer(isValidCustomer)),
    setStrCustomerDetailsData: (customerData) =>
      dispatch(customerActions.setCustomerDetailsData(customerData)),
    setStrCurrModule: (currModule) =>
      dispatch(commonActions.setCurrModule(currModule)),
    setStrItemsBasketId: (itemsbasketId) =>
      dispatch(newSaleActions.setItemsBasketId(itemsbasketId)),
    setStrItemsCartValue: (cartValue) =>
      dispatch(newSaleActions.setItemsCartValue(cartValue)),
    setStrItemsTotalDiscount: (totalDiscount) =>
      dispatch(newSaleActions.setItemsTotalDiscount(totalDiscount)),
    setStrItemsFinalCartValue: (finalCartValue) =>
      dispatch(newSaleActions.setItemsFinalCartValue(finalCartValue)),
    setStrTotalFoodValue: (foodValue) =>
      dispatch(newSaleActions.setTotalFoodValue(foodValue)),
    setStrTotalNonFoodValue: (nonFoodValue) =>
      dispatch(newSaleActions.setTotalNonFoodValue(nonFoodValue)),
    setStrItemsBillBalanceAmount: (balanceAmount) =>
      dispatch(newSaleActions.setItemsBillBalanceAmount(balanceAmount)),
    setStrItemsListData: (itemsList) =>
      dispatch(newSaleActions.setItemsListData(itemsList)),
    setStrNewSaleCurrentStatus: (newSaleCurrStatus) =>
      dispatch(newSaleActions.setNewSaleCurrentStatus(newSaleCurrStatus)),
    setStrItemsCouponNo: (couponNo) =>
      dispatch(newSaleActions.setItemsCoupon(couponNo)),
    setStrItemsBillNo: (billNo) =>
      dispatch(newSaleActions.setItemsBillNo(billNo)),
    setStrItemsListTend: (tendList) =>
      dispatch(newSaleActions.setItemsListTend(tendList)),
    setCancelAllowed: (flag) => dispatch(newSaleActions.setCancelAllowed(flag)),
    setRecommendOffer: (offerList) =>
      dispatch(newSaleActions.setRecommendOffer(offerList)),
    setCouponCnt: (offerCnt) => dispatch(newSaleActions.setCouponCnt(offerCnt)),
    setSlabOfferDesc: (offerDesc) =>
      dispatch(newSaleActions.setSlabOfferDesc(offerDesc)),
    setHomeDelivery: (flag) => dispatch(customerActions.setHomeDelivery(flag)),
    setTranType: (flag) => dispatch(newSaleActions.setTranType(flag)),
    setSelectedDeliverySlot: (flag) =>
      dispatch(newSaleActions.setSelectedDeliverySlot(flag)),
    setAvailableDeliverySlot: (slotList) =>
      dispatch(newSaleActions.setAvailableDeliverySlot(slotList)),
    setCardTransactionStatus: (flag) =>
      dispatch(newSaleActions.setCardTransactionStatus(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnHoldLayout);
