import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { loginActions } from "../components/store/login";
import appConfig from "../appconfig.json";
import BackgroundImage from "../assets/backgroundImg.jpg";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const Login = (props) => {
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loginstatus, setLoginStatus] = useState("");
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [rebootReq, setRebootReq] = useState(false);
  const [isLogOutInProgress, setIsLogOutInProgress] = useState(false);
  const [showLogoutLoader, setShowLogoutLoader] = useState(false);
  const [logoutLoaderMessage, setLogoutLoaderMessage] = useState("Loading...");
  let browserName = "chrome";

  async function login() {
    document.documentElement.requestFullscreen();

    if (window.navigator.userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (window.navigator.userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (window.navigator.userAgent.match(/edg/i)) {
      browserName = "edge";
    }

    if (browserName !== "chrome") {
      toast.error("Use GOOGLE CHROME for Darksaber Login", {
        autoClose: 3000,
      });
      return;
    }
    try {
      setIsLoginInProgress(true);
      setLoginStatus("Logging In. Please wait");
      let item = { userid, password, feversion: appConfig.app_version };
      let result = await fetch(appConfig.api_url + "getDsLoginAuth/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });
      setIsLoginInProgress(false);
      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          let checkingSessionLogs = postSessionLog();
          // checkingSessionLogs.then((response) => {
          // if (response) {
          props.setUserDetailsData(result["data"][0]);

          if (result["data"][0].reboot_required === "Y") {
            setRebootReq(true);
            return;
          }
          setLoginStatus("Login Success");
          functionCashierMetrics(result.data[0].empCode);
          // }
          // });
        } else {
          setLoginStatus(result["message"]);
        }
      } else {
        console.log("Login-login-api", result.status, result.statusText);
      }
    } catch (err) {
      console.log("Login-login", err);
    }
  }

  const functionCashierMetrics = async (cashierId) => {
    try {
      const { data } = await axios.get(
        appConfig.api_url + `getCashierMetrics/${cashierId}`
      );
      if (data.status === "success") {
        props.setCashierMetricsData(data.data[0]);
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
      }
      props.setIsAuthenticated(true);
      props.status(true);
    } catch (error) {
      console.log("Login-getCashierMetrics", error);
    }
  };

  function validateForm(event) {
    event.preventDefault();
    try {
      setLoginStatus("");
      const username = document.getElementById("username").value;
      const password = document.getElementById("password").value;
      if (!username) {
        setLoginStatus("Username cannot be blank");
      } else if (!password) {
        setLoginStatus("Password cannot be blank");
      } else {
        login();
      }
    } catch (err) {
      console.log("Login-validateForm", err);
    }
  }

  const rebootSystem = async () => {
    try {
      let response = await fetch(appConfig.api_url + "getDsSystemReboot/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(""),
      });
      setIsLogOutInProgress(false);
      response = await response.json();
      if (response.status >= 200 && response.status <= 299) {
        if (response.status === "success") {
        }
      }
    } catch (e) {
      console.log("Error in rebootSystemFunction", e);
    }
  };

  const logout = async () => {
    try {
      setIsLogOutInProgress(true);
      setShowLogoutLoader(true);
      setLogoutLoaderMessage("Logging Out. Please Wait");
      let payload = {
        userid: props.userDetails["loginID"],
        session_id: props.userDetails["aurSessionId"],
        pos_id: props.userDetails["pos_id"],
      };

      sessionStorage.clear();
      localStorage.clear();
      let response = await fetch(appConfig.api_url + "getDsLogout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.status >= 200 && response.status <= 299) {
        response = await response.json();
        if (response["status"] === "success") {
          setLogoutLoaderMessage("Reboot will happen soon, Please Wait !!!");
          setTimeout(() => {
            setIsLogOutInProgress(false);
            rebootSystem();
          }, 5000);
        }
      } else {
        toast.error("Error During Logout, Please Try Again");
        console.log(
          "header-logout-api :",
          response.status,
          response.statusText
        );
      }
    } catch (err) {
      console.log("header-logout", err);
    }
  };

  const postSessionLog = async () => {
    try {
      let response = await fetch(appConfig.api_url + "postSessionLog/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      response = await response.json();
      if (response.status === "failure") {
        toast.error(response.message, {
          autoClose: 1500,
        });
        return false;
      } else {
        if (browserName === "chrome") {
          return true;
        } else {
          toast.error(
            "You cannot login from a different Browser use GOOGLE CHROME",
            {
              autoClose: 3000,
            }
          );
          return false;
        }
      }
    } catch (err) {
      console.log("Login-postSessionLog", err);
    }
  };

  const [locVal, setLocVal] = useState("1904_01");
  const disaptch = useDispatch();
  return (
    <>
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {showLogoutLoader ? (
        <div className="overlay d-flex align-items-center justify-content-center">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
          <div style={{ color: "#ffffff", fontSize: "12px" }}>
            {logoutLoaderMessage}
          </div>
        </div>
      ) : (
        ""
      )}
      {rebootReq ? (
        <Modal show={rebootReq} className="modal-trans" centered>
          <Modal.Body>
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title w-100">
                    Update Available ! Press Confirm to Reboot
                  </h5>
                </div>
                <div className="modal-footer justify-content-center">
                  <>
                    <button
                      type="button"
                      autoFocus={true}
                      className="btn btn-danger"
                      onClick={() => {
                        logout();
                        setRebootReq(!rebootReq);
                      }}
                    >
                      Confirm
                    </button>
                  </>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      <div className="loginPage">
        <img
          src={BackgroundImage}
          alt="Background_Image"
          className="backgroundImage"
        />
        <div>
          <div className="login container col-xl-10 col-xxl-8 px-4 py-5 ">
            <div className="row align-items-center g-lg-5 py-5">
              <div className="col-lg-7 text-center text-lg-start">
                <p className="col-lg-10 fs-4"></p>
              </div>
              <div className="col-md-10 mx-auto col-lg-5">
                <form
                  className="p-4 p-md-5 border rounded-3 bg-light"
                  onSubmit={validateForm}
                >
                  <div className="form-floating text-center mb-3">
                    <img
                      className="mx-auto"
                      src={require("../content/images/logo.png")}
                      alt="More Retail Private Limited"
                    />
                  </div>
                  <div>
                    <select
                      value={locVal}
                      defaultValue={locVal}
                      onChange={(e) => {
                        setLocVal(e.target.value);
                        props.setLocValue(e.target.value);
                      }}
                    >
                      <option value="1904_01">1904_01</option>
                      <option value="3089_11">3089_11</option>
                    </select>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      autoComplete="false"
                      className="form-control"
                      placeholder="Username"
                      onChange={(e) => setUserId(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Username</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className="checkbox mb-3">
                    <label>
                      <small
                        className={
                          isLoginInProgress
                            ? "text-muted blink_me"
                            : "text-muted"
                        }
                      >
                        {loginstatus}
                      </small>
                    </label>
                  </div>
                  <button
                    className={
                      "w-100 btn btn-lg btn-primary " +
                      (isLoginInProgress ? "disabled" : "")
                    }
                    disabled={isLoginInProgress}
                    type="submit"
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (reduxState, oProps) => {
  return {
    isAuthenticated: reduxState?.login?.isAuthenticated,
    userDetails: reduxState?.login?.userDetailsData,
    strIsUpdateProgress: reduxState?.login?.isUpdate,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setIsAuthenticated: (isAuthenticated) =>
      dispatch(loginActions.setIsAuthenticated(isAuthenticated)),
    setUserDetailsData: (userData) =>
      dispatch(loginActions.setUserDetailsData(userData)),
    setIsUpdate: (isUpdateProgress) =>
      dispatch(loginActions.setIsUpdate(isUpdateProgress)),
    setCashierMetricsData: (cashierMetricsData) =>
      dispatch(loginActions.setCashierMetricsData(cashierMetricsData)),
    setLocValue: (locValue) => dispatch(loginActions.setLocValue(locValue)),
  };
};

// export default Login
export default connect(mapStateToProps, mapDispatchToProps)(Login);
