import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../../appconfig.json";
import { toast } from "react-toastify";
import { moreRewardsActions } from "../../../components/store/MoreRewards/MoreRewardsStore";

const CustomerCheckForWalletTopUp = ({
  showCustomerName,
  extraFunctionCall = "",
  handleCustomerOtpStatus = "",
  setShowCustomerOTPState = "",
  reset = "",
  showCustomerOTPState,
  mobileNumber,
  setmobileNumber,
}) => {
  const strCustomerDetails = useSelector(
    (state) => state.moreRewards.walletTopUp.customerDetailsData
  );
  const otpStatusForWalletTopUp = useSelector(
    (state) => state.moreRewards.walletTopUp.otpStatus
  );
  const sucessMesage = useSelector(
    (state) => state.moreRewards.walletTopUp.rechargeSuccess
  );
  const resetEnable = useSelector(
    (state) => state.moreRewards.walletTopUp.resetEnable
  );

  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const address = appConfig.api_url;
  const [showCustomerState, setShowCustomerState] = useState(false);
  function frmCustomerSubmit(event) {
    event.preventDefault();
  }
  useEffect(() => {
    if (!showCustomerState) {
      setShowCustomerState(false);
    }
  }, [showCustomerState]);

  function validateSubmitCustomer() {
    setSubmitLoader(true);
    const frmCustId = document.getElementById(
      "walletTopUp.searchCustomer"
    ).value;
    if (frmCustId !== "" && frmCustId.length < 10) {
      toast.error("Enter the valid Customer Mobile Number", {
        autoClose: 1000,
      });
      setSubmitLoader(false);
    } else {
      getCustomerDetails();
    }
  }
  async function getCustomerDetails() {
    try {
      var custid = document.getElementById("walletTopUp.searchCustomer").value;
      dispatch(moreRewardsActions.setCustomerNumberWalletTopUp(custid));

      let item = {
        searchText: custid,
        basket_id: "",
      };
      // handleCustomerOtpStatus();

      let result = await fetch(address + "getDsCustomer/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          if (result.data[0].isCustExisting === false) {
            setShowCustomerOTPState(false);
            setSubmitLoader(false);
            toast.error("Customer Not Yet Registered");
            return;
          }
          dispatch(moreRewardsActions.setIsValidCustomerWalletTopUp(true));
          dispatch(
            moreRewardsActions.setCustomerDetailsDataWalletTopUp(
              result["data"][0]
            )
          );
          setShowCustomerOTPState(true);
          setSubmitLoader(false);
          //Check this
          extraFunctionCall();
          getBasketIDForWalletTopUp();
        } else {
          setShowCustomerOTPState(false);
          setSubmitLoader(false);
          dispatch(moreRewardsActions.setIsValidCustomerWalletTopUp(false));
          dispatch(moreRewardsActions.setCustomerDetailsDataWalletTopUp([]));
          toast.error(result["message"], {
            autoClose: 1000,
          });
        }
      } else {
        setShowCustomerOTPState(false);
        setSubmitLoader(false);

        console.log(
          "CustomerWalletBalanceCheck-getDsCustomer-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("CustomerWalletBalanceCheck-getDsCustomer :", err);
    }
  }
  const cashier_id = useSelector(
    (state) => state.login?.userDetailsData["empCode"]
  );
  const basketId = useSelector(
    (state) => state.moreRewards?.walletTopUp?.basketID
  );

  const getBasketIDForWalletTopUp = async () => {
    try {
      let payload = {
        event: "wallet_confirm",
        cust_id: strCustomerDetails.cust_id,
        customer_number: document.getElementById("walletTopUp.searchCustomer")
          .value,
        cashier_id: cashier_id,
        basket_id: "",
        sub_tran_type: "WALLET",
      };
      let result = await fetch(address + "postDsWalletRecharge/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        if (result["status"] === "success") {
          dispatch(
            moreRewardsActions.setBasketIDWalletTopUp(result.data[0].basket_id)
          );
        }
      } else {
        console.log(
          "CustomerWalletBalanceCheck-postDsWalletRecharge-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("CustomerWalletBalanceCheck-postDsWalletRecharge :", err);
    }
  };

  const handleReset = () => {
    setmobileNumber("");
    setSubmitLoader(false);
    dispatch(moreRewardsActions.setRewardScreen(""));
    dispatch(
      moreRewardsActions.setWalletTopUpReset({
        isValidCustomer: false,
        customerDetailsData: [],
        customerNumber: "",
        walletBalance: [],
        differentRechargeValueData: [],
        cashAmount: "",
        totalCartValue: "",
        rechargeData: [],
        otpStatus: false,
        basketID: "",
        walletRechargeData: [],
        rechargeSuccess: "",
        numberOfTopUpButtons: [],
        inputFieldEnable: true,
        resetEnable: false,
        cashbacks: [],
      })
    );
    document.getElementById("walletTopUp.searchCustomer").value = "";
  };
  return (
    <div
      style={{
        marginLeft: "-0.6rem",
      }}
    >
      <div className="row search-tab">
        <div className="row">
          <div
            className="col-md-5"
            style={{
              paddingRight: "0px",
            }}
          >
            <form onSubmit={frmCustomerSubmit}>
              <div className="input-group">
                <input
                  id="walletTopUp.searchCustomer"
                  name="walletTopUp.searchCustomer"
                  style={{
                    borderRadius: "0px",
                  }}
                  type="number"
                  maxLength={10}
                  className="form-control"
                  placeholder="Customer Number"
                  onWheel={(e) => {
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    const check = e.target.value;
                    if (check.length === 11) {
                      return;
                    }
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setmobileNumber(e.target.value);
                    }
                  }}
                  value={mobileNumber || strCustomerDetails["customer_number"]}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (mobileNumber.length !== 10) {
                        toast.error("Enter a Valid Mobile Number", {
                          autoClose: 1500,
                        });
                        return;
                      }
                      if (!submitLoader) {
                        validateSubmitCustomer();
                      }
                    }
                  }}
                  autoComplete="off"
                  disabled={strCustomerDetails["isCustExisting"]}
                />

                <button
                  className="btn btn-outline-primary"
                  type="button"
                  disabled={strCustomerDetails["isCustExisting"]}
                  onClick={() => {
                    if (mobileNumber.length !== 10) {
                      toast.error("Enter a valid Mobile Number", {
                        autoClose: 1500,
                      });
                      return;
                    }
                    validateSubmitCustomer();
                  }}
                >
                  {submitLoader ? (
                    <label
                      className="spinner-border"
                      style={{
                        width: ".8rem",
                        height: ".8rem",
                      }}
                    ></label>
                  ) : (
                    <label>Submit</label>
                  )}
                </button>
                <button
                  disabled={resetEnable}
                  className="btn btn-primary"
                  style={{
                    marginLeft: "1.1rem",
                    borderRadius: "0px",
                    width: "117px",
                  }}
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>

        {showCustomerName && (
          <div className="row">
            <div className="col-5">
              <div className="form-text">
                <b>
                  Customer :
                  <span style={{ color: "#f04e23" }} id="searchLayout.userName">
                    {strCustomerDetails["custName"]}
                  </span>
                </b>

                {otpStatusForWalletTopUp && (
                  <b style={{ marginLeft: "6rem" }}>
                    Reference No. :
                    <span
                      style={{ color: "#f04e23" }}
                      id="walletTopUp.basketId"
                    >
                      {basketId}
                    </span>
                  </b>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerCheckForWalletTopUp;
