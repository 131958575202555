import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, InputAdornment, OutlinedInput, Skeleton } from "@mui/material";
import appConfig from "../../../appconfig.json";
import { moreRewardsActions } from "../../store/MoreRewards/MoreRewardsStore";
import WalletTopUpTransaction from "./WalletTopUpTransaction";
import WalletOrderSummary from "./WalletOrderSummary";
import CustomerCheckForWalletTopUp from "./CustomerCheckForWalletTopUp";
import { toast } from "react-toastify";
import OtpForWalletTopUp from "../OTP/OtpForWalletTopUp";

const WalletTopUp = () => {
  const address = appConfig.api_url;
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setmobileNumber] = useState("");

  const cashAmount = useSelector(
    (state) => state.moreRewards.walletTopUp.cashAmount
  );
  const [inputButtonValues, setInputButtonValiues] = useState(cashAmount || "");

  useEffect(() => {
    setInputButtonValiues(cashAmount);
  }, [cashAmount]);
  const strIsValidCustomer = useSelector(
    (state) => state?.moreRewards?.walletTopUp?.isValidCustomer
  );

  const strCustomerDetails = useSelector(
    (state) => state.moreRewards?.walletTopUp?.customerDetailsData
  );

  const buttonDataForWalletTopUp = useSelector(
    (state) => state.moreRewards.walletTopUp?.differentRechargeValueData
  );
  const dispatch = useDispatch();

  const [getButtonData, setButtonData] = useState(buttonDataForWalletTopUp);

  const sucessMesage = useSelector(
    (state) => state.moreRewards.walletTopUp?.rechargeSuccess
  );
  const otpStatusForWalletTopUp = useSelector(
    (state) => state.moreRewards.walletTopUp?.otpStatus
  );
  const getNumberOfButtons = async () => {
    setLoading(true);
    const payload = {};
    try {
      let result = await fetch(address + "getDsWalletRechargePrice/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();

        const arr1 = result.data;
        arr1.sort((a, b) => {
          return a.wallet_amt - b.wallet_amt;
        });
        setButtonData(arr1);
        dispatch(
          moreRewardsActions.setDifferentRechargeValueDataWalletTopUp(arr1)
        );
        setLoading(false);
      } else {
        setLoading(false);
        console.log(
          "walletTopUpMoreRewards-getDsWalletRechargePrice-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      setLoading(false);
      console.log("walletTopUpMoreRewards-getDsWalletRechargePrice-api :", err);
    }
  };

  const [currentMonthCashBack, setCurrentMonthCashback] = useState(0);
  const basketId = useSelector(
    (state) => state.moreRewards?.walletTopUp?.basketID
  );

  const getCurrentMonthCashback = async () => {
    setCurrentMonthCashback(0);
    const payload = {
      cust_id: strCustomerDetails.cust_id,
    };
    try {
      let result = await fetch(address + "getDsCustMonthCashback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();
        // const arr1 = getUniqueListBy(result.data, "recharge_amt");
        setCurrentMonthCashback(result.data[0].currentMonthCashback);
      } else {
        console.log(
          "walletTopUpMoreRewards-getDsWalletRechargePrice-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      console.log("walletTopUpMoreRewards-getDsWalletRechargePrice-api :", err);
    }
  };
  const cashBackWalletTopUp = useSelector(
    (state) => state.moreRewards?.walletTopUp?.cashbacks
  );

  const [loadingForSummary, setLoadingForSummary] = useState(false);
  const getWalletRechargeSummary = async (
    nonCard,
    card,
    basketId,
    rechargeAmount,
    from_amt
  ) => {
    setLoadingForSummary(true);

    const payload = {
      currentMonthCashback: currentMonthCashBack,
      rechargeAmount: rechargeAmount,
      noncard: nonCard,
      card: card,
      basketid: basketId,
      from_amt: from_amt,
    };
    try {
      let result = await fetch(address + "getwalletRechargeSummary/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (result.status >= 200 && result.status <= 299) {
        result = await result.json();
      } else {
        setLoadingForSummary(false);

        console.log(
          "walletTopUpMoreRewards-getDsWalletRechargePrice-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      setLoadingForSummary(false);
      console.log("walletTopUpMoreRewards-getDsWalletRechargePrice-api :", err);
    }
  };

  const [showCustomerOTPState, setShowCustomerOTPState] = useState(false);
  const [otpStatus, setOtpStatus] = useState(otpStatusForWalletTopUp);

  const handleCustomerOtpStatus = () => {
    setShowCustomerOTPState(!showCustomerOTPState);
  };

  const handleSetOtpStatus = () => {
    dispatch(
      moreRewardsActions.setOtpStatusWalletTopUp(!otpStatusForWalletTopUp)
    );
  };
  const customerMobileNumber = useSelector(
    (state) =>
      state.moreRewards?.walletTopUp?.customerDetailsData?.customer_number
  );

  const getCashBackStatus = async (rechargeAmount) => {
    dispatch(moreRewardsActions.setInputFieldEnableWalletTopUp(true));
    const payload = {
      customer_number: customerMobileNumber,
      recharge_amount: Number(rechargeAmount),
    };
    try {
      setLoadingForSummary(true);
      let result = await fetch(address + "check_eligible_cashback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
      result = await result.json();

      if (result.status === "success") {
        dispatch(moreRewardsActions.setCashbacksWalletTopUp(result.data[0]));
        dispatch(moreRewardsActions.setRechargeDataWalletTopUp([]));
        dispatch(
          moreRewardsActions.setCashAmountForRechargeWalletTopUp(
            inputButtonValues
          )
        );
        dispatch(
          moreRewardsActions.setTotalCartValueWalletTopUp(inputButtonValues)
        );
        setLoadingForSummary(false);
        dispatch(moreRewardsActions.setInputFieldEnableWalletTopUp(false));
      } else {
        setLoadingForSummary(false);
        toast.error(result.message);
        dispatch(
          moreRewardsActions.setCashbacksWalletTopUp({
            cashBack: 0,
            maxCashBack: 0,
          })
        );
        dispatch(moreRewardsActions.setCashAmountForRechargeWalletTopUp(""));
        dispatch(moreRewardsActions.setTotalCartValueWalletTopUp(""));
        dispatch(moreRewardsActions.setInputFieldEnableWalletTopUp(true));
        console.log(
          "walletTopUpMoreRewards-check_eligible_cashback-api :",
          result.status,
          result.statusText
        );
      }
    } catch (err) {
      setLoadingForSummary(false);

      console.log("walletTopUpMoreRewards-check_eligible_cashback-api :", err);
    }
  };
  useEffect(() => {
    if (!strIsValidCustomer) {
      setInputButtonValiues("");
      setButtonData([]);
      dispatch(moreRewardsActions.setDifferentRechargeValueDataWalletTopUp([]));
    }
  }, [strIsValidCustomer]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
          marginRight: "2rem",
          marginLeft: "-0.8rem",
        }}
      >
        <CustomerCheckForWalletTopUp
          showCustomerName={true}
          extraFunctionCall={getNumberOfButtons}
          setShowCustomerOTPState={setShowCustomerOTPState}
          handleCustomerOtpStatus={handleCustomerOtpStatus}
          showBasketID=""
          showCustomerOTPState={showCustomerOTPState}
          mobileNumber={mobileNumber}
          setmobileNumber={setmobileNumber}
        />

        {/* {otpStatusForWalletTopUp && strCustomerDetails["isCustExisting"] && (
       
      )} */}

        {otpStatusForWalletTopUp && (
          <div
            style={{
              marginLeft: "-0.5rem",
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div
                className="col-md-9"
                style={
                  {
                    // width: "60%",
                  }
                }
              >
                {strCustomerDetails["isCustExisting"] && (
                  <Box className="row" gap={2} ml={1}>
                    {loading && (
                      <Box className="row" gap={2}>
                        <Skeleton
                          className="col-md-4"
                          variant="rectangular"
                          style={{
                            height: "2rem",
                            width: "10rem",
                          }}
                        />
                        <Skeleton
                          className="col-md-4"
                          variant="rectangular"
                          style={{
                            height: "2rem",
                            width: "10rem",
                          }}
                        />
                        <Skeleton
                          className="col-md-4"
                          variant="rectangular"
                          style={{
                            height: "2rem",
                            width: "10rem",
                          }}
                        />
                      </Box>
                    )}

                    {getButtonData.map((elem, index) => (
                      <button
                        disabled={
                          sucessMesage === "BILLBALANCE" ||
                          sucessMesage === "BILLSUCCESS"
                        }
                        key={index}
                        onClick={() => {
                          dispatch(
                            moreRewardsActions.setCashbacksWalletTopUp([])
                          );
                          dispatch(
                            moreRewardsActions.setRechargeDataWalletTopUp([])
                          );
                          dispatch(
                            moreRewardsActions.setCashAmountForRechargeWalletTopUp(
                              elem.wallet_amt
                            )
                          );
                          dispatch(
                            moreRewardsActions.setTotalCartValueWalletTopUp(
                              elem.wallet_amt
                            )
                          );
                          dispatch(
                            moreRewardsActions.setInputFieldEnableWalletTopUp(
                              true
                            )
                          );
                        }}
                        className="col-3 btn btn-primary"
                        style={{
                          height: "2.3rem",

                          borderRadius: "0px",
                          width: "7.3rem",
                          fontSize: "0.6rem",
                          textAlign: "center",
                        }}
                      >
                        {elem.wallet_amt}
                      </button>
                    ))}
                  </Box>
                )}
                <Box
                  className="row"
                  ml={2}
                  mb={2}
                  gap={2}
                  sx={{
                    marginLeft: "0.5rem",
                    marginTop: "0.8rem",
                  }}
                >
                  <OutlinedInput
                    sx={{
                      height: "1.7rem",
                      width: "16.6rem",
                      borderRadius: "0px",
                    }}
                    className="col-md-4"
                    type="text"
                    disabled={
                      sucessMesage === "BILLBALANCE" ||
                      sucessMesage === "BILLSUCCESS"
                    }
                    value={inputButtonValues}
                    id="outlined-adornment-amount"
                    placeholder="Enter Amount"
                    startAdornment={
                      <InputAdornment position="start">₹</InputAdornment>
                    }
                    onChange={(e) => {
                      let val = e.target.value;
                      const regex = /^[0-9\b]+$/;
                      if (Number(val) > 20000) {
                        toast.error("Amount cannot be greater than 20000", {
                          autoClose: 1500,
                        });
                        setInputButtonValiues(20000);
                        return;
                      }
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setInputButtonValiues(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (inputButtonValues === "") {
                          toast.error("Enter Amount to Proceed", {
                            autoClose: 1500,
                          });
                          return;
                        }
                        getCashBackStatus(inputButtonValues);
                      }
                    }}
                  />

                  <button
                    style={{
                      borderRadius: "0px",
                      width: "7.3rem",
                      marginLeft: "-1rem",
                      height: "1.7rem",
                      textAlign: "center",
                    }}
                    disabled={
                      sucessMesage === "BILLBALANCE" ||
                      sucessMesage === "BILLSUCCESS"
                    }
                    className="col-md-2 btn btn-outline-primary"
                    onClick={() => {
                      if (inputButtonValues === "") {
                        toast.error("Enter Amount to Proceed", {
                          autoClose: 1500,
                        });
                        return;
                      }
                      getCashBackStatus(inputButtonValues);
                    }}
                  >
                    {loadingForSummary ? (
                      <label
                        className="spinner-border"
                        style={{
                          width: ".8rem",
                          height: ".8rem",
                        }}
                      ></label>
                    ) : (
                      <label>Submit</label>
                    )}
                  </button>
                </Box>
                {otpStatusForWalletTopUp && <WalletTopUpTransaction />}
              </div>

              <div
                className="col-md-3"
                style={{
                  marginTop: "-3rem",
                }}
              >
                {otpStatusForWalletTopUp && (
                  <WalletOrderSummary
                    cashMoney={cashBackWalletTopUp?.cash}
                    cardMoney={cashBackWalletTopUp?.card}
                    loadingForSummary={loadingForSummary}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <OtpForWalletTopUp
          show={showCustomerOTPState}
          button_clicked={handleCustomerOtpStatus}
          customerNumber={customerMobileNumber || mobileNumber}
          setOtpStatusFunc={handleSetOtpStatus}
        />
      </div>
      {loadingForSummary ? (
        <div
          className=" forWallet  align-items-center justify-content-center"
          style={{ zIndex: "1035" }}
        >
          <div
            style={{
              position: "fixed",
              top: "35%",
              left: "50%",
              width: "30em",
              height: "18em",
              marginLeft: "-15em",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  placeSelf: "center",
                  marginLeft: "1.6rem",
                }}
              >
                Getting Cashback details in Progress
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default WalletTopUp;
