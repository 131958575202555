import React from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const DataGridSingleDetailsTable = ({ data }) => {
  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const columns = [
    {
      field: "item",
      headerName: "Item",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "item_desc",
      headerName: "Item Desc",
      flex: 4,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "qty",
      headerName: "Qty",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unit_price",
      headerName: "Unit Price ( ₹ )",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "discount",
      headerName: "Discount ( ₹ )",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "net_amt",
      headerName: "Net Amount ( ₹ )",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Box
      height={"61vh"}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "none",
          fontSize: "11px",
          border: "0.1px solid lightgrey",
          textAlign: "center",
          alignSelf: "center",
        },
        "& .name-column--cell": {
          color: "#2e7c67",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#333",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#fff",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          color: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "#fff",
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "#333",
          color: "#fff",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${"#141414"} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
          color: `${"#141414"} !important`,
        },
        "& .MuiToolbar-root": {
          color: "white",
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        getRowId={(row) => generateRandom()}
        pagination
        {...data}
      />
    </Box>
  );
};

export default DataGridSingleDetailsTable;
