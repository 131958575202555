import React from "react";

const SuccessPage = ({ newCustomerdetails, strBillorCustomer }) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 text-field">
              Customer Number : {newCustomerdetails.mobile_no || ""}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-12 text-center">
                  <div>
                    <i
                      className="fa fa-check-circle fa-4x ms-1"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <div>
                    <span className="title text-field">
                      {strBillorCustomer.sub_tran_type !== "AMZNOW"
                        ? `Refund added to Wallet Successfully`
                        : `${strBillorCustomer.billno} - Updated Successfully !!!`}
                    </span>
                  </div>
                  {strBillorCustomer.sub_tran_type !== "AMZNOW" ? (
                    <table className="table table-bordered table-sm newsaletable">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">Bill Number</th>
                          <th scope="col">Basket ID</th>
                          <th scope="col">Wallet Balance ( ₹ ) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{strBillorCustomer.billno}</td>
                          <td>{strBillorCustomer.basketId}</td>
                          <td>{strBillorCustomer.wallet_bal}</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
